import axios from "axios";
import { CurrentSiteIdGet } from "./userSettings";
import apiResult from "./apiResult";

export const locationChartData = async (locationId, period, offSet, includeSeconds = false) => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "get",
			url: "v2/LocationChart",
			params: {
				SiteId: siteId,
				LocationId: locationId,
				Period: period,
				OffSet: offSet,
				includeSeconds,
			},
		});

		return new apiResult(true, result.data);
	} catch (err) {
		return new apiResult(false, null);
	}
};

export const chartData = async (groupId, chartIndex, period, offSet, overlays, compareOffSet, compareLocationIds, customFromDateTime, customToDateTime, mode = "GroupChart", customCompareFromDateTime, customCompareToDateTime, includeSeconds = false) => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "get",
			url: "v3/GroupChart",
			params: {
				SiteId: siteId,
				GroupId: groupId,
				GraphIndex: chartIndex,
				Period: period,
				OffSet: offSet,
				LocationIds: overlays,
				compareOffSet,
				compareLocationIds,
				customFromDateTime,
				customToDateTime,
				mode,
				customCompareFromDateTime,
				customCompareToDateTime,
				includeSeconds,
			},
		});

		return new apiResult(true, result.data);
	} catch (err) {
		return new apiResult(false, null);
	}
};

export const multiChartData = async (groupId, graphIndex, timeSpan, startDate, period, noOfPeriods) => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "get",
			url: "v2/GroupChart/Multi",
			params: { siteId, groupId, graphIndex, timeSpan, startDate, period, noOfPeriods },
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const chartDataRaw = async (groupId, chartIndex, period, offSet, overlays, compareOffSet, compareLocationIds, customFromDateTime, customToDateTime, customCompareFromDateTime, customCompareToDateTime) => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "get",
			url: "v2/GroupChart/RawData",
			params: {
				SiteId: siteId,
				GroupId: groupId,
				GraphIndex: chartIndex,
				Period: period,
				OffSet: offSet,
				LocationIds: overlays,
				compareOffSet,
				compareLocationIds,
				customFromDateTime,
				customToDateTime,
				customCompareFromDateTime,
				customCompareToDateTime,
			},
		});

		return new apiResult(true, result.data);
	} catch (err) {
		return new apiResult(false, null);
	}
};

export const zoneChartList = async (zoneId, groupId) => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "get",
			url: "v2/ZoneCharts",
			params: { siteId, zoneId, groupId },
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const groupChartList = async (groupId) => {
	const siteId = CurrentSiteIdGet();
	groupId = (groupId || "").replace("G:", "");

	try {
		const result = await axios({
			method: "get",
			url: "v2/GroupCharts",
			params: { siteId, groupId },
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

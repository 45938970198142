import React, { useState, useEffect } from "react";
import ItemBanner from "../item/ItemBanner";
import defaultImage from "../../assets/img/defaults/default-user-banner.jpg";
import { CurrentUserDisplayName, CurrentUserCompanyName } from "../../api/auth";
import { siteGraphic } from "../../api/site";

const ProfileBanner = () => {
	const [displayName, setDisplayName] = useState("");
	const [companyName, setCompanyName] = useState("");
	const [banner, setBanner] = useState();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const LoadData = async () => {
			const base64 = await siteGraphic();
			if (base64) {
				setBanner(`data:image/png;base64,${base64}`);
			} else {
				setBanner(defaultImage);
			}

			setLoading(false);
		};

		LoadData();
	}, [companyName]);

	useEffect(() => {
		setDisplayName(CurrentUserDisplayName());
		setCompanyName(CurrentUserCompanyName());
	}, []);

	return (
		<ItemBanner>
			{loading ? (
				""
			) : (
				<>
					<ItemBanner.Header coverSrc={banner} userName={displayName} />
					<ItemBanner.Body name={displayName} headline={companyName} />
				</>
			)}
		</ItemBanner>
	);
};

export default ProfileBanner;

import React, { useState, useEffect, createRef } from "react";
import { Alert, Card, CardHeader, CardBody, Button, Row, Col } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StatisticsReportFilter from "../statistics-report/StatisticsReportFilter";
import FluctuationReportFilter from "../fluctuation-report/FluctuationReportFilter";
import MultiPeriodReportFilter from "../multi-period-report/MultiPeriodReportFilter";
import local from "../../../localization/strings";
import { emailReportSave, emailReportDelete } from "../../../api/reports";
import { logEvent } from "../../../helpers/ga";
import HardwareReportFilter from "../hardware-report/HardwareReportFilter";
import { isFeatureEnabled, features } from "../../../config";
import ChartReport from "./ChartReport";

const EditForm = ({ data, onBack }) => {
	const [isNew, setIsNew] = useState(true);
	const [reportType, setReportType] = useState(null);
	const [reportSettings, setReportSettings] = useState(null);
	const [inError, setInError] = useState(false);

	useEffect(() => {
		if (data && data.id) {
			setIsNew(false);
			setReportType(data.reportType);
			setReportSettings(JSON.parse(data.reportSettings));
		} else {
			setIsNew(true);
			setReportType(null);
			setReportSettings(null);
		}
	}, [data]);

	const filterDataChanged = (data) => {
		setInError(false);

		setReportSettings(data);
	};

	const DisplayForm = () => {
		const filterData = reportSettings || (data && data.reportSettings) || {};

		switch (reportType) {
			case "Hardware Report":
				return <HardwareReportFilter filterData={filterData} onChange={filterDataChanged} emailReports />;
			case "Statistics Report":
				return <StatisticsReportFilter filterData={filterData} onChange={filterDataChanged} emailReports />;
			case "Fluctuation Report":
				return <FluctuationReportFilter data={filterData} onChange={filterDataChanged} emailReports />;
			case "Multi-Period Report":
				return <MultiPeriodReportFilter filterData={filterData} onChange={filterDataChanged} emailReports />;
			case "Chart Report":
				if (!filterData.axisLabels) {
					filterData.axisLabels = "On";
				}
				return <ChartReport data={filterData} onChange={filterDataChanged} emailReports />;
			default:
				return null;
		}
	};

	const reportTypeChange = (e) => {
		logEvent("Email Reports", "Report Type Change", `${e.target.value}`);
		setReportType(e.target.value);
	};

	const save = async (_e, values) => {
		logEvent("Email Reports", "Save");

		if (!reportSettings) {
			setInError(true);
			return;
		}

		switch (reportType) {
			case "Hardware Report":
			case "Statistics Report":
			case "Fluctuation Report":
				//Do nothing, yet
				// - Add validation for each type
				break;
			case "Chart Report":
				//Must have a zoneId
				if (!reportSettings.zoneId) {
					setInError(true);
					return;
				}
				//Must have something to show
				if (!reportSettings.showList || reportSettings.showList.length === 0) {
					setInError(true);
					return;
				}
				break;
			case "Multi-Period Report":
				//Must have groupId
				if (!reportSettings.groupId) {
					setInError(true);
					return;
				}
				break;
			default:
				return;
		}

		await emailReportSave(isNew ? null : data.id, values.reportName, reportType, values.emailRecipients, values.frequency, reportSettings);

		onBack();
	};

	const deleteReport = async () => {
		logEvent("Email Reports", "Delete");

		await emailReportDelete(data.id);

		onBack();
	};

	let formRef = createRef();

	return (
		<>
			<Row>
				<Col>
					<Button color="primary" className="btn-sm mb-2" onClick={onBack}>
						<FontAwesomeIcon icon="arrow-alt-circle-left" className="mr-1" />
						{local.TS_Cancel}
					</Button>
				</Col>
				<Col className="text-right">
					{!isNew && (
						<Button color="danger" className="btn-sm mb-2" onClick={deleteReport}>
							<FontAwesomeIcon icon="trash" className="mr-1" />
							{local.TS_Delete}
						</Button>
					)}
				</Col>
			</Row>
			<Card className="mb-3">
				<CardHeader>
					<h5 id="schedule-email-title">{isNew ? local.TF_Schedule_New_Report : local.TF_Edit_Scheduled_Report}</h5>
				</CardHeader>
				<CardBody className="px-3 pt-0">
					<AvForm model={data} onValidSubmit={async (e, values) => await save(e, values)} ref={formRef}>
						<AvField name="reportName" label={local.TS_Name} placeholder={local.TF_Enter_name_for_report} validate={{ required: { value: true, errorMessage: local.TS_Required } }} />
						<Row>
							<Col md={6}>
								<AvField type="select" name="reportType" label={local.TF_Report_Type} validate={{ required: { value: true, errorMessage: local.TS_Required } }} onChange={reportTypeChange}>
									<option value="">{local.TF_Select_type}</option>
									{isFeatureEnabled(features.EmailReportsChartReport) && <option value="Chart Report">{local.TS_ChartReport}</option>}
									{isFeatureEnabled(features.EmailReportsFluctuation) && <option value="Fluctuation Report">{local.TS_FluctuationReport}</option>}
									{isFeatureEnabled(features.EmailReportsHardware) && <option value="Hardware Report">{local.TS_HardwareReport}</option>}
									{isFeatureEnabled(features.EmailReportsMultiPeriod) && <option value="Multi-Period Report">{local.TS_MultiPeriodReport}</option>}
									{isFeatureEnabled(features.EmailReportsStatistics) && <option value="Statistics Report">{local.TS_StatisticsReport}</option>}
								</AvField>
							</Col>
							<Col md={6}>
								<AvField type="select" name="frequency" label={local.TF_Send_email_every} validate={{ required: { value: true, errorMessage: local.TS_Required } }}>
									<option value="">{local.TF_Select_Frequency}</option>
									<option value="Day">{local.TS_Day}</option>
									<option value="Week">{local.TS_Week}</option>
									<option value="Month">{local.TS_Month}</option>
									<option value="Quarter">{local.TS_Quarter}</option>
									<option value="Six Months">{local.TS_6_Months}</option>
									<option value="Year">{local.TS_Year}</option>
								</AvField>
							</Col>
						</Row>
						<AvField name="emailRecipients" label={local.TS_Recipients} placeholder={local.TF_Enter_email_addresses} validate={{ required: { value: true, errorMessage: local.TS_Required } }} helpMessage={local.TF_Separate_email_with_semicolon} />
					</AvForm>
					{inError && <Alert color="danger">{local.TF_EmailReports_Invalid}</Alert>}
					{reportType && <DisplayForm />}
					<Row>
						<Col className="text-right">
							<Button color="primary" className="mt-3" onClick={() => formRef.current.submit()}>
								{isNew ? local.TS_Save : local.TS_Save_Changes}
							</Button>
						</Col>
					</Row>
				</CardBody>
			</Card>
		</>
	);
};

export default EditForm;

import React from 'react'
import { Link } from "react-router-dom";
import local from "../../../localization/strings";

const SearchResultNote = ({item, close}) => {
    return (
		<div className="p-2">
			<Link to="/charts/notes" className="text-nowrap" onClick={close}>
				<b>{local.TF_Search_Note}:</b> {item.entityName}
			</Link>
		</div>
	);

}

export default SearchResultNote;
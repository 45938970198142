import React from "react";
import { features, isFeatureEnabled } from "../../config";
const displayLoggerChannelNamesFeatureEnabled = isFeatureEnabled(features.DisplayLoggerChannelNames);

const WidgetTitleLevel1 = ({ reading }) => {
	// SquirrelChannelNumberDisplay can be null if calculated parameter
	return <>{displayLoggerChannelNamesFeatureEnabled ? <>{reading.squirrelChannelIdentity || reading.SquirrelChannelNumberDisplay || reading.groupName}</> : <>{reading.groupName}</>}</>;
};
const WidgetTitleLevel2 = ({ reading }) => {
	return (
		<>
			{displayLoggerChannelNamesFeatureEnabled ? (
				// squirrelSerialNumber can be null if calculated parameter
				<>{reading.squirrelName || reading.squirrelSerialNumber || `${reading.zoneName} - ${reading.buildingName}`}</>
			) : (
				<>
					{reading.zoneName} - {reading.buildingName}
				</>
			)}
		</>
	);
};

export { WidgetTitleLevel1, WidgetTitleLevel2 };

import React from 'react'
import { Link } from "react-router-dom";
import local from "../../../localization/strings";

const SearchResultAlarmTeam = ({item, close}) => {
	return (
		<div className="p-2">
			<Link to="/site_settings/alarm_setup" className="text-nowrap" onClick={close}>
				<b>{local.TF_Search_AlarmTeam}:</b> {item.entityName}
			</Link>
		</div>
	);
}

export default SearchResultAlarmTeam;
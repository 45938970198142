import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Label } from "reactstrap";
import local from "../../../localization/strings";
import Datetime from "react-datetime";
import "moment/min/locales";
import moment, { isMoment } from "moment";

const CustomDateTime = ({ onStartChanged, onEndChanged, start, end }) => {
	const [startTime, setStartTime] = useState(start);
	const [endTime, setEndTime] = useState(end);
	const [language, setLanguage] = useState("en");

	useEffect(() => {
		setStartTime(start);
		setEndTime(end);
	}, [start, end]);

	useEffect(() => {
		setLanguage(local.getLanguage());
	}, []);

	const startChanged = (e, reset = false) => {
		try {
			if (isMoment(e)) {
				setStartTime(e);
				onStartChanged(e.toDate());
			} else {
				if (reset) {
					setStartTime(moment());
					onStartChanged(moment().toDate());
				}
			}
		} catch {
			onStartChanged(null);
		}
	};

	const endChanged = (e, reset = false) => {
		try {
			if (isMoment(e)) {
				setEndTime(e);
				onEndChanged(e.toDate());
			} else {
				if (reset) {
					setEndTime(moment());
					onEndChanged(moment().toDate());
				}
			}
		} catch {
			onStartChanged(null);
		}
	};

	return (
		<Fragment>
			<Row className="mt-2">
				<Col md={6}>
					<Label>{local.TS_Start_Date_Time}</Label>
					<Datetime locale={language} dateFormat="DD MMM YYYY" timeFormat="HH:mm" value={startTime} onBlur={(e) => startChanged(e, true)} onChange={startChanged} showTimeSelect timeConstraints={{ minutes: { step: 15 } }} />
				</Col>
				<Col md={6}>
					<Label>{local.TS_End_Date_Time}</Label>
					<Datetime locale={language} dateFormat="DD MMM YYYY" timeFormat="HH:mm" value={endTime} onBlur={(e) => endChanged(e, true)} onChange={endChanged} showTimeSelect timeConstraints={{ minutes: { step: 15 } }} />
				</Col>
			</Row>
		</Fragment>
	);
};

export default CustomDateTime;

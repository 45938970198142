import ReactGA from "react-ga";
import { CurrentSiteIdGet } from '../api/userSettings';

export const logEvent = (category, action, label) => {
	ReactGA_setSiteId();
	ReactGA.event({ category, action, label });
};

export const ReactGA_setSiteId = () => {
	const siteId = CurrentSiteIdGet();

	ReactGA.set({ dimension1: `${siteId}` });
}
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row } from "reactstrap";
import { logEvent } from "../../../helpers/ga";
import local from "../../../localization/strings";
import SiteTreeSingleSelect from "../../common/SiteTreeSingleSelect";
import CustomDateTime from "./CustomDateTime";

const FluctuationReportFilter = ({ triggerGenerate, data, onChange, emailReports }) => {
	const lengthOf = (arr, fallback) => {
		if (arr) {
			return arr.length;
		}
		return fallback;
	};

	const [locationId, setLocationId] = useState(null);
	const [startTime, setStartTime] = useState(new Date());
	const [endTime, setEndTime] = useState(new Date());
	const [period, setPeriod] = useState("hour");
	const [timePeriod, setTimePeriod] = useState("Day");
	const [noOfPeriod, setNoOfPeriod] = useState(1);
	const [bandCount, setBandCount] = useState(3);
	const [bands, setBands] = useState([0, 10, 20]);
	const [generateDisabled, setGenerateDisabled] = useState(true);
	const initialLocationId = data.locationId || null;

	useEffect(() => {
		setLocationId(data.locationId || null);
		setTimePeriod(data.timePeriod || "Day");
		setStartTime(data.startTime ? new Date(data.startTime) : new Date());
		setEndTime(data.endTime ? new Date(data.endTime) : new Date());
		setPeriod(data.period || "hour");
		setNoOfPeriod(data.noOfPeriod || 1);
		setBandCount(lengthOf(data.bands, 3));
		setBands(data.bands || [0, 10, 20]);
	}, [data]);

	useEffect(() => {
		if (locationId === null) {
			setGenerateDisabled(true);
			return;
		}

		setGenerateDisabled(false);
	}, [locationId, startTime, endTime]);

	const getBand = (index) => {
		if (bandCount > index) {
			return bands[index];
		}
		return "";
	};

	const blurBand = (index) => {
		const bandValue = getBand(index);
		const bandValueFloat = parseFloat(bandValue);

		if (isNaN(bandValueFloat)) {
			logEvent("Fluctuation Report", "Filter Changed", `Update Band ${index}: 0`);

			setBands([...bands.slice(0, index), 0, ...bands.slice(index + 1)]);

			if (onChange) {
				onChange({ locationId, startTime, endTime, noOfPeriod, period, bands: [...bands.slice(0, index), 0, ...bands.slice(index + 1)], timePeriod });
			}
		} else {
			logEvent("Fluctuation Report", "Filter Changed", `Update Band ${index}: ${bandValueFloat}`);

			setBands([...bands.slice(0, index), bandValueFloat, ...bands.slice(index + 1)]);

			if (onChange) {
				onChange({ locationId, startTime, endTime, noOfPeriod, period, bands: [...bands.slice(0, index), bandValueFloat, ...bands.slice(index + 1)], timePeriod });
			}
		}
	};

	const updateBand = (e, index) => {
		setBands([...bands.slice(0, index), e.target.value, ...bands.slice(index + 1)]);
	};

	const addBand = () => {
		logEvent("Fluctuation Report", "Filter Changed", "Add Band");

		setBandCount(bandCount + 1);
	};

	const removeBand = (index) => {
		logEvent("Fluctuation Report", "Filter Changed", `Remove Band ${index}`);

		setBandCount(bandCount - 1);

		let temp = bands.concat();
		temp.splice(index, 1);
		setBands(temp);

		if (onChange) {
			onChange({ locationId, startTime, endTime, noOfPeriod, period, bands: temp, timePeriod });
		}
	};

	const clicked = (e) => {
		setLocationId(e);

		logEvent("Fluctuation Report", "Filter Changed", `Location Id: ${e}`);

		if (onChange) {
			onChange({ locationId: e, startTime, endTime, noOfPeriod, period, bands, timePeriod });
		}
	};

	const periodChange = (e) => {
		setPeriod(e.target.value);

		logEvent("Fluctuation Report", "Filter Changed", `Period: ${e.target.value}`);

		if (onChange) {
			onChange({ locationId, startTime, endTime, noOfPeriod, period: e.target.value, bands, timePeriod });
		}
	};

	const noOfPeriodChange = (e) => {
		setNoOfPeriod(parseInt(e.target.value));

		logEvent("Fluctuation Report", "Filter Changed", `No Of Period: ${e.target.value}`);

		if (onChange) {
			onChange({ locationId, startTime, endTime, noOfPeriod: e.target.value, period, bands, timePeriod });
		}
	};

	const startTimeChanged = (e) => {
		setStartTime(e);

		logEvent("Fluctuation Report", "Filter Changed", `Start Time: ${e}`);

		if (onChange) {
			onChange({ locationId, startTime: e, endTime, noOfPeriod, period, bands, timePeriod });
		}
	};

	const endTimeChanged = (e) => {
		setEndTime(e);

		logEvent("Fluctuation Report", "Filter Changed", `End Time: ${e}`);

		if (onChange) {
			onChange({ locationId, startTime, endTime: e, noOfPeriod, period, bands, timePeriod });
		}
	};

	const timePeriodChange = (e) => {
		setTimePeriod(e.target.value);

		logEvent("Fluctuation Report", "Filter Changed", `Time Period: ${e.target.value}`);

		if (onChange) {
			onChange({ locationId, startTime, endTime, noOfPeriod, period, bands, timePeriod: e.target.value });
		}
	};

	const generate = () => {
		logEvent("Fluctuation Report", "Generate Click");

		triggerGenerate({ locationId, startTime, endTime, noOfPeriod, period, bands, timePeriod });
	};

	const TimePeriodSelect = () => {
		return (
			<>
				<Input type="select" className="form-control" value={timePeriod} onChange={timePeriodChange}>
					<option value="Day">{local.TF_LastDay}</option>
					<option value="Week">{local.TF_LastWeek}</option>
					<option value="Month">{local.TF_LastMonth}</option>
					<option value="Year">{local.TF_LastYear}</option>
					{!emailReports && <option value="Custom">{local.TF_Custom}</option>}
				</Input>
				{timePeriod === "Custom" ? <CustomDateTime onStartChanged={startTimeChanged} onEndChanged={endTimeChanged} start={startTime} end={endTime} /> : null}
			</>
		);
	};

	return (
		<>
			<Form>
				<h5 className="mb-2">{local.TF_Area_to_report}</h5>
				<div className="border rounded-soft" style={{ height: 250, overflowY: "auto", overflowX: "hidden" }}>
					<SiteTreeSingleSelect expandToLevel={0} siteMapLevel={4} inline={true} onClick={clicked} initialChecked={initialLocationId || ""} />
				</div>
				<div className="mb-3 d-sm-none" />
				<Row>
					<Col sm={6}>
						<h5 className="mb-2 mt-3">{local.TF_Time_period}</h5>
						<TimePeriodSelect />

						<h5 className="mb-2 mt-3">{local.TF_Fluctation_Time_Period}:</h5>
						<div className="form-inline">
							<Input type="select" className="form-control" value={noOfPeriod} onChange={noOfPeriodChange}>
								<option value="1">1</option>
								<option value="2">2</option>
								<option value="3">3</option>
								<option value="4">4</option>
								<option value="5">5</option>
							</Input>
							<Input type="select" className="form-control ml-2" value={period} onChange={periodChange}>
								<option value="hour">{local.TS_Hour_s}</option>
								<option value="day">{local.TS_Day_s}</option>
								<option value="week">{local.TS_Week_s}</option>
								<option value="month">{local.TS_Month_s}</option>
								<option value="year">{local.TS_Year_s}</option>
							</Input>
						</div>
					</Col>
					<Col sm={6}>
						<h5 className="mb-2 mt-3">
							{local.TF_Bands}:{" "}
							<span className="float-right">
								<Button color="primary" onClick={addBand} className="btn-sm">
									+
								</Button>
							</span>
						</h5>
						{[...Array(bandCount)].map((_, i) => (
							<div key={i} className="input-group mb-1">
								<Input type="number" value={getBand(i)} onChange={(e) => updateBand(e, i)} onBlur={() => blurBand(i)} />
								<div className="input-group-append">
									<Button color="danger" onClick={() => removeBand(i)} className="btn-sm">
										X
									</Button>
								</div>
							</div>
						))}
					</Col>
				</Row>
				{triggerGenerate && (
					<Row className="border-top pt-3 mt-3">
						<Col>
							<Button color="primary" onClick={generate} className="col-md-3" disabled={generateDisabled}>
								{local.TS_Generate}
							</Button>
						</Col>
					</Row>
				)}
			</Form>
		</>
	);
};

export default FluctuationReportFilter;

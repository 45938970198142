import React, { useState } from "react";
import UserEdit from "./UserEdit";
import UsersList from "./UsersList";

const TabUsers = () => {
	const [userId, setUserId] = useState(undefined);
	const [userEdit, setUserEdit] = useState(false);

	const editUser = (id) => {
		setUserId(id);
		setUserEdit(true);
	};

	const addingUser = () => {
		setUserId(undefined);
		setUserEdit(true);
	};

	return <>{userEdit ? <UserEdit userId={userId} setUserId={setUserId} setUserEdit={setUserEdit} /> : <UsersList editUser={editUser} addingUser={addingUser} />}</>;
};

export default TabUsers;

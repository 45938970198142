import React, { Fragment } from 'react';
import { Col } from 'reactstrap';
import DashStats from './DashStats';
import DashStatsBlue from './DashStatsBlue';

const DashStatsContainer = () => {

    return (
        <Fragment>
            <Col className="col-12 col-lg-6">
                <DashStats string={"Current Alarms"}
                    number={"1"}
                    selectedArea={"Botany"}
                    duration={"Now"}
                    borderType={"dash-border-warning"} />
                <DashStats string={"Time Spent in Alarm"}
                    number={"4%"}
                    selectedArea={"Botany"}
                    duration={"This month"}
                    borderType={"dash-border-success"} />
                <DashStatsBlue string={"Low batteries"}
                    number={"3"}
                    selectedArea={"Site"}
                    duration={"Now"}
                    fontColour={"#d36a24"} />
            </Col>
        </Fragment>
    )
}

export default DashStatsContainer
import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Table, Button } from "reactstrap";
import local from "../../../localization/strings";
import { emailReportsList } from "../../../api/reports";

const DisplayReportList = ({ triggerModify }) => {
	const [data, setData] = useState([]);

	useEffect(() => {
		const LoadData = async () => {
			var result = await emailReportsList();

			setData(result);
		};

		LoadData();
	}, []);

	return (
		<Card>
			<CardHeader>
				<h5>{local.TF_Scheduled_Emails}</h5>
			</CardHeader>
			<CardBody className="pt-0">
				<Table className="table-hover table-sm mb-0 fs--1 rounded-soft table-striped">
					<thead className="bg-200 text-900">
						<tr>
							<th>{local.TF_Report_Name}</th>
							<th>{local.TS_Type}</th>
							<th>{local.TS_Recipients}</th>
							<th>{local.TF_Send_email_every}</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{data &&
							data.map((item, index) => (
								<tr key={index}>
									<td className="align-middle">{item.reportName}</td>
									<th className="align-middle">{item.reportType}</th>
									<td className="align-middle">{item.emailRecipients}</td>
									<td className="align-middle">{item.frequency}</td>
									<td className="text-right">
										<Button color="info" className="btn-xs" onClick={() => triggerModify(item)}>
											{local.TS_Modify}
										</Button>
									</td>
								</tr>
							))}
					</tbody>
				</Table>
			</CardBody>
		</Card>
	);
};

export default DisplayReportList;

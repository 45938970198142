import React, { useState, useEffect, useContext } from "react";
import { Col, Card, CardBody } from "reactstrap";
import { chartData } from "../../../../api/chart";
import CanvasJSReact from "../../../../lib/canvasjs.react";
import Loader from "../../../common/Loader";
import { UserPreferencesContext } from "../../../../context/userPreferences";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const DashChart = ({ locationIds, period = "Day" }) => {
	const [options, setOptions] = useState(undefined);
	const [loading, setLoading] = useState(true);
	const { siteMap } = useContext(UserPreferencesContext);

	useEffect(() => {
		let isSubscribed = true;
		async function fetchChartData() {
			var textColour = "#46505e";

			if (locationIds.length > 0) {
				const firstLocationId = locationIds.split(",")[0];
				const groupId = siteMap.find((x) => x.locationId === parseInt(firstLocationId))?.groupId || 0;

				var result = await chartData(groupId, 0, period, 0, locationIds, 0, null, null, null, "DashboardChart", null, null);

				if (isSubscribed) {
					if (result.success) {
						result.data.data.forEach((element) => {
							element.dataPoints.forEach((point) => {
								point.x = new Date(point.x);
							});
						});
						result.data.backgroundColor = "";
						result.data.title.fontColor = textColour;
						result.data.subtitles.forEach((st) => {
							st.fontColor = textColour;
						});
						if (result.data.axisX) {
							result.data.axisX.labelMaxWidth = 75;
							result.data.axisX.labelFontColor = textColour;
							result.data.axisX.labelFontSize = "12";
							result.data.axisX.gridThickness = 0.4;
							result.data.axisX.crosshair.snapToDataPoint = true;

							if (result.data.axisX.minimum) {
								result.data.axisX.minimum = new Date(result.data.axisX.minimum);
							}
							if (result.data.axisX.maximum) {
								result.data.axisX.maximum = new Date(result.data.axisX.maximum);
							}
						} else {
							result.data.axisX2.labelMaxWidth = 75;
							result.data.axisX2.labelFontColor = textColour;
							result.data.axisX2.labelFontSize = "12";
							result.data.axisX2.gridThickness = 0.4;
							result.data.axisX2.crosshair.snapToDataPoint = true;

							if (result.data.axisX2.minimum) {
								result.data.axisX2.minimum = new Date(result.data.axisX2.minimum);
							}
							if (result.data.axisX2.maximum) {
								result.data.axisX2.maximum = new Date(result.data.axisX2.maximum);
							}
						}
						if (result.data.axisY2) {
							result.data.axisY2.labelFontColor = textColour;
							result.data.axisY2.labelFontSize = "12";
							result.data.axisY2.gridThickness = 0.4;
							result.data.axisY2.crosshair.snapToDataPoint = true;
						}
						if (result.data.axisY) {
							result.data.axisY.labelFontColor = textColour;
							result.data.axisY.labelFontSize = "12";
							result.data.axisY.gridThickness = 0.4;
							result.data.axisY.crosshair.snapToDataPoint = true;
						}

						result.data.legend.fontColor = textColour;
						result.data.height = 350;

						setOptions(result.data);
						setLoading(false);
					}
				}
			}
		}

		fetchChartData();

		return () => (isSubscribed = false);
	}, [locationIds, period, siteMap]);

	return (
		<Col className="col-12">
			<Card className="rounded-soft dash-border-grey mb-2">
				{loading ? (
					<div className="ml-2">
						<Loader />
					</div>
				) : (
					<CardBody id="chart-multi">{options && <CanvasJSChart options={options} style={{ height: "350px" }} />}</CardBody>
				)}
			</Card>
		</Col>
	);
};

export default DashChart;

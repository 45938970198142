import React, { useState, useEffect } from "react";
import { Col, Card } from "reactstrap";
import DashGauge from "./DashGauge";
import { readingsLatestForLocation } from "../../../../api/readings";
import Loader from "../../../common/Loader";
import WidgetDropdownMenu from "../../../common/WidgetDropdownMenu";
import local from "../../../../localization/strings";

const DashMeterBlue = ({ locationId, latestReadings }) => {
	const [loading, setLoading] = useState(true);
	const [reading, setReading] = useState({});
	const [bgColour, setBgColour] = useState("bg-gradient");
	const [textColour, setTextColour] = useState("#fff");

	useEffect(() => {
		let isSubscribed = true;
		async function fetchMeterData() {
			const data = await readingsLatestForLocation(locationId);

			if (isSubscribed) {
				if (data.length === 0) {
					setReading(undefined);
				} else {
					setReading(...data);
				}

				setLoading(false);
			}
		}

		const latestReading = (latestReadings || []).find((x) => x.locationId === parseInt(locationId));

		if (latestReading) {
			setReading(latestReading);
			setLoading(false);
		} else {
			fetchMeterData();
		}

		if (typeof latestReading?.logging === "boolean" && !latestReading?.logging) {
			setBgColour("bg-gradient");
			setTextColour("#fff");
		} else {
			switch (latestReading?.alarmType || "") {
				case "MainLo":
				case "MainHi":
					setBgColour(latestReading?.clearedBy ? "widgetInAlarmCleared" : "widgetInAlarm");
					setTextColour("#000");
					break;
				default:
					setBgColour("bg-gradient");
					setTextColour("#fff");
					break;
			}
		}

		return () => (isSubscribed = false);
	}, [locationId, latestReadings]);

	return (
		<Col className="col-12 col-sm-6 col-lg-3 mb-2 d-flex align-items-stretch">
			<Card className={`rounded-soft ${bgColour} w-100 dash-border-adjust`}>
				{reading && <WidgetDropdownMenu color="secondary" className="white-dots" size="sm" tag="div" options={[{ name: local.TF_View_Chart, locationId: reading.locationId, groupId: reading.groupId }]} />}
				{loading ? (
					<div className="ml-2">
						<Loader />
					</div>
				) : (
					<>{reading ? <DashGauge reading={reading} textColour={textColour} /> : <div className="text-white">Failed To Load</div>}</>
				)}
			</Card>
		</Col>
	);
};

export default DashMeterBlue;

import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Card, Row, Col, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import MultiPeriodReportFilter from "./MultiPeriodReportFilter";
import MultiPeriodReportDisplay from "./MultiPeriodReportDisplay";
import local from "../../../localization/strings";
import PageTitle from "../../common/PageTitle";
import { userPreferencesGetList, userPreferenceSave } from "../../../api/userPreferences";
import { SendEmailPDF } from "../../../api/email";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logEvent } from "../../../helpers/ga";
import { isFeatureEnabled, features } from "../../../config";
import EmailModal from "../../common/EmailModal";
import * as html2canvas from "html2canvas";
import jsPDF from "jspdf";
import MultiPeriodReportDownload from "./MultiPeriodReportDownload";
import moment from "moment";
import $ from "jquery";

const buttonDefaultClasses = "btn-shadow btn-sm mb-sm-2 py-2 px-2 px-sm-3 py-sm-1";

const MultiPeriodReport = () => {
	const [showFilter, setShowFilter] = useState(true);
	const [reportRunning, setReportRunning] = useState(false);
	const [filterData, setFilterData] = useState({});
	const [showEmail, setShowEmail] = useState(false);

	const generateStats = async (data) => {
		if (!data.groupId) {
			return;
		}

		await userPreferenceSave("MultiPeriodReport_Filter", JSON.stringify(data));

		setReportRunning(true);
		setFilterData(data);
		setShowFilter(false);
	};

	const filter = () => {
		logEvent("Multi-Period Report", "Filter");

		setShowFilter(true);
	};

	useEffect(() => {
		const LoadData = async () => {
			const prefs = await userPreferencesGetList();

			var match = prefs.find((x) => x.preferenceKey === "MultiPeriodReport_Filter");

			if (match) {
				setFilterData(JSON.parse(match.preferenceValue));
			}
		};

		LoadData();
	}, []);

	const toggleEmailModal = () => {
		setShowEmail(!showEmail);
	};

	const endDate = () => {
		let date = moment(filterData.startTime);
		for (let i = 0; i < filterData.noOfTimeSpans; ++i) {
			switch (filterData.period) {
				case "Hour":
					date.add(filterData.noOfPeriod, "h");
					break;
				case "Day":
					date.add(filterData.noOfPeriod, "d");
					break;
				case "Week":
					date.add(filterData.noOfPeriod, "w");
					break;
				case "Month":
					date.add(filterData.noOfPeriod, "M");
					break;
				case "Year":
					date.add(filterData.noOfPeriod, "y");
					break;
				default:
					break;
			}
		}

		return date.format("DD MMM YYYY HH:mm");
	};

	async function genPDFAsync(action) {
		var number = $(".download-mpr").length;

		var doc = new jsPDF();
		doc.setFont("helvetica");
		doc.setFontStyle("bold");
		doc.setFontSize(16);
		doc.text(local.TS_MultiPeriodReport, 105, 8, null, null, "center");
		doc.setFontStyle("normal");
		doc.setFontSize(10);
		doc.text(`${moment(filterData.startTime).format("DD MMM YYYY HH:mm")} - ${endDate()}`, 105, 13, null, null, "center");

		let first = true;
		for (let i = 0; i < number; i++) {
			if (i % 2 === 0) {
				if (!first) {
					doc.addPage();
				}
				await generateConvasAsync(i, doc, 20);
			} else {
				await generateConvasAsync(i, doc, 150);
			}
			first = false;
		}

		switch (action) {
			case "email":
				return doc.output('dataurlstring');
			case "print":
				doc.autoPrint();
				doc.output("dataurlnewwindow");
				break;
			case "download":
			default:
				doc.save(`${local.TS_MultiPeriodReport} ${moment().format("DD MMM YYYY")}.pdf`);
				break;
		}
	}

	async function generateConvasAsync(i, doc, y) {
		await html2canvas(document.querySelector(`.download_${i}`)).then(function(canvas) {
			doc.addImage(canvas.toDataURL("image/jpeg", 1), "JPEG", 10, y);
		});
	}

	const download = async () => {
		logEvent("Multi-Period Report", "Download");

		await genPDFAsync("download");
	};

	const print = async () => {
		logEvent("Multi-Period Report", "Print");

		await genPDFAsync("print");
	};

	function SelectText(element) {
		var doc = document;
		if (doc.body.createTextRange) {
			var range = document.body.createTextRange();
			range.moveToElementText(element);
			range.select();
		} else if (window.getSelection) {
			var selection = window.getSelection();
			var selectionRange = document.createRange();
			selectionRange.selectNodeContents(element);
			selection.removeAllRanges();
			selection.addRange(selectionRange);
		}
	}

	const copy = async () => {
		logEvent("Multi-Period Report", "Copy");

		var charts = $(".react-tabs__tab-panel--selected .timespan");

		charts.each(async (i) => {
			if ($(charts[i]).height() > 50) {
				const classParts = $(charts[i])
					.attr("class")
					.split(" ");

				await html2canvas(document.querySelector(`.${classParts[classParts.length - 1]}`)).then(function(canvas) {
					var img = document.createElement("img");
					img.src = canvas.toDataURL("image/jpeg", 1);

					var div = document.createElement("div");
					div.contentEditable = true;
					div.appendChild(img);
					document.body.appendChild(div);

					// do copy
					SelectText(div);
					document.execCommand("Copy");
					document.body.removeChild(div);

					toast.success(local.TF_item_was_copied);
				});
			}
		});
	};

	const sendEmail = async (to, subject, body) => {
		logEvent("Multi-Period Report", "Send Email", `To: ${to}, Subject: ${subject}`);

		const dataUrl = await genPDFAsync("email");

		const base64Loc = dataUrl.indexOf("base64,");

		await SendEmailPDF(to, subject, body, dataUrl.substring(base64Loc + 7));

		setShowEmail(false);
	};

	return (
		<>
			<PageTitle title={local.TS_MultiPeriodReport} />
			<Row id="toolbar-row" className="justify-content-between">
				{showFilter ? (
					""
				) : (
					<>
						<Col className="col-6">
							<Button size="sm" color="secondary" className={buttonDefaultClasses} onClick={() => filter()}>
								<FontAwesomeIcon icon="filter" />
								<span className="d-none d-md-inline-block ml-2">{local.TS_Filter}</span>
							</Button>
						</Col>
						<Col className="col-6">
							<UncontrolledDropdown size="sm" direction="left" className="allow-overflow float-right">
								<DropdownToggle caret color="info" transform="shrink-3" className={buttonDefaultClasses} disabled={reportRunning}>
									<FontAwesomeIcon icon="share-alt" />
									<span className="d-none d-md-inline-block ml-2">{local.TS_Options}</span>
								</DropdownToggle>
								<DropdownMenu className="menu-border-blue dropdown-menu">
									<>
										{isFeatureEnabled(features.MultiPeriodReportDownload) && (
											<DropdownItem onClick={download}>
												<FontAwesomeIcon icon="download" /> {local.TS_Download}
											</DropdownItem>
										)}
										{isFeatureEnabled(features.MultiPeriodReportPrint) && (
											<DropdownItem onClick={print}>
												<FontAwesomeIcon icon="print" /> {local.TS_Print}
											</DropdownItem>
										)}
										{isFeatureEnabled(features.MultiPeriodReportCopy) && (
											<DropdownItem onClick={copy}>
												<FontAwesomeIcon icon="copy" /> {local.TS_Copy}
											</DropdownItem>
										)}
										{isFeatureEnabled(features.MultiPeriodReportEmail) && (
											<DropdownItem onClick={() => setShowEmail(true)}>
												<FontAwesomeIcon icon="envelope-square" /> {local.TS_Email}
											</DropdownItem>
										)}
									</>
								</DropdownMenu>
							</UncontrolledDropdown>
						</Col>
					</>
				)}
			</Row>
			<Card className="p-3">
				{showFilter ? (
					<MultiPeriodReportFilter triggerGenerate={generateStats} filterData={filterData} />
				) : (
					<>
						<MultiPeriodReportDisplay filterData={filterData} setReportRunning={setReportRunning} />
						<MultiPeriodReportDownload filterData={filterData} />
					</>
				)}
			</Card>

			<EmailModal show={showEmail} sendEmail={sendEmail} toggleEmailModal={toggleEmailModal} />
		</>
	);
};

export default MultiPeriodReport;

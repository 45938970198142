import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MaterialTable, { MTableAction } from "material-table";
import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { tableIcons } from "../../helpers/tableIcons";
import local from "../../localization/strings";
import ColourPickerButton from "../common/ColourPickerButton";

const buttonDefaultClasses = "btn-shadow btn-sm m-sm-2 px-3 py-2 px-md-2 py-md-1 button-font";

const ChartCustomThresholds = ({ onChange, customThresholds }) => {
	const [data, setData] = useState([]);
	const [errors, setErrors] = useState();

	useEffect(() => {
		setData(customThresholds);
	}, [customThresholds]);

	const columns = [
		{
			title: local.TS_Name,
			field: "name",
		},
		{
			title: local.TS_Value,
			field: "value",
			type: "numeric",
		},
		{
			title: local.TS_Axis,
			field: "axis",
			type: "numeric",
			lookup: { 0: local.TS_Left, 1: local.TS_Right },
		},
		{
			title: local.TS_Colour,
			field: "colour",
			render: (rowData) => <div style={{ backgroundColor: rowData.colour }}>&nbsp;</div>,
			editComponent: (props) => <ColourPickerButton value={props.value} onChange={(e) => props.onChange(e)} />,
		},
		{
			title: local.TF_Plotted,
			field: "plotted",
			type: "boolean",
		},
		{
			title: local.TF_LineThickness,
			field: "lineThickness",
			type: "numeric",
		},
		{
			title: local.TF_PlotStyle,
			field: "plotStyle",
			type: "numeric",
			lookup: { 0: local.TF_Solid, 1: local.TF_Dash, 2: local.TF_Dot, 3: local.TF_DashDot, 4: local.TF_DashDotDot },
		},
	];

	const fixData = (newData) => {
		if (!newData.axis) {
			newData.axis = "0";
		}

		if (!newData.colour) {
			newData.colour = "#FFFFFF";
		}

		if (!newData.lineThickness) {
			newData.lineThickness = "1";
		}

		if (!newData.plotStyle) {
			newData.plotStyle = "0";
		}

		return newData;
	};

	const validate = (newData) => {
		const newErrors = [];
		if (!newData.name) {
			newErrors.push(local.TS_Name);
		}
		if ((newData.value || "").length === 0) {
			newErrors.push(local.TS_Value);
		}
		if (newErrors.length > 0) {
			setErrors(newErrors);
			return false;
		}
		setErrors(undefined);
		return true;
	};

	return (
		<>
			{errors &&
				errors.map((f, k) => (
					<div key={k} className="text-danger ml-2">
						{f} {local.TF_IsRequired}
					</div>
				))}
			<div className="MuiTable-SmallHeader MuiTable-VerticalMiddle">
				<MaterialTable
					columns={columns}
					data={data}
					title=""
					icons={tableIcons}
					options={{
						tableLayout: "auto",
						search: false,
						sorting: false,
						paging: false,
						headerStyle: {
							backgroundColor: "#fff",
							color: "#000",
							paddingTop: "7px !important",
						},
						rowStyle: {
							fontFamily: '"Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
						},
					}}
					components={{
						Action: (props) => {
							switch (props.action.tooltip) {
								case "Add":
									return (
										<Button {...props} onClick={props.action.onClick} color="falcon-success" className={`my-1`}>
											<FontAwesomeIcon icon={"plus"} /> {local.TF_Add_Threshold}
										</Button>
									);
								case "Save":
									return (
										<Button {...props} onClick={props.action.onClick} color="primary" className={buttonDefaultClasses}>
											{local.TS_Save}
										</Button>
									);
								default:
									return <MTableAction {...props} />;
							}
						},
					}}
					editable={{
						onRowAdd: (newData) =>
							new Promise((resolve, reject) => {
								setTimeout(() => {
									newData = fixData(newData);
									if (!validate(newData)) {
										reject();
									} else {
										setData([...data, newData]);
										onChange([...data, newData]);
										resolve();
									}
								}, 100);
							}),
						onRowUpdate: (newData, oldData) =>
							new Promise((resolve, reject) => {
								setTimeout(() => {
									newData = fixData(newData);
									if (!validate(newData)) {
										reject();
									} else {
										const dataUpdate = [...data];
										const index = oldData.tableData.id;
										dataUpdate[index] = newData;
										setData([...dataUpdate]);
										onChange([...dataUpdate]);
										resolve();
									}
								}, 100);
							}),
						onRowDelete: (oldData) =>
							new Promise((resolve, _reject) => {
								setTimeout(() => {
									const dataDelete = [...data];
									const index = oldData.tableData.id;
									dataDelete.splice(index, 1);
									setData([...dataDelete]);
									onChange([...dataDelete]);
									resolve();
								}, 1000);
							}),
					}}
				/>
			</div>
		</>
	);
};

export default ChartCustomThresholds;

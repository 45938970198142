import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NavbarVerticalMenuItem = ({ route }) => {
	return (
		<div className="d-flex align-items-center">
			{route.icon && (
				<span className="nav-link-icon">
					<FontAwesomeIcon icon={route.icon} />
				</span>
			)}
			<span>
				{route.name}
				{route.badge && <span className={`ml-1 text-monospace badge badge-pill badge-soft-danger badge-${route.badge} d-none`}>0</span>}
				{route.badge2 && <span className={`ml-1 text-monospace badge badge-pill badge-cleared badge-${route.badge2} d-none`}>0</span>}
			</span>
		</div>
	);
};

NavbarVerticalMenuItem.propTypes = {
	route: PropTypes.shape({
		icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.any]),
		name: PropTypes.string.isRequired,
	}).isRequired,
};

export default NavbarVerticalMenuItem;

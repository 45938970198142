import axios from "axios";
import { CurrentSiteIdGet } from "./userSettings";

export const apiLoggerAdd = async (name, serialNumber) => {
	const siteId = CurrentSiteIdGet();

	try {
		await axios({
			method: "post",
			url: "v2/Squirrel",
			data: {
				siteId,
				squirrel: {
					siteId,
					name,
					serialNumber,
					geniiInfo: true,
					alarmsAvailable: true,
				},
			},
		});
	} catch (err) {
		console.error(err);
	}
};

export const apiLoggerStopStart = async (serialNumber, start) => {
	const siteId = CurrentSiteIdGet();

	try {
		await axios({
			method: "post",
			url: "v2/Configure/LoggerStopStart",
			data: {
				siteId,
				serialNumber,
				start,
			},
		});
	} catch (err) {
		console.error(err);
	}
};

export const apiLoggerStopStartOutstanding = async () => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "get",
			url: "v2/Configure/OutstandingLoggerStopStart",
			params: {
				siteId,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

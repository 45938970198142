import React, { useState, useEffect } from "react";
import { Input, Button } from "reactstrap";
import MaterialTable from "material-table";
import { tableIcons } from "../../helpers/tableIcons";
import local from "../../localization/strings";
import { distributorsGetList } from "../../api/users";

const AssignDistributorList = ({ toggleAssignDistributor }) => {
	const [data, setData] = useState(undefined);

	useEffect(() => {
		const LoadData = async () => {
			setData(await distributorsGetList());
		};

		LoadData();
	}, []);

	const cancelDistributorAssign = () => {
		//cancel distributor assignment
		toggleAssignDistributor();
	};

	const saveDistributorAssign = () => {
		//save distributor assignment
		toggleAssignDistributor();
	};

	const columns = [
		{
			title: local.TF_Assigned,
			render: (rowData) => {
				return <Input type="checkbox" className="pin-button" />;
			},
		},
		{ title: local.TF_CompanyName, field: "companyName" },
		{ title: local.TS_EmailAddress, field: "email" },
	];

	return (
		<>
			<span className="float-right pt-2 mt-n5">
				<Button onClick={() => cancelDistributorAssign()} className="mr-2">
					Cancel
				</Button>
				<Button onClick={() => saveDistributorAssign()} color="primary">
					Save Changes
				</Button>
			</span>
			{data && (
				<div className="MuiTable text-center">
					<MaterialTable
						columns={columns}
						data={data}
						title=""
						icons={tableIcons}
						options={{
							padding: "dense",
							sorting: true,
							exportButton: false,
							paging: true,
							pageSize: 20,
							pageSizeOptions: [20, 50, 100],
							emptyRowsWhenPaging: false,
							headerStyle: {
								backgroundColor: "#fff",
								color: "#000",
							},
							rowStyle: {
								textAlign: "center",
							},
						}}
					/>
				</div>
			)}
		</>
	);
};

export default AssignDistributorList;

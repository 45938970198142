import React, { useState, useEffect } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Row, Col } from "reactstrap";
import PageTitle from "../common/PageTitle";
import local from "../../localization/strings";
import TabUsers from "./TabUsers";
import TabSiteDetails from "./TabSiteDetails";
import TabBilling from "./TabBilling";
import TabHelp from "./TabHelp";
import { CurrentUserHasRole } from "../../api/auth";
import { features, isFeatureEnabled, roles } from "../../config";

const SiteManagement = () => {
	const [activeTab, setActiveTab] = useState(1);
	const [showHelpTab, setShowHelpTab] = useState(false);
	const [showUsersTab, setShowUsersTab] = useState(false);

	useEffect(() => {
		setShowHelpTab(CurrentUserHasRole(roles.SiteAdmin) && !CurrentUserHasRole(roles.Distributor));
		setShowUsersTab(CurrentUserHasRole(roles.Eltek) || CurrentUserHasRole(roles.Distributor) || !isFeatureEnabled(features.UserMaintenanceDisableForSiteAdmins));
	}, []);

	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	const tabNavItem = (index, title, hidden) => {
		return (
			<NavItem>
				<NavLink
					hidden={hidden}
					className={activeTab === index ? "active" : ""}
					onClick={() => {
						toggle(index);
					}}
				>
					{title}
				</NavLink>
			</NavItem>
		);
	};

	return (
		<>
			<Row>
				<Col>
					<PageTitle title={local.TF_Site_Management} />
				</Col>
			</Row>
			<Card>
				<Nav tabs>
					{tabNavItem(1, local.TF_Site_Details, false)}
					{tabNavItem(2, local.TS_Users, !showUsersTab)}
					{tabNavItem(3, local.TF_Billing, true)}
					{tabNavItem(4, local.TS_Help, !showHelpTab)}
				</Nav>
				<TabContent activeTab={activeTab} className="p-3">
					<TabPane tabId={1}>
						<TabSiteDetails activeTab={activeTab} />
					</TabPane>
					<TabPane tabId={2}>
						<TabUsers activeTab={activeTab} />
					</TabPane>
					<TabPane tabId={3}>
						<TabBilling activeTab={activeTab} />
					</TabPane>
					<TabPane tabId={4}>
						<TabHelp activeTab={activeTab} />
					</TabPane>
				</TabContent>
			</Card>
		</>
	);
};

export default SiteManagement;

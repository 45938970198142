import axios from "axios";
import { CurrentSiteIdGet } from "./userSettings";

export const apiSearch = async (searchTerm) => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "get",
			url: "v2/Search",
			params: {
				siteId,
				searchTerm,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
		return [];
	}
};

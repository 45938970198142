import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import ReactDataGrid from "react-data-grid";
import { Card, Col } from "reactstrap";
import { readingsLatestTable } from "../../api/readings";
import { measureText } from "../../helpers/utils";
import local from "../../localization/strings";
import Loader from "../common/Loader";

const limitMaxHeightTo = 400;

const LatestValues = ({ config }) => {
	const [showLoading, setShowLoading] = useState(true);
	const [data, setData] = useState();
	const [cols, setCols] = useState("12");
	const [minHeight, setMinHeight] = useState(75);

	const fetchDataCallback = useCallback(async () => {
		const parts = (config || "").split("|");

		if (parts.length > 2) {
			setCols(parts[2]);
		}

		if (parts.length >= 2) {
			let result = await readingsLatestTable(null, parts[1], true);
			result.columns = result.columns.filter((x) => x.extra !== "DisplayOnLatestValues:False");
			result.columns[0].formatter = NormalFormatter;
			result.columns.slice(1).forEach(function(column) {
				column.formatter = ValueFormatter;
				column.headerRenderer = HeaderValueFormatter;
				column.width = 55;
			});
			const singleBuilding = [...new Set(result.rows.map((x) => x.Building))].length === 1;
			const singleZone = [...new Set(result.rows.map((x) => x.Zone))].length === 1;
			if (singleBuilding) {
				if (singleZone) {
					result.columns[0].name = `${result.rows[0].Building} / ${result.rows[0].Zone}`;
				} else {
					result.columns[0].name = result.rows[0].Building;
				}
			}
			let width = (measureText(result.columns[0].name, 13, null)?.width || 0) + 16;
			try {
				result.rows.forEach((x) => {
					if (singleBuilding) {
						if (singleZone) {
							x.Location = x.Group;
						} else {
							x.Location = `${x.Zone} / ${x.Group}`;
						}
					}

					const thisWidth = measureText(x.Location, 12, null)?.width + 16;
					if (thisWidth > width) {
						width = thisWidth;
					}
				});
			} catch {}
			result.columns[0].width = width || 300;
			const calcMaxHeight = result.rowsCount * 35 + 50;
			setMinHeight(calcMaxHeight > limitMaxHeightTo ? limitMaxHeightTo : calcMaxHeight);
			setData(result);
		}
		setShowLoading(false);
	}, [config]);

	useEffect(() => {
		fetchDataCallback();
	}, [fetchDataCallback]);

	const HeaderValueFormatter = ({ column }) => (
		<div className="text-right" title={column.name}>
			{column.name}
		</div>
	);

	const NormalFormatter = ({ value }) => <div className="rdg_innerCell">{value}</div>;
	const ValueFormatter = ({ value }) => {
		const parts = value.split(":");

		if ((parts[0] === "None" && parts[1] === "") || parts[0] === "") {
			return <div className={`rdg_innerCell text-right`}>{parts[2] === "True" && <FontAwesomeIcon className="danger-icon fs-1" size="lg" icon={faExclamationCircle} title="OUT OF DATE" />} -</div>;
		} else {
			return (
				<div className={`rdg_innerCell text-right alarm-${parts[0] || "none"}`}>
					{parts[2] === "True" && <FontAwesomeIcon className="danger-icon fs-1" size="lg" icon={faExclamationCircle} title="OUT OF DATE" />}
					{parts[1]}
				</div>
			);
		}
	};

	return (
		<Col className={`col-${cols} float-left`}>
			<Card className="mb-2 rounded-soft dash-border-grey">
				<h6 className="m-2 MuiTypography-root MuiTypography-h6">{local.TS_LatestValues}</h6>
				{showLoading ? <Loader /> : <div className="rdg-no-gutters">{data && <ReactDataGrid columns={data.columns} minHeight={minHeight} sortable={true} resizable={true} rowGetter={(i) => data.rows[i]} rowsCount={data.rowsCount} enableRowSelect={null} />}</div>}
			</Card>
		</Col>
	);
};

export default LatestValues;

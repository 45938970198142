import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "reactstrap";
import PageTitle from "../common/PageTitle";
import local from "../../localization/strings";
import { defaultDistributorDetails } from "../../config";
import { siteGet } from "../../api/site";

const Help = () => {
	const [siteData, setSiteData] = useState(null);

	useEffect(() => {
		const LoadData = async () => {
			var result = await siteGet();

			setSiteData(result);
		};

		LoadData();
	}, []);
	return (
		<>
			<PageTitle title={local.TS_DarcaConnectHelp} />
			<Card className="p-3">
				<p>{local.TF_If_you_require_help}:</p>
				<Row>
					<Col xs={5} className="text-right">
						{local.TS_Name}:
					</Col>
					<Col xs={7}>{siteData && (siteData.distributorName || defaultDistributorDetails.name)}</Col>
					<Col xs={5} className="text-right">
						{local.TS_Phone_Number}:
					</Col>
					<Col xs={7}>
						<a href={`tel:${siteData && (siteData.distributorPhone || defaultDistributorDetails.tel)}`}>{siteData && (siteData.distributorPhone || defaultDistributorDetails.tel)}</a>
					</Col>
					<Col xs={5} className="text-right">
						{local.TS_EmailAddress}:
					</Col>
					<Col xs={7}>
						<a href={`mailto:${siteData && (siteData.distributorEmail || defaultDistributorDetails.email)}`}>{siteData && (siteData.distributorEmail || defaultDistributorDetails.email)}</a>
					</Col>
				</Row>
			</Card>
		</>
	);
};

export default Help;

import React from "react";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import AdminArea from "../components/admin-area/AdminArea";
import Distributor from "../components/distributor/Distributor";
import Footer from "../components/footer/Footer";
import NavbarTop from "../components/navbar/NavbarTop";
import User from "../components/user/User";
import { roles } from "../config";
import withTracker from "../hoc/withTracker";

const AdminLayout = () => (
	<div className="container" id="dashboard-container-div">
		<ToastContainer autoClose={3000} />
		<div className="content">
			<NavbarTop admin={true} />
			<Switch>
				<Route path="/admin" exact component={withTracker(AdminArea)} requiredRole={roles.Eltek} />
				<Route path="/distributor" exact component={withTracker(Distributor)} requiredRole={roles.Distributor} />
				<Route path="/user" exact component={withTracker(User)} />
			</Switch>
			{/* Content goes here */}
			<Footer />
		</div>
	</div>
);

export default AdminLayout;

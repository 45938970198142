import React, { useEffect, useState, Fragment } from "react";
import { Table, Input, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import local from "../../localization/strings";
import { groupsGetList, groupUpdate } from "../../api/group";
import SiteLayoutParameters from "./SiteLayoutParameters";
import Loader from "../common/Loader";
import { CurrentUserHasRole } from "../../api/auth";
import { roles, isFeatureEnabled, features } from "../../config";

const SiteLayoutGroups = ({ selected, zoneId, setCurrentGroup, version, editLocationAlarm }) => {
	const [groups, setGroups] = useState([]);
	const [showLoading, setShowLoading] = useState(true);
	const [newName, setNewName] = useState("");
	const [groupId, setGroupId] = useState(null);
	const [editing, setEditing] = useState(false);
	const [currentVersion, setCurrentVersion] = useState(0);
	const [currentZoneId, setCurrentZoneId] = useState();
	const siteEdit = CurrentUserHasRole(roles.SiteAdmin) && isFeatureEnabled(features.SiteEdit);
	const groupNameEdit = !siteEdit && CurrentUserHasRole(roles.DepartmentAdmin) && isFeatureEnabled(features.GroupNameEdit);

	async function loadGroups(zoneId) {
		var data = await groupsGetList(zoneId);

		setGroups(data);
		setShowLoading(false);
	}

	useEffect(() => {
		if (zoneId !== currentZoneId || version !== currentVersion) {
			loadGroups(zoneId);
			setCurrentZoneId(zoneId);
			setCurrentVersion(version);
		}
	}, [zoneId, currentZoneId, version, currentVersion]);

	useEffect(() => {
		setCurrentGroup(groups[0]?.id);
	}, [groups, setCurrentGroup]);

	const groupNameChange = (e) => {
		setNewName(e.target.value);
	};

	const displayIcons = (id, name) => {
		if (groupId && groupId === id) {
			return (
				<>
					<Button className="no-outline" onClick={() => saveChange(id)}>
						<FontAwesomeIcon icon="check" />
					</Button>
					<Button className="no-outline" onClick={() => setEdit(null, "")}>
						<FontAwesomeIcon icon="times" />
					</Button>
				</>
			);
		} else {
			return <FontAwesomeIcon icon="pencil-alt" onClick={() => setEdit(id, name)} />;
		}
	};

	const saveChange = async (id) => {
		const current = groups.find((x) => x.id === id);

		if (current) {
			await groupUpdate(id, current.zoneId, current.index, newName);
		}

		loadGroups(zoneId);

		setEdit(null, "");
	};

	function setEdit(id, name) {
		setEditing(id !== null);
		setNewName(name);
		setGroupId(id);
	}

	const groupsMapWithSiteEdit = groups.map((group, i) => (
		<tr key={i} className={group.id === selected ? "selected-group" : ""}>
			<td className="px-1">
				<Button key={i} color="primary" id={group.id} onClick={() => setCurrentGroup(group.id)} className={group.id === selected ? "w-100 button-is-selected" : "w-100 zone-button"}>
					{group.name}
				</Button>
			</td>
			<SiteLayoutParameters groupId={group.id} editLocationAlarm={editLocationAlarm} />
		</tr>
	));

	const groupsMap = groups.map((group, i) => (
		<tr key={i}>
			<td id={group.id} className="site-layout-group">
				{editing && groupId === group.id ? <Input id={"edit-" + group.id} type="text" value={newName} onChange={groupNameChange} /> : group.name}
				{groupNameEdit && <span className="edit-pencil float-right mr-2">{displayIcons(group.id, group.name)}</span>}
			</td>
			<SiteLayoutParameters groupId={group.id} editLocationAlarm={editLocationAlarm} />
		</tr>
	));

	return (
		<Fragment>
			{showLoading ? (
				<div className="ml-2">
					<Loader />
				</div>
			) : (
				<Table className="text-center">
					<thead className="thead-light">
						<tr>
							<th>{local.TS_Groups}</th>
							<th>{local.TF_Channels}</th>
						</tr>
					</thead>
					<tbody>{siteEdit ? groupsMapWithSiteEdit : groupsMap}</tbody>
				</Table>
			)}
		</Fragment>
	);
};

export default SiteLayoutGroups;

import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import GetCssValuePrefix from "../common/GetCssValuePrefix";

const ParameterBox = (props) => {
	const [boxIsLight, setBoxIsLight] = useState(true);

	useEffect(() => {
		async function setTextColour() {
			if (props.boxColour === "light") {
				setBoxIsLight(true);
			} else {
				setBoxIsLight(false);
			}
		}
		setTextColour();
	}, [props.boxColour]);

	return (
		<Button id={props.buttonId} onClick={props.onClick} style={{ backgroundImage: GetCssValuePrefix() + "linear-gradient(-45deg, " + props.colorOne + ", " + props.colorTwo + ")" }} className={`${boxIsLight ? "light-param-box" : ""} col mb-2`}>
			{props.name}
		</Button>
	);
};

export default ParameterBox;

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";
import { Table } from "reactstrap";

import local from "../../../localization/strings";

const Formulae = () => {
	const [open, setOpen] = useState(false);

	return (
		<>
			<Row>
				<Col className="text-right">
					<Button color="falcon-info" size="sm" onClick={() => setOpen(true)}>
						<FontAwesomeIcon icon="info-circle" /> {local.TS_Formulae}
					</Button>
				</Col>
			</Row>

			<Modal isOpen={open} toggle={() => setOpen(false)} centered backdrop="static">
				<ModalHeader>
					<span className="float-left">{local.TS_Formulae}</span>
					<span className="float-right close-modal" style={{ position: "absolute", top: 15, right: 20 }} onClick={() => setOpen(false)}>
						X
					</span>
				</ModalHeader>
				<ModalBody className="p-0">
					<Table size="sm" className="table-hover m-0 border rounded-soft">
						<thead>
							<tr>
								<th>{local.TS_Formula}</th>
								<th>{local.TS_Description}</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>abs(x)</td>
								<td>{local.TF_Return_abs_value}</td>
							</tr>
							<tr>
								<td>Ceil(x)</td>
								<td>{local.TF_Find_int_ceiling}</td>
							</tr>
							<tr>
								<td>Cos(x)</td>
								<td>{local.TF_Calculate_cos}</td>
							</tr>
							<tr>
								<td>Cosh(x)</td>
								<td>{local.TF_Calculate_hyp_cos}</td>
							</tr>
							<tr>
								<td>exp(x)</td>
								<td>{local.TF_Calculate_exp_func}</td>
							</tr>
							<tr>
								<td>floor(x)</td>
								<td>{local.TF_Calculate_floor}</td>
							</tr>
							<tr>
								<td>hypot(a,b)</td>
								<td>{local.TF_Calculate_hyp_right_tri}</td>
							</tr>
							<tr>
								<td>log(x)</td>
								<td>{local.TF_Calculate_nat_log}</td>
							</tr>
							<tr>
								<td>log10(x)</td>
								<td>{local.TF_Calculate_b10_log}</td>
							</tr>
							<tr>
								<td>max(x)</td>
								<td>{local.TF_Return_larger_of_two}</td>
							</tr>
							<tr>
								<td>min(x)</td>
								<td>{local.TF_Return_smaller_of_two}</td>
							</tr>
							<tr>
								<td>sin(x)</td>
								<td>{local.TF_Calculate_sine}</td>
							</tr>
							<tr>
								<td>sinh(x)</td>
								<td>{local.TF_Calculate_hyp_sine}</td>
							</tr>
							<tr>
								<td>sqrt(x)</td>
								<td>{local.TF_Find_sq_root}</td>
							</tr>
							<tr>
								<td>srand(x)</td>
								<td>{local.TF_Init_pseudorand_series}</td>
							</tr>
							<tr>
								<td>tan(x)</td>
								<td>{local.TF_Calculate_tan}</td>
							</tr>
							<tr>
								<td>tanh(x)</td>
								<td>{local.TF_Calculate_hyp_tan}</td>
							</tr>
						</tbody>
					</Table>
				</ModalBody>
			</Modal>
		</>
	);
};

export default Formulae;

import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import local from "../../localization/strings";

const SiteAccess = ({ siteName, siteId, cancelDisplaySiteId }) => {
	const [modalOpen, setModalOpen] = useState(false);

	useEffect(() => {
		if (siteId) {
			setModalOpen(true);
		} else {
			setModalOpen(false);
		}
	}, [siteId]);

	return (
		<Modal isOpen={modalOpen} toggle={cancelDisplaySiteId} centered backdrop="static">
			<ModalHeader>{siteName}</ModalHeader>
			<ModalBody>
				<p>
					<span className="font-weight-bold">{local.TF_Site_ID}:</span> {siteId}
				</p>
				<Button size="sm" color="secondary" onClick={cancelDisplaySiteId} className="ml-2">
					{local.TS_Close}
				</Button>
			</ModalBody>
		</Modal>
	);
};

export default SiteAccess;

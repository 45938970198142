import React, { useRef, useState } from "react";
import { Form, Input, Dropdown, DropdownMenu, ListGroup, Card } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import local from "../../localization/strings";
import { logEvent } from "../../helpers/ga";
import { apiSearch } from "../../api/search";
import SearchResultBuilding from "./search-results/SearchResultBuilding";
import SearchResultZone from "./search-results/SearchResultZone";
import SearchResultZoneGraphicCaption from "./search-results/SearchResultZoneGraphicCaption";
import SearchResultGroup from "./search-results/SearchResultGroup";
import SearchResultLocation from "./search-results/SearchResultLocation";
import SearchResultNote from "./search-results/SearchResultNote";
import SearchResultSquirrel from "./search-results/SearchResultSquirrel";
import SearchResultAlarmTeam from "./search-results/SearchResultAlarmTeam";
import SearchResultEmailReport from "./search-results/SearchResultEmailReport";
import SearchResultUser from "./search-results/SearchResultUser";

const SearchBox = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [data, setData] = useState(undefined);

	const handleToggle = () => {
		setIsOpen(!isOpen);
	};

	const searchRef = useRef(null);

	const submitHandler = async (e) => {
		e.preventDefault();

		const term = searchRef.current.value;

		logEvent("Search", term);

		var results = await apiSearch(term);
		setData(results);

		setIsOpen(true);
	};

	return (
		<>
			<Form className="search-box" onSubmit={submitHandler}>
				<Input type="search" placeholder={local.TS_Search_Ellipsis} aria-label={local.TS_Search} className="rounded-pill search-input" innerRef={searchRef} />
				<FontAwesomeIcon icon="search" className="position-absolute text-400 search-box-icon" />
				<Dropdown nav inNavbar isOpen={isOpen} toggle={handleToggle}>
					<DropdownMenu className="dropdown-menu-card ml-4">
						<Card className="card-search shadow-none">
							<ListGroup flush className="font-weight-normal">
								<div style={{ maxHeight: 370, overflowY: "auto", overflowX: "hidden" }}>
									{data && (
										<>
											{data.length === 0 && <div className="p-2">{local.TF_Search_No_Matches}</div>}
											{data.map((item, i) => {
												switch (item.entityType) {
													case "Building":
														return <SearchResultBuilding key={`${item.entityType}_${item.id}_${i}`} item={item} close={handleToggle} />;
													case "Zone":
														return <SearchResultZone key={`${item.entityType}_${item.id}_${i}`} item={item} close={handleToggle} />;
													case "ZoneGraphicCaption":
														return <SearchResultZoneGraphicCaption key={`${item.entityType}_${item.id}_${i}`} item={item} close={handleToggle} />;
													case "Group":
														return <SearchResultGroup key={`${item.entityType}_${item.id}_${i}`} item={item} close={handleToggle} />;
													case "Location":
														return <SearchResultLocation key={`${item.entityType}_${item.id}_${i}`} item={item} close={handleToggle} />;
													case "Note":
														return <SearchResultNote key={`${item.entityType}_${item.id}_${i}`} item={item} close={handleToggle} />;
													case "Squirrel":
														return <SearchResultSquirrel key={`${item.entityType}_${item.id}_${i}`} item={item} close={handleToggle} />;
													case "AlarmTeam":
														return <SearchResultAlarmTeam key={`${item.entityType}_${item.id}_${i}`} item={item} close={handleToggle} />;
													case "Email Report":
														return <SearchResultEmailReport key={`${item.entityType}_${item.id}_${i}`} item={item} close={handleToggle} />;
													case "User":
														return <SearchResultUser key={`${item.entityType}_${item.id}_${i}`} item={item} close={handleToggle} />;
													default:
														return (
															<div key={`${item.entityType}_${item.id}`} className="p-2 text-danger">
																{item.entityType}: {item.entityName}
															</div>
														);
												}
											})}
										</>
									)}
								</div>
							</ListGroup>
						</Card>
					</DropdownMenu>
				</Dropdown>
			</Form>
		</>
	);
};

export default SearchBox;

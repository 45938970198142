import axios from "axios";
import { CurrentSiteIdGet } from "./userSettings";

export const apiClearAlarm = async (locationId, serialNumber, channelNumber) => {
	const siteId = CurrentSiteIdGet();

	try {
		await axios({
			method: "patch",
			url: "v2/Alarm/Clear",
			data: {
				siteId,
				locationId,
				serialNumber,
				channelNumber,
			},
		});
	} catch (err) {
		console.error(err);
	}
};

export const apiUnclearAlarm = async (locationId, serialNumber, channelNumber) => {
	const siteId = CurrentSiteIdGet();

	try {
		await axios({
			method: "patch",
			url: "v2/Alarm/Unclear",
			data: {
				siteId,
				locationId,
				serialNumber,
				channelNumber,
			},
		});
	} catch (err) {
		console.error(err);
	}
};

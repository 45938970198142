import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, Label, Modal, ModalBody } from "reactstrap";
import local from "../../localization/strings";
import { AccessSite, CurrentUserSites } from "../../api/auth";
import { CurrentSiteIdSet } from "../../api/userSettings";

const SiteAccess = ({ siteId, cancelAccessSite }) => {
	const [modalOpen, setModalOpen] = useState(false);
	const accessCodeRef = useRef(null);
	const [accessCodeError, setAccessCodeError] = useState(false);

	useEffect(() => {
		if (CurrentUserSites().includes(siteId)) {
			CurrentSiteIdSet(siteId);
			window.location.href = "/";
			return;
		}

		setAccessCodeError(false);
		if (siteId) {
			setModalOpen(true);
		} else {
			setModalOpen(false);
		}
	}, [siteId]);

	const accessSite = async () => {
		var result = await AccessSite(siteId, accessCodeRef.current.value);

		if (result) {
			if (CurrentUserSites().includes(siteId)) {
				CurrentSiteIdSet(siteId);
				window.location.href = "/";
				return;
			}
		}

		setAccessCodeError(true);
	};

	return (
		<Modal isOpen={modalOpen} toggle={cancelAccessSite} centered backdrop="static">
			<ModalBody>
				<Form>
					<div className="form-group">
						<Label for="inputCode">{local.TF_Access_Code}</Label>
						<Input innerRef={accessCodeRef} type="text" className={`form-control ${accessCodeError && "border border-danger"}`} id="inputCode" placeholder={local.TF_Access_Code_Placeholder} />
						{accessCodeError && <div className="text-danger">{local.TF_Access_Code_Error}</div>}
						<small>{local.TF_Access_Code_Find}</small>
					</div>
					<Button size="sm" color="primary" onClick={accessSite}>
						{local.TF_Access_Site}
					</Button>
					<Button size="sm" color="secondary" onClick={cancelAccessSite} className="ml-2">
						{local.TS_Cancel}
					</Button>
				</Form>
			</ModalBody>
		</Modal>
	);
};

export default SiteAccess;

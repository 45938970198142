import axios from "axios";
import { CurrentSiteIdGet } from "./userSettings";

export const parametersGetListForSite = async (siteId, limitToGroupid) => {
	try {
		const result = await axios({
			method: "get",
			url: "v2/Parameters",
			params: {
				SiteId: siteId,
				GroupId: limitToGroupid,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const parametersGetList = async (limitToGroupid) => {
	const siteId = CurrentSiteIdGet();

	return await parametersGetListForSite(siteId, limitToGroupid);
};

export const parameterGet = async (id) => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "get",
			url: "v2/Parameter",
			params: { siteId, id },
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

const zeroNumberOrDefault = (input, defaultValue) => {
	if (input) {
		return input;
	}
	if (input === 0) {
		return 0;
	}
	return defaultValue;
};

const localDateFromMomentOrDefault = (input, defaultValue) => {
	try {
		input.add(input.utcOffset(), "minutes");
		return input.toDate();
	} catch {
		return defaultValue;
	}
};

const fixGraphIndex = (displayGraphIndex) => {
	if (displayGraphIndex && displayGraphIndex > 0) {
		return displayGraphIndex - 1;
	}
	return 0;
};

const colour = (hex) => {
	if (hex) {
		return parseInt(`0x${hex.substr(1)}`);
	}
	return null;
};

const notEmptyAsFloatOrDefault = (input, defaultValue) => {
	if (input === "") {
		return defaultValue;
	}
	try {
		return parseFloat(input);
	} catch {
		return defaultValue;
	}
};

const parameterSave = async ({ id, index, name, type, units, formulaIsCumulative, cumulativeReffedParamId, cumulativeDivisor, cumulativeStartTime, formula, plotted, graphIndex, axis, colour, rangeMin, rangeMax, decimalPlaces, warnAlarmHi, warnAlarmLo, warnAlarmPlot, warnAlarmLineType, warnAlarmColour, warnAlarmColourBlock, mainAlarmHi, mainAlarmLo, mainAlarmPlot, mainAlarmLineType, mainAlarmColour, mainAlarmColourBlock, displayOnLatestValues }) => {
	const siteId = CurrentSiteIdGet();

	try {
		await axios({
			method: "post",
			url: "v2/Parameter",
			data: {
				siteId,
				parameter: { id, index, name, type, units, formulaIsCumulative, cumulativeReffedParamId, cumulativeDivisor, cumulativeStartTime, formula, plotted, graphIndex, axis, colour, rangeMin, rangeMax, decimalPlaces, warnAlarmHi, warnAlarmLo, warnAlarmPlot, warnAlarmLineType, warnAlarmColour, warnAlarmColourBlock, mainAlarmHi, mainAlarmLo, mainAlarmPlot, mainAlarmLineType, mainAlarmColour, mainAlarmColourBlock, displayOnLatestValues },
			},
		});
	} catch (err) {
		console.error(err);
	}
};

export const parameterSaveFromComponent = async (id, index, data) => {
	if (!id) {
		id = -1;
	}

	let obj = {
		id,
		index,
		name: data.name,
		type: data.type || 0,
		units: data.units,
		formulaIsCumulative: data.formulaIsCumulative || 0,
		cumulativeReffedParamId: data.cumulativeReffedParamId || null,
		cumulativeDivisor: zeroNumberOrDefault(data.cumulativeDivisor, null),
		cumulativeStartTime: localDateFromMomentOrDefault(data.cumulativeStartTimeMoment, null),
		formula: data.formula || "",
		plotted: data.plotted,
		graphIndex: fixGraphIndex(data.displayGraphIndex),
		axis: data.axis,
		colour: colour(data.hexColour),
		rangeMin: notEmptyAsFloatOrDefault(data.rangeMin, null),
		rangeMax: notEmptyAsFloatOrDefault(data.rangeMax, null),
		decimalPlaces: notEmptyAsFloatOrDefault(data.decimalPlaces, 0),
		warnAlarmHi: notEmptyAsFloatOrDefault(data.warnAlarmHi, null),
		warnAlarmLo: notEmptyAsFloatOrDefault(data.warnAlarmLo, null),
		warnAlarmPlot: data.warnPlotType === "NoPlot" ? false : true,
		warnAlarmLineType: zeroNumberOrDefault(data.warnAlarmLineType, null),
		warnAlarmColourBlock: data.warnPlotType === "BlockColour" ? true : false,
		warnAlarmColour: colour(data.warnAlarmHexColour),
		mainAlarmHi: notEmptyAsFloatOrDefault(data.mainAlarmHi, null),
		mainAlarmLo: notEmptyAsFloatOrDefault(data.mainAlarmLo, null),
		mainAlarmPlot: data.mainPlotType === "NoPlot" ? false : true,
		mainAlarmLineType: zeroNumberOrDefault(data.mainAlarmLineType, null),
		mainAlarmColourBlock: data.mainPlotType === "BlockColour" ? true : false,
		mainAlarmColour: colour(data.mainAlarmHexColour),
		displayOnLatestValues: data.displayOnLatestValues,
	};

	await parameterSave(obj);
};

export const parametersTable = async (plottedOnly = false) => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "get",
			url: "v2/Parameters/Table",
			params: {
				SiteId: siteId,
				plottedOnly,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const apiTransmitterTypesList = async () => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "get",
			url: "v2/TransmitterTypes",
			params: {
				SiteId: siteId,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const apiDefaultParametersList = async () => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "get",
			url: "v2/DefaultParameters",
			params: {
				SiteId: siteId,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const apiParameterAddDefaults = async (parameters) => {
	const siteId = CurrentSiteIdGet();

	try {
		await axios({
			method: "post",
			url: "v2/DefaultParameters",
			data: {
				siteId,
				defaultParameters: parameters.join(","),
			},
		});
	} catch (err) {
		console.error(err);
	}
};

import { AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import "react-image-lightbox/style.css";
import { toast } from "react-toastify";
import { Button, Card, CardBody, CardHeader, Input } from "reactstrap";
import local from "../../localization/strings";
import { tableIcons } from "../../helpers/tableIcons";
import MaterialTable from "material-table";
import { usersForSiteGetList } from "../../api/users";
import { RoleToDisplayRole } from "../../helpers/utils";
import { apiSiteAuthenticationDetailsGet, apiSiteAuthenticationDetailsSave } from "../../api/auth";

const ConfigureMfa = ({ site, cancel }) => {
	const [data, setData] = useState(undefined);
	const [users, setUsers] = useState(undefined);
	const [loading, setLoading] = useState(true);
	const [selected, setSelected] = useState([]);

	useEffect(() => {
		const LoadData = async () => {
			setLoading(true);
			var result = await apiSiteAuthenticationDetailsGet(site.id);

			setData({ mfaEnabled: result.some((x) => x.detail === "MFA" && !x.userId && x.isEnabled) ? 1 : 0 });

			const workingSelected = [];
			result.forEach((x) => {
				if (x.userId && x.detail === "MFABypass" && x.isEnabled) {
					workingSelected.push(x.userId);
				}
			});
			setSelected(workingSelected);

			setUsers(await usersForSiteGetList(site.id));
			setLoading(false);
		};

		LoadData();
	}, [site.id]);

	async function save(_e, values) {
		await apiSiteAuthenticationDetailsSave(site.id, values.mfaEnabled === 1, selected);

		toast.success(local.TS_Update_Successful);

		cancel();
	}

	const checkedChanged = (siteId) => {
		if (selected.includes(siteId)) {
			setSelected(selected.filter((x) => x !== siteId));
		} else {
			setSelected([...selected, siteId]);
		}
	};

	const columns = [
		{
			title: local.TF_Assigned,
			render: (rowData) => {
				return <Input type="checkbox" className="pin-button" checked={selected.includes(rowData.id.toUpperCase())} onChange={() => checkedChanged(rowData.id.toUpperCase())} />;
			},
		},
		{ field: "displayName", title: local.TS_Name, defaultSort: "asc" }, //
		{ field: "role", title: local.TS_Type, render: (rowData) => <span>{RoleToDisplayRole(rowData.role)}</span> },
		{ field: "email", title: local.TS_Email },
	];

	return (
		<Card className="p-3">
			<CardHeader>
				<h5>{local.TF_MFA}</h5>
			</CardHeader>
			<CardBody>
				{!loading && data && (
					<AvForm model={data} onValidSubmit={async (e, values) => await save(e, values)}>
						<AvRadioGroup name="mfaEnabled" label={local.TS_Enabled}>
							<AvRadio label={local.TS_Disabled} value={0} />
							<AvRadio label={local.TS_Enabled} value={1} />
						</AvRadioGroup>

						<legend>{local.TF_Allow_MFA_Bypass}</legend>

						{users && (
							<div className="MuiTable text-center">
								<MaterialTable
									columns={columns}
									data={users}
									title=""
									icons={tableIcons}
									options={{
										padding: "dense",
										sorting: true,
										exportButton: false,
										paging: true,
										pageSize: 20,
										pageSizeOptions: [20, 50, 100],
										emptyRowsWhenPaging: false,
										headerStyle: {
											backgroundColor: "#fff",
											color: "#000",
										},
										rowStyle: {
											textAlign: "center",
										},
									}}
								/>
							</div>
						)}

						<div className="mt-2">
							<Button color="primary">{local.TS_Save_Changes}</Button>
							<Button onClick={() => cancel()} className="ml-2">
								{local.TS_Close}
							</Button>
						</div>
					</AvForm>
				)}
			</CardBody>
		</Card>
	);
};

export default ConfigureMfa;

import ReactDataGrid from "react-data-grid";
import React, { useEffect, useState } from "react";
import { Card, Col } from "reactstrap";
import local from "../../../../localization/strings";
import Loader from "../../../common/Loader";
import { measureText, uniqueTransmitterSerial } from "../../../../helpers/utils";

const limitMaxHeightTo = 400;

const batteryBackground = (value) => {
	if (value === -1) {
		return "battery-missing";
	}
	if (value > -1 && value <= 10) {
		return "battery-very-low";
	}
	if (value > 10 && value <= 20) {
		return "battery-low";
	}
	if (value > 15 && value <= 100) {
		return "";
	}
	return "battery-very-low";
};

const NormalFormatter = ({ value }) => <div className="rdg_innerCell">{value}</div>;
const BatteryFormatter = ({ value }) => <div className={`rdg_innerCell ${batteryBackground(value)}`}>{value}</div>;

const BatteryLevelTable = ({ latestReadings, config }) => {
	const [loading, setLoading] = useState(true);
	const [gridData, setGridData] = useState(undefined);
	const [cols, setCols] = useState("12");
	const [minHeight, setMinHeight] = useState(75);

	useEffect(() => {
		let isSubscribed = true;
		setLoading(true);
		const LoadData = () => {
			var lowBatteryArray = [];
			if (latestReadings) {
				//filter for the first instance of a transmitter's serial number to avoid duplicate battery levels
				const transmitters = uniqueTransmitterSerial(latestReadings.filter((x) => x.transmitterSerialNumber), (item) => item.transmitterSerialNumber);
				if (transmitters) {
					for (var i = 0; i < transmitters.length; i++) {
						if (transmitters[i].transmitterBatteryLevel > 100 || transmitters[i].transmitterBatteryLevel === null) {
							transmitters[i].transmitterBatteryLevel = -1;
						}
						transmitters[i].transmitterBatteryLevelExport = transmitters[i].transmitterBatteryLevel === -1 ? "-" : transmitters[i].transmitterBatteryLevel;
						lowBatteryArray.push(transmitters[i]);
					}
				}
			}

			if (isSubscribed) {
				const singleBuilding = [...new Set(lowBatteryArray.map((x) => x.buildingName))].length === 1;
				const singleZone = [...new Set(lowBatteryArray.map((x) => x.zoneName))].length === 1;

				let serialNumberWidth = (measureText(local.TS_Serial_Number, 13, null)?.width || 0) + 16;
				lowBatteryArray.forEach((x) => {
					const thisWidth = measureText(x.transmitterSerialNumber, 12, null)?.width + 16;
					if (thisWidth > serialNumberWidth) {
						serialNumberWidth = thisWidth;
					}
				});

				const calcMaxHeight = lowBatteryArray.length * 35 + 50;
				setMinHeight(calcMaxHeight > limitMaxHeightTo ? limitMaxHeightTo : calcMaxHeight);

				if (cols === "12") {
					setGridData({
						columns: [
							{ key: "buildingName", name: local.TS_Building, formatter: NormalFormatter }, //
							{ key: "zoneName", name: local.TS_Zone, formatter: NormalFormatter },
							{ key: "groupName", name: local.TS_Group, formatter: NormalFormatter },
							{ key: "transmitterSerialNumber", name: local.TS_Serial_Number, width: serialNumberWidth, formatter: NormalFormatter },
							{ key: "transmitterBatteryLevel", name: local.TF_Battery, formatter: BatteryFormatter },
						],
						rows: lowBatteryArray,
					});
				} else {
					let locationColumnTitle = local.TS_Location;
					if (singleBuilding) {
						if (singleZone) {
							locationColumnTitle = `${lowBatteryArray[0].buildingName} / ${lowBatteryArray[0].zoneName}`;
						} else {
							locationColumnTitle = lowBatteryArray[0].buildingName;
						}
					}

					let width = (measureText(locationColumnTitle, 13, null)?.width || 0) + 16;
					lowBatteryArray.forEach((x) => {
						if (singleBuilding) {
							if (singleZone) {
								x.location = x.groupName;
							} else {
								x.location = `${x.zoneName} / ${x.groupName}`;
							}
						} else {
							x.location = `${x.buildingName} / ${x.zoneName} / ${x.groupName}`;
						}

						const thisWidth = measureText(x.location, 12, null)?.width + 16;
						if (thisWidth > width) {
							width = thisWidth;
						}
					});

					setGridData({
						columns: [
							{ key: "location", name: locationColumnTitle, width: width, formatter: NormalFormatter }, //
							{ key: "transmitterSerialNumber", name: local.TS_Serial_Number, width: serialNumberWidth, formatter: NormalFormatter },
							{ key: "transmitterBatteryLevel", name: local.TF_Battery, formatter: BatteryFormatter },
						],
						rows: lowBatteryArray,
					});
				}

				setLoading(false);
			}
		};

		const parts = (config || "").split("|");
		if (parts.length > 1 && parts[1]) {
			setCols(parts[1] || "12");
		} else {
			setCols("12");
		}

		LoadData();
		return () => (isSubscribed = false);
	}, [latestReadings, config, cols]);

	return (
		<>
			{
				<Col className={`col-${cols} float-left`}>
					<Card className="mb-2 rounded-soft dash-border-grey">
						<h6 className="m-2 MuiTypography-root MuiTypography-h6">{local.TF_Battery_Levels}</h6>
						{loading ? (
							<div className="ml-2">
								<Loader />
							</div>
						) : (
							<div className="rdg-no-gutters">{gridData && <ReactDataGrid columns={gridData.columns} minHeight={minHeight} sortable={true} resizable={true} rowGetter={(i) => gridData.rows[i]} rowsCount={gridData.rows?.length || 0} enableRowSelect={null} />}</div>
						)}
					</Card>
				</Col>
			}
		</>
	);
};

export default BatteryLevelTable;

import LocalizedStrings from "react-localization";

//TF_Bug_Report_Form will need adding for each language with its associated Smartsheet form
// As of 15/02/21 English and Spanish have bug report forms translated

let strings = new LocalizedStrings({
	en: {
		//Commonly used
		TS_Warning: "Warning",
		TS_Copied: "Copied",
		TS_Close: "Close",
		TS_Day: "Day",
		TS_Week: "Week",
		TS_Month: "Month",
		TS_Quarter: "Quarter",
		TS_6_Months: "6 Months",
		TS_Year: "Year",
		TS_Time: "Time",
		TS_Building: "Building",
		TS_Location: "Location",
		TS_Zone: "Zone",
		TS_Zones: "Zones",
		TS_Go: "Go",
		TS_NewEmailAddress: "New Email address",
		TS_EmailAddress: "Email address",
		TS_Phone_Number: "Phone Number",
		TS_Email: "Email",
		TS_Password: "Password",
		TS_Apply: "Apply",
		TS_Clear: "Clear",
		TS_Reset: "Reset",
		TS_Filter: "Filter",
		TS_Name: "Name",
		TS_Units: "Units",
		TS_Save: "Save",
		TS_Save_Changes: "Save Changes",
		TS_Axis: "Axis",
		TS_Align: "Align",
		TS_Left: "Left",
		TS_Right: "Right",
		TS_Center: "Centre",
		TS_Font: "Font",
		TS_Normal: "Normal",
		TS_Bold: "Bold",
		TS_Italic: "Italic",
		TS_Bold_And_Italic: "Bold & Italic",
		TS_Courier: "Courier",
		TS_Helvetica: "Helvetica",
		TS_Times_New_Roman: "Times New Roman",
		TS_No_Plot: "Not Plotted",
		TS_Line: "Line",
		TS_Colour_Block: "Colour Block",
		TS_Formula: "Formula",
		TS_Formulae: "Formulae",
		TS_Colour: "Colour",
		TS_Decimal_Places: "Decimal Places",
		TS_Change: "Change",
		TS_Type: "Type",
		TS_Set: "Set",
		TS_Parameter: "Parameter",
		TS_Parameters: "Parameters",
		TS_Date_Time: "Date/Time",
		TS_Start_Date_Time: "Start Date/Time",
		TS_End_Date_Time: "End Date/Time",
		TS_Details: "Details",
		TS_Today: "Today",
		TS_This_week: "This week",
		TS_This_month: "This month",
		TS_This_year: "This year",
		TS_Generate: "Generate",
		TS_Sort: "Sort",
		TS_Desc: "Desc",
		TS_Group: "Group",
		TS_Groups: "Groups",
		TS_Thresholds: "Thresholds",
		TS_Cancel: "Cancel",
		TS_Required: "Required!",
		TS_Hour_s: "Hour(s)",
		TS_Day_s: "Day(s)",
		TS_Week_s: "Week(s)",
		TS_Month_s: "Month(s)",
		TS_Year_s: "Year(s)",
		TS_Value: "Value",
		TS_Text: "Text",
		TS_Min: "Min",
		TS_Max: "Max",
		TS_Avg: "Avg",
		TS_For: "For",
		TS_Send: "Send",
		TS_Email_Address: "Email Address",
		TS_Log_out: "Log out",
		TS_Every: "Every",
		TS_Minutes: "Minutes",
		TS_Hours: "Hours",
		TS_Username: "Username",
		TS_Folder: "Folder",
		TS_Czech: "Czech",
		TS_English: "English",
		TS_French: "French",
		TS_German: "German",
		TS_Spanish: "Spanish",
		TS_Description: "Description",
		TS_Recipients: "Recipients",
		TS_Frequency: "Frequency",
		TS_Daily: "Daily",
		TS_Weekly: "Weekly",
		TS_Monthly: "Monthly",
		TS_Quarterly: "Quarterly",
		TS_6_Monthly: "Every 6 months",
		TS_Yearly: "Yearly",
		TS_Modify: "Modify",
		TS_Delete: "Delete",
		TS_Show: "Show",
		TS_Hide: "Hide",
		TS_Configure_Report: "Configure Report",
		TS_Options: "Options",
		TS_Pin: "Pin",
		TS_Download: "Download",
		TS_Print: "Print",
		TS_Copy: "Copy",
		TS_Invalid: "Invalid!",
		TS_Add: "Add",
		TS_Expand_All: "Expand All",
		TS_Collapse_All: "Collapse All",
		TS_Range: "Range",
		TS_Horizontal: "Horizontal",
		TS_Vertical: "Vertical",
		TS_ExportData: "Export Data",
		TS_View: "View",
		TS_Users: "Users",
		TS_Help: "Help",
		TS_Site_Administrator: "Site Administrator",
		TS_Department_Administrator: "Department Administrator",
		TS_Secondary_User: "Secondary User",
		TS_Dormant_User: "Dormant User",
		TS_Department: "Department",
		TS_User: "User",
		TS_None: "None",
		TS_Distributor: "Distributor",
		TS_Duplicate_User: "A user with this email address already exists",
		TS_Max_Lite_Users: "You have reached the maximum number of users permitted for this site. If you require more user accounts, please contact your Distributor or Eltek to upgrade.",
		TS_LastAdmin: "Unable to remove last admin from a site",
		TS_EmailTaken: "Email Address is already taken",
		TS_or: "or",
		TS_Update_Successful: "Update Successful",
		TS_Edit: "Edit",
		TS_Departments: "Departments",
		TS_Remove: "Remove",
		TS_Serial_Number: "Serial Number",
		TS_NO_DATA: "NO DATA",
		TS_No_Data: "No Data",
		TS_Both: "Both",
		TS_Search_Ellipsis: "Search...",
		TS_Search: "Search",
		TS_Blue_Brackets: "(Blue)",
		TS_Actions: "Actions",

		//Index Page
		TS_Dashboard: "Dashboard",
		TS_Metering: "Metering",
		TS_Meters: "Meters",
		TS_LatestValues: "Latest Values",
		TS_Alarms: "Alarms",
		TS_CurrentAlarms: "Current Alarms",
		TS_AlarmHistory: "Alarm History",
		TS_ZoneGraphics: "Zone Graphics",
		TS_Charts: "Charts",
		TS_ZoneCharts: "Zone Charts",
		TS_UserCharts: "User Charts",
		TS_CreateTemplate: "Create Template...",
		TS_Statistics: "Statistics",
		TS_Reports: "Reports",
		TS_PerformanceStatistics: "Performance Statistics",
		TS_StatisticsReport: "Statistics Report",
		TS_HardwareReport: "Hardware Report",
		TS_FluctuationReport: "Fluctuation Report",
		TS_MultiPeriodReport: "Multi-Period Report",
		TS_ChartReport: "Chart Report",
		TS_EmailReports: "Email Reports",
		TS_SiteSettings: "Site Settings",
		TS_UserSettings: "User Settings",
		TS_DarcaConnectHelp: "Darca Connect Help",
		TS_MyManuals: "My Manuals",
		TS_Distributor_details: "Distributor Details",
		TS_Report_an_issue: "Report an issue",
		TF_Admin_Area: "Admin Area",
		TS_Distributor_Area: "Distributor Area",

		//Error pages
		TF_404PageCannotBeFound: "The page you're looking for cannot be found.",
		TF_404MakeSureAddressCorrect: "Make sure the address is correct and that the page hasn't moved. If you think this is a mistake,",
		TF_error_contact_us: "contact us",
		TF_404_Go_to_Dashboard: "Go to Dashboard",
		TF_500_Whoops_something_wrong: "Whoops, something went wrong!",
		TF_500_Try_refreshing_page_or_do_again: "Try refreshing the page, or going back and attempting the action again. If this problem persists,",
		TF_403_AccessForbiddenTitle: "Access Forbidden",
		TF_403Forbidden: "Access forbidden. If you believe that you shouldn't be seeing this error, please",
		TF_LinkExpired: "The link has expired. Please contact your administrator",

		//Bug report links
		TF_Bug_Report_Form: "https://eltekdataloggers.atlassian.net/servicedesk/customer/portal/3",

		//Dashboard
		TF_Selected_area: "Selected area",
		TF_Edit_Dashboard: "Edit Dashboard",
		TF_Save_Changes: "Save Changes",
		TF_Click_and_drag: "Click and drag the boxes to rearrange",
		TF_This_is_your_dashboard: "This is your dashboard. You can pin various items here, such as",
		TF_Look_for_the: "Just look for the",
		TF_Rearrange_and_delete: "You can then rearrange items and remove them on your Dashboard using the 'Edit Dashboard' button.",
		TF_View_Chart: "View Chart",
		TF_Meter_Gauge: "Meter Gauge",
		TF_Meter_Value: "Meter Value",
		TF_Zone_Graphic: "Zone Graphic",
		TF_Stats_Container: "Stats Container",

		//Charts
		TF_Chart: "Chart",
		TF_Another_zone: "Another zone...",
		TF_Axes: "Axes",
		TF_TimeScales: "Time Scales",
		TF_Overlays: "Overlays",
		TF_Compare: "Compare",
		TF_Summary: "Summary",
		TF_Notes: "Notes",
		TF_Refresh: "Refresh",
		TF_Maximise: "Maximise",
		TF_Minimise: "Minimise",
		TF_Export: "Export",
		TF_Custom: "Custom...",
		TF_Add_a_note: "Add a note",
		TF_View_and_edit_notes: "View & edit notes",
		TF_Select_zone_to_display: "Select a zone to display",
		TF_YAxis_Settings: "Ranges",
		TF_Toggle_StripLine: "Toggle Limits",
		TF_Report: "Report",
		TF_Charts_Per_Page: "Charts Per Page",
		TF_Select_time_span: "Select time span",
		TF_First_group: "First group",
		TF_Second_group: "Second group",
		TF_Start_of_data: "Start of data",
		TF_Toggle_Tooltips: "Toggle Tooltips",
		TF_Main_Only: "Main Only",
		TF_Compare_Only: "Compare Only",

		//Forget Password
		TF_ForgotYourPasswordTitle: "Forgot your password?",
		TF_ForgotYourPasswordSubTitle: "Enter your email and we'll send you a reset link.",
		TF_ForgotYourPasswordSendLinkButton: "Send reset link",
		TF_ForgotYourPasswordCantRecoverLink: "I can't recover my account using this page",

		//Login
		TF_LogInTitle: "Log in",
		TF_LogInRememberMe: "Remember me",
		TF_LogInForgotPasswordLink: "Forgot Password?",
		TF_LogInButton: "Log in",
		TF_LogInError: "Log in failed!",

		//Logout
		TF_LogoutTitle: "See you again!",
		TF_LogoutSubTitle: "You are now successfully logged out.",
		TF_LogoutReturnToLoginButton: "Return to Login",

		//Confirm Mail
		TF_ConfirmMailTitle: "Please check your email!",
		TF_ConfirmMailSubTitle: "An email has been sent. Please click on the included link to reset your password.",
		TF_ConfirmMailReturnToLoginButton: "Return to Login",

		//New User
		TF_NewUserTitle: "Set Password",
		TF_NewUserPassword: "Password",
		TF_NewUserConfirmPassword: "Confirm Password",
		TF_NewUserSetButton: "Set",

		//Password Reset
		TF_PasswordResetTitle: "Reset Password",
		TF_PasswordResetNewPassword: "New Password",
		TF_PasswordResetConfirmPassword: "Confirm Password",
		TF_PasswordResetResetButton: "Reset",

		//Error
		TF_ErrorTitle: "Error",
		TF_ErrorGeneral: "An error has occurred",

		//Zone Charts
		TF_ZoneCharts_SelectZone: "Select Zone",

		//Zone Graphics
		TF_ZoneGraphicsTitle: "Zone Graphics",
		TF_ZoneGraphicsSelectZone: "Select Zone",
		TF_Pin_Zone_Graphic: "Pin Zone Graphic",
		TF_Pin_Zone_Graphic_Wide: "Pin Zone Graphic (Wide)",

		//Metering
		TF_MeteringTitle: "Metering",
		TF_MeteringType: "Meter Type",
		TF_MeteringTypeNormal: "Normal",
		TF_MeteringTypeGauge: "Gauge",
		TF_MeteringTypeTable: "Table",
		TS_ShowTransmitterDetails: "Show Details",
		TS_HideTransmitterDetails: "Hide Details",
		TF_added_to_dashboard: "was added to the dashboard",
		TF_Battery: "Battery",
		TF_Unknown_Battery_Level: "Unknown Battery Level",
		TF_Data_from: "Data from",
		TF_Pin_Gauge: "Pin Gauge",
		TF_Pin_Gauge_Blue: "Pin Gauge (Blue)",
		TF_Pin_Meter: "Pin Meter",
		TF_Pin_Meter_Blue: "Pin Meter (Blue)",

		//Notes
		TF_NotesTitle: "Notes",
		TF_NoteText: "Note Text",
		TF_Initial: "Initials",

		//Statistics
		TF_LastHour: "Last Hour",
		TF_LastDay: "Last Day",
		TF_LastWeek: "Last Week",
		TF_LastMonth: "Last Month",
		TF_LastYear: "Last Year",
		TF_Area_to_report: "Area to report:",
		TF_Stats_to_display: "Statistics to display:",
		TF_Time_period: "Time period:",
		TF_Report_Time_Period: "Report Time Period",
		TF_Fluctation_Time_Period: "Fluctuation Time Period",
		TF_Bands: "Bands",
		TF_Arrange_channels_by: "Arrange channels by:",
		TF_All_Statistics: "All Statistics",
		TF_Show_Zone_Name: "Show Zone Name",
		TF_Show_Building_Name: "Show Building Name",
		TF_General_Statistics: "General Statistics",
		TF_Start_End_Time_of_Data: "Start & End Time of Data",
		TF_Valid_Data: "Valid Data",
		TF_Max_Value: "Max Value",
		TF_Time_of_First_Max_Value: "Time of First Max Value",
		TF_Min_Value: "Min Value",
		TF_Time_of_First_Min_Value: "Time of First Min Value",
		TF_Average: "Average",
		TF_Sum: "Sum",
		TF_Standard_Deviation: "Standard Deviation",
		TF_Safe_Limit_Statistics: "Safe Limit Statistics",
		TF_Within: "Within",
		TF_Readings_Within: "Readings Within",
		TF_Time_Within: "Time Within",
		TF_Percent_Readings_Within: "% Readings Within",
		TF_Colour_Code_Within: "Colour Code % Within",
		TF_Outside: "Outside",
		TF_Readings_Outside: "Readings Outside",
		TF_Time_Outside: "Time Outside",
		TF_Percent_Readings_Outside: "% Readings Outside",
		TF_Above: "Above",
		TF_Readings_Above: "Readings Above",
		TF_Time_Above: "Time Above",
		TF_Percent_Readings_Above: "% Readings Above",
		TF_Below: "Below",
		TF_Readings_Below: "Readings Below",
		TF_Time_Below: "Time Below",
		TF_Percent_Readings_Below: "% Readings Below",
		TF_Custom_Threshold_Statistics: "Custom Threshold Statistics",
		TF_Show_Average_Parameter_Stats: "Show Average Parameter Stats",
		TF_Time_Span: "Time Span",
		TF_Number_of_Time_Spans: "Number of Time Spans",
		TF_Set_to_end_of_data: "Set to end of data",
		TF_Scheduled_Emails: "Scheduled Emails",
		TF_Report_Name: "Report Name",
		TF_Schedule_New_Report: "Schedule New Report",
		TF_Edit_Scheduled_Report: "Edit Scheduled Email",
		TF_Enter_name_for_report: "Enter name for report",
		TF_Report_Type: "Report Type",
		TF_Select_type: "Select type",
		TF_Select_Frequency: "Select frequency",
		TF_Separate_email_with_semicolon: "Separate multiple email addresses with a semicolon",
		TF_Send_email_every: "Send email every",
		TF_Enter_email_addresses: "Enter email address(es)",
		TF_Enter_mobile_numbers: "Enter mobile number(s)",
		TF_Mobile_number: "Mobile number",
		TF_Select_Area: "Select Area",
		TF_Select_Options: "Select Options",
		TF_Add_Sequentially: "Add Sequentially",
		TF_Select_Manually: "Select Manually",
		TF_Page_Preview: "Page Preview",
		TF_Layout: "Layout",
		TF_Header_and_Footer: "Header & Footer",
		TF_Header: "Header",
		TF_Footer: "Footer",
		TF_Portrait: "Portrait",
		TF_Landscape: "Landscape",
		TF_ShowConsecutiveNoDatas: "Show Max Consec. No Datas",

		//Site Settings
		TF_Add_a_Logger: "Add a Logger",
		TF_Add_a_Transmitter: "Add a Transmitter",
		TF_Add_an_Alarm: "Add an Alarm",
		TF_Change_Settings: "Change Settings",
		TF_Site_Layout: "Site Layout",
		TF_Site_Layout_description: "Buildings, Zones, Groups, Departments, Channel Alarms",
		TF_Parameter_Setup: "Parameter Setup",
		TF_Parameter_Setup_description: "Physical, Calculated, Axes, Thresholds, Charting, Parameter Alarms",
		TF_Zone_Graphic_Setup: "Zone Graphic Setup",
		TF_Zone_Graphic_Setup_description: "Floorplans, Pointers, Group Overlays",
		TF_Alarm_Setup: "Alarm Emailing & SMS",
		TF_Alarm_Setup_description: "Alarm Teams, Emails, Phone Numbers",
		TF_Database_Settings: "Database Settings",
		TF_Database_Settings_description: "Backup & Restore, Darca software sync",
		TF_General_Settings: "General Settings",
		TF_General_Settings_description: "Display Language",
		TF_Logger_Configuration: "Logger Configuration",
		TF_Site_Management: "Site Management",
		TF_Calibration_Schedule: "Calibration Schedule",
		TF_Import_Options: "Import Options",

		//Site Layout
		TF_SiteLayoutTitle: "Site Layout",
		TF_No_txs_in_dept: "No transmitters in department",
		TF_No_depts_in_site: "No departments exist",
		TF_View_Departments: "View Departments",

		//Parameter Setup
		TF_Display_Param_on: "Display Parameter on:",
		TF_Range_from: "Range from",
		TF_Range_to: "to",
		TF_Chart_Number: "Chart Number",
		TF_Solid: "Solid",
		TF_Dashed: "Dashed",
		TF_Dash: "Dash",
		TF_Dot: "Dot",
		TF_DashDot: "DashDot",
		TF_DashDotDot: "DashDotDot",
		TF_Plot_Warning_Levels: "Plot Threshold Levels",
		TF_Plot_Alarm_Levels: "Plot Alarm Levels",
		TF_General: "General",
		TF_Alarms: "Alarms",
		TF_Charting: "Charting",
		TF_Standard_Formula: "Standard Formula",
		TF_Cumulative_Formula: "Cumulative Formula (total)",
		TF_Fluctuation_Window: "Fluctuation Window (max-min)",
		TF_Rolling_Average: "Rolling Average",
		TF_Add_Parameter: "Add parameter",
		TF_Click_to_change_colour: "Click to change the colour",
		TF_Physical: "Physical",
		TF_Calculated: "Calculated",
		TF_Enter_formula_here: "Enter formula here",
		TF_Window_Readings: "Window Readings",
		TF_Divisor: "Divisor",
		TF_Roundings_per_hour: "e.g. roundings per hour",
		TF_Threshold_Levels: "Threshold Levels",
		TF_Alarm_Levels: "Alarm Levels",
		TF_Threshold_Plot_Style: "Threshold Plot Style",
		TF_Alarm_Plot_Style: "Alarm Plot Style",
		TF_Return_abs_value: "Return aboslute value",
		TF_Find_int_ceiling: "Find integer ceiling",
		TF_Calculate_cos: "Calculate cosine",
		TF_Calculate_hyp_cos: "Calculate hyperbolic cosine",
		TF_Calculate_exp_func: "Calculate exponential function: ex",
		TF_Calculate_floor: "Find integer floor",
		TF_Calculate_hyp_right_tri: "Calculate hypotenuse of right triangle",
		TF_Calculate_nat_log: "Calculate natural logarithm",
		TF_Calculate_b10_log: "Calculate base-10 logarithm",
		TF_Return_larger_of_two: "Return larger of two values",
		TF_Return_smaller_of_two: "Return smaller of two values",
		TF_Calculate_sine: "Calculate sine",
		TF_Calculate_hyp_sine: "Calculate hyperbolic sine",
		TF_Find_sq_root: "Find square root",
		TF_Init_pseudorand_series: "Initialize pseudorandom series",
		TF_Calculate_tan: "Calculate tangent",
		TF_Calculate_hyp_tan: "Calculate hyperbolic tangent",

		//Alarm Setup
		TF_AlarmSetupTitle: "Alarm Setup",
		TF_AddAlarmUser: "Add Alarm User",
		TF_Contact: "Contact",
		TF_Area: "Select Area",
		TF_Alarms_For_User: "Alarms for this user:",
		TF_Data_Alarms: "Data Alarms:",
		TF_Equipment_Alarms: "Equipment Alarms:",
		TF_Custom_Alarms: "Custom Alarms:",
		TF_Channel_in_alarm_description: "Outside alarm limits (Channel in alarm)",
		TF_Reliability_description: "Reliability (3x consecutive 'No Datas')",
		TF_Low_battery_description: "Transmitter battery low",
		TF_Application_Shutdown: "Application Shutdown",
		TF_Unable_contact_logger: "Unable to contact logger / logger power supply removed",
		TF_Monitored_location_for_user: "Monitored locations for user:",
		TF_Emails: "Emails",
		TF_SMS_Texting: "SMS Texting",
		TF_Enter_name: "Enter name",

		//General Settings
		TF_Automatic_Updates: "Automatic Updates",
		TF_Webviewer_Upload: "Webviewer Upload",
		TF_GPRS_Server: "GPRS Server",
		TF_Language: "Language",
		TF_Automatically_update_data: "Automatically update data",
		TF_Every_day_at: "Every day at",
		TF_Synchronise_update_with_logging: "Synchronise update with logging",
		TF_Data_downloaded_after_logged: "Data will be downloaded just after being logged",
		TF_Upload_CSV_for_Webviewer: "Upload CSV data for Eltek Webviewer",
		TF_Upload_data_for_last: "Upload data for last",
		TF_Split_data_for_buildings: "Split data into separate files for each building",
		TF_Host_address: "Host Address",
		TF_Port: "Port",
		TF_Leave_blank_if_using_Gateway: "Leave folder blank if using Eltek Gateway",
		TF_Test_Connection: "Test Connection",
		TF_DC_Language: "Darca Connect language",
		TF_Select_a_time: "Select a time",

		//Site Management
		TF_Site_Details: "Site Details",
		TF_Billing: "Billing",
		TF_Select_Department: "Select Department",
		TF_Delete_User: "Delete User",
		TF_Delete_User_Are_You_Sure: "Are you sure you want to delete this user?",
		TF_User_successfully_updated: "User successfully updated. Please allow up to 15 minutes for the changes to apply.",
		TF_Current_User_successfully_updated: "Successfully updated. Please login again to apply changes.",
		TF_User_successfully_added: "User successfully added. They will receive an email with instructions on how to log in.",
		TF_User_successfully_added_Existing: "User already existed and has been added to this site.  Their existing password has not been updated.",
		TF_User_successfully_deleted: "User successfully deleted.",
		TF_Site_Name: "Site Name",
		TF_Head_of_Site: "Head of Site",
		TF_Access_Site: "Access Site",
		TF_Access_Code: "Access Code",
		TF_Access_Code_Placeholder: "Enter code here",
		TF_Access_Code_Find: "You will need the customer to supply their access code - this can be found in Site Settings > Site Management, in the Help tab",
		TF_Access_Code_Error: "Incorrect access code entered. Please verify that the code is correct and try again.",
		TF_Access_Code_Updated: "Access Code Successfully Updated",
		TF_Site_Updated: "Site Successfully Updated",
		TF_Site_Image: "Site Image",
		TF_Click_image_to_change: "Click on image to view a larger version",
		TF_Change_Image: "Change Image",
		TF_Show_Expanded: "Show Expanded",
		TF_Require_access_code_for: "Require the access code to be supplied when accessing the site by",
		TF_Editing_User: "Editing User",
		TF_Adding_User: "Adding User",
		TF_All_Users: "All Users",
		TF_Users_in_dept: "Users in Department",
		TF_Add_User: "Add User",
		TF_Create_User: "Create User",

		//Notifications
		TF_Notifications: "Notifications",
		TF_NotificationsCurrent: "Current",
		TF_NotificationsHighAlarm: "High Alarm",
		TF_NotificationsLowAlarm: "Low Alarm",
		TF_No_notifications: "No notifications",

		//Current Alarms
		TF_CurrentAlarms_Title: "Current Alarms",
		TF_CurrentAlarms_None: "There are currently no alarms activated",
		TF_Pin_Chart: "Pin Chart",
		TF_Pin_Chart_Blue: "Pin Chart (Blue)",

		//User Settings
		TF_User_Details: "User Details",
		TF_Update_details: "Update Details",
		TF_Account_Information: "Account Information",
		TF_Job_Title: "Job Title",
		TF_Account_Details: "Account Details",
		TF_User_Type: "User Type",
		TF_Created: "Created",
		TF_Edit_User_Details: "Edit User Details",

		//Distributor Page
		TF_View_site: "View Site",
		TF_View_Sites: "View Sites",
		TF_Use_Defaults: "Use Defaults",
		TF_Default_Contact_Details: "Default Contact Details",
		TF_Contact_details_for_customer: "These are the contact details that users for this site will see",

		//Admin Area
		TF_Sites: "Sites",
		TF_All_Sites: "All Sites",
		TF_Distributors: "Distributors",
		TF_Customer: "Customer",
		TF_Distributor: "Distributor",
		TF_ProductType: "Product",
		TF_CompanyName: "Company",

		TF_NumberOfUsers: "Users",
		TF_NumberOfTransmitters: "Transmitters",
		TF_Assign_Distributor: "Assign Distributor",
		TF_Assigned: "Assigned",
		TF_Assign_Sites: "Assign Sites",
		TF_Current_Distributor: "Current Distributor",
		TF_Editing_Distributor: "Editing Distributor",
		TF_Adding_Distributor: "Adding Distributor",
		TF_Distributor_successfully_updated: "Distributor successfully updated. Please allow up to 15 minutes for the changes to apply.",
		TF_Distributor_successfully_added: "Distributor successfully added. They will receive an email with instructions on how to log in.",

		//Help
		TF_If_you_require_help: "If you require help with using any features of this software, please contact",

		//AFTER FEB 2020 - please add new strings after here
		TF_Battery_Levels: "Battery Levels",
		TF_Very_Low: "Very Low",
		TS_Low: "Low",
		TS_Unknown: "Unknown",
		TF_Add_Widgets: "Add Widgets",
		TS_at: "at",
		TF_TransmitterSerialNumber: "Tx S/N",
		TF_PercentNoDataPoints: "% No Datas",
		TF_ConsecutiveNoDatas: "Max Consec. No Datas",
		TF_Battery_Levels_Abbr: "Batt. Level",
		TF_Signal: "Signal",
		TF_Parameter_Abbr: "Param",
		TF_Time_of_First_Max_Value_Abbr: "Time Of 1st Max",
		TF_Time_of_First_Min_Value_Abbr: "Time Of 1st Min",
		TF_Standard_Deviation_Abbr: "Std. Deviation",
		TF_Thresholds_Readings_Within: "T: Within Readings",
		TF_Thresholds_Percent_Within: "T: Within %",
		TF_Thresholds_Readings_Outside: "T: Outside Readings",
		TF_Thresholds_Percent_Outside: "T: Outside %",
		TF_Thresholds_Readings_Above: "T: Above Readings",
		TF_Thresholds_Percent_Above: "T: Above %",
		TF_Thresholds_Readings_Below: "T: Below Readings",
		TF_Thresholds_Percent_Below: "T: Below %",
		TF_Alarm_Readings_Within: "A: Within Readings",
		TF_Alarm_Percent_Within: "A: Within %",
		TF_Alarm_Readings_Outside: "A: Outside Readings",
		TF_Alarm_Percent_Outside: "A: Outside %",
		TF_Alarm_Readings_Above: "A: Above Readings",
		TF_Alarm_Percent_Above: "A: Above %",
		TF_Alarm_Readings_Below: "A: Below Readings",
		TF_Alarm_Percent_Below: "A: Below %",
		TF_No_Data_Found: "(NO DATA FOUND)",
		TF_Top: "Top",
		TF_Building_Name: "Building Name",
		TF_Zone_Name: "Zone Name",
		TF_Group_Name: "Group Name",
		TF_Parameter_Name: "Parameter Name",
		TF_Building_Id: "Building ID",
		TF_Zone_Id: "Zone ID",
		TF_Group_Id: "Group ID",
		TF_Location_Id: "Location ID",
		TF_Parameter_Id: "Param ID",
		TF_Plotted: "Plotted",
		TF_Graph_Index: "Graph Index",
		TF_Plot_Axis: "Plot Axis",
		TF_Plot_Colour: "Plot Colour",
		TF_Thresholds_HiSet: "Thresholds.HiSet",
		TF_Thresholds_HiValue: "Thresholds.HiValue",
		TF_Thresholds_LoSet: "Thresholds.LoSet",
		TF_Thresholds_LoValue: "Thresholds.LoValue",
		TF_Thresholds_Plotted: "Thresholds.Plotted",
		TF_Alarm_HiSet: "Alarm.HiSet",
		TF_Alarm_HiValue: "Alarm.HiValue",
		TF_Alarm_LoSet: "Alarm.LoSet",
		TF_Alarm_LoValue: "Alarm.LoValue",
		TF_Alarm_Plotted: "Alarm.Plotted",
		TF_Range_Min: "Range.Min",
		TF_Total: "Total",
		TF_Thresholds_Time_Within: "ThresholdsTimeWithin",
		TF_Thresholds_Colour_Code_Percent_Within: "ThresholdsColourCodePercentWithin",
		TF_Thresholds_Time_Outside: "ThresholdsTimeOutside",
		TF_Thresholds_Time_Above: "ThresholdsTimeAbove",
		TF_Thresholds_Time_Below: "ThresholdsTimeBelow",
		TF_Alarm_Time_Within: "AlarmTimeWithin",
		TF_Alarm_Colour_Code_Percent_Within: "AlarmColourCodePercentWithin",
		TF_Alarm_Time_Outside: "AlarmTimeOutside",
		TF_Alarm_Time_Above: "AlarmTimeAbove",
		TF_Alarm_Time_Below: "AlarmTimeBelow",
		TF_Fluctuation_Bands_Validator: "2 Bands required as a minimum",
		TF_DC_ALARM: "DC Alarm",
		TF_View_in_table: "View in table",
		TS_Back: "Back",
		TF_Wide_brackets: "(Wide)",
		TF_Area_of_site: "Area of site",
		TF_Only_channel_alarm_reliability_SMS: "Only 'Channel in alarm' and 'Reliability' alarms are sent as SMS; the others are email-only.",
		TF_Site_Language: "Site Language",
		TF_Valid_format_example: "Please enter mobile numbers in international format and without dashes or spaces, e.g. +447890123456",
		TF_Parameter_successfully_updated: "The parameter was successfully updated",
		TS_Lite: "Lite",
		TS_Standard: "Standard",
		TS_Plus: "Plus",
		TS_Pro: "Pro",
		TS_Enabled: "Enabled",
		TS_Disabled: "Disabled",
		TF_ChangeAlarmNotificationsToEnabled: "Enable Alarms Notifications",
		TF_ChangeAlarmNotificationsToDisabled: "Disable Alarms Notifications",
		TF_ChangeProductTypeTo: "Change Product Type To",
		TF_PasswordRules: "Password must be be a minimum of 8 characters, and include capital letters, lowercase letters, numbers and special characters.",
		TF_Subject: "Subject",
		TF_Email_Content: "Email Content",
		TF_No_zone_graphics_set_up: "No zone graphics exist for this site - to configure these, go to Zone Graphic Setup in the Darca Hub desktop software",
		TF_icon_and_pin: "icon, or use the 'Add Widgets' menu below, which will allow you to select what you want to pin.",
		TF_This_is_language_for_site: "This is the language that all automated reports will be sent in - to set your display language, please go to",
		TF_Site_language_changed: "The site language was successfully changed",
		TF_Alarm_Notifications_in_DC: "Use Darca Connect for alarm generation",
		TF_Site_config_changed: "Site configuration was changed successfully",
		TF_Configure_Site: "Configure Site",
		TF_has_sent_email: "has sent you an email",
		TF_item_was_copied: "Item was copied to the clipboard",
		TF_Email_sent_successfully: "Your email was sent successfully",
		TS_Teams: "Teams",
		TS_Team: "Team",
		TF_Add_Alarm_Team: "Add Alarm Team",
		TF_Entire_Building: "Entire Building",
		TS_Yes: "Yes",
		TS_No: "No",
		TF_Change_Password: "Change Password",
		TF_Change_Password_Successful: "Password successfully updated.",
		TF_Current_Password: "Current Password",
		TF_New_Password: "New Password",
		TF_Confirm_Password: "Confirm Password",
		TF_Password_Mismatch: "Password Mismatch",
		TF_Widgets_Added_To_Dashboard: "The selected widgets were added to the dashboard",
		TF_Widgets_Limit_Exceeded: "ERROR: This would exceed the limit of 32 Charts/Zone Graphics and 128 Meters/Gauges",

		// AFTER APRIL 2020 - Please add all new notes under here:
		TS_Change_Area: "Change Area",
		TF_Adding_Site: "Adding Site",
		TF_Create_Site: "Create Site",
		TF_Create_Distributor: "Create Distributor",
		TF_Error_Creating_Site: "Error Creating Site",
		TF_Site_Created_Successfully: "Site Created Successfully",
		TF_Search_No_Matches: "No matches found",
		TF_Search_EmailReport: "Email Report",
		TF_Search_AlarmTeam: "Alarm Team",
		TF_Search_Building: "Building",
		TF_Search_Group: "Group",
		TF_Search_Location: "Location",
		TF_Search_Note: "Note",
		TF_Search_Squirrel: "Squirrel",
		TF_Search_User: "User",
		TF_Search_Zone: "Zone",
		TF_Search_ZoneGraphicCaption: "Zone Caption",
		TF_No_Zone_Graphic_Found: "No Zone Graphic Found",
		TS_Upload: "Upload",
		TF_ZoneGraphicSetup_AddCaption: "Add Caption",
		TF_ZoneGraphicSetup_AddGroup: "Add Group",
		TS_Are_You_Sure: "Are You Sure?",
		TF_EmailReports_Invalid: "Please complete report settings below",
		TF_Demo_Sites: "Demo Sites",
		TF_Transmitter_Types: "Transmitter Types",
		TF_NumberOfTransmitterChannels: "Transmitters Channels",
		TF_LogIntervalPoints: "Log Interval Points",

		TF_ChangeMaxAge: "Change Max Readings Age (in Months)",
		TF_ConnectionError: "Connection Error, please try reloading the page",

		TF_SendInvite: "Send Invite",
		TF_SendInviteSuccessful: "Invite Sent Successfully",

		TF_Logs: "Logs",
		TF_Category: "Category",
		TF_Message: "Message",

		TF_Display_Site_ID: "Display Site ID",
		TF_Site_ID: "Site ID",

		TF_Status: "Status",
		TF_Rssi: "Rssi",

		TF_Alarm_Recording_in_DC: "Use Darca Connect to Record Alarms",
		TS_AlarmTeamsInfo: "An Alarm Team is one or more people that will receive alarm notifications for a defined area of the site, via SMS or email.",
		TF_Select_Alarm_Types: "Select alarm types",
		TF_Add_Email_Addresses_For_Alarm: "Email addresses that will receive alarms",
		TF_Add_Mobiles_For_Alarm: "Mobile numbers that will receive alarms",
		TF_Add_Site_Area_For_Alarm: "Area of the site to receive alarms for",
		TF_Change_widget_type: "Change widget type",
		TS_Clear_All: "Clear All",
		TF_Display_Data: "Display Data",
		TF_Select_Charts_To_Display: "Select charts to display",
		TF_Toggle_Notes: "Toggle Notes",

		// 2022
		TS_Real_Value: "Real Value",
		TF_Alarm_Type: "Alarm Type",
		TF_Alarm_Threshold: "Alarm Threshold",
		TF_Current_Value: "Current Value",
		TS_AlarmHistoryReport: "Alarm Report",
		TF_FirstReading: "First Reading",
		TF_Logger_Configuration_Description: "Configure Loggers",
		TF_Log_Interval: "Log Interval",
		TF_Sample_Interval: "Sample Interval",
		TF_Channels_Used: "Channels Used",
		TF_Channel_Number: "Channel Number",
		TF_High_Set: "High Set",
		TF_High_Start: "High Start",
		TF_Low_Set: "Low Set",
		TF_Low_Start: "Low Start",
		TF_Delay: "Delay",
		TF_Transmitter_Channel: "Tx Channel",
		TF_Transmit_Interval: "Transmit Interval",
		TF_Input: "Input",
		TS_To: "to",
		TF_Change_Pending: "Change Pending",
		TF_No_Change: "No Change",
		TF_Channels: "Channels",
		TF_Channel: "Channel",
		TF_SMS_Numbers: "SMS Numbers",
		TF_Phone_Index: "Index",
		TS_All: "All",
		TF_Phone_Number_Missing: "Phone Number Missing",
		TF_Phone_Number_Invalid: "Phone Numbers Must be in the +44... format",
		TF_Phone_Number_TooLong: "Phone Numbers Must be 20 digits or less",
		TF_Phone_Description_TooLong: "Description Must be 20 characters or less",
		TF_Edit_Inprogress: "Please complete your edit before saving.",
		TF_Group_Missing: "Group Missing",
		TF_Pending_Changes: "Pending Changes",
		TF_Current_Values: "Current Values",
		TF_Squirrel_NoConnection: "Remote configuration is unavailable for this logger",
		TF_Only_Channel_In_Alarms_Sent_To_Mobile: "*Only 'Channel in Alarm' alerts will be sent to mobile numbers",
		TF_Toggle_First_Reading: "Toggle First Reading",
		TF_Continuous: "Continuous",
		TS_5_Minutes: "5 Minutes",
		TS_10_Minutes: "10 Minutes",
		TS_Logger_Interval: "Logger Interval",
		TS_1_Hour: "1 Hour",
		TF_Show_Tooltips: "Show Tooltips",
		TF_Hide_Tooltips: "Hide Tooltips",
		TF_Show_StripLine: "Show Limits",
		TF_Hide_StripLine: "Hide Limits",
		TF_Show_Notes: "Show Notes",
		TF_Hide_Notes: "Hide Notes",
		TF_Small_Notes: "Small Notes",
		TF_Medium_Notes: "Medium Notes",
		TF_Large_Notes: "Large Notes",
		TS_Show_Chart: "Show Chart",
		TS_Show_Location_On_Zone_Graphic: "Show Location on Zone Graphic",
		TS_Clear_Alarm: "Clear Alarm",
		TF_Toolips: "Tooltips",
		TF_Limits: "Limits",
		TF_Apply_Timescale_Changes_To: "Apply Timescale Changes To",
		TF_Site: "Site",
		TF_Documentation: "Documentation",
		TF_Release_Notes: "Release Notes",
		TF_Manuals: "Manuals",
		TS_Date: "Date",
		TS_New_Release_Notes: "New Documentation, click to view",
		TF_1Day: "1 Day",
		TF_2Day: "2 Days",
		TF_3Day: "3 Days",
		TF_4Day: "4 Days",
		TF_5Day: "5 Days",
		TF_6Day: "6 Days",
		TF_7Day: "7 Days",
		TF_Get_SQI_File: "Get SQI File",
		TF_Upload_SQI_File: "Upload SQI File",
		TF_Upload_Full_Text: "Update logger on Darca Connect and send the configuration to the logger",
		TF_Upload_Full_TextSub: "(the logger must be actively sending data to the Eltek Gateway)",
		TF_Upload_Table_Text: "Only update logger on Darca Connect",
		TF_Upload_Table_TextSub: "(the configuration has been applied manually to the logger)",

		TF_SMS_Usage: "SMS Usage",
		TF_SMS_Segments_Used: "SMS Segments Used",
		TS_Of: "of",
		TF_SMS_Alarms: "Use SMS for Alarm Notifications",
		TF_SMS_AnnualSegmentLimit: "Annual SMS Segment Limit",

		TF_Layers_SendToBack: "Send to Back",
		TF_Layers_SendBackward: "Send Backward",
		TF_Layers_BringForward: "Bring Forward",
		TF_Layers_BringToFront: "Bring To Front",

		TF_Description: "Description",
		TF_ContractStart: "Contract Start",

		TS_Unclear_Alarm: "Un-clear Alarm",

		TF_Include_Performance_Statistics: "Include Performance Statistics",
		TF_Refresh_Values: "Refresh Values",
		TF_Refreshing_Values: "Refreshing Values",

		TF_Mute: "Mute",
		TF_Unmute: "Unmute",
		TF_Muted: "Muted",
		TF_Unmuted: "Unmuted",
		TF_OFF: "OFF",
		TF_Log: "Log",
		TF_Turning_On: "Turning ON",
		TF_Turning_Off: "Turning OFF",
		TF_LQI_Limit: "Limit",
		TF_LQI_Average: "Average",
		TF_LQI_Good: "Good",
		TF_LQI_Excellent: "Excellent",
		TF_Darca_hub_offline: "Darca Hub offline",
		TF_Darca_Hub_Offline_Warning: "Darca Hub is not active. Darca Connect will not receive the latest values until the issue has been resolved.",
		TF_Error_HighSet_Below_LowSet: "High Set cannot be less than Low Set",
		TF_Can_Maintain_Users: "Maintain Users?",
		TF_Toggle_Maintain_Users: "Toggle Maintain Users for |X|, are you sure?",

		TF_MuteChannelOnLogger: "Do you also want to mute this channel (|X|) on logger '|Y|'?",
		TF_UnmuteChannelOnLogger: "Do you also want to un-mute this channel (|X|) on logger '|Y|'?",

		TF_Email_Invalid: "Email is invalid",
		TF_Email_TooLong: "Email Must be 254 digits or less",

		TF_PsychometricChart: "Dual Parameter Chart",
		TF_Psychometric: "Dual Parameter",
		TF_SelectGroup: "Select Group",
		TF_XAxis: "X Axis",
		TF_YAxis: "Y Axis",

		TF_Notify: "Notify",
		TF_Notify_Off: "Do Not Notify",

		TF_ShowSafeArea: "Show Safe Area",
		TF_ShowSafeAreaThreshold: "Threshold Limits",
		TF_ShowSafeAreaAlarm: "Alarm Limits",
		TF_ShowStatistics: "Show Statistics",
		TF_ShowThresholds: "Show Thresholds",

		TF_Combined: "Combined",
		TF_End_of_data: "End of data",
		TF_Alarm_Range: "Alarm - Range",
		TF_Threshold_Range: "Threshold - Range",
		TF_No_Alarm: "<No Alarm>",
		TF_StandardDeviation: "Standard Deviation",
		TF_Duration: "Duration",
		TF_Combined_Chart: "Combined Chart",
		TF_Change_widget_period: "Change Period",
		TF_Change_widget_width: "Change Width",
		TS_Wide: "Wide",
		TS_Narrow: "Narrow",
		TS_WidthOneThird: "One Third",
		TS_WidthOneQuarter: "One Quarter",
		TS_WidthHalf: "Half",
		TS_WidthTwoThirds: "Two Thirds",
		TS_WidthThreeQuarters: "Three Quarters",
		TS_WidthFull: "Full",
		TS_ResetChart: "Reset Chart",
		TS_ResetAllCharts: "Reset All Charts",
		TS_ResetOnExit: "Reset On Exit",
		TF_Add_Temporary_Limits: "Add Temporary Limits",
		TF_Logging: "Logging",
		TF_Stopped: "Stopped",
		TF_StartRequest: "Awaiting Start",
		TF_StopRequest: "Awaiting Stop",
		TS_Switch_Site: "Switch Site",

		TS_Emergency_Alarms_Enabled: "Emergency SMS & Email Alarms Activated",
		TS_Emergency_Alarms_Enable: "Activate Emergency SMS & Email Alarms",
		TS_Emergency_Alarms_Disable: "Deactivate Emergency SMS & Email Alarms",
		TS_Emergency_Alarms_Warn: "These alarms will use SMS credits",

		TF_Data: "Data",
		TF_Plot: "Plot",
		TF_IsRequired: "is required",
		TF_LineThickness: "Line Thickness",
		TF_PlotStyle: "Plot Style",

		TF_Alarm_Contacts: "Alarm Contacts",

		TF_Darca_Hub_Offline_ModalAlert: "This alarm warns all users if the Darca Hub server stops contacting Darca Connect. Turning this alarm off is not advised as it could allow connection issues to go unnoticed.",
		TF_Darca_Hub_Offline_Subheading: "Why is this important?",
		TF_Darca_Hub_Offline_Explanation: "If connection issues remain for a significant period of time the hardware Dataloggers may reach full capacity and stop logging data. If this happens, no SMS or email alarms will be sent out and the environmental conditions will no longer be monitored.",
		TF_Darca_Hub_Offline_Button: "I understand, turn off anyway",

		TF_Add_Threshold: "Add Threshold",
		TF_SiteList_TXChannels_Tooltip: "On Locations / With a Transmitter Configured / Logging",
		TF_More_Errors: "*** More ***",
		TS_AlarmContinuousInfo: "SMS alarms will be generated at regular intervals while the alarm condition is present. Please be aware that this could use a large number of SMS credits so should be applied with caution.",
		TF_Axis_Labels: "Axis Labels",
		TF_MFA: "Multi-Factor Authentication",
		TF_MFARequired: "Multi-Factor Authentication Required",
		TF_MFARequest: "Send Code",
		TF_MFARequestAgain: "Re-Send Code",
		TF_MFAVerify: "Verify Code",
		TF_MFACodeInvalid: "The Code is Invalid or has Expired",
		TF_Configure_MFA: "Configure MFA",
		TF_Allow_MFA_Bypass: "Allow MFA Bypass",
	},
	es: {
		//Login Page
		TS_EmailAddress: "Correo electrónico",
		TS_Password: "Contraseña",
		TF_LogInForgotPasswordLink: "¿Olvidaste tu contraseña?",
		TF_LogInButton: "Iniciar sesión",
		//Index Page
		TS_Alarms: "Alarmas",
		TS_Time: "Hora",

		//Bug report links
		TF_Bug_Report_Form: "https://eltekdataloggers.atlassian.net/servicedesk/customer/portal/3",
	},
});

export default strings;

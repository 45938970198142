import { faExclamationCircle, faSignal1, faSignal2, faSignal3, faSignal4 } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactEcharts from "echarts-for-react";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { CardBody } from "reactstrap";
import local from "../../../../localization/strings";
import WidgetDropdownMenu from "../../../common/WidgetDropdownMenu";
import DateTimeFormat from "./DateTimeFormat";
import { WidgetTitleLevel1, WidgetTitleLevel2 } from "../../../common/WidgetTitles";

const DashGauge = ({ reading, textColour }) => {
	const [itemHeight, setItemHeight] = useState("230px");
	const [format, setFormat] = useState("{value}");
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		function gaugeHeight() {
			var w = window.innerWidth;
			if (w <= 576) {
				setItemHeight("230px");
			} else if (w > 576 && w <= 768) {
				setItemHeight("200px");
			} else if (w > 768 && w <= 992) {
				setItemHeight("230px");
			} else if (w > 992 && w <= 1200) {
				setItemHeight("170px");
			} else if (w > 1200 && w <= 1540) {
				setItemHeight("160px");
			} else {
				setItemHeight("220px");
			}
			return itemHeight;
		}

		const readingFormat = () => {
			if (reading) {
				if (typeof reading.logging === "boolean" && !reading.logging) {
					setFormat(local.TF_OFF);
				} else if (reading.realValue === null) {
					setFormat(local.TS_No_Data);
				} else {
					setFormat(reading.realValue.toFixed(reading.decimalPlaces) + reading.units);
				}
			}
			setLoading(false);
		};

		gaugeHeight();
		readingFormat();

		window.addEventListener("resize", gaugeHeight);
	}, [itemHeight, reading]);

	function getOptions() {
		const options = {
			animation: false,
			tooltip: {
				show: false,
			},
			toolbox: {
				show: false,
			},
			series: [
				{
					title: { offsetCenter: [0, "-122%"], color: textColour },
					name: reading.parameterName,
					type: "gauge",
					pointer: {
						width: 6,
					},
					detail: {
						formatter: format,
						offsetCenter: [0, "85%"],
						fontSize: 15,
						fontWeight: "bold",
						color: textColour,
					},
					data: [{ value: format === local.TF_OFF ? null : reading.realValue, name: reading.parameterName }],
					min: reading.rangeMin,
					max: reading.rangeMax,
					splitNumber: 2,
					axisLine: {
						lineStyle: {
							color: [[0.2, "#e63757"], [0.4, "#ffae19"], [0.6, "#18aa6a"], [0.8, "#ffae19"], [1, "#e63757"]],
							width: 8,
							shadowColor: textColour,
							shadowBlur: 2,
						},
					},
					splitLine: {
						show: false,
						length: 10,
					},
					axisTick: {
						show: false,
					},
					axisLabel: {
						color: textColour,
					},
				},
			],
		};

		options.series[0].axisLine.lineStyle.color = [];

		if (reading.redLo) {
			options.series[0].axisLine.lineStyle.color.push([reading.redLo, "#e63757"]);
		}

		if (reading.amberLo) {
			options.series[0].axisLine.lineStyle.color.push([reading.amberLo, "#ffae19"]);
		}

		if (reading.green === 0) {
			reading.green = 1;
		}
		options.series[0].axisLine.lineStyle.color.push([reading.green, "#18aa6a"]);

		if (reading.amberHi) {
			options.series[0].axisLine.lineStyle.color.push([reading.amberHi, "#ffae19"]);
		}

		if (reading.redHi) {
			options.series[0].axisLine.lineStyle.color.push([reading.redHi, "#e63757"]);
		}

		return options;
	}

	const Lqi = () => {
		if (!reading.transmitterLqi) {
			return null;
		}

		let lqiText = local.TF_LQI_Limit;
		let fa = faSignal1;
		switch (reading.transmitterLqi) {
			case "Excellent":
				lqiText = local.TF_LQI_Excellent;
				fa = faSignal4;
				break;
			case "Good":
				lqiText = local.TF_LQI_Good;
				fa = faSignal3;
				break;
			case "Average":
				lqiText = local.TF_LQI_Average;
				fa = faSignal2;
				break;
			case "Limit":
			default:
				break;
		}

		return (
			<div style={{ position: "absolute", left: 10, top: 10, color: textColour }}>
				<FontAwesomeIcon icon={fa} title={lqiText} />
			</div>
		);
	};

	if (!reading) {
		return null;
	}

	return (
		<CardBody>
			{loading ? (
				""
			) : (
				<>
					<div className="dash-gauge">
						<Lqi />
						<WidgetDropdownMenu color="secondary" textColour={textColour} size="sm" tag="div" options={[{ name: local.TF_View_Chart, locationId: reading.locationId, groupId: reading.groupId }]} />
						<ReactEcharts style={{ width: "90%", height: itemHeight, marginBottom: "-30px", marginLeft: "auto", marginRight: "auto", marginTop: "-10px" }} option={getOptions()} lazyUpdate={true} opts={{ renderer: "svg" }} />
					</div>
					<div className="text-center" style={{ color: textColour }}>
						<WidgetTitleLevel1 reading={reading} />
					</div>
					<div className="text-center small mb-0" style={{ color: textColour }}>
						<WidgetTitleLevel2 reading={reading} />
					</div>

					<p className="mt-2 mb-n2 small font-weight-bold text-center" style={{ color: textColour }}>
						{DateTimeFormat(reading.dateTime)} {reading.outOfDateReading && <FontAwesomeIcon className="danger-icon fs-1 ml-1" size="lg" icon={faExclamationCircle} title="OUT OF DATE" />}
					</p>
				</>
			)}
		</CardBody>
	);
};

DashGauge.propTypes = {
	reading: PropTypes.object.isRequired,
};

export default DashGauge;

import React, { useContext, useState, useEffect, Fragment } from "react";
import { Button, Row, Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, FormGroup, Label, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import local from "../../localization/strings";
import { UserPreferencesContext } from "../../context/userPreferences";
import PinSubmenu from "../common/PinSubmenu";
import { isFeatureEnabled, features } from "../../config";

const buttonDefaultClasses = "mr-1 btn-shadow btn-sm mb-sm-2 py-2 px-2 px-sm-3 py-sm-1";

const ChartToolbarNav = (props) => {
	const { getLocationsForGroupId } = useContext(UserPreferencesContext);
	const { groupId, compareIds } = props;
	const [pinTypes, setPinTypes] = useState([]);
	const [subMenuExpanded, setSubMenuExpanded] = useState(false);
	const [adjustMode, setAdjustMode] = useState("Both");

	const adjustOffset = (actionType) => {
		const main = adjustMode === "Both" || adjustMode === "MainOnly";
		const compare = adjustMode === "Both" || adjustMode === "CompareOnly";

		props.onAdjustOffset(actionType, main, compare);
	};

	useEffect(() => {
		const locations = getLocationsForGroupId(groupId);

		const types = [];

		locations.forEach((location) => {
			types.push({ name: `${local.TS_Pin} ${location.locationName}`, type: "DashChart", data: location.locationId });
			types.push({ name: `${local.TS_Pin} ${location.locationName} ${local.TS_Blue_Brackets}`, type: "DashChartBlue", data: location.locationId });
		});

		setPinTypes(types);
	}, [groupId, getLocationsForGroupId]);

	const expandSubMenu = () => {
		var subMenuToggle = document.getElementById("submenu-toggle");
		var subMenu = document.getElementById("submenu-pin");

		if (subMenu.classList.contains("submenu-hide")) {
			subMenuToggle.setAttribute("aria-expanded", "true");
			subMenu.setAttribute("aria-hidden", "false");
			subMenu.classList.remove("submenu-hide");
			subMenu.classList.add("show", "submenu-show");
			setSubMenuExpanded(true);
		} else {
			subMenuToggle.setAttribute("aria-expanded", "false");
			subMenu.setAttribute("aria-hidden", "true");
			subMenu.classList.remove("submenu-show", "show");
			subMenu.classList.add("submenu-hide");
			setSubMenuExpanded(false);
		}
	};

	return (
		<Fragment>
			<div id={props.isMaximised ? "toolbar-offset" : ""}>
				<Row id="chart-toolbar" className="mt-3">
					<Col className="col-2 col-sm-3 col-md-2">
						{props.isModified && (
							<Button
								className={`${buttonDefaultClasses} text-nowrap`}
								color="secondary"
								transform="shrink-3"
								onClick={() => {
									props.action("ResetThisChart");
								}}
							>
								<FontAwesomeIcon icon="undo" />
								<span className="d-none d-md-inline-block ml-2">{local.TS_Reset}</span>
							</Button>
						)}
					</Col>
					<Col className="col-8 col-sm-6 col-md-8 d-flex justify-content-center">
						<Button className={buttonDefaultClasses} color="outline-secondary" transform="shrink-3" onClick={() => adjustOffset("<<")}>
							<FontAwesomeIcon icon="angle-double-left" />
						</Button>
						<Button className={buttonDefaultClasses} color="outline-secondary" transform="shrink-3" onClick={() => adjustOffset("<")}>
							<FontAwesomeIcon icon="angle-left" />
						</Button>
						<Button className={buttonDefaultClasses} color="outline-info" transform="shrink-3" onClick={() => adjustOffset("H")}>
							<FontAwesomeIcon icon="home" />
						</Button>
						<Button className={buttonDefaultClasses} color="outline-danger" transform="shrink-3" onClick={props.onRefresh} title={local.TF_Refresh}>
							<FontAwesomeIcon icon="redo-alt" />
						</Button>
						<Button className={buttonDefaultClasses} color="outline-secondary" transform="shrink-3" onClick={() => adjustOffset(">")}>
							<FontAwesomeIcon icon="angle-right" />
						</Button>
						<Button className={buttonDefaultClasses} color="outline-secondary" transform="shrink-3" onClick={() => adjustOffset(">>")}>
							<FontAwesomeIcon icon="angle-double-right" />
						</Button>
					</Col>
					<Col className="col-2 col-sm-3 col-md-2">
						<UncontrolledDropdown size="sm" direction="up" className="allow-overflow float-right">
							<DropdownToggle caret color="info" transform="shrink-3" className={buttonDefaultClasses}>
								<FontAwesomeIcon icon="share-alt" />
								<span className="d-none d-md-inline-block ml-2">{local.TS_Options}</span>
							</DropdownToggle>
							<DropdownMenu className="menu-border-blue dropdown-menu">
								<Button onClick={() => expandSubMenu()} id="submenu-toggle" transform="shrink-3" aria-haspopup="true" aria-expanded="false" className="dropdown-item">
									{subMenuExpanded ? <FontAwesomeIcon icon="chevron-up" className="mr-1" /> : <FontAwesomeIcon icon="thumbtack" className="mr-1 pin-rotate" />}
									{local.TS_Pin}
								</Button>
								<div id="submenu-pin" tabIndex="-1" role="menu" aria-hidden="true" className="dropdown-submenu submenu-hide">
									<PinSubmenu types={pinTypes} className="mr-2 btn-shadow btn-sm mb-sm-2 p-3 py-sm-1 float-right btn btn-secondary" />
								</div>
								<div className="dropdown-divider" />
								<DropdownItem onClick={() => props.onExport()}>
									<FontAwesomeIcon icon="download" /> {local.TS_Download}
								</DropdownItem>
								<DropdownItem onClick={() => props.onPrint()}>
									<FontAwesomeIcon icon="print" /> {local.TS_Print}
								</DropdownItem>
								<DropdownItem onClick={() => props.onCopy()}>
									<FontAwesomeIcon icon="copy" /> {local.TS_Copy}
								</DropdownItem>
								{isFeatureEnabled(features.ZoneChartsEmail) && (
									<DropdownItem onClick={() => props.onEmail()}>
										<FontAwesomeIcon icon="envelope-square" /> {local.TS_Email}
									</DropdownItem>
								)}
							</DropdownMenu>
						</UncontrolledDropdown>
					</Col>
				</Row>
				<Row>
					<Col className="col-8 offset-2 col-sm-6 offset-sm-3 col-md-8 offset-md-2 d-flex justify-content-center">
						{compareIds && compareIds.length > 0 && (
							<>
								<FormGroup check>
									<Label check>
										<Input type="radio" name="radio1" defaultChecked onClick={() => setAdjustMode("Both")} />
										{local.TS_Both}
									</Label>
								</FormGroup>
								<FormGroup check className="ml-3">
									<Label check>
										<Input type="radio" name="radio1" onClick={() => setAdjustMode("MainOnly")} />
										{local.TF_Main_Only}
									</Label>
								</FormGroup>
								<FormGroup check disabled>
									<Label check className="ml-3">
										<Input type="radio" name="radio1" onClick={() => setAdjustMode("CompareOnly")} />
										{local.TF_Compare_Only}
									</Label>
								</FormGroup>
							</>
						)}
					</Col>
				</Row>
			</div>
		</Fragment>
	);
};

export default ChartToolbarNav;

import React, { Fragment } from 'react';
import { Card } from 'reactstrap'
import local from '../../../../localization/strings';

const DashStats = props => {

    const borderClass = props.borderType + " p-4 mb-2"

    return (
        <Fragment>
            <Card className={borderClass}>
                <h5>{props.string}: <strong>{props.number}</strong></h5>
                <small>{local.TF_Selected_area}: {props.selectedArea}</small>
                <small>{local.TS_For}: {props.duration}</small>
            </Card>
        </Fragment>
    )
}

export default DashStats
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from "reactstrap";
import Avatar from "../common/Avatar";
import local from "../../localization/strings";
import { CurrentSites, CurrentUserDisplayName, CurrentUserRole } from "../../api/auth";
import { siteGet } from "../../api/site";
import { logEvent } from "../../helpers/ga";
import { defaultDistributorDetails } from "../../config";

const ProfileDropdown = ({ admin }) => {
	const [displayName, setDisplayName] = useState("");
	const [distributorDetails, distributorDetailsOpen] = useState(false);
	const [siteData, setSiteData] = useState(null);
	const [userRole, setUserRole] = useState("");

	useEffect(() => {
		const LoadData = async () => {
			var result = await siteGet();

			setSiteData(result);
		};

		!admin && LoadData();

		setUserRole(CurrentUserRole());
		setDisplayName(CurrentUserDisplayName());
	}, [admin]);

	return (
		<>
			<Modal isOpen={distributorDetails} toggle={() => distributorDetailsOpen(!distributorDetails)} centered backdrop="static">
				<ModalHeader>
					<span className="float-left">{local.TS_Distributor_details}</span>
					<span className="float-right close-modal" style={{ position: "absolute", top: 15, right: 20 }} onClick={() => distributorDetailsOpen(!distributorDetails)}>
						X
					</span>
				</ModalHeader>
				<ModalBody>
					<Row>
						<Col xs={5} className="text-right">
							{local.TS_Name}:
						</Col>
						<Col xs={7}>{siteData && (siteData.distributorName || defaultDistributorDetails.name)}</Col>
						<Col xs={5} className="text-right">
							{local.TS_Phone_Number}:
						</Col>
						<Col xs={7}>
							<a href={`tel:${siteData && (siteData.distributorPhone || defaultDistributorDetails.tel)}`}>{siteData && (siteData.distributorPhone || defaultDistributorDetails.tel)}</a>
						</Col>
						<Col xs={5} className="text-right">
							{local.TS_EmailAddress}:
						</Col>
						<Col xs={7}>
							<a href={`mailto:${siteData && (siteData.distributorEmail || defaultDistributorDetails.email)}`}>{siteData && (siteData.distributorEmail || defaultDistributorDetails.email)}</a>
						</Col>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button onClick={() => distributorDetailsOpen(!distributorDetails)}>{local.TS_Close}</Button>
				</ModalFooter>
			</Modal>
			<UncontrolledDropdown nav inNavbar>
				<DropdownToggle nav className="pr-0">
					<Avatar name={displayName} />
				</DropdownToggle>
				<DropdownMenu right className="dropdown-menu-card">
					<div className="bg-white rounded-soft py-2">
						<DropdownItem tag={Link} to="/user_settings">
							{local.TS_UserSettings}
						</DropdownItem>
						{userRole === "Eltek" && (
							<DropdownItem className="text-success" tag={Link} to="/admin">
								{local.TF_Admin_Area}
							</DropdownItem>
						)}
						{userRole === "Distributor" && (
							<DropdownItem className="text-success" tag={Link} to="/distributor">
								{local.TS_Distributor_Area}
							</DropdownItem>
						)}
						{userRole !== "Eltek" && userRole !== "Distributor" && (CurrentSites() || []).length > 1 && (
							<DropdownItem className="text-success" tag={Link} to="/user">
								{local.TS_Switch_Site}
							</DropdownItem>
						)}
						<DropdownItem divider />
						{!admin && (
							<DropdownItem
								onClick={() => {
									logEvent("Profile Dropdown", "Distributor Details");
									distributorDetailsOpen(!distributorDetails);
								}}
							>
								{local.TS_Distributor_details}
							</DropdownItem>
						)}
						<DropdownItem href={local.TF_Bug_Report_Form} target="_blank">
							{local.TS_Report_an_issue}
						</DropdownItem>
						<DropdownItem divider />
						<DropdownItem className="text-warning" tag={Link} to="/authentication/logout">
							{local.TS_Log_out}
						</DropdownItem>
					</div>
				</DropdownMenu>
			</UncontrolledDropdown>
		</>
	);
};

export default ProfileDropdown;

import axios from "axios";
import { CurrentSiteIdGet } from "./userSettings";

export const getDepartments = async () => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "get",
			url: "v2/Departments",
			params: {
				siteId,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const saveDepartment = async (id, index, name, groupIds) => {
	const siteId = CurrentSiteIdGet();

	try {
		await axios({
			method: "post",
			url: "v2/Department",
			data: {
				siteId,
				department: {
					id,
					index,
					name,
				},
				setGroupIds: groupIds,
			},
		});

		return true;
	} catch (err) {
		console.error(err);
		return false;
	}
};

export const deleteDepartment = async (id) => {
	const siteId = CurrentSiteIdGet();

	try {
		await axios({
			method: "delete",
			url: "v2/Department",
			data: {
				siteId,
				id,
			},
		});

		return true;
	} catch (err) {
		console.error(err);
		return false;
	}
};

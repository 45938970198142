import React, { useState, useEffect } from "react";
import MultiPeriodChart from "./MultiPeriodChart";
import { groupChartList } from "../../../api/chart";

const MultiPeriodReportDownload = ({ filterData }) => {
	const [data, setData] = useState(undefined);
	const height = 450 / window.devicePixelRatio;
	const width = 700 / window.devicePixelRatio;

	useEffect(() => {
		const LoadData = async () => {
			var result = await groupChartList(filterData.groupId);

			const charts = [];

			for (const chartIndex in result) {
				for (let i = 1; i <= filterData.noOfTimeSpans; ++i) {
					charts.push({ timeSpan: i, chart: result[chartIndex] });
				}
			}
            setData(charts);
		};

		LoadData();
	}, [filterData]);

	return <div style={{ position: "absolute", top: -1000000, left: -1000000, zIndex: -1 }}>{data && data.map((item, index) => <div key={index} style={{width: width, height: height}} className={`download-mpr download_${index}`}><MultiPeriodChart chart={item.chart} timeSpan={item.timeSpan} filterData={filterData} heightOverride={height} /></div>)}</div>;
};

export default MultiPeriodReportDownload;
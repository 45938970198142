import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "reactstrap";
import ButtonIcon from "../common/ButtonIcon";
import ZoneChartV2 from "./ZoneChartV2";
import PageTitle from "../common/PageTitle";
import local from "../../localization/strings";
import SiteTreeMenu from "../common/SiteTreeMenu";
import { withRouter } from "react-router-dom";
import { logEvent } from "../../helpers/ga";
import { UserPreferencesContext } from "../../context/userPreferences";

const ChartLayout = ({ match }) => {
	const { savePreference, prefTooltip, prefsLoaded } = useContext(UserPreferencesContext);
	const [showSelect, setShowSelect] = useState(true);
	const [zoneId, setZoneId] = useState("");
	const [showFilter, setShowFilter] = useState(true);
	const [tooltip, setTooltip] = useState(false);

	useEffect(() => {
		if (!prefsLoaded) {
			return;
		}
		setTooltip(prefTooltip);
	}, [prefTooltip, prefsLoaded, tooltip]);

	const onZoneSelect = (data) => {
		setZoneId(data.substring(2));
		setShowSelect(false);
	};

	useEffect(() => {
		if (match && match.params && match.params.zoneId) {
			setZoneId(match.params.zoneId);
			setShowSelect(false);
			setShowFilter(false);
		} else {
			setZoneId("");
			setShowSelect(true);
			setShowFilter(true);
		}
	}, [match]);

	const toggleTooltip = () => {
		if (tooltip === "false") {
			savePreference("Chart_Tooltip", "true");
			setTooltip("true");
		} else {
			savePreference("Chart_Tooltip", "false");
			setTooltip("false");
		}
	};

	return (
		<>
			<div id="zone-chart-div">
				<Row>
					<Col>
						<PageTitle title={local.TS_ZoneCharts} className="float-left" />
					</Col>
					{showSelect ? (
						""
					) : (
						<>
							{showFilter && (
								<Col>
									<ButtonIcon
										outline
										className="float-right mt-3 dark-button mx-1"
										size="sm"
										icon="filter"
										iconAlign="left"
										color="dark"
										onClick={() => {
											logEvent("Zone Charts", "Select Zone Button");
											setShowSelect(true);
										}}
									>
										{local.TF_ZoneCharts_SelectZone}
									</ButtonIcon>
								</Col>
							)}
						</>
					)}
				</Row>
				{showSelect ? <SiteTreeMenu onClick={onZoneSelect} siteMapLevel={2} expandToLevel={2} /> : <ZoneChartV2 selectedZoneId={zoneId} tooltip={tooltip} toggleTooltip={toggleTooltip} />}
			</div>
		</>
	);
};

export default withRouter(ChartLayout);

import axios from "axios";
import { CurrentSiteIdGet } from "./userSettings";

export const apiConfigureLoggerChannel = async (serialNumber, channelNumber, highSet, highStart, lowSet, lowStart, alarmDelay, smsGroup, name, log) => {
	const siteId = CurrentSiteIdGet();

	try {
		await axios({
			method: "post",
			url: "v2/Configure/LoggerChannel",
			data: { siteId, serialNumber, channelNumber, highSet, highStart, lowSet, lowStart, alarmDelay, smsGroup, name, log },
		});
	} catch (err) {
		console.error(err);
	}
};

export const apiOutstandingLoggerChannelConfiguration = async (serialNumber) => {
	const siteId = CurrentSiteIdGet();

	try {
		return await axios({
			method: "get",
			url: "v2/Configure/OutstandingLoggerChannel",
			params: { siteId, serialNumber },
		});
	} catch (err) {
		console.error(err);
	}
};

export const apiSQIRequest = async (serialNumber) => {
	const siteId = CurrentSiteIdGet();

	try {
		return await axios({
			method: "post",
			url: "v2/Configure/SqiRequest",
			data: { siteId, serialNumber },
		});
	} catch (err) {
		console.error(err);
	}
};

export const apiSQIRequestOutstanding = async (serialNumber) => {
	const siteId = CurrentSiteIdGet();

	try {
		return await axios({
			method: "get",
			url: "v2/Configure/OutstandingSqiRequest",
			params: { siteId, serialNumber },
		});
	} catch (err) {
		console.error(err);
	}
};

export const apiSQIUpload = async (serialNumber, full, base64String) => {
	const siteId = CurrentSiteIdGet();

	try {
		return await axios({
			method: "post",
			url: "v2/Configure/SqiUpload",
			data: { siteId, serialNumber, full, base64String },
		});
	} catch (err) {
		console.error(err);
	}
};

export const apiSQIUploadOutstanding = async (serialNumber) => {
	const siteId = CurrentSiteIdGet();

	try {
		return await axios({
			method: "get",
			url: "v2/Configure/OutstandingSqiUpload",
			params: { siteId, serialNumber },
		});
	} catch (err) {
		console.error(err);
	}
};

export const apiSquirrelSMSNumbersGetList = async (serialNumber) => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "get",
			url: "v2/SquirrelSMSNumbers",
			params: { siteId, serialNumber },
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const apiConfigureLoggerSMSNumbers = async (serialNumber, smsNumbers) => {
	const siteId = CurrentSiteIdGet();

	try {
		await axios({
			method: "post",
			url: "v2/Configure/LoggerSMSNumbers",
			data: { siteId, serialNumber, smsNumbers },
		});
	} catch (err) {
		console.error(err);
	}
};

export const apiOutstandingLoggerSMSNumbersConfiguration = async (serialNumber) => {
	const siteId = CurrentSiteIdGet();

	try {
		return await axios({
			method: "get",
			url: "v2/Configure/OutstandingLoggerSMSNumbers",
			params: { siteId, serialNumber },
		});
	} catch (err) {
		console.error(err);
	}
};

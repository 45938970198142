import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Button, Card } from "reactstrap";
import Loader from "../../common/Loader";
import local from "../../../localization/strings";
import AddButton from "../../common/AddButton";
import Tooltip from "../../common/Tooltip";
import { isFeatureEnabled, features, roles } from "../../../config";
import { alarmTeamsList } from "../../../api/alarmTeams";
import AlarmTeam from "./AlarmTeam";
import PageTitle from "../../common/PageTitle";
import { CurrentUserHasRole } from "../../../api/auth";
import { dynamicSort } from "../../../helpers/sort";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AlarmTeams = () => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [selected, setSelected] = useState(null);
	const [deptsExist, setDeptsExist] = useState(false);
	const [usersExist, setUsersExist] = useState(false);
	const [showDepartments, setShowDepartments] = useState(false);
	const [showTeams, setShowTeams] = useState(false);

	const LoadData = useCallback(async () => {
		const result = await alarmTeamsList();

		if (result) {
			var deptId = result.map((item) => item.departmentId);
			if (deptId.includes(-1)) {
				setUsersExist(true);
			}
			if (deptId.some((el) => el > 0)) {
				setDeptsExist(true);
			}
		}

		setShowDepartments(isFeatureEnabled(features.Departments));
		setShowTeams(CurrentUserHasRole(roles.SiteAdmin));

		if (!isFeatureEnabled(features.Departments)) {
			setDeptsExist(false);
		}

		setData(result);
		setLoading(false);
	}, []);

	useEffect(() => {
		LoadData();
	}, [LoadData]);

	const select = (id) => {
		setSelected(id);
	};

	const reload = () => {
		LoadData();
		setSelected(null);
	};

	const Department = ({ name, id }) => {
		return (
			<>
				<h5>{name}</h5>
				{data
					.filter((x) => x.departmentId === id)
					.map((item) => (
						<Button key={item.id} onClick={() => select(item.id)} color="primary" className={`p-1 mb-1 w-100 department-alarm-user ${selected === item.id && "selected-alarm"}`}>
							{item.name}
						</Button>
					))}
			</>
		);
	};

	const Departments = () => {
		const departments = [];

		data.forEach((e) => {
			if (e.departmentId !== -1 && !departments.find((x) => x.id === e.departmentId)) {
				departments.push({ id: e.departmentId, name: e.departmentName, index: e.departmentIndex });
			}
		});

		departments.sort(dynamicSort("index"));

		return departments.map((e) => <Department key={e.id} id={e.id} name={e.name} />);
	};

	return (
		<>
			<PageTitle title={local.TF_AlarmSetupTitle} />
			<Card className="pl-1 py-2 pr-2">
				{loading ? (
					<Loader />
				) : (
					<Row>
						<Col md={4}>
							<div className="px-1" style={{ height: 500, overflowY: "auto", overflowX: "hidden" }}>
								{data && (
									<>
										{showDepartments && (
											<>
												<h4>{local.TS_Departments}</h4>
												{deptsExist ? (
													<>
														<Departments />
													</>
												) : (
													<p>{local.TS_None}</p>
												)}
											</>
										)}
										{showTeams && (
											<>
												<Tooltip placement="bottom" id="AlarmTeamTooltip" text={local.TS_AlarmTeamsInfo} />
												<h4>
													{local.TS_Teams} <FontAwesomeIcon icon="question-circle" size="xs" href="#" id="AlarmTeamTooltip" />
												</h4>

												{usersExist ? (
													<>
														{data
															.filter((x) => x.departmentId === -1)
															.map((item, i) => (
																<Button key={i} onClick={() => select(item.id)} color="secondary" className={`p-1 mb-1 w-100 alarm-user ${selected === item.id && "selected-alarm"}`}>
																	{item.name}
																</Button>
															))}
													</>
												) : (
													<p>{local.TS_None}</p>
												)}
											</>
										)}
									</>
								)}
								<AddButton string={local.TF_Add_Alarm_Team} clickFunction={() => select(-1)} buttonClasses={"p-1 w-100"} />
							</div>
						</Col>
						<Col md={8} className="pl-1">
							{selected && <AlarmTeam selected={selected} key={selected} reload={reload} />}
						</Col>
					</Row>
				)}
			</Card>
		</>
	);
};

export default AlarmTeams;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useRef, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button, Card, CardBody, Col, Input, Row, Spinner } from "reactstrap";
import { sendMFACode, sendMFAVerify } from "../../api/auth";
import local from "../../localization/strings";
import Section from "../common/Section";
import Logo from "../navbar/Logo";

const MFA = ({ siteId, onCancel, onDone, match }) => {
	const [codeSent, setCodeSent] = useState(false);
	const [sending, setSending] = useState(false);
	const [tried, setTried] = useState(false);
	const [error, setError] = useState(false);
	const accessCodeRef = useRef(null);

	const sendCode = useCallback(async () => {
		const urlSiteId = match?.params?.siteId;

		setSending(true);
		setTried(false);
		await sendMFACode(urlSiteId ?? siteId);
		setSending(false);
		setCodeSent(true);
	}, [siteId, match]);

	const verifyCode = useCallback(async () => {
		const urlSiteId = match?.params?.siteId;

		setSending(true);
		const result = await sendMFAVerify(urlSiteId ?? siteId, accessCodeRef.current.value);

		if (!result) {
			setError(true);
			setSending(false);
			setTried(true);
		} else {
			if (onDone) {
				onDone();
			} else {
				if (urlSiteId) {
					window.location.href = "/";
				} else {
					window.location.reload();
				}
			}
		}
	}, [siteId, onDone, match]);

	const Inner = () => (
		<div className="m-5 text-center">
			<p className="lead text-800 text-sans-serif font-weight-semi-bold">{local.TF_MFARequired}</p>
			{sending && <Spinner size="sm" />}
			{codeSent && !sending && (
				<>
					<Input innerRef={accessCodeRef} type="password" className={`form-control`} id="inputCode" placeholder={local.TF_Access_Code_Placeholder} />
					{error && <div className="text-danger">{local.TF_MFACodeInvalid}</div>}
				</>
			)}
			{!sending && (
				<div className="">
					{codeSent ? (
						<>
							<Button className="btn btn-primary btn-sm mt-3 mr-3" onClick={verifyCode}>
								<FontAwesomeIcon icon="badge-check" className="mr-2" />
								{local.TF_MFAVerify}
							</Button>
							{tried && (
								<Button className="btn btn-primary btn-sm mt-3 mr-3" onClick={sendCode}>
									<FontAwesomeIcon icon="paper-plane" className="mr-2" />
									{local.TF_MFARequestAgain}
								</Button>
							)}
						</>
					) : (
						<Button className="btn btn-primary btn-sm mt-3 mr-3" onClick={sendCode}>
							<FontAwesomeIcon icon="paper-plane" className="mr-2" />
							{local.TF_MFARequest}
						</Button>
					)}
					<Link className="btn btn-secondary btn-sm mt-3" to="/authentication/logout">
						<FontAwesomeIcon icon="sign-out" className="mr-2" />
						{local.TS_Log_out}
					</Link>
					{onCancel && (
						<Button className="btn btn-secondary btn-sm mt-3 ml-3" to="/authentication/logout" onClick={() => onCancel()}>
							{local.TS_Cancel}
						</Button>
					)}
				</div>
			)}
		</div>
	);

	if (siteId) {
		return <Inner />;
	}

	return (
		<Section className="py-0">
			<Row className="flex-center min-vh-100 py-6">
				<Col sm={11} md={9} lg={7} xl={6} className="col-xxl-5">
					<Logo />
					<Card>
						<CardBody>
							<Inner />
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Section>
	);
};

export default withRouter(MFA);

import axios from 'axios';
import { CurrentSiteIdGet } from './userSettings';

export const zonesGetListForSite = async (siteId, buildingId) =>{

    try{    
        const result = await axios({
            method: 'get',
            url: 'v2/Zones',
            params: {
                SiteId: siteId,
                BuildingId: buildingId
            }
        });

        return result.data;
    }
    catch(err)
    {
        console.error(err);
    }

}

export const zonesGetList = async (buildingId) =>{

    const siteId = CurrentSiteIdGet();

    return await zonesGetListForSite(siteId, buildingId);

}

export const apiZoneGet = async (buildingId, id) => {
	const zones = await zonesGetList(buildingId);

	return zones.find((x) => x.id === id);
};

export const apiZoneSave = async (id, buildingId, name, index) => {
	const siteId = CurrentSiteIdGet();

    if (!id) {
        id = -1;
    }

	try {
		await axios({
			method: "post",
			url: "v2/Zone",
			data: { siteId, zone: { siteId, id, buildingId, name, index } },
		});
	} catch (err) {
		console.error(err);
	}
};

export const apiZoneDelete = async (zoneId) => {
	const siteId = CurrentSiteIdGet();

	try {
		await axios({
			method: "delete",
			url: "v2/Zone",
			data: { siteId, zoneId },
		});
	} catch (err) {
		console.error(err);
	}
};

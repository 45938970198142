import moment, { isMoment } from "moment";
import "moment/min/locales";
import React, { useEffect, useState } from "react";
import Datetime from "react-datetime";
import { Button, Col, Form, Input, Label, Row } from "reactstrap";
import { siteReadingsMaxDateTime } from "../../../api/site";
import { logEvent } from "../../../helpers/ga";
import local from "../../../localization/strings";
import SiteTreeMultiSelect from "../../common/SiteTreeMultiSelect";
import StatsDisplay from "./StatsDisplay";

const StatisticsReportFilter = ({ triggerGenerate, filterData, onChange, emailReports }) => {
	const [period, setPeriod] = useState("Day");
	const [language, setLanguage] = useState("en");
	const [startTime, setStartTime] = useState(new Date());
	const [endTime, setEndTime] = useState(new Date());
	const [arrangeBy, setArrangeBy] = useState("Group");
	const [stats, setStats] = useState([]);
	const [locationIds, setLocationIds] = useState([]);
	const initialLocationIds = filterData.locationIds || [];

	useEffect(() => {
		var contentDiv = document.getElementById("statistics-report-div");

		if (contentDiv) {
			contentDiv.style.marginRight = "0";
		}
	}, []);

	useEffect(() => {
		setLanguage(local.getLanguage());
	}, []);

	useEffect(() => {
		setLocationIds(filterData.locationIds || []);
		setPeriod(filterData.period || "Day");
		setStartTime(filterData.startTime ? new Date(filterData.startTime) : null);
		setEndTime(filterData.endTime ? new Date(filterData.endTime) : null);
		setArrangeBy(filterData.arrangeBy || "Group");
		setStats(filterData.stats || []);
	}, [filterData]);

	const rowClasses = "mt-2";

	const handleArrangeChange = (e) => {
		logEvent("Statistics", "Filter Changed", `Arrange By: ${e.target.value}`);

		setArrangeBy(e.target.value);
		if (onChange) {
			onChange({ locationIds, stats, startTime, endTime, arrangeBy: e.target.value });
		}
	};

	const startTimeChange = (e, reset = false) => {
		try {
			if (isMoment(e)) {
				setStartTime(e.toDate());

				logEvent("Statistics", "Filter Changed", `Start Time: ${e.toDate()}`);

				if (onChange) {
					onChange({ locationIds, stats, startTime: e.toDate(), endTime, arrangeBy, period });
				}
			} else {
				if (reset) {
					setStartTime(moment().toDate());
					if (onChange) {
						onChange({ locationIds, stats, startTime: moment().toDate(), endTime, arrangeBy, period });
					}
				}
			}
		} catch {
			//Do nothing
		}
	};

	const endTimeChange = (e, reset = false) => {
		try {
			if (isMoment(e)) {
				setEndTime(e.toDate());

				logEvent("Statistics", "Filter Changed", `End Time: ${e.toDate()}`);

				if (onChange) {
					onChange({ locationIds, stats, startTime, endTime: e.toDate(), arrangeBy, period });
				}
			} else {
				if (reset) {
					setEndTime(moment().toDate());
					if (onChange) {
						onChange({ locationIds, stats, startTime, endTime: moment().toDate(), arrangeBy, period });
					}
				}
			}
		} catch {
			//Do nothing
		}
	};

	const toEndOfData = async () => {
		logEvent("Statistics", "Filter Changed", "End Time: End Of Data");

		const max = await siteReadingsMaxDateTime();

		setEndTime(moment(max));
		if (onChange) {
			onChange({ locationIds, stats, startTime, endTime: null, arrangeBy, period });
		}
	};

	const locationIdsChange = (e) => {
		logEvent("Statistics", "Filter Changed", `Location Ids: ${e}`);

		setLocationIds(e);
		if (onChange) {
			onChange({ locationIds: e, stats, startTime, endTime, arrangeBy });
		}
	};

	const statsChange = (e) => {
		logEvent("Statistics", "Filter Changed", `Stats: ${e}`);

		setStats(e);
		if (onChange) {
			onChange({ locationIds, stats: e, startTime, endTime, arrangeBy, period });
		}
	};

	const periodChange = (e) => {
		logEvent("Statistics", "Filter Changed", `Period: ${e}`);

		setPeriod(e);
		if (onChange) {
			onChange({ locationIds, stats, startTime, endTime, arrangeBy, period: e });
		}
	};

	const generate = () => {
		logEvent("Statistics", "Generate Click");

		triggerGenerate({ locationIds, stats, startTime, endTime, arrangeBy, period });
	};

	const DateTime = () => {
		return (
			<>
				<Row className={rowClasses}>
					<Col>
						<Label for="datetimepicker">{local.TS_Start_Date_Time}</Label>
					</Col>
					<Col>
						<Datetime locale={language} dateFormat="DD MMM YYYY" timeFormat="HH:mm" value={startTime} onBlur={(e) => startTimeChange(e, true)} onChange={startTimeChange} showTimeSelect timeConstraints={{ minutes: { step: 15 } }} utc />
					</Col>
				</Row>
				<Row className={rowClasses}>
					<Col>
						<Label for="datetimepicker">{local.TS_End_Date_Time}</Label>
					</Col>
					<Col>
						<Datetime locale={language} dateFormat="DD MMM YYYY" timeFormat="HH:mm" value={endTime} onBlur={(e) => endTimeChange(e, true)} onChange={endTimeChange} showTimeSelect timeConstraints={{ minutes: { step: 15 } }} utc />
						<Button color="falcon-info" className="w-100 mt-2" size="sm" onClick={toEndOfData}>
							{local.TF_Set_to_end_of_data}
						</Button>
					</Col>
				</Row>
			</>
		);
	};

	const PeriodSelect = ({ onPeriodChanged, onStartChanged, onEndChanged, period, start, end }) => {
		const periodChanged = (e) => {
			onPeriodChanged(e.target.value);
		};

		return (
			<>
				<Input type="select" className="form-control" value={period} onChange={periodChanged}>
					<option value="Day">{local.TF_LastDay}</option>
					<option value="Week">{local.TF_LastWeek}</option>
					<option value="Month">{local.TF_LastMonth}</option>
					<option value="Year">{local.TF_LastYear}</option>
					{!emailReports && <option value="Custom">{local.TF_Custom}</option>}
				</Input>
				{period === "Custom" ? <DateTime onStartChanged={onStartChanged} onEndChanged={onEndChanged} start={start} end={end} /> : null}
			</>
		);
	};

	return (
		<Form>
			<Row className="mb-2 pb-3">
				<Col sm={12}>
					<h5 className="mb-2">{local.TF_Area_to_report}</h5>
					<div className="border rounded-soft" style={{ height: 250, overflowY: "auto", overflowX: "hidden" }}>
						<SiteTreeMultiSelect expandToLevel={0} siteMapLevel={4} inline={true} onCheckedChanged={locationIdsChange} initialChecked={initialLocationIds.join(",")} />
					</div>
				</Col>
				<Col sm={6}>
					<h5 className="mt-3 mb-1">{local.TF_Stats_to_display}</h5>
					<StatsDisplay stats={stats} setStats={statsChange} />
				</Col>
				<Col sm={6}>
					<h5 className="mt-3 mb-1">{local.TF_Report_Time_Period}:</h5>
					<PeriodSelect onPeriodChanged={(e) => periodChange(e)} period={period} start={startTime} end={endTime} />

					<h5 className="mt-3 mb-1">{local.TF_Arrange_channels_by}</h5>
					<div className="form-inline mt-2">
						<Input type="radio" name="arrangeBy" id="arrangeGroup" value="Group" checked={arrangeBy === "Group"} onChange={handleArrangeChange} />
						<Label for="arrangeGroup">{local.TS_Group}</Label>
						<Input type="radio" name="arrangeBy" id="arrangeParam" value="Parameter" checked={arrangeBy === "Parameter"} onChange={handleArrangeChange} className="ml-3" />
						<Label for="arrangeParam">{local.TS_Parameter}</Label>
					</div>
				</Col>
			</Row>
			{triggerGenerate && (
				<Row className="border-top pt-3 mt-3">
					<Col>
						<Button color="primary" onClick={generate} className="col-md-3">
							{local.TS_Generate}
						</Button>
					</Col>
				</Row>
			)}
		</Form>
	);
};

export default StatisticsReportFilter;

import React from "react";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AddButton = ({clickFunction, buttonClasses, string, block = false, margin = 0, icon}) => {
	const extraClasses = block ? 'd-block' : ''
	const buttonIcon = icon || "plus";

	return (
		<Button onClick={clickFunction} color="falcon-success" className={`m-${margin} ${buttonClasses} ${extraClasses}`}>
			<FontAwesomeIcon icon={buttonIcon} /> {string}
		</Button>
	);
};

export default AddButton;

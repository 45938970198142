import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import arrayMove from "array-move";
import React, { useEffect, useState } from "react";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import { Button, Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { buildingsGetList } from "../../../api/building";
import { groupsGetList } from "../../../api/group";
import { apiLocationsGetList } from "../../../api/location";
import { parametersGetList } from "../../../api/parameter";
import { apiReindex } from "../../../api/site";
import { zonesGetList } from "../../../api/zone";
import local from "../../../localization/strings";

const Reindex = ({ show, entityType, parentId, done }) => {
	const [data, setData] = useState();

	useEffect(() => {
		const LoadData = async () => {
			switch (entityType) {
				case "Building":
					setData(await buildingsGetList());
					break;
				case "Zone":
					setData(await zonesGetList(parentId));
					break;
				case "Group":
					setData(await groupsGetList(parentId));
					break;
				case "Location":
                    const locations = await apiLocationsGetList(parentId);
                    const parameters = await parametersGetList();

                    locations.forEach(location => {
                        const p = parameters.find(x => x.id === location.parameterId);

                        if (p) {
                            location.name = p.name;
                        }
                    })

					setData(locations);
					break;
				default:
					done();
					break;
			}
		};

		if (show) {
			LoadData();
		} else {
			setData();
		}
	}, [show, entityType, parentId, done]);

	const save = async () => {
		let idList = data.map((item) => item.id).join();

		await apiReindex(entityType, idList);

		done();
	};

	const onSortEnd = ({ oldIndex, newIndex }) => {
		const newOrder = arrayMove(data, oldIndex, newIndex);
		setData(newOrder);
	};

	const SortableItem = sortableElement(({ value }) => (
		<Row>
			<Col className="drag-drop-box-chart-report mb-2 mx-3 p-1">
				<Label check className="w-100">
					{value.name}
					<FontAwesomeIcon icon="grip-lines" className="float-right mr-2 mt-1" />
				</Label>
			</Col>
		</Row>
	));

	const SortableContainer = sortableContainer(({ children }) => {
		return <div>{children}</div>;
	});

	return (
		<Modal isOpen={show} toggle={done} centered backdrop="static" size="lg">
			<ModalHeader toggle={done}>{local.TS_Sort}</ModalHeader>
			<ModalBody>
				<SortableContainer onSortEnd={onSortEnd}>{data && data.map((item, index) => <SortableItem key={`item-${index}`} index={index} value={item} />)}</SortableContainer>
				<Row>
					<Col>
						<Button color="primary" className="mt-2 float-left" type="button" onClick={save}>
							{local.TS_Save}
						</Button>
						<Button color="secondary" className="mt-2 ml-2 float-left" type="button" onClick={done}>
							{local.TS_Cancel}
						</Button>
					</Col>
				</Row>
			</ModalBody>
		</Modal>
	);
};

export default Reindex;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import local from "../../localization/strings";

const Error403 = () => {
	return (
		<Card className="text-center">
			<CardBody className="p-5">
				<div className="display-1 text-200 fs-error">403</div>
				<p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">{local.TF_403_AccessForbiddenTitle}</p>
				<hr />
				<p>
					{local.TF_403Forbidden}
					<a href="mailto:no-reply@darcaconnect.com" className="ml-1">
						{local.TF_error_contact_us}
					</a>
					.
				</p>
				<Link className="btn btn-primary btn-sm mt-3" to="/">
					<FontAwesomeIcon icon="home" className="mr-2" />
					{local.TF_404_Go_to_Dashboard}
				</Link>
			</CardBody>
		</Card>
	);
};

export default Error403;

import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import local from "../../../localization/strings";
import { UserPreferencesContext } from "../../../context/userPreferences";

const SearchResultLocation = ({ item, close }) => {
	const [location, setLocation] = useState(undefined);
	const { getLocation } = useContext(UserPreferencesContext);

	useEffect(() => {
		setLocation(getLocation(parseInt(item.id)));
	}, [item, getLocation]);

	return (
		<div className="p-2">
			{location && (
				<Link to={`/charts/zone_charts/${location.zoneId}/${location.groupId}`} className="text-nowrap" onClick={close}>
					<b>{local.TF_Search_Location}:</b> {item.entityName}
				</Link>
			)}
		</div>
	);
};

export default SearchResultLocation;

import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Card, Col, CustomInput, FormGroup, Input, Label, Row } from "reactstrap";
import { apiSQIUpload } from "../../../api/apiLoggerConfiguration";
import local from "../../../localization/strings";
import PageTitle from "../../common/PageTitle";

const UploadSqi = ({ match, history }) => {
	const [data, setData] = useState();
	const [type, setType] = useState("Full");
	const [image, setImage] = useState(null);
	const [changed, setChanged] = useState(false);
	const [uploadLabel, setUploadLabel] = useState(local.TS_Upload);

	useEffect(() => {
		setData({ serialNumber: match?.params?.serialNumber });
	}, [match]);

	const save = async (_, _values) => {
		await apiSQIUpload(match?.params?.serialNumber, type === "Full", image);

		history.push(`/site_settings/logger/${match?.params?.serialNumber}`);
	};

	const toBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const handleNewImage = async (e) => {
		setUploadLabel(e.target.files[0].name);
		const base64WithHeader = await toBase64(e.target.files[0]);
		const base64Loc = base64WithHeader.indexOf("base64,");

		setImage(base64WithHeader.substring(base64Loc + 7));

		setChanged(true);
	};

	return (
		<>
			<PageTitle title={local.TF_Upload_SQI_File} />
			{data && (
				<Card className="p-4">
					<AvForm model={data} onValidSubmit={async (e, values) => await save(e, values)}>
						<AvField name="serialNumber" label={local.TS_Serial_Number} disabled />
						<FormGroup tag="fieldset">
							<FormGroup check>
								<Label check>
									<Input type="radio" value="Full" name="uploadType" onChange={() => setType("Full")} checked={type === "Full"} /> {local.TF_Upload_Full_Text} <span className="small">{local.TF_Upload_Full_TextSub}</span>
								</Label>
							</FormGroup>
							<FormGroup check>
								<Label check>
									<Input type="radio" value="Tables" name="uploadType" onChange={() => setType("Tables")} checked={type === "Tables"} /> {local.TF_Upload_Table_Text} <span className="small">{local.TF_Upload_Table_TextSub}</span>
								</Label>
							</FormGroup>
						</FormGroup>
						<div className="site-image-link">
							<CustomInput type="file" id="newImage" name="newImage" label={uploadLabel} onChange={(e) => handleNewImage(e)} />
						</div>

						<Row>
							<Col>
								<Button color="primary" className="mt-3" disabled={!changed}>
									{local.TS_Upload}
								</Button>
								<Button type="button" color="secondary" className="mt-3 ml-2" onClick={() => history.push(`/site_settings/logger/${match?.params?.serialNumber}`)}>
									{local.TS_Cancel}
								</Button>
							</Col>
						</Row>
					</AvForm>
				</Card>
			)}
		</>
	);
};

export default withRouter(UploadSqi);

import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import local from "../../localization/strings";

const DeleteDropdown = (props) => {
	const [open, setOpen] = useState(false);

	return (
		<div style={{ position: "absolute", right: 6, top: -2, zIndex: 800 }}>
			<Dropdown isOpen={open} toggle={() => setOpen(!open)}>
				<DropdownToggle className="dashboard-delete">X</DropdownToggle>
				<DropdownMenu right className="menu-border">
					<DropdownItem id={`remove_${props.id}`} onClick={() => props.onRemove(props.idx)}>
						<FontAwesomeIcon icon="times" className="mr-1 delete-item-icon" />
						{local.TS_Remove}
					</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		</div>
	);
};

export default DeleteDropdown;

import { fas } from "@fortawesome/pro-solid-svg-icons";
import { features, roles } from "./config";
import local from "./localization/strings";

var userLanguage = localStorage.getItem("UserLanguage") || "";
if (userLanguage === "") {
	userLanguage = local.getInterfaceLanguage();
	local.setLanguage(userLanguage);
} else {
	local.setLanguage(userLanguage);
}

export const homeRoutes = {
	name: local.TS_Dashboard,
	to: "/",
	exact: true,
	icon: fas.faTh,
};

export const meteringRoutes = {
	name: local.TS_LatestValues,
	to: "/metering",
	icon: fas.faTachometerAlt,
};

export const alarmRoutes = {
	name: local.TS_Alarms,
	to: "/alarms/current",
	icon: fas.faBell,
	badge: "AlarmCount",
	badge2: "AlarmClearedCount",
	feature: features.CurrentAlarms,
};

export const zoneGraphicRoutes = {
	name: local.TS_ZoneGraphics,
	to: "/zone_graphics",
	icon: fas.faMapMarkedAlt,
	feature: features.HasStorage,
};

export const chartRoutes = {
	name: local.TS_Charts,
	to: "/charts",
	icon: fas.faChartArea,
	children: [
		{ to: "/charts/zone_charts", name: local.TS_ZoneCharts, icon: fas.faBars }, //
		{ to: "/charts/dual_parameter_charts", name: local.TF_Psychometric, icon: fas.faChartScatter, feature: features.PsychometricChart },
		{ to: "/charts/notes", name: local.TF_Notes, icon: fas.faStickyNote },
	],
};

export const statsRoutes = {
	name: local.TS_Reports,
	to: "/reports",
	icon: fas.faChartBar,
	divideafter: "true",
	children: [
		{ to: "/reports/statistics_report", name: local.TS_StatisticsReport }, //
		{ to: "/reports/hardware_report", name: local.TS_HardwareReport },
		{ to: "/reports/fluctuation_report", name: local.TS_FluctuationReport },
		{ to: "/reports/multi_period_report", name: local.TS_MultiPeriodReport, feature: features.MultiPeriodReport },
		{ to: "/reports/chart_report", name: local.TS_ChartReport },
		{ to: "/reports/export_data", name: local.TS_ExportData },
		{ to: "/reports/alarm_history", name: local.TS_AlarmHistoryReport, feature: features.AlarmHistoryReport },
		{ to: "/reports/email_reports", name: local.TS_EmailReports, icon: fas.faEnvelope, feature: features.EmailReports, role: roles.SiteAdmin },
	],
};

export const siteSettingsRoutes = {
	name: local.TS_SiteSettings,
	to: "/site_settings",
	icon: fas.faCogs,
};

export const userSettingsRoutes = {
	name: local.TS_UserSettings,
	to: "/user_settings",
	icon: fas.faUserCog,
	divideafter: "true",
};

export const releaseNotesRoutes = {
	name: local.TF_Documentation,
	to: "/release-notes",
	badge: "NewReleaseNotes",
	icon: fas.faListAlt,
	feature: features.HasStorage,
};

export const helpRoutes = {
	name: local.TS_DarcaConnectHelp,
	to: "/help",
	icon: fas.faQuestion,
};

// To be added after Nov 2019

// export const manualsRoutes = {
//   name: local.TS_MyManuals,
//   to: '/manuals',
//   icon: fas.faBook
// };

export default [
	homeRoutes,
	meteringRoutes,
	alarmRoutes,
	zoneGraphicRoutes,
	chartRoutes,
	statsRoutes,
	siteSettingsRoutes,
	userSettingsRoutes,
	releaseNotesRoutes,
	helpRoutes,
	// manualsRoutes
];

import axios from "axios";
import { CurrentSiteIdGet } from "./userSettings";

export const zoneGraphic = async (zoneId) => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "get",
			url: "v2/ZoneGraphic",
			params: { siteId, zoneId },
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const zoneGraphicOverlays = async (zoneId) => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "get",
			url: "v2/ZoneGraphicOverlay",
			params: { siteId, zoneId },
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const apiZoneGraphicSetup = async (zoneId, base64String, zoneGraphicGroups, zoneGraphicCaptions) => {
	const siteId = CurrentSiteIdGet();

	if (zoneGraphicGroups) {
		zoneGraphicGroups.forEach((x) => {
			if (!x.id || x.id <= 0) {
				x.id = -1;
            }
            x.zoneId = zoneId;
		});
	}

	if (zoneGraphicCaptions) {
		zoneGraphicCaptions.forEach((x) => {
			if (!x.id || x.id <= 0) {
				x.id = -1;
            }
            x.zoneId = zoneId;
		});
	}

	try {
		const result = await axios({
			method: "post",
			url: "v2/ZoneGraphic/Setup",
			data: { siteId, zoneId, base64String, zoneGraphicGroups, zoneGraphicCaptions },
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

import React, { useState, useEffect } from 'react'
import useWindowSize from "../../hooks/useWindowSize";

const AutoHeightDiv = ({padding, children}) => {
    const [minHeight, setMinHeight] = useState(500);
    const size = useWindowSize();

	useEffect(() => {
		size.then((r) => setMinHeight(r.height - padding));
	}, [size, padding]);

    return <div style={{ height: minHeight, overflowY: "auto", overflowX: "hidden" }}>{children}</div>;
}

export default AutoHeightDiv;
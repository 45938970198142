import React, { useState, useEffect } from "react";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import StatsTypes from "./StatsTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faCheckSquare, faChevronRight, faChevronDown, faPlusSquare, faMinusSquare, faFolder, faFolderOpen, faFile } from "@fortawesome/pro-solid-svg-icons";

const AreaDisplay = ({ stats, setStats }) => {
	const [expanded, setExpanded] = useState([("value", "all-statistics")]);
	const [checked, setChecked] = useState([]);

	useEffect(() => {
		if (stats) {
			setChecked(stats);
		} 
	}, [stats]);

	const checkedChanged = (checked) => {
		setStats(checked);
		setChecked(checked);
	};

	return (
		<div className="border rounded-soft mt-2 p-2">
			<CheckboxTree
				nodes={StatsTypes}
				checked={checked}
				expanded={expanded}
				onCheck={(checked) => checkedChanged(checked)}
				onExpand={(expanded) => setExpanded(expanded)}
				showNodeIcon={true}
				showExpandAll={false}
				optimisticToggle={true}
				onlyLeafCheckboxes={false}
				icons={{
					check: <FontAwesomeIcon className="rct-icon rct-icon-check" icon={faCheckSquare} size="lg" fixedWidth />,
					uncheck: <FontAwesomeIcon className="rct-icon rct-icon-uncheck" icon={faSquare} size="lg" fixedWidth />,
					halfCheck: <FontAwesomeIcon className="rct-icon rct-icon-half-check" icon={faCheckSquare} size="lg" fixedWidth />,
					expandClose: <FontAwesomeIcon className="rct-icon rct-icon-expand-close icon-margin-fix" icon={faChevronRight} size="lg" fixedWidth />,
					expandOpen: <FontAwesomeIcon className="rct-icon rct-icon-expand-open icon-margin-fix" icon={faChevronDown} size="lg" fixedWidth />,
					expandAll: <FontAwesomeIcon className="rct-icon rct-icon-expand-all" icon={faPlusSquare} size="lg" fixedWidth />,
					collapseAll: <FontAwesomeIcon className="rct-icon rct-icon-collapse-all" icon={faMinusSquare} size="lg" fixedWidth />,
					parentClose: <FontAwesomeIcon className="rct-icon rct-icon-parent-close" icon={faFolder} size="lg" fixedWidth />,
					parentOpen: <FontAwesomeIcon className="rct-icon rct-icon-parent-open" icon={faFolderOpen} size="lg" fixedWidth />,
					leaf: <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faFile} size="lg" fixedWidth />,
				}}
			/>
		</div>
	);
};

export default AreaDisplay;

import React, { useState, useEffect } from "react";
import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MaterialTable from "material-table";
import { tableIcons } from "../../helpers/tableIcons";
import local from "../../localization/strings";
import { distributorsGetList } from "../../api/users";
import AddButton from "../common/AddButton";
import { siteStats } from "../../api/site";

const DistributorList = ({ assignSites, edit }) => {
	const [data, setData] = useState(undefined);
	const [siteData, setSiteData] = useState(undefined);

	useEffect(() => {
		const LoadData = async () => {
			setSiteData(await siteStats());
			setData(await distributorsGetList());
		};

		LoadData();
	}, []);

	const siteName = (id) => {
		const site = siteData.find((x) => x.id.toLowerCase() === id.toString().toLowerCase());

		if (site) {
			return site.displayName;
		}
		return id;
	};

	const columns = [
		{ title: local.TF_Distributor, field: "displayName", defaultSort: "asc" },
		{ title: local.TS_EmailAddress, field: "email" },
		{ title: local.TF_CompanyName, field: "companyName" },
		{
			title: local.TF_Sites,
			render: (rowData) => {
				return rowData.siteList.map((item) => {
					return <div key={item}>{siteName(item)}</div>;
				});
			},
		},
		{
			title: local.TS_Actions,
			render: (rowData) => (
				<UncontrolledDropdown style={{ position: "static" }}>
					<DropdownToggle tag="div" className="pin-button">
						<FontAwesomeIcon icon="ellipsis-v" fixedWidth />
					</DropdownToggle>
					<DropdownMenu className="border">
						<DropdownItem onClick={() => edit(rowData.id)}>{local.TF_Update_details}</DropdownItem>
						<DropdownItem onClick={() => assignSites(rowData)}>{local.TF_Assign_Sites}</DropdownItem>
					</DropdownMenu>
				</UncontrolledDropdown>
			),
		},
	];

	return (
		<>
			<span className="float-right pt-2 mt-n5">
				<AddButton string={local.TF_Create_Distributor} clickFunction={() => edit(undefined)} />
			</span>

			{data && (
				<div className="MuiTable text-center">
					<MaterialTable
						columns={columns}
						data={data}
						title=""
						icons={tableIcons}
						options={{
							padding: "dense",
							sorting: true,
							exportButton: true,
							exportAllData: true,
							exportFileName: "Distributor List",
							paging: true,
							pageSize: 20,
							pageSizeOptions: [20, 50, 100],
							emptyRowsWhenPaging: false,
							headerStyle: {
								backgroundColor: "#fff",
								color: "#000",
							},
							rowStyle: {
								textAlign: "center",
							},
						}}
					/>
				</div>
			)}
		</>
	);
};

export default DistributorList;

import React, { useState, useEffect, useRef, useCallback } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import Loader from "../../common/Loader";
import { groupChartList } from "../../../api/chart";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import MultiPeriodChart from "./MultiPeriodChart";
import useKeyPress from "../../../hooks/useKeyPress";
import { logEvent } from "../../../helpers/ga";
import $ from "jquery";

const MultiPeriodReportDisplay = ({ filterData, setReportRunning }) => {
	const [loading, setLoading] = useState(true);
	const [charts, setCharts] = useState([]);
	const [timeSpans, setTimeSpans] = useState([]);
	const [selectedTab, setSelectedTab] = useState(0);
	const [sliderValue, setSliderValue] = useState(1);
	const [marks, setMarks] = useState({});
	const leftPress = useKeyPress("ArrowLeft");
	const rightPress = useKeyPress("ArrowRight");
	const pageUpPress = useKeyPress("PageUp");
	const pageDownPress = useKeyPress("PageDown");
	const timeoutRef = useRef(null);

	const tabActive = () => {
		const currentActive = document.activeElement;

		if (currentActive.className && currentActive.className.includes("react-tabs__tab--selected")) {
			return true;
		}
		return false;
	};

	const moveLeft = useCallback(() => {
		logEvent("Multi-Period Report", "Left Arrow Key");
		if (selectedTab > 0) {
			setSelectedTab(selectedTab - 1);
		} else {
			if (sliderValue > 1) {
				setSliderValue((p) => p - 1);
				setSelectedTab(charts.length - 1);
			}
		}
	}, [selectedTab, sliderValue, charts]);

	const moveRight = useCallback(() => {
		logEvent("Multi-Period Report", "Right Arrow Key");
		if (selectedTab < charts.length - 1) {
			setSelectedTab(selectedTab + 1);
		} else {
			if (sliderValue < filterData.noOfTimeSpans) {
				setSliderValue((p) => p + 1);
				setSelectedTab(0);
			}
		}
	}, [selectedTab, charts.length, sliderValue, filterData]);

	const moveUp = useCallback(() => {
		logEvent("Multi-Period Report", "Page Up Key");
		if (sliderValue < filterData.noOfTimeSpans) {
			setSliderValue((p) => p + 1);
		}
	}, [sliderValue, filterData]);

	const moveDown = useCallback(() => {
		logEvent("Multi-Period Report", "Page Down Key");
		if (sliderValue > 1) {
			setSliderValue((p) => p - 1);
		}
	}, [sliderValue]);

	useEffect(() => {
		if (leftPress && !tabActive()) {
			if (timeoutRef.current !== null) {
				clearTimeout(timeoutRef.current);
			}

			timeoutRef.current = setTimeout(() => {
				timeoutRef.current = null;

				moveLeft();
			}, 500);
		}
	}, [leftPress, moveLeft]);

	useEffect(() => {
		if (rightPress && !tabActive()) {
			if (timeoutRef.current !== null) {
				clearTimeout(timeoutRef.current);
			}

			timeoutRef.current = setTimeout(() => {
				timeoutRef.current = null;

				moveRight();
			}, 500);
		}
	}, [rightPress, moveRight]);

	useEffect(() => {
		if (pageUpPress) {
			if (timeoutRef.current !== null) {
				clearTimeout(timeoutRef.current);
			}

			timeoutRef.current = setTimeout(() => {
				timeoutRef.current = null;

				moveUp();
			}, 500);
		}
	}, [pageUpPress, moveUp]);

	useEffect(() => {
		if (pageDownPress) {
			if (timeoutRef.current !== null) {
				clearTimeout(timeoutRef.current);
			}

			timeoutRef.current = setTimeout(() => {
				timeoutRef.current = null;

				moveDown();
			}, 500);
		}
	}, [pageDownPress, moveDown]);

	useEffect(() => {
		const LoadData = async () => {
			var result = await groupChartList(filterData.groupId);

			const newTimeSpans = [];
			for (let i = 1; i <= filterData.noOfTimeSpans; ++i) {
				newTimeSpans.push(i);
			}
			setTimeSpans(newTimeSpans);

			setCharts(result);
			setLoading(false);
			setReportRunning(false);
		};

		const marksObj = {};
		for (let i = 0; i < filterData.noOfTimeSpans; i++) {
			marksObj[i + 1] = i + 1;
		}
		setMarks(marksObj);

		LoadData();
	}, [filterData, setReportRunning]);

	const sliderChange = (value) => {
		setSliderValue(value);
	};

	useEffect(() => {
		$(".timespan").height(1);
		$(`.timespan_${sliderValue}`).height(400);
	}, [sliderValue]);

	return (
		<>
			{loading ? (
				<Loader />
			) : (
				<>
					<Slider min={1} max={filterData.noOfTimeSpans} defaultValue={1} value={sliderValue} dots marks={marks} className="mb-4" onChange={sliderChange} />

					<Tabs selectedIndex={selectedTab} onSelect={(index) => setSelectedTab(index)}>
						<TabList style={{ overflowX: "auto", overflowY: "hidden", whiteSpace: "nowrap" }}>
							{charts.map((chart, index) => (
								<Tab key={index}>{chart.groupName}</Tab>
							))}
						</TabList>

						{charts.map((chart, index) => (
							<TabPanel key={index} forceRender>
								{timeSpans.map((i) => (
									<div className={`timespan timespan_${i} chart_${index}_${i}`} style={{ height: i === 1 ? 400 : 0, overflowY: 'hidden' }} key={`${index}_${i}`}>
										<MultiPeriodChart chart={chart} timeSpan={i} filterData={filterData} />
									</div>
								))}
							</TabPanel>
						))}
					</Tabs>
				</>
			)}
		</>
	);
};

export default MultiPeriodReportDisplay;

import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { CurrentUserHasRole } from "../../api/auth";
import { parametersGetList } from "../../api/parameter";
import { features, isFeatureEnabled, roles } from "../../config";
import local from "../../localization/strings";
import AddButton from "../common/AddButton";
import IntToHex from "../common/IntToHex";
import LightenDarkenColor from "../common/LightenDarkenColour";
import LightOrDark from "../common/LightOrDark";
import Loader from "../common/Loader";
import Parameter from "./Parameter";
import ParameterButton from "./ParameterButton";

const Parameters = () => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [selected, setSelected] = useState(null);
	const canAdd = CurrentUserHasRole(roles.SiteAdmin) && isFeatureEnabled(features.ParameterAdd);

	const LoadData = useCallback(async () => {
		const result = await parametersGetList();

		setData(result);
		setLoading(false);
	}, []);

	useEffect(() => {
		LoadData();
	}, [LoadData]);

	const select = (id) => {
		setSelected(id);
	};

	const reload = () => {
		LoadData();
	};

	return (
		<>
			{loading ? (
				<Loader />
			) : (
				<Row>
					<Col md={4}>
						<div className="px-1 pb-2 border-bottom mb-2 border-md-0" style={{ height: 500, overflowY: "auto", overflowX: "hidden" }}>
							{data &&
								data.map((item, i) => (
									<Row key={i}>
										<Col className="pb-1">
											<ParameterButton buttonClick={() => select(item.id)} color="primary" buttonClasses={`p-1 w-100 parameter ${selected === item.id && "selected-parameter"}`} name={item.name} colorOne={"#" + IntToHex(item.colour)} colorTwo={"#" + LightenDarkenColor(IntToHex(item.colour), 60)} boxColour={LightOrDark(IntToHex(item.colour))} />
										</Col>
									</Row>
								))}
							{canAdd && (
								<Row>
									<Col className="pb-1">
										<AddButton string={local.TF_Add_Parameter} clickFunction={() => select(-1)} buttonClasses={"p-1 w-100"} />
									</Col>
								</Row>
							)}
						</div>
					</Col>
					<Col md={8}>{selected && <Parameter key={selected} selected={selected} parameters={data} reload={reload} />}</Col>
				</Row>
			)}
		</>
	);
};

export default Parameters;

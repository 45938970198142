import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { apiLocationSaveAlarms, apiLocationsGetList } from "../../../api/location";
import { parametersGetList } from "../../../api/parameter";
import { siteMapFlat } from "../../../api/site";
import local from "../../../localization/strings";

const LocationAlarm = ({ groupId, locationId, locationEditAlarm, cancel, saved }) => {
	const [data, setData] = useState(undefined);
	const [parameter, setParameter] = useState();
	const [map, setMap] = useState();

	//Get all parameters
	//remove any on this group except this location, if in edit mode

	useEffect(() => {
		const LoadData = async () => {
			const parametersResult = await parametersGetList();
			const result = await apiLocationsGetList(groupId);
			const siteMap = await siteMapFlat();
			setMap(siteMap.find((x) => x.locationId === locationId));

			if (locationId) {
				const found = result.find((x) => x.id === locationId);
				found.warnAlarmLo = found.warnAlarmLo || typeof found.warnAlarmLo === "number" ? found.warnAlarmLo : "";
				found.warnAlarmHi = found.warnAlarmHi || typeof found.warnAlarmHi === "number" ? found.warnAlarmHi : "";
				found.mainAlarmLo = found.mainAlarmLo || typeof found.mainAlarmLo === "number" ? found.mainAlarmLo : "";
				found.mainAlarmHi = found.mainAlarmHi || typeof found.mainAlarmHi === "number" ? found.mainAlarmHi : "";
				setParameter(parametersResult.find((x) => x.id === found.parameterId));
				setData(found);
			} else {
				setData({});
			}
		};

		if (locationEditAlarm) {
			LoadData();
		} else {
			setData();
			setParameter();
		}
	}, [groupId, locationId, locationEditAlarm]);

	async function save(_e, values) {
		await apiLocationSaveAlarms(locationId, data.parameterId, data.groupId, values.warnAlarmLo, values.warnAlarmHi, values.mainAlarmLo, values.mainAlarmHi);

		saved();
	}

	return (
		<Modal isOpen={locationEditAlarm} toggle={cancel} centered backdrop="static">
			<ModalHeader toggle={cancel}>{local.TF_Channel}</ModalHeader>
			<ModalBody>
				{data && (
					<AvForm model={data} onValidSubmit={async (e, values) => await save(e, values)}>
						<Row>
							<Col xs={12} className="text-center">
								<h5>{map?.buildingName}</h5>
							</Col>
						</Row>
						<Row>
							<Col xs={12} className="text-center">
								<h5>{map?.zoneName}</h5>
							</Col>
						</Row>
						<Row>
							<Col xs={12} className="text-center">
								<h5>{map?.groupName}</h5>
							</Col>
						</Row>
						<Row>
							<Col xs={12} className="mb-2 text-center">
								<h5>{parameter.name}</h5>
							</Col>
						</Row>
						<Row>
							<Col md={6}>
								<div className="border border-warning py-0 px-2">
									<h6 className="bg-warning p-2 mx-n2 text-white">{local.TF_Threshold_Levels}</h6>
									<AvField name="warnAlarmLo" type="number" label={local.TS_Min} />
									<AvField name="warnAlarmHi" type="number" label={local.TS_Max} />
								</div>
							</Col>
							<Col md={6}>
								<div className="border border-danger py-0 px-2">
									<h6 className="bg-danger p-2 mx-n2 text-white">{local.TF_Alarm_Levels}</h6>
									<AvField name="mainAlarmLo" type="number" label={local.TS_Min} />
									<AvField name="mainAlarmHi" type="number" label={local.TS_Max} />
								</div>
							</Col>
						</Row>

						<Row>
							<Col>
								<Button color="primary" className="mt-2 float-left">
									{local.TS_Save}
								</Button>
								<Button color="secondary" className="mt-2 ml-2 float-left" type="button" onClick={cancel}>
									{local.TS_Cancel}
								</Button>
							</Col>
						</Row>
					</AvForm>
				)}
			</ModalBody>
		</Modal>
	);
};

export default LocationAlarm;

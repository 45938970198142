import axios from "axios";
import { CurrentSiteIdGet } from "./userSettings";

export const emailReport = async (reportType, reportSettings, emailRecipients, subject, body) => {
	const siteId = CurrentSiteIdGet();

	try {
		await axios({
			method: "post",
			url: "v2/Report/Email",
			data: {
				siteId,
				reportType,
				reportSettings: JSON.stringify(reportSettings),
				emailRecipients,
				subject,
				body,
			},
		});
	} catch (err) {
		console.error(err);
	}
};

export const performanceStatistics = async (groupIds, period, noOfPeriod, customFromDate, customToDate) => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "post",
			url: "v3/Report/PerformanceStatistics",
			data: {
				siteId,
				groupIds,
				period,
				noOfPeriod,
				customFromDate,
				customToDate,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const exportData = async (locationIds, fromDate, toDate, includeSeconds = false) => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "post",
			url: "v2/Report/ExportData",
			data: {
				siteId,
				locationIds,
				fromDate,
				toDate,
				includeSeconds,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const hardware = async (groupIds, period, noOfPeriod, customFromDate, customToDate, showNullCount, showMinMaxAvg) => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "post",
			url: "v2/Report/Hardware",
			data: {
				siteId,
				groupIds,
				period,
				noOfPeriod,
				customFromDate,
				customToDate,
				showNullCount,
				showMinMaxAvg,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const alarmHistory = async (groupIds, period, noOfPeriod, customFromDate, customToDate, includeSeconds = false) => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "post",
			url: "v2/Report/AlarmHistory",
			data: {
				siteId,
				groupIds,
				period,
				noOfPeriod,
				customFromDate,
				customToDate,
				includeSeconds,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const statistics = async (locationIds, fromDate, toDate, stats, period, includeSeconds = false) => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "post",
			url: "v2/Report/Statistics",
			data: {
				siteId,
				locationIds,
				fromDate,
				toDate,
				stats,
				period,
				includeSeconds,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const fluctuation = async (locationId, fromDate, toDate, period, noOfPeriod, bands, timePeriod) => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "get",
			url: "v2/Report/Fluctuation",
			params: {
				siteId,
				locationId,
				fromDate,
				toDate,
				period,
				noOfPeriod,
				bands: bands.join(","),
				timePeriod,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const emailReportsList = async () => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "get",
			url: "v2/EmailReports",
			params: {
				siteId,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const emailReportSave = async (id, reportName, reportType, emailRecipients, frequency, reportSettings) => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "post",
			url: "v2/EmailReport",
			data: {
				siteId,
				id,
				reportName,
				reportType,
				emailRecipients,
				frequency,
				reportSettings: JSON.stringify(reportSettings),
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const emailReportDelete = async (id) => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "delete",
			url: "v2/EmailReport",
			params: {
				siteId,
				id,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const apiEmailChartReport = async (token, reportType) => {
	try {
		const result = await axios({
			method: "get",
			url: "v2/EmailChartReport",
			params: {
				token,
				reportType,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

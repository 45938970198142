import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardHeader, CardBody, Row, Col, Button, Alert } from "reactstrap";
import local from "../../localization/strings";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { currentUserChangePassword } from "../../api/users";
import { toast } from "react-toastify";

const model = {
	currentPassword: "",
	newPassword: "",
	confirmPassword: "",
};

const ChangePassword = ({ triggerChangedPassword }) => {
	const [error, setError] = useState(undefined);

	async function save(_e, values) {
		const result = await currentUserChangePassword(values.currentPassword, values.newPassword);

		switch (result) {
			case "PasswordMismatch":
				setError(local.TF_Password_Mismatch);
				return;
			case "OK":
				setError(undefined);
				toast.success(local.TF_Change_Password_Successful);

				triggerChangedPassword();
				break;
			default:
				setError(local.TF_PasswordRules);
				return;
		}
	}

	return (
		<>
			<CardHeader>
				<Row className="align-items-center">
					<Col>
						<h5 className="mb-0">{local.TF_Change_Password}</h5>
					</Col>
				</Row>
			</CardHeader>
			<CardBody className="bg-light border-top">
				<AvForm model={model} onValidSubmit={async (e, values) => await save(e, values)}>
					<AvField name="currentPassword" type="password" label={local.TF_Current_Password} validate={{ required: { value: true, errorMessage: local.TS_Required } }} />
					<Alert color="info" className="d-flex flex-row mx-1 overflow-auto card">
						<div className="notification-avatar mr-2">
							<FontAwesomeIcon icon="info" fixedWidth size="1x" className="mr-2" />
						</div>
						<div className="notification-body w-100">
							<p className="mb-0">{local.TF_PasswordRules}</p>
						</div>
					</Alert>
					<AvField name="newPassword" type="password" label={local.TF_New_Password} validate={{ required: { value: true, errorMessage: local.TS_Required } }} />
					<AvField name="confirmPassword" type="password" label={local.TF_Confirm_Password} validate={{ required: { value: true, errorMessage: local.TS_Required }, match: { value: "newPassword", errorMessage: local.TS_Invalid } }} />

					{error && <Alert color="danger">{error}</Alert>}
					<Button color={"primary"} className="mt-2">
						{local.TS_Save_Changes}
					</Button>
				</AvForm>
			</CardBody>
		</>
	);
};

export default ChangePassword;

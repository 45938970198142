import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { logEvent } from "../../helpers/ga";
import local from "../../localization/strings";
import ButtonIcon from "../common/ButtonIcon";
import PageTitle from "../common/PageTitle";
import SiteTreeMenu from "../common/SiteTreeMenu";
import GroupChart from "./GroupChart";

const ChartLayout = () => {
	const [groupId, setGroupId] = useState();

	const onZoneSelect = (data) => {
		setGroupId(data.substring(2));
	};

	return (
		<>
			<div id="zone-chart-div">
				<Row>
					<Col>
						<PageTitle title={local.TF_PsychometricChart} className="float-left" />
					</Col>
					<Col>
						{groupId && (
							<ButtonIcon
								outline
								className="float-right mt-3 dark-button mx-1"
								size="sm"
								icon="filter"
								iconAlign="left"
								color="dark"
								onClick={() => {
									logEvent("Psychometric Charts", "Select Group Button");
									setGroupId(undefined);
								}}
							>
								{local.TF_SelectGroup}
							</ButtonIcon>
						)}
					</Col>
				</Row>
				{!groupId ? (
					<SiteTreeMenu onClick={onZoneSelect} siteMapLevel={3} expandToLevel={1} />
				) : (
					<>
						<GroupChart selectedGroupId={groupId} />
					</>
				)}
			</div>
		</>
	);
};

export default withRouter(ChartLayout);

import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { apiGroupGet, apiGroupSave } from "../../../api/group";
import local from "../../../localization/strings";

const GroupEdit = ({ zoneId, groupId, groupEdit, cancel, saved }) => {
	const [data, setData] = useState(undefined);

	useEffect(() => {
		const LoadData = async () => {
			const result = await apiGroupGet(zoneId, groupId);

			setData(result);
		};

		if (groupEdit) {
			if (groupId) {
				LoadData();
			} else {
				setData({ name: "" });
			}
		} else {
			setData(undefined);
		}
	}, [zoneId, groupId, groupEdit]);

	async function save(_e, values) {
        await apiGroupSave(groupId, zoneId, values.name, data.index);
        
        saved();
	}

	return (
		<Modal isOpen={groupEdit} toggle={cancel} centered backdrop="static">
			<ModalHeader toggle={cancel}>{local.TS_Group}</ModalHeader>
			<ModalBody>
				{data && (
					<AvForm model={data} onValidSubmit={async (e, values) => await save(e, values)}>
						<AvField name="name" label={local.TS_Name} validate={{ required: { value: true, errorMessage: local.TS_Required } }} maxLength={30} />

						<Row>
							<Col>
								<Button color="primary" className="mt-2 float-left">
									{local.TS_Save}
								</Button>
								<Button color="secondary" className="mt-2 ml-2 float-left" type="button" onClick={cancel}>
									{local.TS_Cancel}
								</Button>
							</Col>
						</Row>
					</AvForm>
				)}
			</ModalBody>
		</Modal>
	);
};

export default GroupEdit;

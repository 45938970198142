import arrayMove from "array-move";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { Button, Col, Row } from "reactstrap";
import { UserPreferencesContext } from "../../context/userPreferences";
import { logEvent } from "../../helpers/ga";
import local from "../../localization/strings";
import DashWidgetDropdown from "./DashWidgetDropdown";
import DeleteDropdown from "./DeleteDropdown";
import SwitchPeriodDropdown from "./SwitchPeriodDropdown";
import SwitchToDropdown from "./SwitchToDropdown";
import SwitchWidthDropdown from "./SwitchWidthDropdown";

const DashboardEdit = ({ setSortable }) => {
	const [componentSize, setComponentSize] = useState([]);
	const { dashboardLayout, getLocationName, getZoneDetails, sortDashboard } = useContext(UserPreferencesContext);

	const localGetLocationName = useCallback((id) => getLocationName(id) || local.TS_Unknown, [getLocationName]);
	const localGetLocationNames = useCallback(
		(ids) => {
			const locationNames = [];

			(ids || "").split(",").forEach((id) => {
				locationNames.push(getLocationName(id) || null);
			});

			return locationNames
				.filter((x) => x)
				.sort()
				.join(", ");
		},
		[getLocationName],
	);
	const localGetZoneDetails = useCallback((id) => getZoneDetails(id), [getZoneDetails]);
	const getDaysName = useCallback((period) => {
		switch (period) {
			case "Day":
				return local.TS_Day;
			case "Week":
				return local.TS_Week;
			case "Month":
				return local.TS_Month;
			case "1Day":
				return local.TF_1Day;
			case "2Day":
				return local.TF_2Day;
			case "3Day":
				return local.TF_3Day;
			case "4Day":
				return local.TF_4Day;
			case "5Day":
				return local.TF_5Day;
			case "6Day":
				return local.TF_6Day;
			case "7Day":
				return local.TF_7Day;
			default:
				return local.TF_1Day;
		}
	}, []);

	const buildBlock = useCallback(
		(e) => {
			const parts = e.split("|");

			let extra = "";
			if (parts[0] === "DashChart" || parts[0] === "DashChartBlue") {
				extra = getDaysName(parts.length > 2 ? parts[2] : "1Day");
			}
			if (parts[0] === "MultiChart") {
				extra = getDaysName(parts.length > 2 ? parts[2] : "Day");
			}
			let cols = "12";
			let width = "98%";
			if (parts[0] === "DashBatteryTable") {
				cols = parts.length > 1 && parts[1] ? parts[1] : "12";
			}
			if (parts[0] === "LatestValues") {
				cols = parts.length > 2 ? parts[2] : "12";
			}
			switch (cols) {
				case "3":
					width = "23%";
					break;
				case "4":
					width = "32%";
					break;
				case "6":
					width = "48%";
					break;
				case "8":
					width = "64%";
					break;
				case "9":
					width = "73%";
					break;
				default:
				case "12":
					width = "98%";
					break;
			}

			switch (parts[0]) {
				case "DashMeter":
					return { data: e, value: "23%", name: `${local.TF_Meter_Gauge} - ${localGetLocationName(parts[1])}`, height: "150px", className: "col-3", switchTo: true };
				case "DashMeterBlue":
					return { data: e, value: "23%", name: `${local.TF_Meter_Gauge} - ${localGetLocationName(parts[1])}`, height: "150px", className: "bg-gradient text-white col-3", switchTo: true };
				case "DashMeterBlock":
					return { data: e, value: "23%", name: `${local.TF_Meter_Value} - ${localGetLocationName(parts[1])}`, height: "150px", className: "col-3", switchTo: true };
				case "DashMeterBlockBlue":
					return { data: e, value: "23%", name: `${local.TF_Meter_Value} - ${localGetLocationName(parts[1])}`, height: "150px", className: "bg-gradient text-white col-3", switchTo: true };
				case "MultiChart":
					return { data: e, value: "98%", name: `${local.TF_Chart} - ${extra} - ${localGetLocationNames(parts[1])}`, height: "250px", className: "col-12", switchTo: false, switchPeriod: true };
				case "DashChart":
					return { data: e, value: "98%", name: `${local.TF_Chart} - ${extra} - ${localGetLocationName(parts[1])}`, height: "150px", className: "col-12", switchTo: true };
				case "DashChartBlue":
					return { data: e, value: "98%", name: `${local.TF_Chart} - ${extra} - ${localGetLocationName(parts[1])}`, height: "150px", className: "bg-gradient text-white col-12", switchTo: true };
				case "DashZoneGraphic":
					return { data: e, value: "48%", name: `${local.TF_Zone_Graphic} - ${localGetZoneDetails(parts[1])}`, height: "300px", className: "col-6" };
				case "DashZoneGraphicWide":
					return { data: e, value: "98%", name: `${local.TF_Zone_Graphic} - ${localGetZoneDetails(parts[1])}`, height: "300px", className: "col-12" };
				case "DashStatsContainer":
					return { data: e, value: "48%", name: `${local.TF_Stats_Container}`, height: "300px", className: "col-6" };
				case "DashBatteryDoughnut":
					return { data: e, value: "23%", name: `${local.TF_Battery_Levels}`, height: "150px", className: "col-3" };
				case "DashBatteryTable":
					return { data: e, value: width, name: `${local.TF_Battery_Levels}`, height: "300px", className: `col-${cols}`, switchWidth: true };
				case "LatestValues":
					return { data: e, value: width, name: `${local.TS_LatestValues}`, height: "300px", className: `col-${cols}`, switchWidth: true };
				default:
					return null;
			}
		},
		[localGetLocationName, localGetLocationNames, localGetZoneDetails, getDaysName],
	);

	useEffect(() => {
		async function loadSite() {
			var componentBlocks = [];

			dashboardLayout.forEach(function(e) {
				const component = buildBlock(e);
				if (component) {
					componentBlocks.push(component);
				}
			});

			setComponentSize(componentBlocks);
		}

		loadSite();
	}, [dashboardLayout, buildBlock]);

	const toggleSortable = () => {
		logEvent("Dashboard", "Save Clicked");
		setSortable(false);
		sortDashboard(componentSize);
	};

	const SortableItem = SortableElement(({ data, value, name, height, className, switchTo, switchPeriod, switchWidth, id, idx }) => (
		<Col id={id} className={"drag-drop-box mb-2 card float-left " + className} style={{ width: value, height: height }}>
			<Row>
				<Col className="sortable-text col-10 pt-2">{name}</Col>
				<DeleteDropdown id={id} idx={idx} onRemove={onRemove} />
				{switchTo && <SwitchToDropdown idx={idx} data={data} onSwitchTo={onSwitchTo} />}
				{switchPeriod && <SwitchPeriodDropdown idx={idx} data={data} onSwitchPeriod={onSwitchPeriod} />}
				{switchWidth && <SwitchWidthDropdown idx={idx} data={data} onSwitchWidth={onSwitchWidth} />}
			</Row>
		</Col>
	));

	const SortableList = SortableContainer(({ items }) => {
		return (
			<div className="w-100">
				{items.map((array, index) => (
					<SortableItem id={"item_" + index} key={index} index={index} value={array.value} data={array.data} name={array.name} height={array.height} className={array.className} switchTo={array.switchTo} switchWidth={array.switchWidth} switchPeriod={array.switchPeriod} idx={index} />
				))}
			</div>
		);
	});

	const onSortEnd = ({ oldIndex, newIndex }) => {
		setComponentSize(arrayMove(componentSize, oldIndex, newIndex));
	};

	function onClear() {
		logEvent("Dashboard", "Clear Components");
		setComponentSize([]);
	}

	function onSwitchTo(index, to, period) {
		const data = componentSize[index].data;

		logEvent("Dashboard", "Switch Widget", data);

		const parts = data.split("|");
		parts[0] = to;
		if (period) {
			if (parts.length > 2) {
				parts[2] = period;
			} else {
				parts.push(period);
			}
		}

		const newArray = [...componentSize];
		newArray.splice(index, 1, buildBlock(parts.join("|")));
		setComponentSize(newArray);
	}

	function onSwitchPeriod(index, period) {
		const data = componentSize[index].data;

		const parts = data.split("|");
		if (period) {
			if (parts.length > 2) {
				parts[2] = period;
			} else {
				parts.push(period);
			}
		}

		const newArray = [...componentSize];
		newArray.splice(index, 1, buildBlock(parts.join("|")));
		setComponentSize(newArray);
	}

	function onSwitchWidth(index, width) {
		const data = componentSize[index].data;

		const parts = data.split("|");

		const partToUpdate = parts[0] === "DashBatteryTable" ? 1 : 2;

		if (width) {
			if (parts.length > partToUpdate) {
				parts[partToUpdate] = width;
			} else {
				parts.push(width);
			}
		}

		const newArray = [...componentSize];
		newArray.splice(index, 1, buildBlock(parts.join("|")));
		setComponentSize(newArray);
	}

	function onRemove(index) {
		logEvent("Dashboard", "Remove Component", componentSize[index].data);
		const newArray = [...componentSize];
		newArray.splice(index, 1);
		setComponentSize(newArray);
	}

	return (
		<>
			<Row className="mb-3">
				<Col>
					<DashWidgetDropdown className="float-left" />
					<span className="font-italic mt-2 ml-5">{local.TF_Click_and_drag}</span>
					<Button onClick={toggleSortable} color="falcon-info" className="float-right mr-4">
						{local.TF_Save_Changes}
					</Button>
					{componentSize.length > 0 && (
						<Button onClick={() => onClear()} color="falcon-danger" className="float-right mr-3">
							{local.TS_Clear_All}
						</Button>
					)}
				</Col>
			</Row>
			<Row>
				<Col>
					<SortableList items={componentSize} onSortEnd={onSortEnd} axis={"xy"} useWindowAsScrollContainer={true} />
				</Col>
			</Row>
			<Row className="mb-6 mt-2">
				<Col>
					<DashWidgetDropdown direction={"up"} className="float-left" />
					<Button onClick={toggleSortable} color="falcon-info" className="float-right mr-4">
						{local.TF_Save_Changes}
					</Button>
					{componentSize.length > 0 && (
						<Button onClick={() => onClear()} color="falcon-danger" className="float-right mr-3">
							{local.TS_Clear_All}
						</Button>
					)}
				</Col>
			</Row>
		</>
	);
};

export default DashboardEdit;

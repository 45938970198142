import React, { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";
import { CurrentSiteRequiresMFA, CurrentUserRole } from "../api/auth";
import { CurrentSiteIdGet } from "../api/userSettings";
import AlarmCurrent from "../components/alarm/AlarmCurrent";
import AlarmTeams from "../components/alarm/alarm-teams/AlarmTeams";
import { ProtectedRoute } from "../components/auth/ProtectedRoute";
import ChartLayout from "../components/chart/ChartLayout";
import GradientIcons from "../components/common/icon/GradientIcons";
import Dashboard from "../components/dashboard/Dashboard";
import Footer from "../components/footer/Footer";
import GeneralSettings from "../components/general-settings/GeneralSettings";
import Help from "../components/help/Help";
import MeteringAll from "../components/metering/MeteringAll";
import MFA from "../components/mfa/MFA";
import NavbarTop from "../components/navbar/NavbarTop";
import NavbarVertical from "../components/navbar/NavbarVertical";
import NotesAll from "../components/notes/NotesAll";
import ParameterSetup from "../components/parameter-setup/ParameterSetup";
import UserSettings from "../components/profile/Profile";
import PsychometricChartLayout from "../components/psychometric-chart/PsychometricChartLayout";
import ReleaseNotes from "../components/release-notes/ReleaseNotes";
import SiteLayout from "../components/site-layout/SiteLayout";
import LoggerWizard from "../components/site-layout/wizards/LoggerWizard";
import ParameterWizard from "../components/site-layout/wizards/ParameterWizard";
import SiteManagement from "../components/site-management/SiteManagement";
import SiteSettings from "../components/site-settings/SiteSettings";
import Logger from "../components/site-settings/loggers/logger";
import loggerChannel from "../components/site-settings/loggers/loggerChannel";
import loggerSMSNumbers from "../components/site-settings/loggers/loggerSMSNumbers";
import Loggers from "../components/site-settings/loggers/loggers";
import uploadSqi from "../components/site-settings/loggers/uploadSqi";
import AlarmReport from "../components/statistics/alarm-report/AlarmReport";
import ChartReport from "../components/statistics/chart-report/ChartReport";
import EmailReports from "../components/statistics/email-reports/EmailReports";
import ExportData from "../components/statistics/export-data/ExportData";
import FluctuationReport from "../components/statistics/fluctuation-report/FluctuationReport";
import HardwareReport from "../components/statistics/hardware-report/HardwareReport";
import MultiPeriodReport from "../components/statistics/multi-period-report/MultiPeriodReport";
import PerformanceStatistics from "../components/statistics/performance-statistics/PerformanceStatistics";
import StatisticsReport from "../components/statistics/statistics-report/StatisticsReport";
import ZoneGraphicLayout from "../components/zone-graphics/ZoneGraphicLayout";
import ZoneGraphicSetup from "../components/zone-graphics/setup/ZoneGraphicSetup";
import { features, roles } from "../config";
import AppContext from "../context/AppContext";
import { UserPreferencesProvider } from "../context/userPreferences";
import withTracker from "../hoc/withTracker";

const DashboardLayout = () => {
	const { isFluid } = useContext(AppContext);

	const siteId = CurrentSiteIdGet();
	if (!siteId) {
		switch (CurrentUserRole()) {
			case roles.Eltek:
				return <Redirect to="/admin" />;
			case roles.Distributor:
				return <Redirect to="/distributor" />;
			default:
				return <Redirect to="/authentication/login" />;
		}
	}

	return (
		<>
			{CurrentSiteRequiresMFA() ? (
				<MFA />
			) : (
				<UserPreferencesProvider>
					<div className={isFluid ? "container-fluid" : "container"} id="dashboard-container-div">
						<GradientIcons />
						<NavbarVertical />
						<div className="content">
							<NavbarTop />
							<div className="mt-2">
								<Switch>
									<Route path="/" exact component={withTracker(Dashboard)} />
									<Route path="/zone_graphics/setup" exact component={withTracker(ZoneGraphicSetup)} />
									<Route path="/zone_graphics/:zoneId/:groupId" exact component={withTracker(ZoneGraphicLayout)} />
									<Route path="/zone_graphics/:zoneId" exact component={withTracker(ZoneGraphicLayout)} />
									<Route path="/zone_graphics" exact component={withTracker(ZoneGraphicLayout)} />
									<Route path="/charts/zone_charts/:zoneId/:groupId/:focusOn" component={withTracker(ChartLayout)} />
									<Route path="/charts/zone_charts/:zoneId/:groupId" component={withTracker(ChartLayout)} />
									<Route path="/charts/zone_charts" exact component={withTracker(ChartLayout)} />
									<ProtectedRoute path="/charts/dual_parameter_charts" exact component={withTracker(PsychometricChartLayout)} requiredRole={roles.Any} requiredFeature={features.PsychometricChart} />
									<Route path="/charts/notes" exact component={withTracker(NotesAll)} />
									<Route path="/reports/performance_statistics" exact component={withTracker(PerformanceStatistics)} />
									<Route path="/reports/hardware_report" exact component={withTracker(HardwareReport)} />
									<Route path="/reports/statistics_report" exact component={withTracker(StatisticsReport)} />
									<Route path="/reports/alarm_history" exact component={withTracker(AlarmReport)} />
									<Route path="/reports/fluctuation_report" exact component={withTracker(FluctuationReport)} />
									<ProtectedRoute path="/reports/multi_period_report" exact component={withTracker(MultiPeriodReport)} requiredRole={roles.Any} requiredFeature={features.MultiPeriodReport} />
									<Route path="/reports/chart_report" exact component={withTracker(ChartReport)} />
									<ProtectedRoute path="/reports/email_reports" exact component={withTracker(EmailReports)} requiredRole={roles.SiteAdmin} requiredFeature={features.EmailReports} />
									<ProtectedRoute path="/reports/export_data" exact component={withTracker(ExportData)} requiredRole={roles.Any} />
									<Route path="/site_settings/site_layout" exact component={withTracker(SiteLayout)} />
									<Route path="/site_settings/parameter_setup" exact component={withTracker(ParameterSetup)} />
									<Route path="/site_settings/parameter_wizard" exact component={withTracker(ParameterWizard)} />
									<Route path="/site_settings/logger_wizard" exact component={withTracker(LoggerWizard)} />
									<Route path="/site_settings/general_settings" exact component={withTracker(GeneralSettings)} />
									<ProtectedRoute path="/site_settings/loggers" exact component={withTracker(Loggers)} requiredRole={roles.SiteAdmin} requiredFeature={features.LoggerConfiguration} />
									<ProtectedRoute path="/site_settings/logger/:serialNumber/:channelNumber" exact component={withTracker(loggerChannel)} requiredRole={roles.SiteAdmin} requiredFeature={features.LoggerConfiguration} />
									<ProtectedRoute path="/site_settings/logger/:serialNumber" exact component={withTracker(Logger)} requiredRole={roles.SiteAdmin} requiredFeature={features.LoggerConfiguration} />
									<ProtectedRoute path="/site_settings/logger-sms-numbers/:serialNumber" exact component={withTracker(loggerSMSNumbers)} requiredRole={roles.SiteAdmin} requiredFeature={features.LoggerConfiguration} />
									<ProtectedRoute path="/site_settings/upload-sqi/:serialNumber" exact component={withTracker(uploadSqi)} requiredRole={roles.SiteAdmin} requiredFeature={features.LoggerConfiguration} />
									<Route path="/site_settings/alarm_setup" exact component={withTracker(AlarmTeams)} />
									<Route path="/site_settings/site_management" exact component={withTracker(SiteManagement)} />
									<Route path="/site_settings" exact component={withTracker(SiteSettings)} />
									<Route path="/user_settings" exact component={withTracker(UserSettings)} />
									<Route path="/help" exact component={withTracker(Help)} />
									<Route path="/metering" exact component={withTracker(MeteringAll)} />
									<ProtectedRoute path="/release-notes" exact component={withTracker(ReleaseNotes)} requiredRole={roles.Any} requiredFeature={features.HasStorage} />
									<ProtectedRoute path="/alarms/current" exact component={withTracker(AlarmCurrent)} requiredRole={roles.Any} requiredFeature={features.CurrentAlarms} />
									<Redirect to="/errors/404" />
								</Switch>
							</div>
							<Footer />
						</div>
					</div>
				</UserPreferencesProvider>
			)}
		</>
	);
};

export default DashboardLayout;

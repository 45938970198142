import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, Card, TabPane } from "reactstrap";
import classnames from "classnames";
import local from "../../localization/strings";
import PageTitle from "../common/PageTitle";
import { isFeatureEnabled, features } from "../../config";

import AutomaticUpdates from "./AutomaticUpdates";
import Language from "./Language";

const GeneralSettings = () => {
	const [tabActive, setTabActive] = useState(1);

	const toggle = (tab) => {
		if (tabActive !== tab) {
			setTabActive(tab);
		}
	};

	return (
		<>
			<PageTitle title={local.TF_General_Settings} />
			<Nav tabs>
				{isFeatureEnabled(features.SettingsAutomaticUpdates) && (
					<NavItem>
						<NavLink
							className={classnames({ active: tabActive === 1 })}
							onClick={() => {
								toggle(1);
							}}
						>
							{local.TF_Automatic_Updates}
						</NavLink>
					</NavItem>
				)}
				<NavItem>
					<NavLink
						className={classnames({ active: tabActive === 2 })}
						onClick={() => {
							toggle(2);
						}}
					>
						{local.TF_Language}
					</NavLink>
				</NavItem>
			</Nav>
			<TabContent activeTab={tabActive}>
				<TabPane tabId={1}>
					<Card className="px-4">
						<AutomaticUpdates />
					</Card>
				</TabPane>
				<TabPane tabId={2}>
					<Card className="px-2">
						<Language />
					</Card>
				</TabPane>
			</TabContent>
		</>
	);
};

export default GeneralSettings;

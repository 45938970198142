import React, { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import local from "../../localization/strings";
import "react-input-range/lib/css/index.css";
import { CurrentUserDisplayName } from "../../api/auth";
import { toast } from "react-toastify";

const EmailModal = ({ show, cancel, sendEmail }) => {
	const [open, setOpen] = useState(show);
	const [currentUser, setCurrentUser] = useState("Someone");

	useEffect(() => {
		setOpen(show);
		setCurrentUser(CurrentUserDisplayName());
	}, [show]);

	const submit = (_e, values) => {
		sendEmail(values.emailAddress, values.emailSubject, values.emailBody);
		toast.success(local.TF_Email_sent_successfully);
	};

	return (
		<Modal isOpen={open} toggle={() => setOpen(false)} centered backdrop="static">
			<ModalHeader>
				<span className="float-left">{local.TS_Email}</span>
				<span className="float-right close-modal" style={{ position: "absolute", top: 15, right: 20 }} onClick={() => cancel()}>
					X
				</span>
			</ModalHeader>
			<ModalBody>
				<AvForm onValidSubmit={async (e, values) => await submit(e, values)}>
					<AvField name="emailAddress" label={local.TS_Email_Address} validate={{ email: { value: true, errorMessage: local.TS_Invalid }, required: { value: true, errorMessage: local.TS_Required } }} />
					<AvField name="emailSubject" label={local.TF_Subject} value={"Darca Connect - " + currentUser + " " + local.TF_has_sent_email} validate={{ required: { value: true, errorMessage: local.TS_Required } }} />
					<AvField name="emailBody" label={local.TF_Email_Content} rows="5" type="textarea" validate={{ required: { value: true, errorMessage: local.TS_Required } }} />
					<hr className="mx-n3" />
					<Button color="primary" type="submit">
						{local.TS_Send}
					</Button>
					<Button className="ml-2" onClick={() => cancel()}>
						{local.TS_Cancel}
					</Button>
				</AvForm>
			</ModalBody>
		</Modal>
	);
};

export default EmailModal;

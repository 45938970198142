import moment from "moment";
import { features, isFeatureEnabled } from "../../../../config";

const DateTimeFormat = (dateTime, showSeconds = false) => {
	if (dateTime && dateTime !== "0001-01-01T00:00:00") {
		if (isFeatureEnabled(features.Seconds)) {
			return moment(dateTime).format("DD MMM YYYY HH:mm:ss");
		}

		return moment(dateTime).format("DD MMM YYYY HH:mm");
	} else {
		return "";
	}
};

export default DateTimeFormat;

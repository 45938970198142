import moment from "moment";
import { features, isFeatureEnabled } from "../config";

export const FixDate = (date) => {
	if (!date) {
		return date;
	}

	if (typeof date === "string") {
		if (date.includes(" ")) {
			const m = moment(date, "Do MMM YYYY");

			return m.format("YYYY-MM-DD");
		}
		const m = moment(date);

		return m.format("YYYY-MM-DD");
	} else if (moment.isMoment(date)) {
		return date.format("YYYY-MM-DD");
	} else {
		const m = moment([date.getFullYear(), date.getMonth(), date.getDate()]);

		return m.format("YYYY-MM-DD");
	}
};

export const formatDateTime = (stringValue) => {
	if (stringValue) {
		const timestamp = stringValue.split("T");

		const date = timestamp[0].split("-");
		var yyyy = date[0];
		var mm = date[1];
		var dd = date[2];
		var time = timestamp[1].split(":");
		var h = time[0];
		var m = time[1];
		var s = time[2];

		if (isFeatureEnabled(features.Seconds)) {
			return h + ":" + m + ":" + s + ", " + dd + "/" + mm + "/" + yyyy;
		}
		return h + ":" + m + ", " + dd + "/" + mm + "/" + yyyy;
	} else {
		return null;
	}
};

export const formatISO = (date) => {
	if (!date) {
		return date;
	}

	if (typeof date === "string") {
		if (date.includes(" ")) {
			const m = moment(date, "Do MMM YYYY HH:mm:ss");

			return m.utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
		}
		const m = moment(date);

		return m.utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
	} else if (moment.isMoment(date)) {
		return date.utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
	} else {
		const m = moment([date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()]);

		return m.utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
	}
};

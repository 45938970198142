import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import local from "../../../localization/strings";
import { UserPreferencesContext } from "../../../context/userPreferences";

const SearchResultGroup = ({ item, close }) => {
	const [zoneId, setZoneId] = useState(undefined);
	const { getZoneForGroupId } = useContext(UserPreferencesContext);

	useEffect(() => {
		setZoneId(getZoneForGroupId(parseInt(item.id)));
	}, [item, getZoneForGroupId]);

	return (
		<div className="p-2">
			<Link to={`/charts/zone_charts/${zoneId}/${item.id}`} className="text-nowrap" onClick={close}>
				<b>{local.TF_Search_Group}:</b> {item.entityName}
			</Link>
		</div>
	);
};

export default SearchResultGroup;

import axios from "axios";
import { CurrentSiteIdGet } from "./userSettings";

export const buildingsGetListForSite = async (siteId) => {
	try {
		const result = await axios({
			method: "get",
			url: "v2/Buildings",
			params: {
				SiteId: siteId,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const buildingsGetList = async () => {
	const siteId = CurrentSiteIdGet();

	return await buildingsGetListForSite(siteId);
};

export const apiBuildingGet = async (id) => {
	const buildings = await buildingsGetList();

	return buildings.find((x) => x.id === id);
};

export const apiBuildingSave = async (id, name, index) => {
	const siteId = CurrentSiteIdGet();

    if (!id) {
        id = -1;
    }

	try {
		await axios({
			method: "post",
			url: "v2/Building",
			data: { siteId, building: { siteId, id, name, index } },
		});
	} catch (err) {
		console.error(err);
	}
};

export const apiBuildingDelete = async (buildingId) => {
	const siteId = CurrentSiteIdGet();

	try {
		await axios({
			method: "delete",
			url: "v2/Building",
			data: { siteId, buildingId },
		});
	} catch (err) {
		console.error(err);
	}
};

import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Switch, withRouter } from "react-router-dom";
import { ProtectedRoute } from "../components/auth/ProtectedRoute";
import { roles } from "../config";

import AdminLayout from "./AdminLayout";
import DashboardLayout from "./DashboardLayout";
import ErrorLayout from "./ErrorLayout";

import loadable from "@loadable/component";
import { ToastContainer } from "react-toastify";
import MFA from "../components/mfa/MFA";
import squirrelChannelsForCode from "../components/squirrels/squirrel-channels-for-code";
import EmailChartReport from "../components/statistics/email-chart-reports.js/EmailChartReport";
import withTracker from "../hoc/withTracker";

const AuthBasicLayout = loadable(() => import("./AuthBasicLayout"));

const Layout = ({ location }) => {
	useEffect(() => {
		AuthBasicLayout.preload();
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	return (
		<>
			<ToastContainer />
			<Router fallback={<span />}>
				<Switch>
					<Route path="/authentication" component={AuthBasicLayout} />
					<Route path="/errors" component={ErrorLayout} />
					<Route path="/admin" component={AdminLayout} />
					<Route path="/distributor" component={AdminLayout} />
					<Route path="/user" component={AdminLayout} />
					<Route path="/email_chart/:token/:reportType" component={withTracker(EmailChartReport)} />
					<Route path="/a/:code" exact component={withTracker(squirrelChannelsForCode)} />
					<Route path="/mfa/:siteId" exact component={withTracker(MFA)} />
					<ProtectedRoute component={DashboardLayout} requiredRole={roles.Any} />
				</Switch>
			</Router>
		</>
	);
};

Layout.propTypes = { location: PropTypes.object.isRequired };

export default withRouter(Layout);

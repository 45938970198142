import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import ListGroup from "reactstrap/es/ListGroup";
import ListGroupItem from "reactstrap/es/ListGroupItem";
import { apiSquirrelsGetList } from "../../api/apiSquirrels";
import { CurrentUserHasRole } from "../../api/auth";
import { apiLoggerStopStartOutstanding } from "../../api/logger";
import { readingsInAlarm } from "../../api/readings";
import { features, isFeatureEnabled, loggerStopStartRefreshValuesPollInterval, meterPollingInterval, roles } from "../../config";
import { UserPreferencesContext } from "../../context/userPreferences";
import { isIterableArray } from "../../helpers/utils";
import useInterval from "../../hooks/useInterval";
import local from "../../localization/strings";
import FalconCardHeader from "../common/FalconCardHeader";
import RouterNoSignalIcon from "../common/icon/RouterNoSignal";
import AlarmNotification from "../notification/AlarmNotification";
import LoggerStatus from "../notification/LoggerStatus";

const NotificationDropdown = () => {
	const [notifications, setNotifications] = useState([]);
	const [loadingAlarm, setLoadingAlarm] = useState(true);
	const [loadingLogger, setLoadingLogger] = useState(true);
	const [outstandingStopStart, setOutstandingStopStart] = useState({});
	const [squirrels, setSquirrels] = useState([]);
	const [any, setAny] = useState(false);
	const [isOpenAlarm, setIsOpenAlarm] = useState(false);
	const [isOpenLoggerStarted, setIsOpenLoggerStarted] = useState(false);
	const [isOpenLoggerStopped, setIsOpenLoggerStopped] = useState(false);
	const { newReleaseNotes } = useContext(UserPreferencesContext);

	const setBadge = useCallback((badgeName, count) => {
		var elem = $(`.badge-${badgeName}`);
		elem.removeClass("d-none");
		if (elem) {
			elem.text(count);
			if (count === 0) {
				elem.addClass("d-none");
			}
		}
	}, []);

	const fetchDataCallback = useCallback(async () => {
		const result = await readingsInAlarm();

		if (result) {
			setNotifications(result);
			setAny(result && result.filter((x) => !x.clearedBy).length > 0);

			setBadge("AlarmCount", result?.filter((x) => !x.clearedBy).length || 0);
			setBadge("AlarmClearedCount", result?.filter((x) => x.clearedBy).length || 0);

			setLoadingAlarm(false);
		} else {
			setLoadingAlarm(true);
		}
	}, [setBadge]);

	const fetchDataLogger = useCallback(async () => {
		if (isFeatureEnabled(features.LoggerStopStart) && CurrentUserHasRole(roles.SiteAdmin)) {
			setIsOpenLoggerStarted(false);
			setIsOpenLoggerStopped(false);
			setOutstandingStopStart(await apiLoggerStopStartOutstanding());
			setSquirrels(await apiSquirrelsGetList());
		}

		setLoadingLogger(false);
	}, []);

	useEffect(() => {
		var elem = $(".badge-NewReleaseNotes");
		if (elem) {
			if (newReleaseNotes) {
				elem.removeClass("d-none");
				elem.text("!");
			} else {
				elem.addClass("d-none");
			}
		}
	}, [newReleaseNotes]);

	useInterval(() => {
		fetchDataCallback();
		fetchDataLogger();
	}, meterPollingInterval);

	useInterval(async () => {
		if (Object.keys(outstandingStopStart).length > 0) {
			await fetchDataLogger();
		}
	}, loggerStopStartRefreshValuesPollInterval);

	useEffect(() => {
		//Delay by a random up to 2s to stop this and the dashboard both hitting to get readings at the exact same time
		const delay = Math.floor(Math.random() * 2000);

		const interval = setTimeout(() => {
			fetchDataCallback();
			fetchDataLogger();
		}, delay);

		return () => {
			clearTimeout(interval);
		};
	}, [fetchDataCallback, fetchDataLogger]);

	const handleToggleAlarm = (e) => {
		e.preventDefault();
		setIsOpenAlarm(!isOpenAlarm);
	};
	const handleToggleLoggerStarted = (e) => {
		e.preventDefault();
		setIsOpenLoggerStarted(!isOpenLoggerStarted);
	};
	const handleToggleLoggerStopped = (e) => {
		e.preventDefault();
		setIsOpenLoggerStopped(!isOpenLoggerStopped);
	};

	return (
		<>
			{newReleaseNotes && (
				<div className="unread-indicator py-2" title={local.TS_New_Release_Notes}>
					<Link className="text-secondary" to={`/release-notes`}>
						<FontAwesomeIcon icon="list-alt" transform="shrink-6" className="fs-4" />
					</Link>
				</div>
			)}
			{isFeatureEnabled(features.LoggerStopStart) && CurrentUserHasRole(roles.SiteAdmin) && (
				<>
					{squirrels.some((x) => typeof x.loggerStatus === "boolean" && !x.loggerStatus) && (
						<Dropdown nav inNavbar isOpen={isOpenLoggerStopped} toggle={handleToggleLoggerStopped}>
							<DropdownToggle nav className={`px-0 bell-Alarm`}>
								{!loadingLogger && <RouterNoSignalIcon />}
							</DropdownToggle>
							<DropdownMenu right className="dropdown-menu-card">
								<Card className="card-notification shadow-none" style={{ maxWidth: "30rem" }}>
									<ListGroup flush className="font-weight-normal fs--1">
										<div style={{ maxHeight: 370, overflowY: "auto", overflowX: "hidden" }}>
											{squirrels
												.filter((x) => typeof x.loggerStatus === "boolean" && !x.loggerStatus)
												.map((sq, k) => (
													<ListGroupItem key={k}>
														<LoggerStatus squirrel={sq} outstanding={outstandingStopStart} reload={fetchDataLogger} />
													</ListGroupItem>
												))}
										</div>
									</ListGroup>
								</Card>
							</DropdownMenu>
						</Dropdown>
					)}
					{squirrels.some((x) => x.loggerStatus) && (
						<Dropdown nav inNavbar isOpen={isOpenLoggerStarted} toggle={handleToggleLoggerStarted}>
							<DropdownToggle nav className={`px-0 bell-Alarm`}>
								{!loadingLogger && <FontAwesomeIcon icon="router" transform="shrink-6" className="fs-4 text-success" />}
							</DropdownToggle>
							<DropdownMenu right className="dropdown-menu-card">
								<Card className="card-notification shadow-none" style={{ maxWidth: "30rem" }}>
									<ListGroup flush className="font-weight-normal fs--1">
										<div style={{ maxHeight: 370, overflowY: "auto", overflowX: "hidden" }}>
											{squirrels
												.filter((x) => x.loggerStatus)
												.map((sq, k) => (
													<ListGroupItem key={k}>
														<LoggerStatus squirrel={sq} outstanding={outstandingStopStart} reload={fetchDataLogger} />
													</ListGroupItem>
												))}
										</div>
									</ListGroup>
								</Card>
							</DropdownMenu>
						</Dropdown>
					)}
				</>
			)}
			<Dropdown nav inNavbar isOpen={isOpenAlarm} toggle={handleToggleAlarm}>
				<DropdownToggle nav className={`${any ? "unread-indicator" : ""} px-0 bell-Alarm`}>
					{loadingAlarm ? (
						<FontAwesomeIcon icon="spinner" transform="shrink-6" className="fs-4 fa-spin" />
					) : (
						<>
							<FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-4" />
						</>
					)}
				</DropdownToggle>
				<DropdownMenu right className="dropdown-menu-card">
					<Card className="card-notification shadow-none" style={{ maxWidth: "30rem" }}>
						<FalconCardHeader className="card-header" title={local.TF_Notifications} titleTag="h6" light={false} />
						<ListGroup flush className="font-weight-normal fs--1">
							<div className="list-group-title upper">{local.TF_NotificationsCurrent}</div>
							<div style={{ maxHeight: 370, overflowY: "auto", overflowX: "hidden" }}>
								{(!notifications || notifications.length === 0) && <p className="font-italic ml-2">{local.TF_No_notifications}</p>}
								{isIterableArray(notifications) &&
									notifications
										.filter((x) => !x.clearedBy)
										.map((notification, index) => (
											<ListGroupItem key={index} onClick={handleToggleAlarm}>
												<AlarmNotification {...notification} flush />
											</ListGroupItem>
										))}
							</div>
						</ListGroup>
					</Card>
				</DropdownMenu>
			</Dropdown>
		</>
	);
};

export default NotificationDropdown;

import $ from "jquery";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { BrowserRouter as Router } from "react-router-dom";
import { configureAxios } from "./api/axiosHelper";
import { gaTrackingId } from "./config";
import Layout from "./layouts/Layout";
import local from "./localization/strings";

import "react-datetime/css/react-datetime.css";
import "react-image-lightbox/style.css";
import "react-toastify/dist/ReactToastify.min.css";

import "./assets/scss/_user-matt.scss";
import "./assets/scss/_user.scss";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faAlignJustify, faAngleDoubleDown, faAngleDoubleRight, faArrowAltCircleLeft, faBatteryHalf, faBell, faCalendar, faChevronRight, faCog, faCogs, faDatabase, faInfo, faInfoCircle, faListOl, faMapMarkedAlt, faObjectGroup, faPencilAlt, faPlus, faPlusCircle, faRedoAlt, faShareSquare, faSitemap, faSlidersH, faStickyNote, faVolumeMute, faVolumeUp, faWaveSquare, faWindowMaximize, faWindowMinimize } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft, faArrowsH, faArrowsV, faBatteryEmpty, faBatteryFull, faBatteryQuarter, faBatterySlash, faCheck, faEllipsisV, faFileChartLine, faFileExport, faGripLines, faQuestion, faShareAlt, faSlidersHSquare, faTimes, faUndoAlt, faWarehouse } from "@fortawesome/pro-solid-svg-icons";
import { ReactGA_setSiteId } from "./helpers/ga";

library.add(faCogs, faChevronRight, faCalendar, faWaveSquare, faPlus, faListOl, faStickyNote, faRedoAlt, faWindowMaximize, faWindowMinimize, faShareSquare, faPlusCircle, faSitemap, faSlidersH, faMapMarkedAlt, faBell, faDatabase, faCog, faPencilAlt, faWarehouse, faInfoCircle, faAngleDoubleDown, faAngleDoubleRight, faFileChartLine, faFileExport, faArrowAltCircleLeft, faEllipsisV, faShareAlt, faInfo, faBatteryFull, faBatteryHalf, faBatteryQuarter, faBatteryEmpty, faBatterySlash, faObjectGroup, faAlignJustify, faCalendar, faArrowsH, faArrowsV, faSlidersHSquare, faGripLines, faCheck, faTimes, faQuestion, faArrowLeft, faUndoAlt, faVolumeMute, faVolumeUp);

configureAxios();

ReactGA.initialize({
	trackingId: gaTrackingId(),
});
ReactGA_setSiteId();

const App = () => {
	useEffect(() => {
		const stopProp = (e) => {
			e.stopPropagation();
		};
		$("body").on("keypress keydown keyup", ".modal-body", stopProp);

		return () => {
			$("body").off("keypress keydown keyup", ".modal-body", stopProp);
		};
	}, []);
	useEffect(() => {
		var userLanguage = localStorage.getItem("UserLanguage") || "";
		if (userLanguage === "") {
			userLanguage = local.getInterfaceLanguage();
			local.setLanguage(userLanguage);
			localStorage.setItem("UserLanguage", userLanguage);
		} else {
			local.setLanguage(userLanguage);
		}
	}, []);

	return (
		<Router basename={process.env.PUBLIC_URL}>
			<Layout />
		</Router>
	);
};

export default App;

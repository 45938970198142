import React, { useState, useEffect, useCallback } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Col } from "reactstrap";
import local from "../../../localization/strings";
import { parametersTable } from "../../../api/parameter";
import Loader from "../../common/Loader";
import ReactDataGrid from "react-data-grid";
import useWindowSize from "../../../hooks/useWindowSize";

const ChartOverlay = (props) => {
	const [open, setOpen] = useState(false);
	const { isMaximised } = props;
	const [data, setData] = useState([]);
	const [showLoading, setShowLoading] = useState(true);
	const [minHeight, setMinHeight] = useState(500);
	const [overlays, setOverlays] = useState([]);

	const size = useWindowSize();

	useEffect(() => {
		setOpen(props.showOverlay);
	}, [props.showOverlay]);

	useEffect(() => {
		setOverlays(props.overlays);
	}, [props.overlays]);

	const cancel = () => {
		setOpen(false);
		props.close();
	};

	useEffect(() => {
		size.then((r) => {
			if (isMaximised) {
				setMinHeight(r.height - 150);
			} else {
				setMinHeight(r.height - 375);
			}
		});
	}, [size, isMaximised]);

	const updateSelectedCallback = useCallback(
		(e) => {
			var id = e.target.id;
			var updated = overlays;

			var index = updated.indexOf(id);
			if (index > -1) {
				updated.splice(index, 1);
			}

			if (e.target.checked === true) {
				updated.push(id);
			}

			setOverlays(updated);
		},
		[overlays],
	);

	useEffect(() => {
		async function LoadData() {
			var result = await parametersTable();

			result.columns.slice(2).forEach(function(column) {
				column.formatter = ValueFormatter;
			});

			setData(result);

			setShowLoading(false);
		}

		const ValueFormatter = ({ value }) => {
			if (!value) {
				return <div />;
			}

			const parts = value.split(":");

			const id = parts[0];
			const colour = parts[1];

			var style = {
				backgroundColor: colour,
			};

			if (colour === "#FFFFFF") {
				style.border = "1px solid black";
			}

			var isChecked = overlays.includes(id);

			return (
				<div className="mx-auto p-1 text-center rounded overlay-parameter mb-n2" style={style}>
					<input type="checkbox" onChange={updateSelectedCallback} defaultChecked={isChecked} id={id} />
				</div>
			);
		};

		LoadData();
	}, [updateSelectedCallback, overlays]);

	return (
		<Modal size="lg" isOpen={open} toggle={() => setOpen(false)} centered backdrop="static">
			<ModalHeader>
				<span className="float-left">{local.TF_Overlays}</span>
				<span className="float-right close-modal" style={{ position: "absolute", top: 15, right: 20 }} onClick={cancel}>
					X
				</span>
			</ModalHeader>
			<ModalBody>
				{showLoading ? (
					<Loader extraclassname="loader-chartSized" />
				) : (
					<div className="MuiTable-Small">
						<ReactDataGrid columns={data.columns} rowGetter={(i) => data.rows[i]} rowsCount={data.rowsCount} minHeight={minHeight} enableRowSelect={null} />
					</div>
				)}
			</ModalBody>
			<ModalFooter>
				<Col className="float-left">
					<Button color="primary" className="float-left" onClick={() => props.onSave(overlays)}>
						{local.TS_Save}
					</Button>
					<Button className="ml-2 float-left" onClick={cancel}>
						{local.TS_Cancel}
					</Button>
				</Col>
			</ModalFooter>
		</Modal>
	);
};

export default ChartOverlay;

import MaterialTable from "material-table";
import moment from "moment";
import "moment/min/locales";
import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import { logsList } from "../../api/site";
import { tableIcons } from "../../helpers/tableIcons";
import local from "../../localization/strings";
import Loader from "../common/Loader";

const LogsTab = () => {
	const [data, setData] = useState(undefined);
	const [showLoading, setShowLoading] = useState(true);

	const columns = [{ title: local.TS_Date_Time, field: "dateTime", defaultSort: "desc", render: (rowData) => <div>{moment(rowData.dateTime).format("DD MMM YYYY HH:mm")}</div> }, { title: local.TF_Category, field: "category" }, { title: local.TF_Message, field: "logMessage" }, { title: local.TF_Site_Name, field: "siteName" }];

	useEffect(() => {
		const LoadData = async () => {
			setData(await logsList(true));

			setShowLoading(false);
		};

		LoadData();
	}, []);

	return (
		<>
			<Card className="placeholder-card">
				{showLoading ? (
					<Loader />
				) : (
					data && (
						<div className="MuiTable text-center">
							<MaterialTable
								columns={columns}
								data={data}
								title=""
								icons={tableIcons}
								options={{
									padding: "dense",
									sorting: true,
									exportButton: true,
									exportAllData: true,
									exportFileName: "Logs",
									paging: true,
									pageSize: 20,
									pageSizeOptions: [20, 50, 100],
									emptyRowsWhenPaging: false,
									headerStyle: {
										backgroundColor: "#fff",
										color: "#000",
									},
									rowStyle: {
										textAlign: "center",
									},
								}}
							/>
						</div>
					)
				)}
			</Card>
		</>
	);
};

export default LogsTab;

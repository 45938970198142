import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { apiVersionGet } from "../../api/apiReleaseNotes";

const Footer = () => {
	const [versionNumber, setVersionNumber] = useState(undefined);

	useEffect(() => {
		const LoadData = async () => {
			const serverVersion = await apiVersionGet();
			setVersionNumber(serverVersion);
		};
		LoadData();
	}, []);

	return (
		<footer>
			<Row noGutters className="justify-content-between text-center fs--1 mt-4 mb-3">
				<Col sm="auto">
					<p className="mb-0 text-600">
						Darca Connect <span className="d-none d-sm-inline-block">| </span>
						<br className="d-sm-none" /> {new Date().getFullYear()} &copy;{" "}
						<a href="http://www.eltekdataloggers.co.uk" target="_blank" rel="noopener noreferrer">
							Eltek Ltd.
						</a>
					</p>
				</Col>
				<Col sm="auto">{versionNumber && <p className="mb-0 text-600">v{versionNumber}</p>}</Col>
			</Row>
		</footer>
	);
};

export default Footer;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as html2canvas from "html2canvas";
import moment from "moment";
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from "reactstrap";
import { SendEmail } from "../../api/email";
import { zoneGraphic, zoneGraphicOverlays } from "../../api/zoneGraphics";
import { features, isFeatureEnabled } from "../../config";
import { UserPreferencesContext } from "../../context/userPreferences";
import { logEvent } from "../../helpers/ga";
import { zoneGraphicGroupArrow } from "../../helpers/utils";
import withRedirect from "../../hoc/withRedirect";
import useWindowSize from "../../hooks/useWindowSize";
import local from "../../localization/strings";
import EmailModal from "../common/EmailModal";
import PageTitle from "../common/PageTitle";
import PinSubmenu from "../common/PinSubmenu";
import SiteTreeMenu from "../common/SiteTreeMenu";

const buttonDefaultClasses = "btn-shadow btn-sm mb-sm-2 py-2 px-2 px-sm-3 py-sm-1";

const ZoneGraphicLayout = ({ setRedirect, setRedirectUrl, match }) => {
	const [showSelect, setShowSelect] = useState(false);
	const [image, setImage] = useState(null);
	const [captions, setCaptions] = useState([]);
	const [groups, setGroups] = useState([]);
	const [zoneId, setZoneId] = useState("");
	const [zoneName, setZoneName] = useState("");
	const [focusGroupId, setFocusGroupId] = useState(undefined);
	const [buildingName, setBuildingName] = useState("");
	const [pinTypes, setPinTypes] = useState([]);
	const [subMenuExpanded, setSubMenuExpanded] = useState(false);
	const [showEmail, setShowEmail] = useState(false);
	const [siteTree, setSiteTree] = useState(true);
	const size = useWindowSize();
	const titleFontSize = "8pt";
	const fontSize = "8pt";
	const font = "Verdana";
	const { getZone } = useContext(UserPreferencesContext);

	useEffect(() => {
		if (match && match.params && match.params.groupId) {
			setFocusGroupId(parseInt(match.params.groupId));
		} else {
			setFocusGroupId(undefined);
		}

		if (match && match.params && match.params.zoneId) {
			const intZoneId = parseInt(match.params.zoneId);

			const zone = getZone(intZoneId);

			if (zone) {
				setShowSelect(false);
				onZoneSelect(`Z:${intZoneId}`, zone.zoneName, zone.buildingName);
			} else {
				setShowSelect(true);
			}
		} else {
			setShowSelect(true);
		}
	}, [match, getZone]);

	useEffect(() => {
		const types = [];

		types.push({ name: `${local.TF_Pin_Zone_Graphic}`, type: "DashZoneGraphic", data: zoneId, zoneName: zoneName, buildingName: buildingName }, { name: `${local.TF_Pin_Zone_Graphic_Wide}`, type: "DashZoneGraphicWide", data: zoneId, zoneName: zoneName, buildingName: buildingName });

		setPinTypes(types);
	}, [zoneId, zoneName, buildingName]);

	const expandSubMenu = () => {
		var subMenuToggle = document.getElementById("submenu-toggle");
		var subMenu = document.getElementById("submenu-pin");

		if (subMenu.classList.contains("submenu-hide")) {
			subMenuToggle.setAttribute("aria-expanded", "true");
			subMenu.setAttribute("aria-hidden", "false");
			subMenu.classList.remove("submenu-hide");
			subMenu.classList.add("show", "submenu-show");
			setSubMenuExpanded(true);
		} else {
			subMenuToggle.setAttribute("aria-expanded", "false");
			subMenu.setAttribute("aria-hidden", "true");
			subMenu.classList.remove("submenu-show", "show");
			subMenu.classList.add("submenu-hide");
			setSubMenuExpanded(false);
		}
	};

	const onZoneSelect = async (data, zoneName, buildingName) => {
		const zoneId = data.substring(2);

		const imageResult = await zoneGraphic(zoneId);

		setImage(imageResult);

		if (imageResult) {
			var overlays = await zoneGraphicOverlays(zoneId);

			setCaptions(overlays.captions);
			setGroups(overlays.groups);
		}
		setZoneId(zoneId);
		setZoneName(zoneName);
		setBuildingName(buildingName);

		setShowSelect(false);
	};

	useEffect(() => {
		var dashboardContentDiv = document.getElementById("dashboard-container-div");

		if (dashboardContentDiv) {
			var contentDiv = document.getElementById("zone-graphic-div");
			contentDiv.style.marginRight = `-${dashboardContentDiv.offsetLeft}px`;
		}
	}, [size]);

	function saveAs(uri, filename) {
		var link = document.createElement("a");

		if (typeof link.download === "string") {
			link.href = uri;
			link.download = filename;

			//Firefox requires the link to be in the body
			document.body.appendChild(link);

			//simulate click
			link.click();

			//remove the link when done
			document.body.removeChild(link);
		} else {
			window.open(uri);
		}
	}

	function saveElem() {
		logEvent("Zone Graphics", "Save", `${zoneId}`);

		window.scrollTo(0, 0);
		html2canvas(document.querySelector("#printme")).then(function(canvas) {
			saveAs(canvas.toDataURL(), `${local.TF_Zone_Graphic} - ${buildingName} - ${zoneName} - ${moment().format("DD MMM YYYY")}.png`);
		});
	}

	function copyElem() {
		logEvent("Zone Graphics", "Copy", `${zoneId}`);

		window.scrollTo(0, 0);
		html2canvas(document.querySelector("#printme")).then(function(canvas) {
			var canvasDataUrl = canvas.toDataURL("image/png");

			var x = document.createElement("IMG");
			x.setAttribute("src", canvasDataUrl);
			x.setAttribute("width", "100%");
			x.setAttribute("height", "360");
			x.setAttribute("alt", "Chart");
			x.setAttribute("id", "chartImage");

			document.getElementById("temp").appendChild(x);

			var element = document.getElementById("chartImage");
			var range = document.createRange();
			range.selectNode(element);
			window.getSelection().removeAllRanges();
			window.getSelection().addRange(range);
			document.execCommand("Copy");
			document.getElementById("temp").removeChild(x);
			toast.success(local.TF_item_was_copied);
		});
	}

	function printElem() {
		logEvent("Zone Graphics", "Print", `${zoneId}`);

		var content = document.getElementById("printme").innerHTML;
		var mywindow = window.open("", local.TS_Print, "height=600,width=800");

		mywindow.document.write("<html><head><title>" + local.TS_Print + "</title>");
		mywindow.document.write("</head><body style='-webkit-print-color-adjust:exact;color-adjust: exact;'>");
		mywindow.document.write(content);
		mywindow.document.write("</body></html>");

		mywindow.document.close();
		mywindow.focus();
		mywindow.print();
		mywindow.close();
		return true;
	}

	const groupClicked = (group) => {
		logEvent("Zone Graphics", "Group Clicked", `ZoneId: ${zoneId}, GroupId: ${group.groupId}`);

		setRedirectUrl(`/charts/zone_charts/${zoneId}/${group.groupId}`);
		setRedirect(true);
	};

	const sendEmail = async (to, subject, body) => {
		logEvent("Zone Graphics", "Send Email", `To: ${to}, Subject: ${subject}`);

		window.scrollTo(0, 0);
		html2canvas(document.querySelector("#printme")).then(async function(canvas) {
			var canvasDataUrl = canvas.toDataURL("image/png");

			const base64Loc = canvasDataUrl.indexOf("base64,");

			await SendEmail(to, subject, body, canvasDataUrl.substring(base64Loc + 7));
		});

		setShowEmail(false);
	};

	const siteTreeFound = useCallback((value) => {
		setSiteTree(value);
	}, []);

	return (
		<div id="zone-graphic-div">
			<PageTitle title={local.TF_ZoneGraphicsTitle} />
			{showSelect ? (
				<Card>
					<SiteTreeMenu inline onClick={onZoneSelect} onlyIfHasZoneGraphic expandToLevel={2} siteMapLevel={2} siteTreeFound={siteTreeFound} />
					{!siteTree && (
						<div className="p-2">
							<div className="alert alert-info p-2">{local.TF_No_zone_graphics_set_up}.</div>
						</div>
					)}
				</Card>
			) : (
				<Fragment>
					<div id="temp" />
					<Row className="justify-content-between">
						<Col className="col-3">
							<Button
								size="sm"
								color="secondary"
								className={buttonDefaultClasses}
								onClick={() => {
									logEvent("Zone Graphics", "Select Zone Button");
									setShowSelect(true);
								}}
							>
								<FontAwesomeIcon icon="filter" />
								<span className="d-none d-md-inline-block ml-2">{local.TF_ZoneCharts_SelectZone}</span>
							</Button>
						</Col>
						<Col className="col-6 text-center">
							<h5>
								{buildingName} - {zoneName}
							</h5>
						</Col>
						<Col className="col-3">
							<UncontrolledDropdown size="sm" direction="left" className="allow-overflow float-right">
								<DropdownToggle caret color="info" transform="shrink-3" className={buttonDefaultClasses}>
									<FontAwesomeIcon icon="share-alt" />
									<span className="d-none d-md-inline-block ml-2">{local.TS_Options}</span>
								</DropdownToggle>
								<DropdownMenu className="menu-border-blue dropdown-menu">
									<Button onClick={() => expandSubMenu()} id="submenu-toggle" transform="shrink-3" aria-haspopup="true" aria-expanded="false" className="dropdown-item">
										{subMenuExpanded ? <FontAwesomeIcon icon="chevron-up" className="mr-1" /> : <FontAwesomeIcon icon="thumbtack" className="mr-1 pin-rotate" />}
										{local.TS_Pin}
									</Button>
									<div id="submenu-pin" tabIndex="-1" role="menu" aria-hidden="true" className="dropdown-submenu submenu-hide">
										<PinSubmenu types={pinTypes} className="mr-2 btn-shadow btn-sm mb-sm-2 p-3 py-sm-1 float-right btn btn-secondary" />
									</div>
									{/* v0.9 */}
									<div className="dropdown-divider" />
									{isFeatureEnabled(features.ZoneGraphicsDownload) && (
										<DropdownItem onClick={() => saveElem()}>
											<FontAwesomeIcon icon="download" /> {local.TS_Download}
										</DropdownItem>
									)}
									<DropdownItem onClick={() => printElem()}>
										<FontAwesomeIcon icon="print" /> {local.TS_Print}
									</DropdownItem>
									{isFeatureEnabled(features.ZoneGraphicsCopy) && (
										<DropdownItem onClick={() => copyElem()}>
											<FontAwesomeIcon icon="copy" /> {local.TS_Copy}
										</DropdownItem>
									)}
									{isFeatureEnabled(features.ZoneGraphicsEmail) && (
										<DropdownItem onClick={() => setShowEmail(true)}>
											<FontAwesomeIcon icon="envelope-square" /> {local.TS_Email}
										</DropdownItem>
									)}
								</DropdownMenu>
							</UncontrolledDropdown>
						</Col>
					</Row>
					<div style={{ overflow: "auto" }}>
						<Card className="p-4 mt-2">
							<Scrollbars autoHeight autoHeightMin={400} autoHeightMax={1000} width={"100%"}>
								<div id="printme" style={{ position: "relative", margin: "5px" }} className="d-inline-block">
									{image ? <img alt="" className="" src={`data:image/png;base64,${image}`} /> : <div>{local.TF_No_Zone_Graphic_Found}</div>}
									{/* Do all lines first or they stop you clicking on group boxes */}
									{groups.map((group, index) => {
										return <Fragment key={`${index}_Line`}>{group.hasArrow && <div style={zoneGraphicGroupArrow(group)} />}</Fragment>;
									})}
									{groups.map((group, index) => {
										const focus = focusGroupId === group.groupId;
										return (
											<Fragment key={index}>
												<div className="zone-graphic-readings" style={{ left: `${group.x}px`, top: `${group.y}px`, position: "absolute", backgroundColor: "white", borderColor: focus ? "red" : "black", borderWidth: focus ? "3px" : "1px", borderStyle: "solid", padding: "5px 10px", textAlign: "left", whiteSpace: "nowrap", lineHeight: "1.2" }} onClick={() => groupClicked(group)}>
													<div style={{ fontSize: titleFontSize, fontWeight: "bold", fontFamily: font }}>{group.name}</div>
													{group.readings.map((reading, readingIndex) => (
														<Fragment key={readingIndex}>
															{reading.realValue !== 0 && !reading.realValue ? (
																<div style={{ fontSize: fontSize, fontFamily: font }}>
																	{reading.name}:<span style={{ padding: "0 2px" }}>{local.TS_NO_DATA}</span>
																</div>
															) : reading.mainAlarm ? (
																<div style={{ fontSize: fontSize, fontFamily: font }}>
																	{reading.name}:<span style={{ backgroundColor: "red", color: "white", padding: "0 2px" }}>{reading.realValue.toFixed(reading.decimalPlaces)}</span>
																</div>
															) : reading.warnAlarm ? (
																<div style={{ fontSize: fontSize, fontFamily: font }}>
																	{reading.name}:<span style={{ backgroundColor: "orange", color: "white", padding: "0 2px" }}>{reading.realValue.toFixed(reading.decimalPlaces)}</span>
																</div>
															) : (
																<div style={{ fontSize: fontSize, fontFamily: font }}>
																	{reading.name}:<span style={{ padding: "0 2px" }}>{reading.realValue.toFixed(reading.decimalPlaces)}</span>
																</div>
															)}
														</Fragment>
													))}
												</div>
											</Fragment>
										);
									})}
									{captions.map((caption, index) => (
										<div key={index} style={{ left: `${caption.x}px`, top: `${caption.y}px`, position: "absolute", backgroundColor: "white", borderColor: "black", borderWidth: "1px", borderStyle: "solid", padding: "5px", textAlign: "left", whiteSpace: "nowrap", lineHeight: "1" }}>
											<div style={{ fontSize: fontSize, fontFamily: font }}>{caption.caption}</div>
										</div>
									))}
								</div>
							</Scrollbars>
						</Card>
					</div>
					<EmailModal show={showEmail} sendEmail={sendEmail} cancel={() => setShowEmail(false)} />
				</Fragment>
			)}
		</div>
	);
};

export default withRedirect(withRouter(ZoneGraphicLayout));

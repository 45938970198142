import React, { useState, useEffect } from "react";
import Loader from "../../common/Loader";
import useWindowSize from "../../../hooks/useWindowSize";
import { exportData } from "../../../api/reports";
import MaterialTable from "material-table";
import { tableIcons } from "../../../helpers/tableIcons";
import { features, isFeatureEnabled } from "../../../config";

const ExportDataDisplay = ({ filterData, setReportRunning }) => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({});
	const size = useWindowSize();

	useEffect(() => {
		var dashboardContentDiv = document.getElementById("dashboard-container-div");

		if (dashboardContentDiv) {
			var contentDiv = document.getElementById("export-data-report-div");

			if (contentDiv) {
				contentDiv.style.marginRight = `-${dashboardContentDiv.offsetLeft}px`;
			}
		}
	}, [size]);

	useEffect(() => {
		const LoadData = async () => {
			const { locationIds, startTime, endTime } = filterData;

			if (locationIds.length === 0) {
				setData(null);
			} else {
				const resultsData = await exportData(locationIds.join(","), startTime, endTime, isFeatureEnabled(features.Seconds));

				resultsData.columns[0].cellStyle = { fontWeight: "bold" };

				setData(resultsData);
			}
			setReportRunning(false);
			setLoading(false);
		};

		LoadData();
	}, [filterData, setReportRunning]);

	return <>{loading ? <Loader /> : <div className="m-n3">{data && <MaterialTable columns={data.columns} data={data.rows} title={data.title} icons={tableIcons} options={{ header: false, emptyRowsWhenPaging: false, exportButton: true, exportAllData: true, exportFileName: "Export", paging: true, pageSize: 25, pageSizeOptions: [25] }} />}</div>}</>;
};

export default ExportDataDisplay;

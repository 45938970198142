import React, { useEffect, useState } from "react";
import ChartReportPdf from "./ChartReportPdf";
import local from "../../../localization/strings";

const ChartReportPage = ({ filterData, zoneCharts, setReportRunning }) => {
	const [data, setData] = useState({});

	useEffect(() => {
		let orderList = filterData.orderList;
		let showList = filterData.showList;

		if (!orderList) {
			orderList = zoneCharts.map((item) => {
				return `${item.groupId}:${item.graphIndex}`;
			});
			filterData.orderList = orderList;
		}

		if (!showList) {
			filterData.showList = [];
		}

		setData(filterData);
	}, [filterData, zoneCharts]);

	return (
		<div style={{ minHeight: "400px", height: "70vh" }} className={`border rounded-soft w-100 ${filterData ? "" : "thick-border"}`}>
			{data && data.showList && data.showList.length >= 1 ? <ChartReportPdf filterData={data} previewHeight={"70vh"} setReportRunning={setReportRunning} /> : <p>{local.TF_Select_Charts_To_Display}</p>}
		</div>
	);
};

export default ChartReportPage;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MaterialTable from "material-table";
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, UncontrolledDropdown } from "reactstrap";
import { UserPreferencesContext } from "../../context/userPreferences";
import { tableIcons } from "../../helpers/tableIcons";
import local from "../../localization/strings";
import Loader from "../common/Loader";
import { formatNumberM, getAverageM, getDataValueM, getEndOfDataM, getMaxDataFirstTimeM, getMaxDataM, getMinDataFirstTimeM, getMinDataM, getRangeM, getReadingsAboveM, getReadingsAboveValueM, getReadingsBelowM, getReadingsBelowValueM, getReadingsOutsideM, getReadingsWithinM, getSiteMapValueM, getStandardDeviationM, getStartOfDataM, getSumM, getTimesM, getTimesValueM, getValidDataM, percent } from "./StatisticsHelper";

const buttonDefaultClasses = "mr-1 btn-shadow btn-sm mb-sm-2 py-2 px-2 px-sm-3 py-sm-1";

const ChartSummary = ({ show, data, action, customThresholds }) => {
	const [open, setOpen] = useState(false);
	const [raw, setRaw] = useState({});
	const { siteMap, preferences } = useContext(UserPreferencesContext);
	const [ids, setIds] = useState([]);
	const [selectedTab, setSelectedTab] = useState(0);
	const [timeKey, setTimeKey] = useState(Date.now());
	const tableRef = useRef(null);

	useEffect(() => {
		setIds([]);
		setRaw(data);
		setTimeKey(Date.now());
		setOpen(show);
		setIds(data?.columns?.filter((x) => x.field !== "DateTime")?.map((x) => x.field));
	}, [show, data]);

	const exportTable = () => {
		let csvRows = [];
		const separator = ",";
		const tableEle = tableRef.current;
		//only get direct children of the table in question (thead, tbody)
		Array.from(tableEle.children).forEach(function(node) {
			//using scope to only get direct tr of node
			node.querySelectorAll(":scope > tr").forEach(function(tr) {
				let csvLine = [];
				//again scope to only get direct children
				tr.querySelectorAll(":scope > td").forEach(function(td) {
					//clone as to not remove anything from original
					let copytd = td.cloneNode(true);
					let data;
					if (copytd.dataset.val) data = copytd.dataset.val.replace(/(\r\n|\n|\r)/gm, "");
					else {
						Array.from(copytd.children).forEach(function(remove) {
							//remove nested elements before getting text
							remove.parentNode.removeChild(remove);
						});
						data = copytd.textContent.replace(/(\r\n|\n|\r)/gm, "");
					}
					data = data
						.replace(/(\s\s)/gm, " ")
						.replace(/"/g, '""')
						.replace(/°/g, "deg");
					csvLine.push('"' + data + '"');
				});
				csvRows.push(csvLine.join(separator));
			});
		});
		var a = document.createElement("a");
		a.style = "display: none; visibility: hidden"; //safari needs visibility hidden
		a.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csvRows.join("\n"));
		a.download = "statistics.csv";
		document.body.appendChild(a);
		a.click();
		a.remove();
	};

	return (
		<Modal isOpen={open} toggle={() => setOpen(false)} centered backdrop="static" size="xl">
			<ModalHeader>
				<span className="float-left">{local.TF_Summary}</span>
				<span className="float-right close-modal" style={{ position: "absolute", top: 15, right: 20 }} onClick={() => action("HideSummary")}>
					X
				</span>
			</ModalHeader>
			<ModalBody>
				<Tabs selectedIndex={selectedTab} onSelect={(index) => setSelectedTab(index)}>
					<TabList style={{ overflowX: "auto", overflowY: "hidden", whiteSpace: "nowrap" }}>
						<Tab>{local.TS_Statistics}</Tab>
						<Tab>{local.TF_Data}</Tab>
					</TabList>

					<TabPanel>
						<div style={{ overflowX: "auto" }}>
							<table className="text-left table-shaded-col1 table-nowrap-row4plus table-pad-x-1 table-bordered fs--2" ref={tableRef}>
								<tbody>
									<tr>
										<td>{local.TS_Building}</td>
										{ids?.map((id) => <td key={`buildingName_${id}`}>{getSiteMapValueM(timeKey, siteMap, id, "buildingName")}</td>)}
									</tr>
									<tr>
										<td>{local.TS_Zone}</td>
										{ids?.map((id) => <td key={`zoneName_${id}`}>{getSiteMapValueM(timeKey, siteMap, id, "zoneName")}</td>)}
									</tr>
									<tr>
										<td>{local.TS_Group}</td>
										{ids?.map((id) => <td key={`groupName_${id}`}>{getSiteMapValueM(timeKey, siteMap, id, "groupName")}</td>)}
									</tr>
									<tr>
										<td>{local.TS_Parameter}</td>
										{ids?.map((id) => (
											<td key={`parameter_${id}`}>
												{getSiteMapValueM(timeKey, siteMap, id, "locationName")} ({getDataValueM(timeKey, raw, id, "parameterUnits")})
											</td>
										))}
									</tr>
									<tr>
										<td>{local.TF_Start_of_data}</td>
										{ids?.map((id) => <td key={`startofdata_${id}`}>{getStartOfDataM(timeKey, data, id)}</td>)}
									</tr>
									<tr>
										<td>{local.TF_End_of_data}</td>
										{ids?.map((id) => <td key={`endofdata_${id}`}>{getEndOfDataM(timeKey, data, id)}</td>)}
									</tr>
									<tr>
										<td>{local.TF_Duration}</td>
										{ids?.map((id) => <td key={`duration_${id}`}>{getTimesM(timeKey, data, preferences, true, id)?.totalTimeString}</td>)}
									</tr>
									<tr>
										<td>{local.TF_Valid_Data}</td>
										{ids?.map((id) => <td key={`validdata_${id}`}>{getValidDataM(timeKey, data, id)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>)}
									</tr>
									<tr>
										<td>{local.TF_Max_Value}</td>
										{ids?.map((id) => <td key={`maxvalue_${id}`}>{formatNumberM(timeKey, data, getMaxDataM(timeKey, data, id), id)}</td>)}
									</tr>
									<tr>
										<td>{local.TF_Time_of_First_Max_Value}</td>
										{ids?.map((id) => <td key={`timeoffirstmaxvalue_${id}`}>{getMaxDataFirstTimeM(timeKey, data, id)}</td>)}
									</tr>
									<tr>
										<td>{local.TF_Min_Value}</td>
										{ids?.map((id) => <td key={`minvalue_${id}`}>{formatNumberM(timeKey, data, getMinDataM(timeKey, data, id), id)}</td>)}
									</tr>
									<tr>
										<td>{local.TF_Time_of_First_Min_Value}</td>
										{ids?.map((id) => <td key={`timeoffirstminvalue_${id}`}>{getMinDataFirstTimeM(timeKey, data, id)}</td>)}
									</tr>
									<tr>
										<td>{local.TF_Average}</td>
										{ids?.map((id) => <td key={`average_${id}`}>{formatNumberM(timeKey, data, getAverageM(timeKey, data, id), id)}</td>)}
									</tr>
									<tr>
										<td>{local.TF_Sum}</td>
										{ids?.map((id) => <td key={`sum_${id}`}>{formatNumberM(timeKey, data, getSumM(timeKey, data, id), id)}</td>)}
									</tr>
									<tr>
										<td>{local.TF_StandardDeviation}</td>
										{ids?.map((id) => <td key={`standarddeviation_${id}`}>{getStandardDeviationM(timeKey, data, id)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>)}
									</tr>
									<tr>
										<td colSpan={1}>&nbsp;</td>
									</tr>
									<tr>
										<td className="font-weight-bold">{local.TF_Alarm_Range}</td>
										{ids?.map((id) => <td key={`alarmrange_${id}`}>{getRangeM(timeKey, data, preferences, true, id)}</td>)}
									</tr>
									<tr>
										<td>{local.TF_Readings_Within}</td>
										{ids?.map((id) => <td key={`readingswithin_${id}`}>{getReadingsWithinM(timeKey, data, preferences, true, id)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>)}
									</tr>
									<tr>
										<td>{local.TF_Percent_Readings_Within}</td>
										{ids?.map((id) => <td key={`percentreadingswithin_${id}`}>{percent(getReadingsWithinM(timeKey, data, preferences, true, id), getValidDataM(timeKey, data, id))}</td>)}
									</tr>
									<tr>
										<td>{local.TF_Time_Within}</td>
										{ids?.map((id) => <td key={`timewithin_${id}`}>{getTimesM(timeKey, data, preferences, true, id)?.withinTimeString}</td>)}
									</tr>
									<tr>
										<td>{local.TF_Readings_Outside}</td>
										{ids?.map((id) => <td key={`readingsoutside_${id}`}>{getReadingsOutsideM(timeKey, data, preferences, true, id)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>)}
									</tr>
									<tr>
										<td>{local.TF_Percent_Readings_Outside}</td>
										{ids?.map((id) => <td key={`percentreadingsoutside_${id}`}>{percent(getReadingsOutsideM(timeKey, data, preferences, true, id), getValidDataM(timeKey, data, id))}</td>)}
									</tr>
									<tr>
										<td>{local.TF_Time_Outside}</td>
										{ids?.map((id) => <td key={`timeoutside_${id}`}>{getTimesM(timeKey, data, preferences, true, id)?.outsideTimeString}</td>)}
									</tr>
									<tr>
										<td>{local.TF_Readings_Below}</td>
										{ids?.map((id) => <td key={`readingsbelow_${id}`}>{getReadingsBelowM(timeKey, data, preferences, true, id)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>)}
									</tr>
									<tr>
										<td>{local.TF_Percent_Readings_Below}</td>
										{ids?.map((id) => <td key={`percentreadingsbelow_${id}`}>{percent(getReadingsBelowM(timeKey, data, preferences, true, id), getValidDataM(timeKey, data, id))}</td>)}
									</tr>
									<tr>
										<td>{local.TF_Time_Below}</td>
										{ids?.map((id) => <td key={`timebelow_${id}`}>{getTimesM(timeKey, data, preferences, true, id)?.belowTimeString}</td>)}
									</tr>
									<tr>
										<td>{local.TF_Readings_Above}</td>
										{ids?.map((id) => <td key={`readingsabove_${id}`}>{getReadingsAboveM(timeKey, data, preferences, true, id)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>)}
									</tr>
									<tr>
										<td>{local.TF_Percent_Readings_Above}</td>
										{ids?.map((id) => <td key={`percentreadingsabove_${id}`}>{percent(getReadingsAboveM(timeKey, data, preferences, true, id), getValidDataM(timeKey, data, id))}</td>)}
									</tr>
									<tr>
										<td>{local.TF_Time_Above}</td>
										{ids?.map((id) => <td key={`timeabove_${id}`}>{getTimesM(timeKey, data, preferences, true, id)?.aboveTimeString}</td>)}
									</tr>
									<tr>
										<td colSpan={1}>&nbsp;</td>
									</tr>
									<tr>
										<td className="font-weight-bold">{local.TF_Threshold_Range}</td>
										{ids?.map((id) => <td key={`thresholdrange_${id}`}>{getRangeM(timeKey, data, preferences, false, id)}</td>)}
									</tr>
									<tr>
										<td>{local.TF_Readings_Within}</td>
										{ids?.map((id) => <td key={`readingswithin_${id}`}>{getReadingsWithinM(timeKey, data, preferences, false, id)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>)}
									</tr>
									<tr>
										<td>{local.TF_Percent_Readings_Within}</td>
										{ids?.map((id) => <td key={`percentreadingswithin_${id}`}>{percent(getReadingsWithinM(timeKey, data, preferences, false, id), getValidDataM(timeKey, data, id))}</td>)}
									</tr>
									<tr>
										<td>{local.TF_Time_Within}</td>
										{ids?.map((id) => <td key={`timewithin_${id}`}>{getTimesM(timeKey, data, preferences, false, id)?.withinTimeString}</td>)}
									</tr>
									<tr>
										<td>{local.TF_Readings_Outside}</td>
										{ids?.map((id) => <td key={`readingsoutside_${id}`}>{getReadingsOutsideM(timeKey, data, preferences, false, id)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>)}
									</tr>
									<tr>
										<td>{local.TF_Percent_Readings_Outside}</td>
										{ids?.map((id) => <td key={`percentreadingsoutside_${id}`}>{percent(getReadingsOutsideM(timeKey, data, preferences, false, id), getValidDataM(timeKey, data, id))}</td>)}
									</tr>
									<tr>
										<td>{local.TF_Time_Outside}</td>
										{ids?.map((id) => <td key={`timeoutside_${id}`}>{getTimesM(timeKey, data, preferences, false, id)?.outsideTimeString}</td>)}
									</tr>
									<tr>
										<td>{local.TF_Readings_Below}</td>
										{ids?.map((id) => <td key={`readingsbelow_${id}`}>{getReadingsBelowM(timeKey, data, preferences, false, id)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>)}
									</tr>
									<tr>
										<td>{local.TF_Percent_Readings_Below}</td>
										{ids?.map((id) => <td key={`percentreadingsbelow_${id}`}>{percent(getReadingsBelowM(timeKey, data, preferences, false, id), getValidDataM(timeKey, data, id))}</td>)}
									</tr>
									<tr>
										<td>{local.TF_Time_Below}</td>
										{ids?.map((id) => <td key={`timebelow_${id}`}>{getTimesM(timeKey, data, preferences, false, id)?.belowTimeString}</td>)}
									</tr>
									<tr>
										<td>{local.TF_Readings_Above}</td>
										{ids?.map((id) => <td key={`readingsabove_${id}`}>{getReadingsAboveM(timeKey, data, preferences, false, id)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>)}
									</tr>
									<tr>
										<td>{local.TF_Percent_Readings_Above}</td>
										{ids?.map((id) => <td key={`percentreadingsabove_${id}`}>{percent(getReadingsAboveM(timeKey, data, preferences, false, id), getValidDataM(timeKey, data, id))}</td>)}
									</tr>
									<tr>
										<td>{local.TF_Time_Above}</td>
										{ids?.map((id) => <td key={`timeabove_${id}`}>{getTimesM(timeKey, data, preferences, false, id)?.aboveTimeString}</td>)}
									</tr>
									{customThresholds &&
										customThresholds.map((ct, k) => (
											<Fragment key={k}>
												<tr>
													<td colSpan={1}>&nbsp;</td>
												</tr>
												<tr>
													<td className="font-weight-bold">
														{ct.name} ({ct.value})
													</td>
													{ids?.map((id) => <td key={id}>&nbsp;</td>)}
												</tr>
												<tr>
													<td>{local.TF_Readings_Below}</td>
													{ids?.map((id) => <td key={`readingsbelow_${id}`}>{getReadingsBelowValueM(timeKey, data, id, ct.value, ct.axis)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>)}
												</tr>
												<tr>
													<td>{local.TF_Percent_Readings_Below}</td>
													{ids?.map((id) => <td key={`percentreadingsbelow_${id}`}>{percent(getReadingsBelowValueM(timeKey, data, id, ct.value, ct.axis), getValidDataM(timeKey, data, id))}</td>)}
												</tr>
												<tr>
													<td>{local.TF_Time_Below}</td>
													{ids?.map((id) => <td key={`timebelow_${id}`}>{getTimesValueM(timeKey, data, id, ct.value, ct.axis)?.belowTimeString}</td>)}
												</tr>
												<tr>
													<td>{local.TF_Readings_Above}</td>
													{ids?.map((id) => <td key={`readingsabove_${id}`}>{getReadingsAboveValueM(timeKey, data, id, ct.value, ct.axis)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>)}
												</tr>
												<tr>
													<td>{local.TF_Percent_Readings_Above}</td>
													{ids?.map((id) => <td key={`percentreadingsabove_${id}`}>{percent(getReadingsAboveValueM(timeKey, data, id, ct.value, ct.axis), getValidDataM(timeKey, data, id))}</td>)}
												</tr>
												<tr>
													<td>{local.TF_Time_Above}</td>
													{ids?.map((id) => <td key={`timeabove_${id}`}>{getTimesValueM(timeKey, data, id, ct.value, ct.axis)?.aboveTimeString}</td>)}
												</tr>
											</Fragment>
										))}
								</tbody>
							</table>
							<UncontrolledDropdown size="sm" direction="up" className="allow-overflow float-right mt-1">
								<DropdownToggle caret color="info" transform="shrink-3" className={buttonDefaultClasses}>
									<FontAwesomeIcon icon="share-alt" />
									<span className="d-none d-md-inline-block ml-2">{local.TS_Options}</span>
								</DropdownToggle>
								<DropdownMenu className="menu-border-blue dropdown-menu">
									<DropdownItem onClick={exportTable}>
										<FontAwesomeIcon icon="download" /> {local.TS_Download}
									</DropdownItem>
								</DropdownMenu>
							</UncontrolledDropdown>
						</div>
					</TabPanel>
					<TabPanel>
						{raw?.loading ? (
							<Loader />
						) : (
							<div className="mx-n3 my-n2">
								<MaterialTable columns={raw.columns} data={raw.rows} title={raw.title} icons={tableIcons} options={{ sorting: true, exportButton: true, exportAllData: true, exportFileName: raw.exportTitle, paging: true, pageSize: 50, pageSizeOptions: [50], headerStyle: { backgroundColor: "#edf2f9", color: "#01579b" } }} />
							</div>
						)}
					</TabPanel>
				</Tabs>
			</ModalBody>
		</Modal>
	);
};

export default ChartSummary;

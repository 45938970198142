import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "reactstrap";
import { apiDefaultParametersList, apiParameterAddDefaults, apiTransmitterTypesList } from "../../../api/parameter";
import withRedirect from "../../../hoc/withRedirect";
import local from "../../../localization/strings";
import IntToHex from "../../common/IntToHex";
import LightenDarkenColor from "../../common/LightenDarkenColour";
import LightOrDark from "../../common/LightOrDark";
import Loader from "../../common/Loader";
import PageTitle from "../../common/PageTitle";
import ParameterButton from "../../parameter-setup/ParameterButton";

const ParameterWizard = ({ setRedirect, setRedirectUrl }) => {
	const [loading, setLoading] = useState(true);
	const [transmitterTypes, setTransmitterTypes] = useState([]);
	const [selectedTransmitterTypes, setSelectedTransmitterTypes] = useState([]);
	const [defaultParameters, setDefaultParameters] = useState([]);
	const [selectedDefaultParameters, setSelectedDefaultParameters] = useState([]);

	useEffect(() => {
		const LoadData = async () => {
			const transmitterTypesResult = await apiTransmitterTypesList();
			setTransmitterTypes(transmitterTypesResult);

			const defaultParametersResult = await apiDefaultParametersList();
			setDefaultParameters(defaultParametersResult);

			setLoading(false);
		};

		LoadData();
	}, []);

	const handleSelectTransmitterType = (name) => {
		if (selectedTransmitterTypes.includes(name)) {
			setSelectedTransmitterTypes([...selectedTransmitterTypes.filter((x) => x !== name)]);
		} else {
			setSelectedTransmitterTypes([...selectedTransmitterTypes, name]);
		}
	};

	useEffect(() => {
		if (selectedTransmitterTypes.length > 0) {
			const list = [];

			selectedTransmitterTypes.forEach((s) => {
				const found = transmitterTypes.find((x) => x.name === s);

				if (found) {
					found.defaultParameters.split(",").forEach((dp) => {
						if (!list.includes(dp)) {
							list.push(dp);
						}
					});
				}
			});

			setSelectedDefaultParameters(list);
		} else {
			setSelectedDefaultParameters([]);
		}
	}, [selectedTransmitterTypes, transmitterTypes]);

	const handleSelectDefaultParameter = (name) => {
		if (selectedTransmitterTypes.length > 0) {
			return;
		}

		if (selectedDefaultParameters.includes(name)) {
			setSelectedDefaultParameters([...selectedDefaultParameters.filter((x) => x !== name)]);
		} else {
			setSelectedDefaultParameters([...selectedDefaultParameters, name]);
		}
	};

	const handleSave = async () => {
		await apiParameterAddDefaults(selectedDefaultParameters);

		setRedirectUrl("/site_settings/parameter_setup");
		setRedirect(true);
	};

	return (
		<>
			{loading ? (
				<Loader />
			) : (
				<>
					<PageTitle title={`${local.TS_Add} ${local.TS_Parameters}`} />
					<Card className="p-4">
						<h5>{local.TF_Transmitter_Types}</h5>

						<Row>
							{transmitterTypes.map((tt, i) => (
								<Col xs="3" key={i} className={`p-3 ${selectedTransmitterTypes.includes(tt.name) && "bg-primary"}`}>
									<Button color="secondary" onClick={() => handleSelectTransmitterType(tt.name)} className="w-100 p-4 m-1 border border-white">
										{tt.name}
									</Button>
								</Col>
							))}
						</Row>

						<h5 className="mt-3">{local.TS_Parameters}</h5>

						<Row>
							{defaultParameters.map((dp, i) => (
								<Col xs="3" key={i} className={`p-3 ${selectedDefaultParameters.includes(dp.name) && "bg-primary"}`}>
									<ParameterButton buttonClick={() => handleSelectDefaultParameter(dp.name)} color="primary" buttonClasses="w-100 p-4 m-1 border border-white parameter" name={dp.name} colorOne={"#" + IntToHex(dp.colour)} colorTwo={"#" + LightenDarkenColor(IntToHex(dp.colour), 60)} boxColour={LightOrDark(IntToHex(dp.colour))} />
								</Col>
							))}
						</Row>

						<Button className="mt-2" color="primary" onClick={handleSave}>
							{local.TS_Save}
						</Button>
					</Card>
				</>
			)}
		</>
	);
};

export default withRedirect(ParameterWizard);

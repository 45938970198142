import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Alert, Button, Card, CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";

import { apiClearAlarm, apiUnclearAlarm } from "../../api/alarms";
import { CurrentUserHasRole } from "../../api/auth";
import { locationChartData } from "../../api/chart";
import { roles, features, isFeatureEnabled } from "../../config";
import { UserPreferencesContext } from "../../context/userPreferences";
import { logEvent } from "../../helpers/ga";
import CanvasJSReact from "../../lib/canvasjs.react";
import local from "../../localization/strings";
import Loader from "../common/Loader";
import Pin from "../common/Pin";
import { WidgetTitleLevel1, WidgetTitleLevel2 } from "../common/WidgetTitles";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const AlarmCurrentItem = ({ item, reload }) => {
	const [chartOptions, setChartOptions] = useState({});
	const [showChart, setShowChart] = useState(false);
	const [loadingChart, setLoadingChart] = useState(true);
	const { getZoneForGroupId } = useContext(UserPreferencesContext);
	const [alarmAction, setAlarmAction] = useState(null);

	useEffect(() => {
		var id = window.location.hash.substr(1);
		id = parseInt(id);
		if (id === item.locationId) {
			var ele = document.getElementById(id);
			window.scrollTo(ele.offsetLeft, ele.offsetTop);
		}
	});

	const loadChart = async () => {
		if (showChart === true) {
			logEvent("Current Alarms", "Hide Chart", `${item.locationId}`);
			setShowChart(false);
		} else {
			logEvent("Current Alarms", "View Chart", `${item.locationId}`);

			var textColour = "#46505e";
			var result = await locationChartData(item.locationId, "Day", 0);

			if (result.success) {
				result.data.data.forEach((element) => {
					element.dataPoints.forEach((point) => {
						point.x = new Date(point.x);
					});
				});
				if (result.data.axisX) {
					result.data.axisX.crosshair.snapToDataPoint = true;
				}
				if (result.data.axisX2) {
					result.data.axisX2.crosshair.snapToDataPoint = true;
				}
				if (result.data.axisY) {
					result.data.axisY.crosshair.snapToDataPoint = true;
				}
				if (result.data.axisY2) {
					result.data.axisY2.crosshair.snapToDataPoint = true;
				}
				result.data.backgroundColor = "";
				result.data.title.fontColor = textColour;
				result.data.subtitles[0].fontColor = textColour;
				if (result.data.axisX) {
					result.data.axisX.labelFontColor = textColour;
					result.data.axisX.labelFontSize = "12";
					result.data.axisX.gridThickness = 0.4;
					result.data.axisX.crosshair.snapToDataPoint = true;

					if (result.data.axisX.minimum) {
						result.data.axisX.minimum = new Date(result.data.axisX.minimum);
					}
					if (result.data.axisX.maximum) {
						result.data.axisX.maximum = new Date(result.data.axisX.maximum);
					}
				} else {
					result.data.axisX2.labelFontColor = textColour;
					result.data.axisX2.labelFontSize = "12";
					result.data.axisX2.gridThickness = 0.4;
					result.data.axisX2.crosshair.snapToDataPoint = true;

					if (result.data.axisX2.minimum) {
						result.data.axisX2.minimum = new Date(result.data.axisX2.minimum);
					}
					if (result.data.axisX2.maximum) {
						result.data.axisX2.maximum = new Date(result.data.axisX2.maximum);
					}
				}
				if (result.data.axisY2) {
					result.data.axisY2.labelFontColor = textColour;
					result.data.axisY2.labelFontSize = "12";
					result.data.axisY2.gridThickness = 0.4;
					result.data.axisY2.crosshair.snapToDataPoint = true;
				} else {
					result.data.axisY.labelFontColor = textColour;
					result.data.axisY.labelFontSize = "12";
					result.data.axisY.gridThickness = 0.4;
					result.data.axisY.crosshair.snapToDataPoint = true;
				}

				result.data.legend.fontColor = textColour;
				result.data.height = 250;

				setChartOptions(result.data);
				setLoadingChart(false);
			}

			setShowChart(true);
		}
	};

	const performAction = useCallback(
		async (action, serialNumber, channelNumber) => {
			if (action === "clear") {
				await apiClearAlarm(item.locationId, serialNumber, channelNumber);
			}
			if (action === "unclear") {
				await apiUnclearAlarm(item.locationId, serialNumber, channelNumber);
			}
			setAlarmAction(null);

			if (reload) {
				reload();
			}
		},
		[reload, item.locationId],
	);

	return (
		<Fragment>
			{isFeatureEnabled(features.SMSCloudRelayMuting) && alarmAction && (
				<Modal isOpen={alarmAction !== null} toggle={() => setAlarmAction(null)} centered backdrop="static">
					<ModalHeader>
						{alarmAction === "clear" && <span className="float-left">{local.TF_MuteChannelOnLogger.replace("|X|", item.squirrelChannelIdentity || item.SquirrelChannelNumberDisplay).replace("|Y|", item.squirrelName || item.squirrelSerialNumber)}</span>}
						{alarmAction === "unclear" && <span className="float-left">{local.TF_UnmuteChannelOnLogger.replace("|X|", item.squirrelChannelIdentity || item.SquirrelChannelNumberDisplay).replace("|Y|", item.squirrelName || item.squirrelSerialNumber)}</span>}
						<span className="float-right close-modal" style={{ position: "absolute", top: 15, right: 20 }} onClick={() => setAlarmAction(null)}>
							X
						</span>
					</ModalHeader>
					<ModalBody>
						<Button color="primary" className="mt-2 mr-2 float-left" type="button" onClick={() => performAction(alarmAction, item.squirrelSerialNumber, item.squirrelChannelNumber)}>
							Yes
						</Button>
						<Button color="secondary" className="mt-2 float-left" type="button" onClick={() => performAction(alarmAction, null, null)}>
							No
						</Button>
					</ModalBody>
				</Modal>
			)}
			<Alert color={item.clearedBy ? "cleared" : "danger"} className="d-flex flex-row mb-1 overflow-auto card" id={item.locationId}>
				<div className="notification-avatar current-alarm-bell mr-2">
					<FontAwesomeIcon icon="bell" fixedWidth size="2x" className="mr-2" />
				</div>
				<div className="notification-body w-100">
					<p className="mb-0 font-weight-bold">
						<WidgetTitleLevel1 reading={item} />
					</p>
					<p className="mb-0">
						<WidgetTitleLevel2 reading={item} />
					</p>
					<p className="mb-0">
						{item.highAlarm ? local.TF_NotificationsHighAlarm : local.TF_NotificationsLowAlarm}
						{" (" + item.parameterName + ")"}:
						<span className="font-weight-bold ml-1">
							{item.realValue ? item.realValue.toFixed(item.decimalPlaces) : item.realValue}
							{item.units}
						</span>
					</p>
				</div>
				<div className="notification-avatar ml-auto text-right">
					<Pin color="secondary" size="sm" className="menu-pin" menuClass="menu-border-red" tag="div" types={[{ name: `${local.TF_Pin_Chart}`, type: "DashChart", data: item.locationId }, { name: `${local.TF_Pin_Chart_Blue}`, type: "DashChartBlue", data: item.locationId }]} />
					<div className="d-flex">
						<Button color="light" className={showChart ? "p-2 alarm-chart-selected" : "p-2 current-alarm-chart"} onClick={() => loadChart()} title={local.TS_Show_Chart}>
							<FontAwesomeIcon icon="chart-area" fixedWidth size="lg" />
						</Button>
						{isFeatureEnabled(features.HasStorage) && (
							<Link to={`/zone_graphics/${getZoneForGroupId(item.groupId)}/${item.groupId}`} className="p-2 current-alarm-chart" title={local.TS_Show_Location_On_Zone_Graphic}>
								<FontAwesomeIcon icon="map-marked-alt" fixedWidth size="lg" />
							</Link>
						)}
						{CurrentUserHasRole(roles.SiteAdmin) && (
							<>
								{item.clearedBy ? (
									<Button color="light" className="p-2 current-alarm-chart" onClick={() => (isFeatureEnabled(features.SMSCloudRelayMuting) && item.squirrelSerialNumber ? setAlarmAction("unclear") : performAction("unclear", null, null))} title={local.TS_Unclear_Alarm}>
										<FontAwesomeIcon icon="play-circle" fixedWidth size="lg" />
									</Button>
								) : (
									<Button color="light" className="p-2 current-alarm-chart" onClick={() => (isFeatureEnabled(features.SMSCloudRelayMuting) && item.squirrelSerialNumber ? setAlarmAction("clear") : performAction("clear", null, null))} title={local.TS_Clear_Alarm}>
										<FontAwesomeIcon icon="stop-circle" fixedWidth size="lg" />
									</Button>
								)}
							</>
						)}
					</div>
				</div>
			</Alert>
			{showChart && (
				<Card className="mb-1">
					<CardBody>{loadingChart ? <Loader /> : <CanvasJSChart options={chartOptions} />}</CardBody>
				</Card>
			)}
		</Fragment>
	);
};

export default AlarmCurrentItem;

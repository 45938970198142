import React, { useState, useEffect } from "react";
import defaultImage from "../../assets/img/defaults/default-user-banner.jpg";
import { Card, Col, Button, Row, CardHeader, CardBody, CustomInput } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import local from "../../localization/strings";
import { defaultDistributorDetails } from "../../config";
import { siteGetForSite, siteGraphicForSite, siteGraphicUploadForSite, siteDistributorDetailsSet } from "../../api/site";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { toast } from "react-toastify";

const SiteSettingsForm = ({ id, cancelSiteSettings, reload }) => {
	const [siteData, setSiteData] = useState(null);
	const [siteImageOpen, setSiteImageOpen] = useState(false);
	const [image, setImage] = useState([""]);
	const [siteImage, setSiteImage] = useState(null);
	const [imageBrowsed, setImageBrowsed] = useState(false);

	useEffect(() => {
		const LoadData = async () => {
			setSiteData(await siteGetForSite(id));

			const base64 = await siteGraphicForSite(id);
			if (base64) {
				setSiteImage(`data:image/png;base64,${base64}`);
			} else {
				setSiteImage(defaultImage);
			}
		};

		setSiteData(undefined);
		setImage(undefined);
		setSiteImage(undefined);
		LoadData();
	}, [id]);

	const handleNewImage = (e) => {
		setImage(e.target.files[0]);
		setSiteImage(URL.createObjectURL(e.target.files[0]));
		setImageBrowsed(true);
	};

	const distributorName = () => (siteData ? siteData.distributorName || defaultDistributorDetails.name : "");
	const distributorPhone = () => (siteData ? siteData.distributorPhone || defaultDistributorDetails.tel : "");
	const distributorEmail = () => (siteData ? siteData.distributorEmail || defaultDistributorDetails.email : "");

	const toBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	async function saveSiteImage(_e, _values) {
		const base64WithHeader = await toBase64(image);
		const base64Loc = base64WithHeader.indexOf("base64,");

		await siteGraphicUploadForSite(id, base64WithHeader.substring(base64Loc + 7));

		setImageBrowsed(false);

		await reload();

		toast.success(local.TS_Update_Successful);
	}

	async function saveDistributorDetails(_e, values) {
		await siteDistributorDetailsSet(id, values.distributorName, values.distributorPhone, values.distributorEmail);

		toast.success(local.TS_Update_Successful);
	}

	return (
		<>
			<span className="float-right pt-2 mt-n5">
				<Button onClick={() => cancelSiteSettings()} className="mr-2">
					{local.TS_Close}
				</Button>
			</span>
			<Card className="p-3">
				<Row>
					<Col className="col-12 col-lg-6">
						<Card>
							<CardHeader>
								<h5>{local.TS_Distributor_details}</h5>
							</CardHeader>
							<CardBody className="bg-light border-top p-3">
								<small>{local.TF_Contact_details_for_customer}</small>
								<AvForm onValidSubmit={async (e, values) => await saveDistributorDetails(e, values)}>
									<AvField name="distributorName" label={local.TS_Name} value={distributorName()} />
									<AvField name="distributorPhone" label={local.TS_Phone_Number} value={distributorPhone()} />
									<AvField name="distributorEmail" label={local.TS_Email} value={distributorEmail()} />
									<Button color="primary">{local.TS_Save_Changes}</Button>
								</AvForm>
							</CardBody>
						</Card>
					</Col>
					<Col className="col-12 col-lg-6 mt-3 mt-lg-0">
						<Card>
							<CardHeader>
								<Row>
									<Col>
										<h5>{local.TF_Site_Details}</h5>
									</Col>
								</Row>
							</CardHeader>
							<CardBody className="bg-light border-top p-3">
								<AvForm onValidSubmit={async (e, values) => await saveSiteImage(e, values)}>
									<Row>
										<Col>
											<label>{local.TF_Site_Image}</label>
										</Col>
									</Row>

									{siteImage && (
										<>
											<Row>
												<Col>
													<Button onClick={() => setSiteImageOpen(true)} className="site-image-link">
														<img src={siteImage} className="rounded-soft" alt="Site" />
													</Button>
													{siteImageOpen && <Lightbox mainSrc={siteImage} onCloseRequest={() => setSiteImageOpen(false)} />}
												</Col>
											</Row>
											<Row>
												<Col>
													<small className="ml-3">{local.TF_Click_image_to_change}</small>
												</Col>
											</Row>
										</>
									)}

									<div className="site-image-link">
										<CustomInput type="file" id="newImage" name="newImage" label={local.TF_Change_Image} onChange={(e) => handleNewImage(e)} />
									</div>
									<Row>
										<Col>
											<Button color="primary" className="mt-3" disabled={!imageBrowsed}>
												{local.TS_Save_Changes}
											</Button>
										</Col>
									</Row>
								</AvForm>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Card>
		</>
	);
};

export default SiteSettingsForm;

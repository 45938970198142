import React, { Fragment } from 'react';
import ContentWithAsideLayout from '../../layouts/ContentWithAsideLayout';
import ProfileBanner from './ProfileBanner';
import ProfileDetails from './ProfileDetails';

const Profile = () => {
  return (
    <Fragment>
      <ContentWithAsideLayout banner={<ProfileBanner />} >
      </ContentWithAsideLayout >

      <ProfileDetails />
    </Fragment>
  );
};

export default Profile;

import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { fluctuation } from "../../../api/reports";
import Loader from "../../common/Loader";

const FluctuationReportDisplay = ({ data, reportComplete }) => {
	const [loading, setLoading] = useState(true);
	const [report, setReport] = useState({});

	useEffect(() => {
		const LoadData = async () => {
			const result = await fluctuation(data.locationId, data.startTime, data.endTime, data.period, data.noOfPeriod, data.bands, data.timePeriod);

			setReport(result);
			setLoading(false);
			reportComplete(result);
		};

		LoadData();
	}, [data, reportComplete]);

	return (
		<>
			{loading ? (
				<Loader />
			) : (
				<div className="rdg-no-gutters">
					{report &&
						Object.keys(report).map((key) => (
							<Row key={key}>
								<Col className="font-weight-bold text-right">{key}</Col>
								<Col>{report[key]}</Col>
							</Row>
						))}
				</div>
			)}
		</>
	);
};

export default FluctuationReportDisplay;

import React, { useState, useEffect } from "react";
import defaultImage from "../../assets/img/defaults/default-user-banner.jpg";
import { siteGraphicForSite } from "../../api/site";

const SiteThumbnail = ({ id, displayName }) => {
	const [siteImage, setSiteImage] = useState(defaultImage);

	useEffect(() => {
		async function loadData() {
			const base64 = await siteGraphicForSite(id);
			if (base64) {
				setSiteImage(`data:image/png;base64,${base64}`);
			} else {
				setSiteImage(defaultImage);
			}
		}

		loadData();
	}, [id, displayName]);

	return <>{siteImage && <img src={siteImage} className="mx-auto rounded-soft rounded-bottom-0 bg-dark user-settings-banner-shadow site-image-thumb" alt={displayName} />}</>;
};

export default SiteThumbnail;

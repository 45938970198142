import React, { useState } from "react";
import AssignDistributorList from "./AssignDistributorList";
import SiteList from "./SiteList";
import SiteAdd from "./SiteAdd";
import DemoAdd from "./DemoAdd";

const SiteTab = () => {
	const [assignDistributor, setAssignDistributor] = useState(false);
	const [adding, setAdding] = useState(false);
	const [addingDemo, setAddingDemo] = useState(false);

	const toggleAssignDistributor = () => {
		//setAssignDistributor(!assignDistributor);
		setAssignDistributor(false);
	};

	const addDemo = () => {
		setAddingDemo(true);
	};

	return (
		<>
			{adding ? <SiteAdd cancelAdd={() => setAdding(false)} /> : <>{assignDistributor ? <AssignDistributorList toggleAssignDistributor={toggleAssignDistributor} /> : !addingDemo && <SiteList toggleAssignDistributor={toggleAssignDistributor} add={() => setAdding(true)} addDemo={addDemo} />}</>}
			{addingDemo && <DemoAdd cancelAdd={() => setAddingDemo(false)} />}
		</>
	);
};

export default SiteTab;

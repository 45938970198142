import React from "react";

const RouterNoSignalIcon = () => {
	return (
		<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="router" className="svg-inline--fa fa-w-18 fa-null fa-rotate-null fa-pull-null fs-4 text-danger" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" style={{ transformOrigin: "0.5625em 0.5em" }}>
			<g transform="translate(100 100)">
				<g transform="translate(0, 0)  scale(0.625, 0.625)  rotate(0 0 0)">
					<path className="fa-primary" d="M376 248c0-13.3-10.7-24-24-24s-24 10.7-24 24v72H64c-35.3 0-64 28.7-64 64v64c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V384c0-35.3-28.7-64-64-64H376V248zM96 384a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm64 32a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" />
				</g>
			</g>
		</svg>
	);
};

export default RouterNoSignalIcon;

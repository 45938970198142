import React, { useState, useEffect } from "react";
import { Button, Input } from "reactstrap";
import MaterialTable from "material-table";
import { tableIcons } from "../../helpers/tableIcons";
import { siteStats } from "../../api/site";
import local from "../../localization/strings";
import "moment/min/locales";
import moment from "moment";
import { userAddToSite, userRemoveFromSite } from "../../api/users";

const SiteList = ({ cancelAssignSites, distributor }) => {
	const [data, setData] = useState(undefined);
	const [selected, setSelected] = useState([]);

	useEffect(() => {
		const LoadData = async () => {
			setSelected(distributor.siteList);

			setData(await siteStats());
		};

		LoadData();
	}, [distributor]);

	const checkedChanged = (siteId) => {
		if (selected.includes(siteId)) {
			setSelected(selected.filter((x) => x !== siteId));
		} else {
			setSelected([...selected, siteId]);
		}
	};

	const saveSitesAssign = () => {
		selected
			.filter((x) => !distributor.siteList.includes(x))
			.forEach(async (siteId) => {
				await userAddToSite(distributor.email, siteId);
			});

		distributor.siteList
			.filter((x) => !selected.includes(x))
			.forEach(async (siteId) => {
				await userRemoveFromSite(distributor.email, siteId);
			});

		cancelAssignSites();
	};

	const columns = [
		{
			title: local.TF_Assigned,
			render: (rowData) => {
				return <Input type="checkbox" className="pin-button" checked={selected.includes(rowData.id.toUpperCase())} onChange={() => checkedChanged(rowData.id.toUpperCase())} />;
			},
		},
		{ title: local.TF_Customer, field: "displayName", defaultSort: "asc" },
		{ title: local.TF_Current_Distributor, field: "distributorName" },
		{
			title: local.TF_Created,
			field: "dateCreated",
			render: (rowData) => <div>{moment(rowData.dateCreated).format("DD MMM YYYY")}</div>,
		},
	];

	return (
		<>
			<span className="float-right pt-2 mt-n5">
				<Button onClick={() => cancelAssignSites()} className="mr-2">
					{local.TS_Cancel}
				</Button>
				<Button onClick={() => saveSitesAssign()} color="primary">
					{local.TS_Save_Changes}
				</Button>
			</span>

			{data && (
				<div className="MuiTable text-center">
					<MaterialTable
						columns={columns}
						data={data}
						title=""
						icons={tableIcons}
						options={{
							padding: "dense",
							sorting: true,
							exportButton: false,
							paging: true,
							pageSize: 20,
							pageSizeOptions: [20, 50, 100],
							emptyRowsWhenPaging: false,
							headerStyle: {
								backgroundColor: "#fff",
								color: "#000",
							},
							rowStyle: {
								textAlign: "center",
							},
						}}
					/>
				</div>
			)}
		</>
	);
};

export default SiteList;

import React, { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Button, Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";
import local from "../../../localization/strings";

const ChartReportHeaderFooter = ({ show, filterData, close, update }) => {
	const [open, setOpen] = useState(false);
	const [model, setModel] = useState({});

	useEffect(() => {
		let headerOptions = {};
		if (filterData.header) {
			headerOptions.headerText = filterData.header.text || "";
			headerOptions.headerAlign = filterData.header.align || "center";
			headerOptions.headerFont = filterData.header.font || "helvetica";
			headerOptions.headerFontSize = filterData.header.fontSize || 16;
			headerOptions.headerFontStyle = filterData.header.fontStyle || "bold";
		} else {
			headerOptions.headerText = "";
			headerOptions.headerAlign = "center";
			headerOptions.headerFont = "helvetica";
			headerOptions.headerFontSize = 16;
			headerOptions.headerFontStyle = "bold";
		}

		let footerOptions = {};
		if (filterData.footer) {
			footerOptions.footerText = filterData.footer.text || "";
			footerOptions.footerAlign = filterData.footer.align || "center";
			footerOptions.footerFont = filterData.footer.font || "helvetica";
			footerOptions.footerFontSize = filterData.footer.fontSize || 16;
			footerOptions.footerFontStyle = filterData.footer.fontStyle || "bold";
		} else {
			footerOptions.footerText = "";
			footerOptions.footerAlign = "center";
			footerOptions.footerFont = "helvetica";
			footerOptions.footerFontSize = 16;
			footerOptions.footerFontStyle = "normal";
		}

		setModel({ ...headerOptions, ...footerOptions });

		setOpen(show);
	}, [show, filterData]);

	async function save(_e, values) {
		const data = {
			header: {
				text: values.headerText,
				align: values.headerAlign,
				font: values.headerFont,
				fontSize: values.headerFontSize,
				fontStyle: values.headerFontStyle
			},
			footer: {
				text: values.footerText,
				align: values.footerAlign,
				font: values.footerFont,
				fontSize: values.footerFontSize,
				fontStyle: values.footerFontStyle
			}
		}

		update(data);
	}

	const cancel = () => {
		setOpen(false);
		close();
	};

	return (
		<Modal isOpen={open} toggle={() => setOpen(false)} centered backdrop="static">
			<ModalHeader>
				<span className="float-left">{local.TF_Header_and_Footer}</span>
				<span className="float-right close-modal" style={{ position: "absolute", top: 15, right: 20 }} onClick={cancel}>X</span>
			</ModalHeader>
			<ModalBody>
				<AvForm model={model} onValidSubmit={async (e, values) => await save(e, values)}>
					<h2>{local.TF_Header}</h2>
					<AvField name="headerText" id="headerText" />
					<Row>
						<Col>
							<AvField type="select" name="headerFont">
								<option value="courier">{local.TS_Courier}</option>
								<option value="helvetica">{local.TS_Helvetica}</option>
								<option value="times">{local.TS_Times_New_Roman}</option>
							</AvField>
						</Col>
						<Col>
							<AvField type="select" name="headerFontSize">
								<option value="10">10</option>
								<option value="12">12</option>
								<option value="14">14</option>
								<option value="16">16</option>
								<option value="18">18</option>
							</AvField>
						</Col>
					</Row>
					<Row>
						<Col>
							<AvField type="select" name="headerFontStyle">
								<option value="normal">{local.TS_Normal}</option>
								<option value="bold">{local.TS_Bold}</option>
								<option value="italic">{local.TS_Italic}</option>
								<option value="bolditalic">{local.TS_Bold_And_Italic}</option>
							</AvField>
						</Col>
						<Col>
							<AvField type="select" name="headerAlign">
								<option value="left">{local.TS_Left}</option>
								<option value="center">{local.TS_Center}</option>
								<option value="right">{local.TS_Right}</option>
							</AvField>
						</Col>
					</Row>
					<h2>{local.TF_Footer}</h2>
					<AvField name="footerText" id="footerText" />
					<Row>
						<Col>
							<AvField type="select" name="footerFont">
								<option value="courier">{local.TS_Courier}</option>
								<option value="helvetica">{local.TS_Helvetica}</option>
								<option value="times">{local.TS_Times_New_Roman}</option>
							</AvField>
						</Col>
						<Col>
							<AvField type="select" name="footerFontSize">
								<option value="10">10</option>
								<option value="12">12</option>
								<option value="14">14</option>
								<option value="16">16</option>
								<option value="18">18</option>
							</AvField>
						</Col>
					</Row>
					<Row>
						<Col>
							<AvField type="select" name="footerFontStyle">
								<option value="normal">{local.TS_Normal}</option>
								<option value="bold">{local.TS_Bold}</option>
								<option value="italic">{local.TS_Italic}</option>
								<option value="bolditalic">{local.TS_Bold_And_Italic}</option>
							</AvField>
						</Col>
						<Col>
							<AvField type="select" name="footerAlign">
								<option value="left">{local.TS_Left}</option>
								<option value="center">{local.TS_Center}</option>
								<option value="right">{local.TS_Right}</option>
							</AvField>
						</Col>
					</Row>
					<hr className="mx-n3" />
					<Button color="primary">{local.TS_Save}</Button>
					<Button className="ml-2" onClick={cancel}>
						{local.TS_Cancel}
					</Button>
				</AvForm>
			</ModalBody>
		</Modal>
	);
};

export default ChartReportHeaderFooter;

import React from "react";
import { Card, CardBody } from "reactstrap";
import local from "../../localization/strings";

const Error500 = () => (
	<Card className="text-center">
		<CardBody className="p-5">
			<div className="display-1 text-200 fs-error">500</div>
			<p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">{local.TF_500_Whoops_something_wrong}</p>
			<hr />
			<p>
				{local.TF_500_Try_refreshing_page_or_do_again}
				<a href="mailto:no-reply@darcaconnect.com" className="ml-1">
					{local.TF_error_contact_us}
				</a>
				.
			</p>
		</CardBody>
	</Card>
);

export default Error500;

import React from 'react'
import { Link } from "react-router-dom";
import local from "../../../localization/strings";

const SearchResultZone = ({item, close}) => {
    return (
		<div className="p-2">
			<Link to={`/zone_graphics/${item.id}`} className="text-nowrap" onClick={close}>
				<b>{local.TF_Search_Zone}:</b> {item.entityName}
			</Link>
		</div>
	);
}

export default SearchResultZone;
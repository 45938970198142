import axios from "axios";
import { CurrentSiteIdGet } from "./userSettings";

export const userPreferencesGetList = async () => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "get",
			url: "v2/UserPreferences",
			params: {
				SiteId: siteId,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const userPreferenceSave = async (key, value) => {
	const siteId = CurrentSiteIdGet();

	try {
		await axios({
			method: "post",
			url: "v2/UserPreference",
			data: {
				SiteId: siteId,
				UserPreference: {
					PreferenceKey: key,
					PreferenceValue: value,
				},
			},
		});
	} catch (err) {
		console.error(err);
	}
};

import React, { useEffect, useState } from "react";
import ChartReportFilter from "../chart-report/ChartReportFilter";
import { zoneChartList } from "../../../api/chart";
import ChartReportToolbar from "../chart-report/ChartReportToolbar";

const ChartReport = ({ data, onChange, emailReports }) => {
	const [showZoneSelect, setShowZoneSelect] = useState(true);
	const [filterData, setFilterData] = useState({});
	const [zoneCharts, setZoneCharts] = useState([]);

	const LoadZoneCharts = async (zoneId) => {
		const result = await zoneChartList(zoneId.replace("Z:", ""));
		setZoneCharts(result);
		return result;
	};

	useEffect(() => {
		if (data.zoneId) {
			LoadZoneCharts(data.zoneId);
			setShowZoneSelect(false);
		} else {
			setShowZoneSelect(true);
		}

		setFilterData(data);
	}, [data]);

	const updatePreferences = async (prefs) => {
		const newData = { ...filterData, ...prefs };

		setFilterData(newData);

		if (onChange) {
			onChange(newData);
		}
	};

	const updateZoneId = async (zoneId) => {
		const charts = await LoadZoneCharts(zoneId);
		const list = charts.map((item) => {
			return `${item.groupId}:${item.graphIndex}`;
		});

		await updatePreferences({ zoneId, orderList: list, showList: list });

		setShowZoneSelect(false);
	};

	return <>{showZoneSelect ? <ChartReportFilter selected={updateZoneId} filterData={filterData} /> : <ChartReportToolbar filter={updatePreferences} filterData={filterData} showSelectZone={setShowZoneSelect} zoneCharts={zoneCharts} emailReports={emailReports} />}</>;
};

export default ChartReport;

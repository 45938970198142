import React from "react";
import { UncontrolledTooltip } from "reactstrap";

const Tooltip = ({ id, text, placement }) => {
	return (
		<div id="tooltip-place">
			<UncontrolledTooltip placement={placement} target={id} container="#tooltip-place">
				{text}
			</UncontrolledTooltip>
		</div>
	);
};

export default Tooltip;

import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import PageTitle from "../../common/PageTitle";
import local from "../../../localization/strings";
import AddButton from "../../common/AddButton";
import DisplayReportList from "./DisplayReportList";
import EditForm from "./EditForm";
import { logEvent } from "../../../helpers/ga";

const EmailReports = () => {
	const [data, setData] = useState(null);
	const [inEditMode, setInEditMode] = useState(false);

	const addReport = () => {
		logEvent('Email Reports', 'New')
    setData(null);
    setInEditMode(true);
	};

	const triggerBack = () => {
		logEvent('Email Reports', 'Back')
    setData(null);
    setInEditMode(false);
  };
  

	const triggerModify = (data) => {
		logEvent('Email Reports', 'Modify', `${data.id}`)
	  setData(data);
    setInEditMode(true);
	};

	//need function to check if any scheduled email reports exist and set state accordingly

	return (
		<>
			<PageTitle title={local.TS_EmailReports} />

			{inEditMode ? (
				<EditForm data={data} onBack={triggerBack} />
			) : (
				<>
					<Row className="mb-2">
						<Col className="float-right text-right">
							<AddButton clickFunction={addReport} string={local.TF_Schedule_New_Report} />
						</Col>
					</Row>
					<DisplayReportList triggerModify={triggerModify} />
				</>
			)}
		</>
	);
};

export default EmailReports;

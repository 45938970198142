import React from "react";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CancelButton = ({clickFunction, buttonClasses, string, block = false, margin = 0, icon}) => {
	const extraClasses = block ? 'd-block' : ''
	const buttonIcon = icon || "angle-left";

	return (
		<Button onClick={clickFunction} color="secondary" className={`m-${margin} ${buttonClasses} ${extraClasses}`}>
			<FontAwesomeIcon icon={buttonIcon} /> {string}
		</Button>
	);
};

export default CancelButton;

import React, { useEffect, useState, Fragment } from "react";
import { Button, Table } from "reactstrap";
import local from "../../localization/strings";
import { zonesGetList } from "../../api/zone";
import Loader from "../common/Loader";

const SiteLayoutZones = ({ selected, buildingId, setCurrentZone, version }) => {
	const [zones, setZones] = useState([]);
	const [showLoading, setShowLoading] = useState(true);
    const [currentVersion, setCurrentVersion] = useState(0);
    const [currentBuildingId, setCurrentBuildingId] = useState(0);

	useEffect(() => {
		async function loadZones(buildingId) {
			var data = await zonesGetList(buildingId);

			setZones(data);

			setShowLoading(false);
		}

		if (currentVersion !== version || buildingId !== currentBuildingId) {
			loadZones(buildingId);
            setCurrentVersion(version);
            setCurrentBuildingId(buildingId);
		}
	}, [buildingId, currentBuildingId, version, currentVersion]);

	const zonesMap = zones.map((zone, i) => (
		<Button
			key={i}
			color="primary"
			id={zone.id}
			onClick={() => 
				setCurrentZone(zone.id)}
			className={zone.id === selected ? "button-is-selected col mb-3" : "col mb-3 zone-button"}
		>
			{zone.name}
		</Button>
	));

	return (
		<Fragment>
			<Table className="text-center mb-1">
				<thead className="thead-light">
					<tr>
						<th>{local.TS_Zones}</th>
					</tr>
				</thead>
			</Table>
			{showLoading ? (
				<div className="ml-2">
					<Loader />
				</div>
			) : (
				zonesMap
			)}
		</Fragment>
	);
};

export default SiteLayoutZones;

import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { Button, Col, Label, Row } from "reactstrap";
import { CurrentUserHasRole } from "../../api/auth";
import { deleteDepartment, saveDepartment } from "../../api/department";
import { roles } from "../../config";
import local from "../../localization/strings";
import SiteTreeMultiSelect from "../common/SiteTreeMultiSelect";

const Department = ({ depts, selected, done }) => {
	const [groupIds, setGroupsIds] = useState("");
	const [data, setData] = useState(undefined);
	const [newGroupIds, setNewGroupsIds] = useState([]);

	useEffect(() => {
		const found = depts.find((x) => x.id === selected);

		if (found) {
			const foundGroupIds = (found.groupIds || "").split(",").map((item) => `G:${item}`);
			setData(found);
			setGroupsIds(foundGroupIds.join(","));
			setNewGroupsIds(foundGroupIds);
		} else {
			setData({ id: -1, index: 999, name: "" });
			setGroupsIds("");
			setNewGroupsIds([]);
		}
		return () => {
			setGroupsIds("");
			setData(undefined);
		};
	}, [depts, selected]);

	const save = async (_e, values) => {
		await saveDepartment(selected, data.index, values.name, newGroupIds.map((x) => x.replace("G:", "")));

		done();
	};

	const deleteItem = async () => {
		await deleteDepartment(selected);

		done();
	};

	return (
		<Col className="col-12 col-md-8 border-top border-md-0 mt-3 mt-md-0">
			{data && (
				<AvForm model={data} key={data.id} onValidSubmit={async (e, values) => await save(e, values)}>
					<Row>
						<Col xs="auto" className="pt-1">
							<Label>{local.TS_Name}:</Label>
						</Col>
						<Col>
							<AvField name="name" validate={{ required: { value: true, errorMessage: local.TS_Required } }} />
						</Col>
					</Row>
					<h4 className="mt-3 mt-md-0">{local.TS_Groups}</h4>
					<div style={{ height: 600, overflowY: "auto", overflowX: "hidden" }}>
						<SiteTreeMultiSelect level={3} expandToLevel={3} initialChecked={groupIds} inline onCheckedChanged={setNewGroupsIds} />
					</div>
					<Row>
						<Col>
							<Button color="primary" className="mt-2">
								{local.TS_Save_Changes}
							</Button>
						</Col>
						{CurrentUserHasRole(roles.SiteAdmin) && selected !== -1 && (
							<Col>
								<Button color="danger" size="sm" className="mt-3 float-right" onClick={deleteItem} disabled={data.userCount > 0}>
									{local.TS_Delete}
								</Button>
							</Col>
						)}
					</Row>
				</AvForm>
			)}
		</Col>
	);
};

export default Department;

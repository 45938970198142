import local from "../../localization/strings";
import { memoize, values } from "lodash";
import moment from "moment";
import "moment/min/locales";

export const getDataValueI = (timeKey, data, locationId, field) => {
	try {
		if (data?.columns) {
			const location = data.columns.find((x) => x.field === locationId);
			if (location) {
				return location[field];
			}
		}
	} catch {}
	return null;
};
export const getDataValueM = memoize(getDataValueI, (...args) => values(args).join("_"));

export const getSiteMapValueI = (timeKey, siteMap, id, field) => {
	try {
		const location = siteMap.find((x) => x.locationId === parseInt(id.substr(1)));
		if (location) {
			return location[field];
		}
	} catch {}
	return null;
};
export const getSiteMapValueM = memoize(getSiteMapValueI, (...args) => values(args).join("_"));

export const getStartOfDataI = (timeKey, data, locationId) => {
	try {
		if (data?.rows) {
			if (data.rows.length > 0) {
				return moment(data.rows[0].DateTime).format("DD MMM YYYY HH:mm");
			}
		}
	} catch {}
	return null;
};
export const getStartOfDataM = memoize(getStartOfDataI, (...args) => values(args).join("_"));

export const getEndOfDataI = (timeKey, data, locationId) => {
	try {
		if (data?.rows) {
			if (data.rows.length > 0) {
				return moment(data.rows[data.rows.length - 1].DateTime).format("DD MMM YYYY HH:mm");
			}
		}
	} catch {}
	return null;
};
export const getEndOfDataM = memoize(getEndOfDataI, (...args) => values(args).join("_"));

function formatDuration(duration) {
	let parts = [];

	// return nothing when the duration is falsy or not correctly parsed (P0D)
	if (!duration || duration.toISOString() === "P0D") return "00:00:00";

	if (duration.years() >= 1) {
		const years = Math.floor(duration.years());
		parts.push(years + "y");
	}

	if (duration.months() >= 1) {
		const months = Math.floor(duration.months());
		parts.push(months + "m");
	}

	if (duration.days() >= 1) {
		const days = Math.floor(duration.days());
		parts.push(days + "d");
	}

	const hours = Math.floor(duration.hours());
	const minutes = Math.floor(duration.minutes());
	const seconds = Math.floor(duration.seconds());
	parts.push(`${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`);

	return parts.join(" ");
}

export const getLoHiI = (timeKey, data, preferences, mainAlarm, locationId) => {
	try {
		const foundPref = preferences.find((x) => x.preferenceKey === `GroupChart_AlarmOverride_${locationId}`)?.preferenceValue || undefined;
		if (foundPref) {
			const prefsObj = JSON.parse(foundPref);
			if (mainAlarm) {
				return { lo: prefsObj?.mainAlarmLo, hi: prefsObj?.mainAlarmHi };
			}
			return { lo: prefsObj?.warnAlarmLo, hi: prefsObj?.warnAlarmHi };
		}

		const col = data?.columns.find((x) => x.field === locationId);

		if (mainAlarm) {
			return { lo: col?.mainAlarmLo, hi: col?.mainAlarmHi };
		}
		return { lo: col?.warnAlarmLo, hi: col?.warnAlarmHi };
	} catch {}
};
export const getLoHiM = memoize(getLoHiI, (...args) => values(args).join("_"));

export const getTimesI = (timeKey, data, preferences, mainAlarm, locationId) => {
	const times = {};
	try {
		if (data?.rows) {
			const temp = data.rows.filter((x) => x[locationId]);
			if (temp.length === 0) {
				return null;
			}

			times.startOfData = moment(temp[0].DateTime);
			times.endOfData = moment(temp[temp.length - 1].DateTime);
			times.totalTime = times.endOfData.diff(times.startOfData);
			times.totalTimeString = formatDuration(moment.duration(times.totalTime));

			const { lo, hi } = getLoHiM(timeKey, data, preferences, mainAlarm, locationId);
			const loSet = typeof lo === "number";
			const hiSet = typeof hi === "number";

			let timeHighTotal = 0;
			let timeHighStart = undefined;
			let lastReadingHigh = false;

			let timeLowTotal = 0;
			let timeLowStart = undefined;
			let lastReadingLow = false;

			temp.forEach((reading) => {
				const value = reading[locationId];

				if (hiSet && value >= hi) {
					if (lastReadingLow) {
						timeLowTotal += moment.duration(moment(reading.DateTime).diff(timeLowStart));
					}
					if (!lastReadingHigh) {
						timeHighStart = moment(reading.DateTime);
					}
					lastReadingHigh = true;
					lastReadingLow = false;
				} else if (loSet && value <= lo) {
					if (lastReadingHigh) {
						timeHighTotal += moment.duration(moment(reading.DateTime).diff(timeHighStart));
					}
					if (!lastReadingLow) {
						timeLowStart = moment(reading.DateTime);
					}
					lastReadingHigh = false;
					lastReadingLow = true;
				} else if (lastReadingHigh || lastReadingLow) {
					if (lastReadingHigh) {
						timeHighTotal += moment.duration(moment(reading.DateTime).diff(timeHighStart));
					}
					if (lastReadingLow) {
						timeLowTotal += moment.duration(moment(reading.DateTime).diff(timeLowStart));
					}
					timeHighStart = undefined;
					timeLowStart = undefined;

					lastReadingHigh = false;
					lastReadingLow = false;
				}
			});

			// Check again in case final reading was high/low
			if (lastReadingHigh) {
				timeHighTotal += moment.duration(times.endOfData.diff(timeHighStart));
			}
			if (lastReadingLow) {
				timeLowTotal += moment.duration(times.endOfData.diff(timeLowStart));
			}

			times.hi = hi;
			times.hiSet = hiSet;
			times.aboveTime = hiSet ? timeHighTotal : null;
			times.aboveTimeString = hiSet ? formatDuration(moment.duration(timeHighTotal)) : null;
			times.lo = lo;
			times.loSet = loSet;
			times.belowTime = loSet ? timeLowTotal : null;
			times.belowTimeString = loSet ? formatDuration(moment.duration(timeLowTotal)) : null;
			times.outsideTime = hiSet || loSet ? timeLowTotal + timeHighTotal : null;
			times.outsideTimeString = hiSet || loSet ? formatDuration(moment.duration(times.outsideTime)) : null;
			times.withinTime = hiSet || loSet ? times.totalTime - times.outsideTime : null;
			times.withinTimeString = hiSet || loSet ? formatDuration(moment.duration(times.withinTime)) : null;

			return times;
		}
	} catch {}
	return null;
};
export const getTimesM = memoize(getTimesI, (...args) => values(args).join("_"));

export const getValidDataI = (timeKey, data, locationId) => {
	try {
		if (data?.rows) {
			return data.rows.filter((x) => typeof x[locationId] === "number").length;
		}
	} catch {}
	return null;
};
export const getValidDataM = memoize(getValidDataI, (...args) => values(args).join("_"));

export const getMaxDataI = (timeKey, data, locationId) => {
	try {
		if (data?.rows) {
			const dataPoints = data.rows.filter((x) => typeof x[locationId] === "number").map((o) => o[locationId]);
			if (dataPoints.length > 0) {
				return Math.max(...dataPoints);
			}
		}
	} catch {}
	return null;
};
export const getMaxDataM = memoize(getMaxDataI, (...args) => values(args).join("_"));

export const formatNumberI = (timeKey, data, value, locationId) => {
	try {
		if (data?.columns) {
			const dps = data?.columns.find((x) => x.field === locationId)?.decimalPlaces;
			return value?.toLocaleString(undefined, { maximumFractionDigits: dps, minimumFractionDigits: dps });
		}
	} catch {}
	return null;
};
export const formatNumberM = memoize(formatNumberI, (...args) => values(args).join("_"));

export const getMaxDataFirstTimeI = (timeKey, data, locationId) => {
	try {
		if (data?.rows) {
			const temp = data.rows.filter((x) => x[locationId] === getMaxDataM(timeKey, data, locationId));
			if (temp.length > 0) {
				return moment(temp[0].DateTime).format("DD MMM YYYY HH:mm");
			}
		}
	} catch {}
	return null;
};
export const getMaxDataFirstTimeM = memoize(getMaxDataFirstTimeI, (...args) => values(args).join("_"));

export const getMinDataI = (timeKey, data, locationId) => {
	try {
		if (data?.rows) {
			const dataPoints = data.rows.filter((x) => typeof x[locationId] === "number").map((o) => o[locationId]);
			if (dataPoints.length > 0) {
				return Math.min(...dataPoints);
			}
		}
	} catch {}
	return null;
};
export const getMinDataM = memoize(getMinDataI, (...args) => values(args).join("_"));

export const getMinDataFirstTimeI = (timeKey, data, locationId) => {
	try {
		if (data?.rows) {
			const temp = data.rows.filter((x) => x[locationId] === getMinDataM(timeKey, data, locationId));
			if (temp.length > 0) {
				return moment(temp[0].DateTime).format("DD MMM YYYY HH:mm");
			}
		}
	} catch {}
	return null;
};
export const getMinDataFirstTimeM = memoize(getMinDataFirstTimeI, (...args) => values(args).join("_"));

export const getSumI = (timeKey, data, locationId) => {
	try {
		if (data?.rows) {
			return data.rows.reduce((a, b) => a + (b[locationId] || 0), 0);
		}
	} catch {}
	return null;
};
export const getSumM = memoize(getSumI, (...args) => values(args).join("_"));

export const getAverageI = (timeKey, data, locationId) => {
	try {
		if (data?.rows) {
			const sum = getSumM(timeKey, data, locationId);
			const points = getValidDataM(timeKey, data, locationId);
			if (sum && points) {
				return sum / points;
			}
		}
	} catch {}
	return null;
};
export const getAverageM = memoize(getAverageI, (...args) => values(args).join("_"));

export const getStandardDeviationI = (timeKey, data, locationId) => {
	let sumOfSquares = 0;
	let validReadings = 0;
	if (data?.rows) {
		data.rows.forEach((x) => {
			const value = x[locationId];
			if (typeof value === "number") {
				sumOfSquares += value ** 2;
				++validReadings;
			}
		});

		if (validReadings === 0) {
			return 0;
		}

		const variance = sumOfSquares / validReadings - getAverageM(timeKey, data, locationId) ** 2;

		if (variance <= 0) {
			return 0;
		}
		return Math.sqrt(variance);
	}
	return null;
};
export const getStandardDeviationM = memoize(getStandardDeviationI, (...args) => values(args).join("_"));

export const getRangeI = (timeKey, data, preferences, mainAlarm, locationId) => {
	try {
		if (data?.columns) {
			const { lo, hi } = getLoHiM(timeKey, data, preferences, mainAlarm, locationId);

			if (typeof lo === "number" && typeof hi === "number") {
				return `${formatNumberM(timeKey, data, lo, locationId)} - ${formatNumberM(timeKey, data, hi, locationId)}`;
			} else if (typeof lo === "number") {
				return `<= ${formatNumberM(timeKey, data, lo, locationId)}`;
			} else if (typeof hi === "number") {
				return `>= ${formatNumberM(timeKey, data, hi, locationId)}`;
			}

			return local.TF_No_Alarm;
		}
	} catch {}
	return null;
};
export const getRangeM = memoize(getRangeI, (...args) => values(args).join("_"));

export const getReadingsWithinI = (timeKey, data, preferences, mainAlarm, locationId) => {
	try {
		if (data?.columns && data?.rows) {
			const { lo, hi } = getLoHiM(timeKey, data, preferences, mainAlarm, locationId);

			if (typeof lo === "number" || typeof hi === "number") {
				return data.rows.filter((x) => typeof x[locationId] === "number" && (!lo || x[locationId] > lo) && (!hi || x[locationId] < hi)).length;
			}
		}
	} catch {}
	return null;
};
export const getReadingsWithinM = memoize(getReadingsWithinI, (...args) => values(args).join("_"));

export const percent = (a, b) => {
	try {
		if (typeof a === "number" && b) {
			return ((a * 100) / b).toLocaleString(undefined, { maximumFractionDigits: 1, minimumFractionDigits: 1 }) + "%";
		}
	} catch {}
	return null;
};

export const getReadingsOutsideI = (timeKey, data, preferences, mainAlarm, locationId) => {
	try {
		if (data?.columns && data?.rows) {
			const { lo, hi } = getLoHiM(timeKey, data, preferences, mainAlarm, locationId);

			if (typeof lo === "number" || typeof hi === "number") {
				return data.rows.filter((x) => typeof x[locationId] === "number" && ((lo && x[locationId] <= lo) || (hi && x[locationId] >= hi))).length;
			}
		}
	} catch {}
	return null;
};
export const getReadingsOutsideM = memoize(getReadingsOutsideI, (...args) => values(args).join("_"));

export const getReadingsAboveI = (timeKey, data, preferences, mainAlarm, locationId) => {
	try {
		if (data?.columns && data?.rows) {
			const { hi } = getLoHiM(timeKey, data, preferences, mainAlarm, locationId);

			if (typeof hi === "number") {
				return data.rows.filter((x) => typeof x[locationId] === "number" && (hi && x[locationId] >= hi)).length;
			}
		}
	} catch {}
	return null;
};
export const getReadingsAboveM = memoize(getReadingsAboveI, (...args) => values(args).join("_"));

export const getReadingsBelowI = (timeKey, data, preferences, mainAlarm, locationId) => {
	try {
		if (data?.columns && data?.rows) {
			const { lo } = getLoHiM(timeKey, data, preferences, mainAlarm, locationId);

			if (typeof lo === "number") {
				return data.rows.filter((x) => typeof x[locationId] === "number" && (lo && x[locationId] <= lo)).length;
			}
		}
	} catch {}
	return null;
};
export const getReadingsBelowM = memoize(getReadingsBelowI, (...args) => values(args).join("_"));

export const getReadingsAboveValueI = (timeKey, data, locationId, value, axis) => {
	try {
		if (data?.columns && data?.rows) {
			const location = data.columns.find((x) => x.field === locationId);
			if (`${location.parameterAxis}` !== axis) {
				return null;
			}

			const hi = typeof value === "number" ? value : parseFloat(value);

			if (typeof hi === "number") {
				return data.rows.filter((x) => typeof x[locationId] === "number" && (hi && x[locationId] >= hi)).length;
			}
		}
	} catch {}
	return null;
};
export const getReadingsAboveValueM = memoize(getReadingsAboveValueI, (...args) => values(args).join("_"));

export const getReadingsBelowValueI = (timeKey, data, locationId, value, axis) => {
	try {
		if (data?.columns && data?.rows) {
			const location = data.columns.find((x) => x.field === locationId);
			if (`${location.parameterAxis}` !== axis) {
				return null;
			}

			const lo = typeof value === "number" ? value : parseFloat(value);

			if (typeof lo === "number") {
				return data.rows.filter((x) => typeof x[locationId] === "number" && (lo && x[locationId] <= lo)).length;
			}
		}
	} catch {}
	return null;
};
export const getReadingsBelowValueM = memoize(getReadingsBelowValueI, (...args) => values(args).join("_"));

export const getTimesValueI = (timeKey, data, locationId, value, axis) => {
	const times = {};
	try {
		if (data?.columns && data?.rows) {
			const location = data.columns.find((x) => x.field === locationId);
			if (`${location.parameterAxis}` !== axis) {
				return null;
			}

			const temp = data.rows.filter((x) => x[locationId]);
			if (temp.length === 0) {
				return null;
			}

			times.startOfData = moment(temp[0].DateTime);
			times.endOfData = moment(temp[temp.length - 1].DateTime);
			times.totalTime = times.endOfData.diff(times.startOfData);
			times.totalTimeString = formatDuration(moment.duration(times.totalTime));

			const lo = typeof value === "number" ? value : parseFloat(value);
			const hi = typeof value === "number" ? value : parseFloat(value);
			const loSet = typeof lo === "number";
			const hiSet = typeof hi === "number";

			let timeHighTotal = 0;
			let timeHighStart = undefined;
			let lastReadingHigh = false;

			let timeLowTotal = 0;
			let timeLowStart = undefined;
			let lastReadingLow = false;

			temp.forEach((reading) => {
				const value = reading[locationId];

				if (hiSet && value >= hi) {
					if (lastReadingLow) {
						timeLowTotal += moment.duration(moment(reading.DateTime).diff(timeLowStart));
					}
					if (!lastReadingHigh) {
						timeHighStart = moment(reading.DateTime);
					}
					lastReadingHigh = true;
					lastReadingLow = false;
				} else if (loSet && value <= lo) {
					if (lastReadingHigh) {
						timeHighTotal += moment.duration(moment(reading.DateTime).diff(timeHighStart));
					}
					if (!lastReadingLow) {
						timeLowStart = moment(reading.DateTime);
					}
					lastReadingHigh = false;
					lastReadingLow = true;
				} else if (lastReadingHigh || lastReadingLow) {
					if (lastReadingHigh) {
						timeHighTotal += moment.duration(moment(reading.DateTime).diff(timeHighStart));
					}
					if (lastReadingLow) {
						timeLowTotal += moment.duration(moment(reading.DateTime).diff(timeLowStart));
					}
					timeHighStart = undefined;
					timeLowStart = undefined;

					lastReadingHigh = false;
					lastReadingLow = false;
				}
			});

			// Check again in case final reading was high/low
			if (lastReadingHigh) {
				timeHighTotal += moment.duration(times.endOfData.diff(timeHighStart));
			}
			if (lastReadingLow) {
				timeLowTotal += moment.duration(times.endOfData.diff(timeLowStart));
			}

			times.hi = hi;
			times.hiSet = hiSet;
			times.aboveTime = hiSet ? timeHighTotal : null;
			times.aboveTimeString = hiSet ? formatDuration(moment.duration(timeHighTotal)) : null;
			times.lo = lo;
			times.loSet = loSet;
			times.belowTime = loSet ? timeLowTotal : null;
			times.belowTimeString = loSet ? formatDuration(moment.duration(timeLowTotal)) : null;
			times.outsideTime = hiSet || loSet ? timeLowTotal + timeHighTotal : null;
			times.outsideTimeString = hiSet || loSet ? formatDuration(moment.duration(times.outsideTime)) : null;
			times.withinTime = hiSet || loSet ? times.totalTime - times.outsideTime : null;
			times.withinTimeString = hiSet || loSet ? formatDuration(moment.duration(times.withinTime)) : null;

			return times;
		}
	} catch {}
	return null;
};
export const getTimesValueM = memoize(getTimesValueI, (...args) => values(args).join("_"));

import React, { useState, useEffect, useCallback } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane, Alert } from "reactstrap";
import PageTitle from "../common/PageTitle";
import local from "../../localization/strings";
import DistributorTab from "./DistributorTab";
import { logsList, logAcknowledge } from "../../api/site";
import SiteTab from "./SiteTab";
import LogsTab from "./LogsTab";
import moment from "moment";
import "moment/min/locales";

const AdminArea = () => {
	const [activeTab, setActiveTab] = useState(1);
	const [logs, setLogs] = useState([]);

	const LoadLogs = useCallback(async () => {
		var result = await logsList();
		setLogs(result);
	}, []);

	useEffect(() => {
		LoadLogs();
	}, [LoadLogs]);

	const clearLog = async (id) => {
		await logAcknowledge(id);
		await LoadLogs();
	};

	const clearAll = async () => {
		for (let i = 0; i < logs.length; i++) {
			clearLog(logs[i].id);
		}
	};

	const toggle = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	const tabNavItem = (index, title, hidden) => {
		return (
			<NavItem>
				<NavLink
					hidden={hidden}
					className={activeTab === index ? "active" : ""}
					onClick={() => {
						toggle(index);
					}}
				>
					{title}
				</NavLink>
			</NavItem>
		);
	};

	return (
		<>
			<PageTitle title={local.TF_Admin_Area} />
			{logs &&
				logs.length > 0 &&
				logs.map((item) => (
					<Alert color="danger" key={item.id}>
						{item.siteName && <h5>{item.siteName}</h5>}
						<div className="text-nowrap text-truncate inline-block" title={`${item.category}: ${item.logMessage}`}>
							<b>{item.category}</b>: {item.logMessage}
						</div>
						<div>
							<button onClick={() => clearLog(item.id)}>{local.TS_Clear}</button>
							<span className="float-right">({moment(item.dateTime).format("DD MMM YYYY HH:mm")})</span>
						</div>
					</Alert>
				))}
			{logs && logs.length > 0 && (
				<div className="row">
					<div className="col">
						<button className="btn btn-danger mb-3" onClick={() => clearAll()}>
							Clear All
						</button>
					</div>
					{logs.length > 25 && <div className="col text-danger font-weight-bold text-right text-uppercase">{local.TF_More_Errors}</div>}
				</div>
			)}

			<Nav tabs>
				{tabNavItem(1, local.TF_All_Sites, false)}
				{tabNavItem(2, local.TF_Distributors, false)}
				{tabNavItem(3, local.TF_Logs, false)}
			</Nav>
			<TabContent activeTab={activeTab}>
				<TabPane tabId={1}>
					<SiteTab />
				</TabPane>
				<TabPane tabId={2}>
					<DistributorTab />
				</TabPane>
				<TabPane tabId={3}>
					<LogsTab />
				</TabPane>
			</TabContent>
		</>
	);
};

export default AdminArea;

import moment, { isMoment } from "moment";
import "moment/min/locales";
import React, { useEffect, useState } from "react";
import Datetime from "react-datetime";
import { Button, Col, Form, Label, Row } from "reactstrap";
import { siteReadingsMaxDateTime } from "../../../api/site";
import { logEvent } from "../../../helpers/ga";
import local from "../../../localization/strings";
import SiteTreeMultiSelect from "../../common/SiteTreeMultiSelect";

const ExportDataFilter = ({ triggerGenerate, filterData, onChange }) => {
	const [language, setLanguage] = useState("en");
	const [startTime, setStartTime] = useState(new Date());
	const [endTime, setEndTime] = useState(new Date());
	const [locationIds, setLocationIds] = useState([]);
	const initialLocationIds = filterData.locationIds || [];

	useEffect(() => {
		var contentDiv = document.getElementById("export-data-report-div");

		if (contentDiv) {
			contentDiv.style.marginRight = "0";
		}
	}, []);

	useEffect(() => {
		setLanguage(local.getLanguage());
	}, []);

	useEffect(() => {
		setLocationIds(filterData.locationIds || []);
		setStartTime(filterData.startTime ? new Date(filterData.startTime) : null);
		setEndTime(filterData.endTime ? new Date(filterData.endTime) : null);
	}, [filterData]);

	const rowClasses = "mt-2";

	const startTimeChange = (e, reset = false) => {
		try {
			if (isMoment(e)) {
				setStartTime(e.toDate());

				logEvent("Export Data", "Filter Changed", `Start Time: ${e.toDate()}`);

				if (onChange) {
					onChange({ locationIds, startTime: e.toDate(), endTime });
				}
			} else {
				if (reset) {
					setStartTime(moment());
					if (onChange) {
						onChange({ locationIds, startTime: moment().toDate(), endTime });
					}
				}
			}
		} catch {
			//Do nothing
		}
	};

	const endTimeChange = (e, reset = false) => {
		try {
			if (isMoment(e)) {
				setEndTime(e.toDate());

				logEvent("Export Data", "Filter Changed", `End Time: ${e.toDate()}`);

				if (onChange) {
					onChange({ locationIds, startTime, endTime: e.toDate() });
				}
			} else {
				if (reset) {
					setEndTime(moment());
					if (onChange) {
						onChange({ locationIds, startTime, endTime: moment().toDate() });
					}
				}
			}
		} catch {
			//Do nothing
		}
	};

	const toEndOfData = async () => {
		logEvent("Export Data", "Filter Changed", "End Time: End Of Data");

		const max = await siteReadingsMaxDateTime();

		setEndTime(moment(max));
		if (onChange) {
			onChange({ locationIds, startTime, endTime: null });
		}
	};

	const locationIdsChange = (e) => {
		logEvent("Export Data", "Filter Changed", `Location Ids: ${e}`);

		setLocationIds(e);
		if (onChange) {
			onChange({ locationIds: e, startTime, endTime });
		}
	};

	const generate = () => {
		logEvent("Export Data", "Generate Click");

		triggerGenerate({ startTime, endTime, locationIds });
	};

	return (
		<Form>
			<Row className="mb-2 pb-3">
				<Col sm={12}>
					<h5 className="mb-2">{local.TF_Area_to_report}</h5>
					<div className="border rounded-soft" style={{ height: 250, overflowY: "auto", overflowX: "hidden" }}>
						<SiteTreeMultiSelect expandToLevel={0} siteMapLevel={4} inline={true} onCheckedChanged={locationIdsChange} initialChecked={initialLocationIds.join(",")} />
					</div>
				</Col>
				<Col sm={6}>
					<h5 className="mt-3 mb-1">{local.TF_Time_period}</h5>
					<Row className={rowClasses}>
						<Col>
							<Label for="datetimepicker">{local.TS_Start_Date_Time}</Label>
						</Col>
						<Col>
							<Datetime locale={language} dateFormat="DD MMM YYYY" timeFormat={null} value={startTime} onBlur={(e) => startTimeChange(e, true)} onChange={startTimeChange} utc={true} />
						</Col>
					</Row>
					<Row className={rowClasses}>
						<Col>
							<Label for="datetimepicker">{local.TS_End_Date_Time}</Label>
						</Col>
						<Col>
							<Datetime locale={language} dateFormat="DD MMM YYYY" timeFormat={null} value={endTime} onBlur={(e) => endTimeChange(e, true)} onChange={endTimeChange} utc={true} />
							<Button color="falcon-info" className="w-100 mt-2" size="sm" onClick={toEndOfData}>
								{local.TF_Set_to_end_of_data}
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
			{triggerGenerate && (
				<Row className="border-top pt-3 mt-3">
					<Col>
						<Button color="primary" onClick={generate} className="col-md-3">
							{local.TS_Generate}
						</Button>
					</Col>
				</Row>
			)}
		</Form>
	);
};

export default ExportDataFilter;

import axios from "axios";
import { CurrentSiteIdGet } from "./userSettings";

export const alarmTeamsList = async () => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "get",
			url: "v2/AlarmTeams",
			params: {
				siteId,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const alarmTeamGet = async (id) => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "get",
			url: "v2/AlarmTeam",
			params: {
				siteId,
				id,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const alarmTeamDelete = async (id) => {
	const siteId = CurrentSiteIdGet();

	try {
		await axios({
			method: "delete",
			url: "v2/AlarmTeam",
			data: {
				siteId,
				id,
			},
		});
	} catch (err) {
		console.error(err);
	}
};

export const alarmTeamSave = async (id, departmentId, name, alarms, emailAddresses, mobileNumbers, groupIds, continuous) => {
	const siteId = CurrentSiteIdGet();

	if (!continuous) {
		continuous = null;
	}

	try {
		const result = await axios({
			method: "post",
			url: "v2/AlarmTeam",
			data: { siteId, id, alarmTeamDepartmentId: departmentId, name, alarms, emailAddresses, mobileNumbers, groupIds, continuous },
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const alarmTeamEmergency = async (siteId, activate) => {
	try {
		await axios({
			method: "post",
			url: "v2/AlarmTeam/Emergency",
			data: { siteId, activate },
		});
	} catch (err) {
		console.error(err);
	}
};

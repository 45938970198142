import local from "../localization/strings";
import { logEvent } from "../helpers/ga";

export const availableLanguages = () => {
	var result = [["English", "en-GB"], ["Español", "es"]];

	return result;
};

export const changeLanguage = (language, location) => {
	logEvent(location || "Location not defined", "Language Changed");
	localStorage.setItem("UserLanguage", language);
	local.setLanguage(language);
	window.location.reload();
};

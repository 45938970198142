import React, { useContext } from "react";
import { DropdownItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserPreferencesContext } from "../../context/userPreferences";
import { toast } from "react-toastify";
import local from "../../localization/strings";
import { logEvent } from "../../helpers/ga";

//requires the dropdown menu & toggle already configured - this is for mapping the dropdown items & pinning function only

const PinSubmenu = ({ types, icon, extraFunction }) => {
	const { pinToDashboard } = useContext(UserPreferencesContext);

	const pin = async (item) => {
		logEvent("Pin", item.type, `${item.data}`);
		if (await pinToDashboard(item.type, item.data, item.zoneName, item.buildingName)) {
			//if another function needs to fire when the pin is added, it happens here (e.g. window.location.reload() for adding widgets from the dashboard)
			extraFunction && extraFunction();
			notify(item.name);
		} else {
			toast.error(local.TF_Widgets_Limit_Exceeded);
		}
	};

	const notify = (item) => {
		toast.success(item + " " + local.TF_added_to_dashboard);
	};

	return (
		<div className="pin-button">
			{types.map((item, index) => (
				<DropdownItem key={index} onClick={() => pin(item)}>
					<FontAwesomeIcon icon={icon || "thumbtack"} className="mr-1 pin-rotate" />
					{item.name}
				</DropdownItem>
			))}
		</div>
	);
};

export default PinSubmenu;

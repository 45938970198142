import React, { useCallback } from "react";
import { Alert } from "reactstrap";
import local from "../../localization/strings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { apiLoggerStopStart } from "../../api/logger";

const LoggerStatus = ({ squirrel, outstanding, reload }) => {
	const sendStopStart = useCallback(async () => {
		//If the status is try then it's logging and we need to tell it to stop
		const start = squirrel.loggerStatus ? false : true;

		await apiLoggerStopStart(squirrel.serialNumber, start);

		if (reload) {
			reload();
		}
	}, [squirrel, reload]);

	return (
		<Alert color={squirrel.loggerStatus ? "success" : "danger"} className="m-0 p-1">
			<div className="notification-body">
				<div className="d-flex">
					<div className="flex-grow-1">
						<p className="mb-0 font-weight-bold">{squirrel.name}</p>
						<p className="mb-0 fs--2">S/N: {squirrel.serialNumber}</p>
						{Object.keys(outstanding).includes(squirrel.serialNumber) ? <p className="mb-0 mt-2 font-weight-bold">{squirrel.loggerStatus ? local.TF_StopRequest : local.TF_StartRequest}</p> : <p className="mb-0 mt-2 font-weight-bold">{squirrel.loggerStatus ? local.TF_Logging : local.TF_Stopped}</p>}
					</div>
					{Object.keys(outstanding).includes(squirrel.serialNumber) ? (
						<div className="d-flex justify-content-end align-items-center pr-2">
							<FontAwesomeIcon icon="hourglass-half" size="2x" />
						</div>
					) : (
						<div className="d-flex justify-content-end align-items-center pr-2">{squirrel.loggerStatus ? <FontAwesomeIcon icon="stop-circle" size="2x" onClick={sendStopStart} className="cursor-pointer" /> : <FontAwesomeIcon icon="play-circle" size="2x" onClick={sendStopStart} className="cursor-pointer" />}</div>
					)}
				</div>
			</div>
		</Alert>
	);
};

export default LoggerStatus;

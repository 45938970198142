import { AvField, AvForm, AvGroup, AvInput, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import moment from "moment";
import "moment/min/locales";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Col, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { CurrentUserHasRole } from "../../api/auth";
import { parameterGet, parameterSaveFromComponent } from "../../api/parameter";
import { features, isFeatureEnabled, roles } from "../../config";
import local from "../../localization/strings";
import AvDateTime from "../common/AvDateTime";
import ColourPickerButton from "../common/ColourPickerButton";
import IntToHex from "../common/IntToHex";
import Loader from "../common/Loader";
import Formulae from "./calc-forms/Formulae";

const defaultParameter = {
	name: "",
	units: "",
	type: 0,
	axis: 0,
	warnPlotType: "NoPlot",
	mainPlotType: "NoPlot",
};

const Parameter = ({ selected, parameters, reload }) => {
	const [parameter, setParameter] = useState(null);
	const [loading, setLoading] = useState(true);
	const [activeTab, setActiveTab] = useState(1);
	const [type, setType] = useState(null);
	const [formulaType, setFormulaType] = useState(null);
	const [units, setUnits] = useState(null);
	const [warnAlarmPlotType, setWarnAlarmPlotType] = useState(null);
	const [mainAlarmPlotType, setMainAlarmPlotType] = useState(null);
	const canEdit = CurrentUserHasRole(roles.SiteAdmin) && isFeatureEnabled(features.ParameterEdit);

	useEffect(() => {
		let isSubscribed = true;

		const LoadData = async () => {
			const result = await parameterGet(selected);

			if (isSubscribed) {
				if (result) {
					result.hexColour = `#${IntToHex(result.colour)}`;
					result.warnAlarmHexColour = `#${IntToHex(result.warnAlarmColour || 0)}`;
					result.mainAlarmHexColour = `#${IntToHex(result.mainAlarmColour || 0)}`;
					result.cumulativeStartTimeMoment = moment(result.cumulativeStartTime);
					result.displayGraphIndex = result.graphIndex + 1;

					//Fixing Nulls
					result.cumulativeReffedParamId = result.cumulativeReffedParamId || "";
					result.warnAlarmLo = result.warnAlarmLo || typeof result.warnAlarmLo === "number" ? result.warnAlarmLo : "";
					result.warnAlarmHi = result.warnAlarmHi || typeof result.warnAlarmHi === "number" ? result.warnAlarmHi : "";
					result.warnAlarmPlot = result.warnAlarmPlot || 0;
					result.warnAlarmLineType = result.warnAlarmLineType || 0;
					result.warnAlarmColourBlock = result.warnAlarmColourBlock || 0;
					result.warnAlarmColour = result.warnAlarmColour || 0;
					result.mainAlarmLo = result.mainAlarmLo || typeof result.mainAlarmLo === "number" ? result.mainAlarmLo : "";
					result.mainAlarmHi = result.mainAlarmHi || typeof result.mainAlarmHi === "number" ? result.mainAlarmHi : "";
					result.mainAlarmPlot = result.mainAlarmPlot || 0;
					result.mainAlarmLineType = result.mainAlarmLineType || 0;
					result.mainAlarmColourBlock = result.mainAlarmColourBlock || 0;
					result.mainAlarmColour = result.mainAlarmColour || 0;

					if (result.warnAlarmPlot === 0) {
						result.warnPlotType = "NoPlot";
					} else {
						if (result.warnAlarmColourBlock) {
							result.warnPlotType = "BlockColour";
						} else {
							result.warnPlotType = "Line";
						}
					}
					setWarnAlarmPlotType(result.warnPlotType);

					if (result.mainAlarmPlot === 0) {
						result.mainPlotType = "NoPlot";
					} else {
						if (result.mainAlarmColourBlock) {
							result.mainPlotType = "BlockColour";
						} else {
							result.mainPlotType = "Line";
						}
					}
					setMainAlarmPlotType(result.mainPlotType);

					setParameter(result);
					setType(result.type);
					setFormulaType(result.formulaIsCumulative);
					setUnits(result.units);
				} else {
					setWarnAlarmPlotType();
					setMainAlarmPlotType();
					setParameter(defaultParameter);
					setType(0);
					setFormulaType();
					setUnits("");
				}

				setLoading(false);
			}
		};

		LoadData();
		return () => (isSubscribed = false);
	}, [selected]);

	const typeChanged = (e) => {
		setType(parseInt(e.target.value));
	};

	const warmAlarmPlotTypeChanged = (e) => {
		setWarnAlarmPlotType(e.target.value);
	};

	const mainAlarmPlotTypeChanged = (e) => {
		setMainAlarmPlotType(e.target.value);
	};

	const formulaTypeChanged = (e) => {
		setFormulaType(parseInt(e.target.value));
	};

	const save = async (_e, values) => {
		await parameterSaveFromComponent(selected, parameter.index, values);
		reload();
		toast.success(local.TF_Parameter_successfully_updated);
	};

	const addDegrees = () => {
		setUnits(`${units}°`);
	};

	return (
		<>
			{loading && <Loader />}
			{parameter && (
				<AvForm model={parameter} key={selected} onValidSubmit={async (e, values) => await save(e, values)}>
					<AvField name="name" validate={{ required: { value: true, errorMessage: local.TS_Required } }} disabled={!canEdit} />
					<Nav tabs>
						<NavItem>
							<NavLink className={`${activeTab === 1 && "active"}`} onClick={() => setActiveTab(1)}>
								{local.TF_General}
							</NavLink>
						</NavItem>
						{isFeatureEnabled(features.LocationAlarms) && (
							<NavItem>
								<NavLink className={`${activeTab === 2 && "active"}`} onClick={() => setActiveTab(2)}>
									{local.TF_Alarms}
								</NavLink>
							</NavItem>
						)}
						<NavItem>
							<NavLink className={`${activeTab === 3 && "active"}`} onClick={() => setActiveTab(3)}>
								{local.TF_Calculated}
							</NavLink>
						</NavItem>
					</Nav>
					<TabContent activeTab={activeTab} className="border p-2">
						<TabPane tabId={1}>
							<Row>
								<Col md={6}>
									<Label>{local.TS_Colour}</Label>
								</Col>
								<Col md={5}>
									<Label>{local.TS_Units}</Label>
								</Col>
								<Col md={1} />
							</Row>
							<Row>
								<Col md={6}>
									<AvInput tag={ColourPickerButton} name="hexColour" disabled={!canEdit} />
								</Col>
								<Col md={5} className="pr-0">
									<AvField name="units" value={units} onChange={(e) => setUnits(e.target.value)} disabled={!canEdit} className="no-radius-right" />
								</Col>
								<Col md={1} className="pl-0">
									{canEdit && (
										<Button className="px-2 degrees-button" color="outline-info" onClick={addDegrees}>
											°
										</Button>
									)}
								</Col>
							</Row>
							<hr />
							<Label>{local.TF_Display_Param_on}</Label>
							<AvGroup check>
								<Label check>
									<AvInput type="checkbox" name="plotted" disabled={!canEdit} /> Chart
								</Label>
							</AvGroup>
							<AvGroup check>
								<Label check>
									<AvInput type="checkbox" name="displayOnLatestValues" disabled={!canEdit} /> Latest Values
								</Label>
							</AvGroup>
							<hr />
							<Row>
								<Col sm={6}>
									<AvField name="displayGraphIndex" type="number" label={local.TF_Chart_Number} disabled={!canEdit} />
								</Col>
								<Col sm={6}>
									<AvField name="axis" type="select" label={local.TS_Axis} disabled={!canEdit}>
										<option value={0}>{local.TS_Left}</option>
										<option value={1}>{local.TS_Right}</option>
									</AvField>
								</Col>
							</Row>
							<Row>
								<Col sm={4}>
									<AvField name="rangeMin" type="number" label={local.TF_Range_from} disabled={!canEdit} />
								</Col>
								<Col sm={4}>
									<AvField name="rangeMax" type="number" label={local.TF_Range_to} disabled={!canEdit} />
								</Col>
								<Col sm={4}>
									<AvField name="decimalPlaces" type="number" label={local.TS_Decimal_Places} disabled={!canEdit} />
								</Col>
							</Row>
						</TabPane>
						<TabPane tabId={2}>
							<Row>
								<Col md={6}>
									<div className="border border-warning py-0 px-2">
										<h6 className="bg-warning p-2 mx-n2 text-white">{local.TF_Threshold_Levels}</h6>
										<AvField name="warnAlarmLo" type="number" label={local.TS_Min} disabled={!canEdit} />
										<AvField name="warnAlarmHi" type="number" label={local.TS_Max} disabled={!canEdit} />
									</div>
								</Col>
								<Col md={6}>
									<div className="border border-danger py-0 px-2">
										<h6 className="bg-danger p-2 mx-n2 text-white">{local.TF_Alarm_Levels}</h6>
										<AvField name="mainAlarmLo" type="number" label={local.TS_Min} disabled={!canEdit} />
										<AvField name="mainAlarmHi" type="number" label={local.TS_Max} disabled={!canEdit} />
									</div>
								</Col>
							</Row>
							<Row>
								<Col md={6}>
									<div className="border border-warning py-0 px-2" style={{ minHeight: 200 }}>
										<h6 className="bg-warning p-2 mx-n2 text-white">{local.TF_Threshold_Plot_Style}</h6>
										<AvRadioGroup name="warnPlotType" required disabled={!canEdit}>
											<AvRadio label={local.TS_No_Plot} value="NoPlot" onChange={warmAlarmPlotTypeChanged} />
											<AvRadio label={local.TS_Line} value="Line" onChange={warmAlarmPlotTypeChanged} />
											<AvRadio label={local.TS_Colour_Block} value="BlockColour" onChange={warmAlarmPlotTypeChanged} />
										</AvRadioGroup>
										{warnAlarmPlotType === "Line" && (
											<AvField name="warnAlarmLineType" type="select" disabled={!canEdit}>
												<option value={0}>{local.TF_Dash}</option>
												<option value={1}>{local.TF_Dot}</option>
												<option value={2}>{local.TF_DashDot}</option>
												<option value={3}>{local.TF_DashDotDot}</option>
											</AvField>
										)}
										{warnAlarmPlotType === "BlockColour" && <AvInput tag={ColourPickerButton} name="warnAlarmHexColour" disabled={!canEdit} />}
									</div>
								</Col>
								<Col md={6}>
									<div className="border border-danger py-0 px-2" style={{ minHeight: 200 }}>
										<h6 className="bg-danger p-2 mx-n2 text-white">{local.TF_Alarm_Plot_Style}</h6>
										<AvRadioGroup name="mainPlotType" required disabled={!canEdit}>
											<AvRadio label={local.TS_No_Plot} value="NoPlot" onChange={mainAlarmPlotTypeChanged} />
											<AvRadio label={local.TS_Line} value="Line" onChange={mainAlarmPlotTypeChanged} />
											<AvRadio label={local.TS_Colour_Block} value="BlockColour" onChange={mainAlarmPlotTypeChanged} />
										</AvRadioGroup>
										{mainAlarmPlotType === "Line" && (
											<AvField name="mainAlarmLineType" type="select" disabled={!canEdit}>
												<option value={0}>{local.TF_Dash}</option>
												<option value={1}>{local.TF_Dot}</option>
												<option value={2}>{local.TF_DashDot}</option>
												<option value={3}>{local.TF_DashDotDot}</option>
											</AvField>
										)}
										{mainAlarmPlotType === "BlockColour" && <AvInput tag={ColourPickerButton} name="mainAlarmHexColour" disabled={!canEdit} />}
									</div>
								</Col>
							</Row>
						</TabPane>
						<TabPane tabId={3}>
							<Label>{local.TS_Type}</Label>
							<AvRadioGroup inline name="type" required className="mt-2" disabled>
								<AvRadio label={local.TF_Physical} value={0} onChange={typeChanged} />
								<AvRadio label={local.TF_Calculated} value={1} onChange={typeChanged} />
							</AvRadioGroup>

							{type === 1 && (
								<AvField label={local.TS_Formula} type="select" name="formulaIsCumulative" onChange={formulaTypeChanged} disabled>
									<option value={0}>{local.TF_Standard_Formula}</option>
									<option value={1}>{local.TF_Cumulative_Formula}</option>
									<option value={2}>{local.TF_Fluctuation_Window}</option>
									<option value={3}>{local.TF_Rolling_Average}</option>
								</AvField>
							)}
							{type === 1 && (
								<>
									{formulaType === 0 && (
										<>
											<AvField type="textarea" rows={6} label={local.TS_Formula} name="formula" disabled />
											<Formulae />
										</>
									)}
									{(formulaType === 1 || formulaType === 2 || formulaType === 3) && (
										<>
											<AvField type="select" label={local.TS_Parameter} name="cumulativeReffedParamId" disabled>
												{parameters.map((item, i) => (
													<option key={i} value={item.id}>
														{item.name}
													</option>
												))}
											</AvField>
											<AvField type="number" name="cumulativeDivisor" label={formulaType === 1 ? local.TF_Divisor : local.TF_Window_Readings} disabled />
											{formulaType === 1 && (
												<>
													<Label>{local.TS_Start_Date_Time}</Label>
													<AvInput tag={AvDateTime} name="cumulativeStartTimeMoment" utc={false} disabled={!canEdit} />
												</>
											)}
										</>
									)}
								</>
							)}
						</TabPane>
					</TabContent>
					{canEdit && (
						<Button className="mt-2" color="primary">
							{local.TS_Save}
						</Button>
					)}
				</AvForm>
			)}
		</>
	);
};

export default Parameter;

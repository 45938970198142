import React from 'react';
import { Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import local from '../../localization/strings';

const AlarmNotification = (notification) => {

  return (
    <Alert color='danger' className='m-0 p-0'>
      <Link className='notification notification-flush' to={`/alarms/current#${notification.locationId}`}>
        <div className="notification-avatar">
          <FontAwesomeIcon icon='bell' fixedWidth size='2x' className='mr-2' />
        </div>
        <div className="notification-body">
          <p className='mb-0 font-weight-bold'>
            {notification.groupName} ({notification.zoneName} - {notification.buildingName})
      </p>
          <p className='mb-0'>{notification.highAlarm ? local.TF_NotificationsHighAlarm : local.TF_NotificationsLowAlarm}: {notification.realValue}{notification.units}</p>
        </div>
      </Link>
    </Alert >
  )
};

export default AlarmNotification;

import React, { Fragment } from 'react';
import { Card } from 'reactstrap'
import local from '../../../../localization/strings';

const DashStatsBlue = props => {

    return (
        <Fragment>
            <Card className="rounded-soft bg-gradient p-4 mb-2 text-white">
                <h5 className="text-white">{props.string}: <span style={{ color: props.fontColour, fontSize: "1.4rem" }} className="font-weight-bold">{props.number}</span></h5>
                <small>{local.TF_Selected_area}: {props.selectedArea}</small>
                <small className="mb-1">{local.TS_For}: {props.duration}</small>
            </Card>
        </Fragment>
    )
}

export default DashStatsBlue
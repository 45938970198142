import React, { useCallback, useEffect, useState } from "react";
import { apiLocationsGetList } from "../../api/location";
import { parametersGetList } from "../../api/parameter";
import IntToHex from "../common/IntToHex";
import LightenDarkenColor from "../common/LightenDarkenColour";
import LightOrDark from "../common/LightOrDark";
import Loader from "../common/Loader";
import ParameterBox from "./ParameterBox";

const SiteLayoutParameters = (props) => {
	const [parameters, setParameters] = useState([]);
	const [showLoading, setShowLoading] = useState(true);

	useEffect(() => {
		async function loadParameters(groupId) {
			setShowLoading(true);

			var data = await parametersGetList(groupId);

			setParameters(data);

			setShowLoading(false);
		}
		loadParameters(props.groupId);
	}, [props.groupId]);

	const handleClick = useCallback(async (parameter, groupId, editLocationAlarm) => {
		const location = (await apiLocationsGetList(groupId)).find((x) => x.parameterId === parameter.id);

		if (editLocationAlarm && location?.id) {
			editLocationAlarm(location.id, groupId);
		}
	}, []);

	const parametersMap = parameters.map((parameter, i) => {
		return (
			<ParameterBox
				key={i} //
				buttonId={parameter.id}
				setCurrentParameter={props.setCurrentParameter}
				name={parameter.name}
				colorOne={"#" + IntToHex(parameter.colour)}
				colorTwo={"#" + LightenDarkenColor(IntToHex(parameter.colour), 80)}
				boxColour={LightOrDark(IntToHex(parameter.colour))}
				onClick={() => handleClick(parameter, props.groupId, props.editLocationAlarm)}
			/>
		);
	});

	return (
		<td>
			{showLoading ? (
				<div className="ml-2">
					<Loader />
				</div>
			) : (
				parametersMap
			)}
		</td>
	);
};

export default SiteLayoutParameters;

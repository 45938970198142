import React, { useEffect, useState, useCallback, useMemo } from "react";
import { parametersTable } from "../../api/parameter";
import Loader from "../common/Loader";
import ReactDataGrid from "react-data-grid";
import useWindowSize from "../../hooks/useWindowSize";
import { Row, Col } from "reactstrap";
import local from "../../localization/strings";
import Datetime from "react-datetime";
import "moment/min/locales";
import moment, { isMoment } from "moment";

const ChartOverlay = ({ onChange, overlays, isMaximised, isCompare, options, updateRange }) => {
	const [data, setData] = useState([]);
	const [showLoading, setShowLoading] = useState(true);
	const [selected, setSelected] = useState(overlays || []);
	const [minHeight, setMinHeight] = useState(500);
	const [compareFrom, setCompareFrom] = useState(undefined);
	const [compareTo, setCompareTo] = useState(undefined);
	const [language, setLanguage] = useState("en");

	useEffect(() => {
		setLanguage(local.getLanguage());
	}, []);

	const size = useWindowSize();

	useEffect(() => {
		size.then((r) => {
			if (isMaximised) {
				setMinHeight(r.height - 150);
			} else {
				setMinHeight(r.height - 375);
			}
		});
	}, [size, isMaximised]);

	useEffect(() => {
		if (isCompare) {
			setCompareFrom(options.compareFrom);
			setCompareTo(options.compareTo);
		} else {
			setCompareFrom(undefined);
			setCompareTo(undefined);
		}
	}, [isCompare, options]);

	const updateSelectedCallback = useCallback(
		(e) => {
			var id = e.target.id;
			var updated = selected;

			var index = updated.indexOf(id);
			if (index > -1) {
				updated.splice(index, 1);
			}

			if (e.target.checked === true) {
				updated.push(id);
			}

			setSelected(updated);

			onChange(updated);
		},
		[selected, onChange],
	);

	const getParametersTable = useMemo(async () => {
		return await parametersTable(true);
	}, []);

	useEffect(() => {
		async function LoadData() {
			var result = await getParametersTable;

			result.columns.slice(2).forEach(function(column) {
				column.formatter = ValueFormatter;
			});

			setData(result);

			setShowLoading(false);
		}

		const ValueFormatter = ({ value }) => {
			if (!value) {
				return <div />;
			}

			const parts = value.split(":");

			const id = parts[0];
			const colour = parts[1];

			var style = {
				backgroundColor: colour,
			};

			if (colour === "#FFFFFF") {
				style.border = "1px solid black";
			}

			var isChecked = selected.includes(id);

			return (
				<div className="mx-auto p-1 text-center rounded overlay-parameter mb-n2" style={style}>
					<input type="checkbox" onChange={updateSelectedCallback} defaultChecked={isChecked} id={id} />
				</div>
			);
		};

		LoadData();
	}, [updateSelectedCallback, selected, getParametersTable]);

	const changeCompareFrom = (e, reset = false) => {
		try {
			if (isMoment(e)) {
				setCompareFrom(e.toDate());
				updateRange(e.toDate(), compareTo);
			} else {
				if (reset) {
					setCompareFrom(moment().toDate());
					updateRange(moment().toDate(), compareTo);
				}
			}
		} catch {
			//console.error("Failed to Parse Date!");
		}
	};

	const changeCompareTo = (e, reset = false) => {
		try {
			if (isMoment(e)) {
				setCompareTo(e.toDate());
				updateRange(compareFrom, e.toDate());
			} else {
				if (reset) {
					setCompareTo(moment().toDate());
					updateRange(compareFrom, moment().toDate());
				}
			}
		} catch {
			//console.error("Failed to Parse Date!");
		}
	};

	return showLoading ? (
		<Loader extraclassname="loader-chartSized" />
	) : (
		<div className="MuiTable-Small">
			{isCompare && (
				<>
					<Row className="p-2">
						<Col>
							<Datetime timeFormat="HH:mm" dateFormat="DD MMM YYYY" value={compareFrom} onBlur={(e) => changeCompareFrom(e, true)} onChange={changeCompareFrom} closeOnSelect={false} input={true} locale={language} utc={true} />
						</Col>
						<Col xs={1} className="text-center">
							{local.TF_Range_to}
						</Col>
						<Col>
							<Datetime timeFormat="HH:mm" dateFormat="DD MMM YYYY" value={compareTo} onBlur={(e) => changeCompareTo(e, true)} onChange={changeCompareTo} closeOnSelect={false} input={true} locale={language} utc={true} />
						</Col>
					</Row>
				</>
			)}
			<ReactDataGrid columns={data.columns} rowGetter={(i) => data.rows[i]} rowsCount={data.rowsCount} minHeight={minHeight} enableRowSelect={null} />
		</div>
	);
};

export default ChartOverlay;

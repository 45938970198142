import React, { Fragment, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { Button, Col, Form, Input, Label, Row, TabPane } from "reactstrap";
import { apiSiteSettingsList, apiSiteSettingsSave } from "../../api/site";
import { siteSettingsGetBooleanValue, siteSettingsGetStringValue } from "../../helpers/settingsHelper";
import local from "../../localization/strings";

const isDisabled = true;

const AutomaticUpdates = () => {
	const [loading, setLoading] = useState(true);
	const [autoUpdateData, setAutoUpdateData] = useState(false);
	const [everyX, setEveryX] = useState(true);
	const [autoUpdateNumber, setAutoUpdateNumber] = useState(0);
	const [autoUpdatePeriod, setAutoUpdatePeriod] = useState(1);
	const [autoUpdateTime, setAutoUpdateTime] = useState(new Date());
	const [synchroniseUpdate, setSynchroniseUpdate] = useState(true);

	/*
		AutoUpdateData - 0 or 1 (tickbox to turn on/off auto updates
		AutoUpdateEveryPeriod - 0 or 1 (tick box to activate ‘Every’ x hours/mins options)
		AutoUpdateNumber - number x (for 'Every' x hours/mins)
		AutoUpdatePeriod - 1 = mins, 2 = hours (we had 0 = seconds but has since been removed) active when 'Every x mins/hours' checkbox ticked
		AutoUpdateEveryDay - 0 or 1 (tickbox to activate ‘Every Day at…’ 
		AutoUpdateTime - 24 hr clock, with no seconds, which is active when ‘Every Day at…' is selected (ie. hh:mm)
		SynchroniseUpdate - checkbox to Synchronise update with logging
	*/

	useEffect(() => {
		const loadSiteSettings = async () => {
			const siteSettings = await apiSiteSettingsList();

			setAutoUpdateData(siteSettingsGetBooleanValue(siteSettings, "AutoUpdateData", false));
			setEveryX(siteSettingsGetBooleanValue(siteSettings, "AutoUpdateEveryPeriod", true));
			setAutoUpdateNumber(siteSettingsGetStringValue(siteSettings, "AutoUpdateNumber", "30"));
			setAutoUpdatePeriod(siteSettingsGetStringValue(siteSettings, "AutoUpdatePeriod", "1"));
			setAutoUpdateTime(new Date(`2022-01-01 ${siteSettingsGetStringValue(siteSettings, "AutoUpdateTime", "00:05")}`));
			setSynchroniseUpdate(siteSettingsGetBooleanValue(siteSettings, "SynchroniseUpdate", false));

			setLoading(false);
		};

		loadSiteSettings();
	}, []);

	const save = async () => {
		const settings = [{ settingKey: "AutoUpdateData", settingValue: autoUpdateData ? "1" : "0" }, { settingKey: "AutoUpdateEveryPeriod", settingValue: everyX ? "1" : "0" }, { settingKey: "AutoUpdateEveryDay", settingValue: everyX ? "0" : "1" }, { settingKey: "AutoUpdateNumber", settingValue: autoUpdateNumber }, { settingKey: "AutoUpdatePeriod", settingValue: autoUpdatePeriod }, { settingKey: "AutoUpdateTime", settingValue: `${("0" + autoUpdateTime.getHours()).slice(-2)}:${("0" + autoUpdateTime.getMinutes()).slice(-2)}` }, { settingKey: "SynchroniseUpdate", settingValue: synchroniseUpdate ? "1" : "0" }];
		await apiSiteSettingsSave(settings);
		toast.success(local.TS_Update_Successful);
	};

	const rowClasses = "mt-3 px-3 px-md-4";
	const rowClassesSub = "px-3 px-md-4";

	if (loading) {
		return null;
	}

	return (
		<TabPane tabId="1" className="py-3">
			<Fragment>
				<Form>
					<Row className={rowClasses}>
						<Input className="form-check-input" type="checkbox" checked={autoUpdateData} id="auto-update-toggle" onChange={(e) => setAutoUpdateData(e.target.checked)} disabled={isDisabled} />
						<Label className="form-check-label" for="auto-update-toggle">
							{local.TF_Automatically_update_data}
						</Label>
					</Row>
					<Row className={rowClasses}>
						<Col className="col-md-2 ml-3">
							<Input className="form-check-input mt-2" type="radio" name="everySetTime" id="everySetTime" checked={everyX} disabled={isDisabled || !autoUpdateData} onChange={() => setEveryX(true)} />
							<Label className="form-check-label mt-2" for="everySetTime">
								{local.TS_Every}
							</Label>
						</Col>
						<Col className="col-md-3">
							<Input type="number" className="form-control" min={0} max={999} value={autoUpdateNumber} step={1} disabled={isDisabled || !autoUpdateData || !everyX} onChange={(e) => setAutoUpdateNumber(e.target.value)} />
						</Col>
						<Col className="col-md-5">
							<Input type="select" className="form-control" value={autoUpdatePeriod} disabled={isDisabled || !autoUpdateData || !everyX} onChange={(e) => setAutoUpdatePeriod(e.target.value)}>
								<option value="1">{local.TS_Minutes}</option>
								<option value="2">{local.TS_Hours}</option>
							</Input>
						</Col>
					</Row>
					<Row className={rowClasses}>
						<Col className="col-md-2 ml-3">
							<Input className="form-check-input mt-2" type="radio" name="everySetTime" id="everyDayAt" checked={!everyX} disabled={isDisabled || !autoUpdateData} onChange={() => setEveryX(false)} />
							<Label className="form-check-label mt-2" for="everyDayAt">
								{local.TF_Every_day_at}
							</Label>
						</Col>
						<Col className="col-md-5">
							<DatePicker selected={autoUpdateTime} onChange={(date) => setAutoUpdateTime(date)} showTimeSelect showTimeSelectOnly dateFormat="HH:mm" timeFormat="HH:mm" timeIntervals={15} timeCaption={local.TS_Time} disabled={isDisabled || !autoUpdateData || everyX} />
						</Col>
					</Row>
					<Row className={rowClasses}>
						<Input className="form-check-input" type="checkbox" checked={synchroniseUpdate} id="sync-update" disabled={isDisabled || !autoUpdateData} onChange={(e) => setSynchroniseUpdate(e.target.checked)} />
						<Label className="form-check-label" for="sync-update">
							{local.TF_Synchronise_update_with_logging}
						</Label>
					</Row>
					<Row className={rowClassesSub}>
						<small id="sync-help" className="form-text text-muted">
							{local.TF_Data_downloaded_after_logged + "."}
						</small>
					</Row>
					{!isDisabled && (
						<Row className="mt-3">
							<Button color="primary" type="button" onClick={() => save()}>
								{local.TS_Save}
							</Button>
						</Row>
					)}
				</Form>
			</Fragment>
		</TabPane>
	);
};

export default AutomaticUpdates;

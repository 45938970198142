import { AvField, AvForm } from "availity-reactstrap-validation";
import React from "react";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import local from "../../localization/strings";

const AlarmEdit = ({ data, onCancel, onSave, onReset }) => {
	async function save(_e, values) {
		await onSave({
			id: data.id, //
			warnAlarmLo: parseFloat(values.warnAlarmLo),
			warnAlarmHi: parseFloat(values.warnAlarmHi),
			mainAlarmLo: parseFloat(values.mainAlarmLo),
			mainAlarmHi: parseFloat(values.mainAlarmHi),
		});
	}

	return (
		<Modal isOpen={!!data} toggle={onCancel} centered backdrop="static">
			<ModalHeader toggle={onCancel}>{local.TF_Add_Temporary_Limits}</ModalHeader>
			<ModalBody>
				<AvForm model={data} onValidSubmit={async (e, values) => await save(e, values)}>
					<Row>
						<Col xs={12} className="text-center">
							<h5>{data?.buildingName}</h5>
						</Col>
					</Row>
					<Row>
						<Col xs={12} className="text-center">
							<h5>{data?.zoneName}</h5>
						</Col>
					</Row>
					<Row>
						<Col xs={12} className="text-center">
							<h5>{data?.groupName}</h5>
						</Col>
					</Row>
					<Row>
						<Col xs={12} className="mb-2 text-center">
							<h5>{data?.parameterName}</h5>
						</Col>
					</Row>
					<Row>
						<Col md={6}>
							<div className="border border-warning py-0 px-2">
								<h6 className="bg-warning p-2 mx-n2 text-white">{local.TF_Threshold_Levels}</h6>
								<AvField name="warnAlarmLo" type="number" label={local.TS_Min} />
								<AvField name="warnAlarmHi" type="number" label={local.TS_Max} />
							</div>
						</Col>
						<Col md={6}>
							<div className="border border-danger py-0 px-2">
								<h6 className="bg-danger p-2 mx-n2 text-white">{local.TF_Alarm_Levels}</h6>
								<AvField name="mainAlarmLo" type="number" label={local.TS_Min} />
								<AvField name="mainAlarmHi" type="number" label={local.TS_Max} />
							</div>
						</Col>
					</Row>

					<Row>
						<Col>
							<Button color="primary" className="mt-2 float-left">
								{local.TS_Save}
							</Button>
							<Button color="secondary" className="mt-2 ml-2 float-left" type="button" onClick={onCancel}>
								{local.TS_Cancel}
							</Button>
							<Button color="secondary" className="mt-2 ml-2 float-right" type="button" onClick={() => onReset(data.id)}>
								{local.TF_Use_Defaults}
							</Button>
						</Col>
					</Row>
				</AvForm>
			</ModalBody>
		</Modal>
	);
};

export default AlarmEdit;

import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import local from "../../localization/strings";

const SwitchToDropdown = (props) => {
	const [open, setOpen] = useState(false);

	const parts = props.data.split("|");
	const current = parts[0];

	let period = "";
	if (current === "DashChart" || current === "DashChartBlue") {
		if (parts.length > 2) {
			period = parts[2] || "1Day";
		} else {
			period = "1Day";
		}
	}

	return (
		<div className="dropdown-container">
			<div id={`${props.idx}_dropdown`} className={open ? "float-on-top" : "edit-dropdown"}>
				<Dropdown isOpen={open} toggle={() => setOpen(!open)}>
					<DropdownToggle tag="button" type="button" className="btn btn-outline fs-2 button-title">
						⋮
					</DropdownToggle>
					<DropdownMenu right className="menu-border">
						<DropdownItem header>{local.TF_Change_widget_type}:</DropdownItem>
						{(current !== "DashChart" || (current === "DashChart" && period !== "1Day")) && (
							<DropdownItem id={`switchColour_${props.id}`} onClick={() => props.onSwitchTo(props.idx, "DashChart", "1Day")}>
								{local.TF_Chart} - {local.TF_1Day}
							</DropdownItem>
						)}
						{(current !== "DashChart" || (current === "DashChart" && period !== "2Day")) && (
							<DropdownItem id={`switchColour_${props.id}`} onClick={() => props.onSwitchTo(props.idx, "DashChart", "2Day")}>
								{local.TF_Chart} - {local.TF_2Day}
							</DropdownItem>
						)}
						{(current !== "DashChart" || (current === "DashChart" && period !== "3Day")) && (
							<DropdownItem id={`switchColour_${props.id}`} onClick={() => props.onSwitchTo(props.idx, "DashChart", "3Day")}>
								{local.TF_Chart} - {local.TF_3Day}
							</DropdownItem>
						)}
						{(current !== "DashChart" || (current === "DashChart" && period !== "4Day")) && (
							<DropdownItem id={`switchColour_${props.id}`} onClick={() => props.onSwitchTo(props.idx, "DashChart", "4Day")}>
								{local.TF_Chart} - {local.TF_4Day}
							</DropdownItem>
						)}
						{(current !== "DashChart" || (current === "DashChart" && period !== "5Day")) && (
							<DropdownItem id={`switchColour_${props.id}`} onClick={() => props.onSwitchTo(props.idx, "DashChart", "5Day")}>
								{local.TF_Chart} - {local.TF_5Day}
							</DropdownItem>
						)}
						{(current !== "DashChart" || (current === "DashChart" && period !== "6Day")) && (
							<DropdownItem id={`switchColour_${props.id}`} onClick={() => props.onSwitchTo(props.idx, "DashChart", "6Day")}>
								{local.TF_Chart} - {local.TF_6Day}
							</DropdownItem>
						)}
						{(current !== "DashChart" || (current === "DashChart" && period !== "7Day")) && (
							<DropdownItem id={`switchColour_${props.id}`} onClick={() => props.onSwitchTo(props.idx, "DashChart", "7Day")}>
								{local.TF_Chart} - {local.TF_7Day}
							</DropdownItem>
						)}

						{(current !== "DashChartBlue" || (current === "DashChartBlue" && period !== "1Day")) && (
							<DropdownItem id={`switchColour_${props.id}`} onClick={() => props.onSwitchTo(props.idx, "DashChartBlue", "1Day")}>
								{local.TF_Chart} {local.TS_Blue_Brackets} - {local.TF_1Day}
							</DropdownItem>
						)}
						{(current !== "DashChartBlue" || (current === "DashChartBlue" && period !== "2Day")) && (
							<DropdownItem id={`switchColour_${props.id}`} onClick={() => props.onSwitchTo(props.idx, "DashChartBlue", "2Day")}>
								{local.TF_Chart} {local.TS_Blue_Brackets} - {local.TF_2Day}
							</DropdownItem>
						)}
						{(current !== "DashChartBlue" || (current === "DashChartBlue" && period !== "3Day")) && (
							<DropdownItem id={`switchColour_${props.id}`} onClick={() => props.onSwitchTo(props.idx, "DashChartBlue", "3Day")}>
								{local.TF_Chart} {local.TS_Blue_Brackets} - {local.TF_3Day}
							</DropdownItem>
						)}
						{(current !== "DashChartBlue" || (current === "DashChartBlue" && period !== "4Day")) && (
							<DropdownItem id={`switchColour_${props.id}`} onClick={() => props.onSwitchTo(props.idx, "DashChartBlue", "4Day")}>
								{local.TF_Chart} {local.TS_Blue_Brackets} - {local.TF_4Day}
							</DropdownItem>
						)}
						{(current !== "DashChartBlue" || (current === "DashChartBlue" && period !== "5Day")) && (
							<DropdownItem id={`switchColour_${props.id}`} onClick={() => props.onSwitchTo(props.idx, "DashChartBlue", "5Day")}>
								{local.TF_Chart} {local.TS_Blue_Brackets} - {local.TF_5Day}
							</DropdownItem>
						)}
						{(current !== "DashChartBlue" || (current === "DashChartBlue" && period !== "6Day")) && (
							<DropdownItem id={`switchColour_${props.id}`} onClick={() => props.onSwitchTo(props.idx, "DashChartBlue", "6Day")}>
								{local.TF_Chart} {local.TS_Blue_Brackets} - {local.TF_6Day}
							</DropdownItem>
						)}
						{(current !== "DashChartBlue" || (current === "DashChartBlue" && period !== "7Day")) && (
							<DropdownItem id={`switchColour_${props.id}`} onClick={() => props.onSwitchTo(props.idx, "DashChartBlue", "7Day")}>
								{local.TF_Chart} {local.TS_Blue_Brackets} - {local.TF_7Day}
							</DropdownItem>
						)}

						{current !== "DashMeter" && (
							<DropdownItem id={`switchColour_${props.id}`} onClick={() => props.onSwitchTo(props.idx, "DashMeter")}>
								{local.TF_Meter_Gauge}
							</DropdownItem>
						)}
						{current !== "DashMeterBlue" && (
							<DropdownItem id={`switchColour_${props.id}`} onClick={() => props.onSwitchTo(props.idx, "DashMeterBlue")}>
								{local.TF_Meter_Gauge} {local.TS_Blue_Brackets}
							</DropdownItem>
						)}
						{current !== "DashMeterBlock" && (
							<DropdownItem id={`switchColour_${props.id}`} onClick={() => props.onSwitchTo(props.idx, "DashMeterBlock")}>
								{local.TF_Meter_Value}
							</DropdownItem>
						)}
						{current !== "DashMeterBlockBlue" && (
							<DropdownItem id={`switchColour_${props.id}`} onClick={() => props.onSwitchTo(props.idx, "DashMeterBlockBlue")}>
								{local.TF_Meter_Value} {local.TS_Blue_Brackets}
							</DropdownItem>
						)}
					</DropdownMenu>
				</Dropdown>
			</div>
		</div>
	);
};

export default SwitchToDropdown;

import React, { useState, useEffect } from "react";
import { CardHeader, CardBody, Row, Col, Button, Form, Label, Input, Card } from "reactstrap";
import ProfileDetailsDisplay from "./ProfileDetailsDisplay";
import ProfileDetailsEdit from "./ProfileDetailsEdit";
import { CurrentUserDisplayName, CurrentUserRole, CurrentUserEmailAddress } from "../../api/auth";
import { isFeatureEnabled, features } from "../../config";
import { changeLanguage, availableLanguages } from "../../localization/LanguageList";
import local from "../../localization/strings";
import ChangePassword from "./ChangePassword";

const ProfileDetails = () => {
	const [profileEdit, setProfileEdit] = useState(false);
	const [changePassword, setChangePassword] = useState(false);
	const [displayName] = useState(CurrentUserDisplayName());
	const [role] = useState(CurrentUserRole());
	const [userEmail] = useState(CurrentUserEmailAddress());
	const [language, setLanguage] = useState("");
	const languageOptions = availableLanguages();

	useEffect(() => {
		const userLanguage = localStorage.getItem("UserLanguage") || "";
		setLanguage(userLanguage);
	}, []);

	const triggerChangePassword = () => {
		setChangePassword(true);
	};

	const triggerChangedPassword = () => {
		setChangePassword(false);
	};

	const triggerProfileEdit = () => {
		setProfileEdit(!profileEdit);
	};

	const triggerProfileSave = () => {
		setProfileEdit(!profileEdit);
	};

	return (
		<>
			<Card className="mb-3">
				{
					profileEdit 
					? 
					isFeatureEnabled(features.EditUserProfile) && <ProfileDetailsEdit name={displayName} email={userEmail} triggerProfileSave={triggerProfileSave} /> 
					:
					changePassword
					?
					<ChangePassword triggerChangedPassword={triggerChangedPassword} />
					: 
					<ProfileDetailsDisplay name={displayName} userType={role} triggerChangePassword={triggerChangePassword} triggerProfileEdit={triggerProfileEdit} />
				}
			</Card>
			{isFeatureEnabled(features.Localisation) && (
				<Card>
					<CardHeader>
						<Row className="align-items-center">
							<Col>
								<h5 className="mb-0">{local.TF_Language}</h5>
							</Col>
						</Row>
					</CardHeader>
					<CardBody className="bg-light border-top">
						<Form>
							<Row className="px-3">
								<Label for="user-language">{local.TF_DC_Language}</Label>
								<Input type="select" id="user-language" className="form-control" value={language} onChange={(e) => setLanguage(e.target.value)}>
									{languageOptions.map((lang, i) => {
										return (
											<option key={i} value={lang[1]}>
												{lang[0]}
											</option>
										);
									})}
								</Input>
							</Row>
						</Form>
						<Row className="mt-3 px-3">
							<Button color="primary" onClick={() => changeLanguage(language, "General Settings")}>
								{local.TS_Save}
							</Button>
						</Row>
					</CardBody>
				</Card>
			)}
		</>
	);
};

export default ProfileDetails;

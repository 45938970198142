import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { Collapse, Nav, NavItem } from "reactstrap";
import { NavLink, withRouter } from "react-router-dom";
import NavbarVerticalMenuItem from "./NavbarVerticalMenuItem";
import { isFeatureEnabled } from "../../config";
import { CurrentUserHasRole } from "../../api/auth";

const NavbarVerticalMenu = ({ routes, location, handleNavbarVerticalCollapse }) => {
	const [opened, setOpened] = useState(null);
	useEffect(() => {
		let openedDropdown = null;
		routes.forEach((route, index) => {
			if (location.pathname.indexOf(route.to) === 0) openedDropdown = index;
		});

		setOpened(openedDropdown);
		// eslint-disable-next-line
	}, []);

	const toggleOpened = (e, index) => {
		e.preventDefault();
		setOpened(opened === index ? null : index);
	};

	return routes.map((route, index) => {
		if (!route.children) {
			if (route.feature && !isFeatureEnabled(route.feature)) {
				return null;
			}
			if (route.role && !CurrentUserHasRole(route.role)){
				return null;
			}

			return (
				<Fragment key={index}>
					<NavItem>
						<NavLink className="nav-link" {...route} onClick={handleNavbarVerticalCollapse}>
							<NavbarVerticalMenuItem route={route} />
						</NavLink>
					</NavItem>
					{route.divideafter === "true" && <div className="dropdown-divider border-300" />}
				</Fragment>
			);
		}

		return (
			<Fragment key={index}>
				<NavItem>
					<NavLink to="#!" onClick={(e) => toggleOpened(e, index)} className="nav-link dropdown-indicator" aria-expanded={opened === index}>
						<NavbarVerticalMenuItem route={route} />
					</NavLink>
					<Collapse isOpen={opened === index}>
						<Nav>
							<NavbarVerticalMenu routes={route.children} location={location} handleNavbarVerticalCollapse={handleNavbarVerticalCollapse} />
						</Nav>
					</Collapse>
				</NavItem>
				{route.divideafter === "true" && <div className="dropdown-divider border-300" />}
			</Fragment>
		);
	});
};

NavbarVerticalMenu.propTypes = {
	routes: PropTypes.array.isRequired,
	location: PropTypes.object.isRequired,
};

export default withRouter(NavbarVerticalMenu);

import React, { useState, useEffect, useContext } from "react";
import { Button, Row, Col, CustomInput } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import local from "../../localization/strings";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { siteGet, siteGraphic, siteGraphicUpload, siteNameSet } from "../../api/site";
import { UserPreferencesContext } from "../../context/userPreferences";
import { toast } from "react-toastify";

const TabSiteDetails = ({ activeTab }) => {
	const [siteData, setSiteData] = useState(null);
	const [siteImageOpen, setSiteImageOpen] = useState(false);
	const [image, setImage] = useState([""]);
	const [uploaded, setUploaded] = useState(false);
	const [siteImage, setSiteImage] = useState(null);
	const { LoadSiteData } = useContext(UserPreferencesContext);

	useEffect(() => {
		const LoadData = async () => {
			setSiteData(await siteGet());

			const base64 = await siteGraphic();
			if (base64) {
				setSiteImage(`data:image/png;base64,${base64}`);
			}
		};

		if (activeTab === 1) {
			LoadData();
		} else {
			setSiteData(undefined);
			setImage(undefined);
			setSiteImage(undefined);
		}
	}, [activeTab]);

	const toBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	async function save(_e, values) {
		await siteNameSet(values.displayName);

		if (uploaded) {
			const base64WithHeader = await toBase64(image);
			const base64Loc = base64WithHeader.indexOf("base64,");

			await siteGraphicUpload(base64WithHeader.substring(base64Loc + 7));
		}

		await LoadSiteData();

		toast.success(local.TF_Site_Updated);
	}

	const handleNewImage = (e) => {
		setImage(e.target.files[0]);
		setSiteImage(URL.createObjectURL(e.target.files[0]));
		setUploaded(true);
	};

	return (
		<>
			{siteData && (
				<AvForm model={siteData} onValidSubmit={async (e, values) => await save(e, values)}>
					<AvField name="displayName" label={local.TF_Site_Name} validate={{ required: { value: true, errorMessage: local.TS_Required }, maxLength: { value: 30 } }} />
					<Row>
						<Col>
							<label>{local.TF_Site_Image}</label>
						</Col>
					</Row>
					{siteImage && (
						<>
							<Row>
								<Col>
									<Button onClick={() => setSiteImageOpen(true)} className="site-image-link">
										<img src={siteImage} className="rounded-soft" alt="Site" />
									</Button>
									{siteImageOpen && <Lightbox mainSrc={siteImage} onCloseRequest={() => setSiteImageOpen(false)} />}
								</Col>
							</Row>
							<Row>
								<Col>
									<small className="ml-3">{local.TF_Click_image_to_change}</small>
								</Col>
							</Row>
						</>
					)}
					<div className="site-image-link">
						<CustomInput type="file" id="newImage" name="newImage" label={local.TF_Change_Image} onChange={(e) => handleNewImage(e)} />
					</div>
					<hr />
					<Row>
						<Col>
							<Button color="primary" className="mt-3">
								{local.TS_Save_Changes}
							</Button>
						</Col>
					</Row>
				</AvForm>
			)}
		</>
	);
};

export default TabSiteDetails;

import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import local from "../../localization/strings";

const SwitchWidthDropdown = (props) => {
	const [open, setOpen] = useState(false);

	return (
		<div className="dropdown-container">
			<div id={`${props.idx}_dropdown`} className={open ? "float-on-top" : "edit-dropdown"}>
				<Dropdown isOpen={open} toggle={() => setOpen(!open)}>
					<DropdownToggle tag="button" type="button" className="btn btn-outline fs-2 button-title">
						⋮
					</DropdownToggle>
					<DropdownMenu right className="menu-border">
						<DropdownItem header>{local.TF_Change_widget_width}:</DropdownItem>
						<DropdownItem id={`switchColour_${props.id}`} onClick={() => props.onSwitchWidth(props.idx, "3")}>
							{local.TS_WidthOneQuarter}
						</DropdownItem>
						<DropdownItem id={`switchColour_${props.id}`} onClick={() => props.onSwitchWidth(props.idx, "4")}>
							{local.TS_WidthOneThird}
						</DropdownItem>
						<DropdownItem id={`switchColour_${props.id}`} onClick={() => props.onSwitchWidth(props.idx, "6")}>
							{local.TS_WidthHalf}
						</DropdownItem>
						<DropdownItem id={`switchColour_${props.id}`} onClick={() => props.onSwitchWidth(props.idx, "8")}>
							{local.TS_WidthTwoThirds}
						</DropdownItem>
						<DropdownItem id={`switchColour_${props.id}`} onClick={() => props.onSwitchWidth(props.idx, "9")}>
							{local.TS_WidthThreeQuarters}
						</DropdownItem>
						<DropdownItem id={`switchColour_${props.id}`} onClick={() => props.onSwitchWidth(props.idx, "12")}>
							{local.TS_WidthFull}
						</DropdownItem>
					</DropdownMenu>
				</Dropdown>
			</div>
		</div>
	);
};

export default SwitchWidthDropdown;

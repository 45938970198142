import axios from "axios";
import { CurrentSiteIdGet } from "./userSettings";

export const groupsGetListForSite = async (siteId, zoneId) => {
	try {
		const result = await axios({
			method: "get",
			url: "v2/Groups",
			params: {
				SiteId: siteId,
				ZoneId: zoneId,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const groupsGetList = async (zoneId) => {
	const siteId = CurrentSiteIdGet();

	return await groupsGetListForSite(siteId, zoneId);
};

export const apiGroupGet = async (zoneId, id) => {
	const groups = await groupsGetList(zoneId);

	return groups.find((x) => x.id === id);
};

export const apiGroupSave = async (id, zoneId, name, index) => {
	if (!id) {
		id = -1;
	}

	return await groupUpdate(id, zoneId, index, name);
};

export const groupUpdate = async (id, zoneId, index, name) => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "post",
			url: "v2/Group",
			data: {
				siteId,
				group: {
					siteId,
					id,
					zoneId,
					index,
					name,
				},
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const apiGroupDelete = async (groupId) => {
	const siteId = CurrentSiteIdGet();

	try {
		await axios({
			method: "delete",
			url: "v2/Group",
			data: { siteId, groupId },
		});
	} catch (err) {
		console.error(err);
	}
};

import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Card, CardBody, CardFooter, CardHeader, Col } from "reactstrap";
import { readingsLatestForLocation } from "../../../../api/readings";
import local from "../../../../localization/strings";
import Loader from "../../../common/Loader";
import WidgetDropdownMenu from "../../../common/WidgetDropdownMenu";
import DateTimeFormat from "./DateTimeFormat";
import { WidgetTitleLevel1, WidgetTitleLevel2 } from "../../../common/WidgetTitles";

const dividerBorder = "1px solid rgba(0, 0, 0, 0.15)";

const DashMeterBlock = ({ locationId, latestReadings }) => {
	const [loading, setLoading] = useState(true);
	const [reading, setReading] = useState({});
	const [bgColour, setBgColour] = useState("");

	useEffect(() => {
		let isSubscribed = true;
		async function fetchMeterData() {
			const data = await readingsLatestForLocation(locationId);

			if (isSubscribed) {
				if (data.length === 0) {
					setReading(undefined);
				} else {
					setReading(...data);
				}

				setLoading(false);
			}
		}

		const latestReading = (latestReadings || []).find((x) => x.locationId === parseInt(locationId));

		if (latestReading) {
			setReading(latestReading);
			setLoading(false);
		} else {
			fetchMeterData();
		}

		if (typeof latestReading?.logging === "boolean" && !latestReading?.logging) {
			setBgColour("");
		} else {
			switch (latestReading?.alarmType || "") {
				case "MainLo":
				case "MainHi":
					setBgColour(latestReading?.clearedBy ? "widgetInAlarmCleared" : "widgetInAlarm");
					break;
				default:
					setBgColour("");
					break;
			}
		}

		return () => (isSubscribed = false);
	}, [locationId, latestReadings]);

	return (
		<Col className={`col-12 col-sm-6 col-lg-3 mb-2 float-left`}>
			<Card className={`h-100 dash-border-grey dash-text ${bgColour}`}>
				{loading ? (
					<div className="ml-2">
						<Loader />
					</div>
				) : (
					<>
						{reading ? (
							<>
								<WidgetDropdownMenu color="secondary" size="sm" tag="div" options={[{ name: local.TF_View_Chart, locationId: reading.locationId, groupId: reading.groupId }]} />
								<CardHeader className="bg-transparent pb-0">
									<h6 className="dash-block-header">
										<WidgetTitleLevel1 reading={reading} />
									</h6>
								</CardHeader>
								<CardBody className="fs--1 pt-0">
									{typeof reading.logging === "boolean" && !reading.logging ? (
										<span className="display-4 font-weight-normal" style={{ letterSpacing: "-0.05em" }}>
											{local.TF_OFF}
										</span>
									) : (
										<>
											{reading.realValue || reading.realValue === 0 ? (
												<span className="display-3 font-weight-normal">{reading.realValue.toFixed(reading.decimalPlaces)}</span>
											) : (
												<span className="display-4 font-weight-normal" style={{ letterSpacing: "-0.05em" }}>
													{local.TS_No_Data}
												</span>
											)}
										</>
									)}
									<span className="font-weight-normal">{reading.units}</span>
									<div className="font-weight-normal">{reading.parameterName}</div>
								</CardBody>
								<CardFooter className="text-right bg-transparent" style={{ borderTop: dividerBorder }}>
									<WidgetTitleLevel2 reading={reading} />
									<p className="mt-2 mb-n2 small font-weight-bold">
										{DateTimeFormat(reading.dateTime)}
										{reading.outOfDateReading && <FontAwesomeIcon className="danger-icon fs-1 ml-1" size="lg" icon={faExclamationCircle} title="OUT OF DATE" />}
									</p>
								</CardFooter>
							</>
						) : (
							<div>Failed To Load</div>
						)}
					</>
				)}
			</Card>
		</Col>
	);
};

export default DashMeterBlock;

import React from "react";
import { Link } from "react-router-dom";
import local from "../../../localization/strings";

const SearchResultEmailReport = ({ item, close }) => {
	return (
		<div className="p-2">
			<Link to="/reports/email_reports" className="text-nowrap" onClick={close}>
				<b>{local.TF_Search_EmailReport}:</b> {item.entityName}
			</Link>
		</div>
	);
};

export default SearchResultEmailReport;

import React, { createContext, useState, useEffect, useCallback } from "react";
import { userPreferencesGetList, userPreferenceSave } from "../../api/userPreferences";
import { apiSiteFeaturesList, siteGet, siteMapFlat } from "../../api/site";
import { environments, getEnvironment } from "../../config";
import { apiReleaseNotesGetList } from "../../api/apiReleaseNotes";

export const Context = createContext({});

export const Provider = ({ children }) => {
	const [prefsLoaded, setPrefsLoaded] = useState(false);
	const [siteFeaturesLoaded, setSiteFeaturesLoaded] = useState(false);
	const [preferences, setPreferences] = useState([]);
	const [prefsObj, setPrefsObj] = useState({});
	const [dashboardLayout, setDashboardLayout] = useState([]);
	const [dashboardLayoutLoaded, setDashboardLayoutLoaded] = useState(false);
	const [siteId, setSiteId] = useState(null);
	const [siteName, setSiteName] = useState("");
	const [siteDefaultLocale, setSiteDefaultLocale] = useState("");
	const [siteProductType, setSiteProductType] = useState("Lite");
	const [siteMap, setSiteMap] = useState([]);
	const [prefMeterLocationIds, setPrefMeterLocationIds] = useState("");
	const [prefMeterSort, setPrefMeterSort] = useState("");
	const [prefMeterType, setPrefMeterType] = useState("");
	const [prefTooltip, setPrefTooltip] = useState("true");
	const [newReleaseNotes, setNewReleaseNotes] = useState(false);
	const [releaseNotesChecked, setReleaseNotesChecked] = useState(false);

	const LoadSiteData = useCallback(async () => {
		var data = await siteGet();

		setSiteId(data.id);
		setSiteName(data.displayName);
		setSiteDefaultLocale(data.defaultLocale);
		setSiteProductType(data.productType);

		var map = await siteMapFlat();
		setSiteMap(map);

		const siteFeatures = await apiSiteFeaturesList();
		localStorage.setItem("SiteFeatures", JSON.stringify(siteFeatures));
		setSiteFeaturesLoaded(true);
	}, []);

	const LoadData = useCallback(async () => {
		var result = await userPreferencesGetList();

		const pref = {};
		result.forEach((x) => {
			pref[x.preferenceKey.replace("_", "")] = x.preferenceValue;
		});
		if (getEnvironment() === environments.Localhost) {
			console.table(pref);
		}
		setPrefsObj(pref);
		setPreferences(result);

		if (siteId) {
			if (!releaseNotesChecked) {
				const releaseNotes = await apiReleaseNotesGetList(siteId);
				if (releaseNotes.length > 0 && (pref?.LastReleaseNote || "") < releaseNotes.reduce((prev, current) => (prev.date > current.date ? prev : current), "").date) {
					setNewReleaseNotes(true);
				} else {
					setNewReleaseNotes(false);
				}
				setReleaseNotesChecked(true);
			}
		}

		const dashboardLayoutPref = result.filter((x) => x.preferenceKey === "Dashboard");
		const dashboardLayoutPrefDefault = result.filter((x) => x.preferenceKey === "DashboardDefault");
		if (dashboardLayoutPref && dashboardLayoutPref.length > 0 && !!dashboardLayoutPref[0].preferenceValue && dashboardLayoutPref[0].preferenceValue !== "[]") {
			setDashboardLayout(JSON.parse(dashboardLayoutPref[0].preferenceValue));
		} else if (dashboardLayoutPrefDefault && dashboardLayoutPrefDefault.length > 0) {
			setDashboardLayout(JSON.parse(dashboardLayoutPrefDefault[0].preferenceValue));
		}
		setDashboardLayoutLoaded(true);

		setPrefMeterLocationIds(getValue(result, "Meter_LocationIds", ""));
		setPrefMeterSort(getValue(result, "Meter_Sort", "groupName"));
		setPrefMeterType(getValue(result, "Meter_Type", "Gauge"));
		setPrefTooltip(getValue(result, "Chart_Tooltip", "true"));

		setPrefsLoaded(true);
	}, [siteId, releaseNotesChecked]);

	function getValue(prefs, key, defaultValue) {
		const found = prefs.find((x) => x.preferenceKey === key);

		if (found) {
			return found.preferenceValue;
		}
		return defaultValue;
	}

	const saveTierPreference = async (tier, zoneId, groupId, chartIndex, key, value, reload) => {
		let currentObject = JSON.parse(prefsObj[key.replace("_", "")] || "{}");

		switch (tier) {
			case "Site":
				currentObject = { S: value };
				break;

			case "Zone":
				const zoneKey = `S/${zoneId}`;
				Object.keys(currentObject).forEach((k) => {
					if (k.startsWith(zoneKey)) {
						delete currentObject[k];
					}
				});

				currentObject[zoneKey] = value;
				break;

			case "Chart":
				if (!value) {
					delete currentObject[`S/${zoneId}/${groupId}/${chartIndex}`];
				} else {
					currentObject[`S/${zoneId}/${groupId}/${chartIndex}`] = value;
				}
				break;
			default:
				return;
		}

		const newValue = JSON.stringify(currentObject);

		prefsObj[key.replace("_", "")] = newValue;
		setPrefsObj(prefsObj);
		if (newValue === "{}") {
			await userPreferenceSave(key, "");
		} else {
			await userPreferenceSave(key, newValue);
		}

		if (reload === undefined || reload) {
			LoadData();
		}
	};

	const clearPreference = async (key, reload) => {
		if (prefsObj[key.replace("_", "")]) {
			prefsObj[key.replace("_", "")] = "";
			setPrefsObj(prefsObj);
			await userPreferenceSave(key, "");

			if (reload === undefined || reload) {
				LoadData();
			}
		}
	};

	const savePreference = async (key, value, reload) => {
		prefsObj[key.replace("_", "")] = value;
		setPrefsObj(prefsObj);
		await userPreferenceSave(key, value);

		if (reload === undefined || reload) {
			LoadData();
		}
	};

	const savePreferenceCallback = useCallback(
		async (key, value, reload) => {
			await userPreferenceSave(key, value);

			if (reload) {
				LoadData();
			} else {
				//prefsObj[key.replace("_", "")] = value;
				setPrefsObj((x) => (x[key.replace("_", "")] = value));
			}
		},
		[LoadData],
	);

	//Allowed
	// - 32 Charts/Zone Graphics
	// - 128 Meters
	// - Any Battery Table/Doughnut
	const allowAddWidgets = (layout, addingType, addingCount) => {
		let countChartOrGraphics = 0;
		let countMeters = 0;

		layout.forEach((item) => {
			const parts = item.split("|");

			switch (parts[0]) {
				case "DashChart":
				case "DashChartBlue":
				case "DashZoneGraphic":
				case "DashZoneGraphicWide":
					++countChartOrGraphics;
					break;
				case "DashMeter":
				case "DashMeterBlue":
				case "DashMeterBlock":
				case "DashMeterBlockBlue":
					++countMeters;
					break;
				default:
					break;
			}
		});

		if (addingType) {
			switch (addingType) {
				case "DashChart":
				case "DashChartBlue":
				case "DashZoneGraphic":
				case "DashZoneGraphicWide":
					countChartOrGraphics += addingCount;
					break;
				case "DashMeter":
				case "DashMeterBlue":
				case "DashMeterBlock":
				case "DashMeterBlockBlue":
					countMeters += addingCount;
					break;
				default:
					break;
			}
		}

		if (getEnvironment() === environments.Localhost) {
			console.log(`Dashboard Widget Count (Charts/Meters): ${countChartOrGraphics}/${countMeters}`);
		}

		if (countChartOrGraphics > 32 || countMeters > 128) {
			return false;
		}

		return true;
	};

	const pinToDashboard = async (type, data, zoneName, buildingName) => {
		if (!allowAddWidgets(dashboardLayout, type, 1)) {
			return false;
		}

		dashboardLayout.push(`${type}|${data || ""}|${zoneName || ""}|${buildingName || ""}`);

		await savePreference("Dashboard", JSON.stringify(dashboardLayout), false);

		return true;
	};

	const pinLocationWidgetsToDashboard = async (type, locationIds) => {
		if (type === "MultiChart") {
			if (!allowAddWidgets(dashboardLayout, type, 1)) {
				return false;
			}

			dashboardLayout.push(`${type}|${(locationIds || []).slice(0, 20).join(",")}`);

			await savePreference("Dashboard", JSON.stringify(dashboardLayout), false);
		} else {
			if (!allowAddWidgets(dashboardLayout, type, locationIds.length)) {
				return false;
			}

			locationIds.forEach((id) => dashboardLayout.push(`${type}|${id || ""}`));

			await savePreference("Dashboard", JSON.stringify(dashboardLayout), false);
		}

		return true;
	};

	const pinZoneWidgetsToDashboard = async (type, zoneIds) => {
		if (!allowAddWidgets(dashboardLayout, type, zoneIds.length)) {
			return false;
		}

		zoneIds.forEach((id) => {
			if (id && id.length > 0) {
				dashboardLayout.push(`${type}|${id.substring(2)}`);
			}
		});

		await savePreference("Dashboard", JSON.stringify(dashboardLayout), false);

		return true;
	};

	const sortDashboard = async (sorted) => {
		var newLayout = sorted.map((e) => e.data);

		setDashboardLayout(newLayout);

		await savePreference("Dashboard", JSON.stringify(newLayout), true);
	};

	const getLocationName = (locationId) => {
		if (locationId) {
			const item = siteMap.find((x) => x.locationId.toString() === locationId);
			if (item) {
				return `${item.buildingName} - ${item.zoneName} - ${item.groupName} - ${item.locationName}`;
			}
		}
		return "";
	};

	const getGroupName = (groupId) => {
		if (groupId) {
			const item = siteMap.find((x) => x.groupId === parseInt(groupId));
			if (item) {
				return `${item.buildingName} - ${item.zoneName} - ${item.groupName}`;
			}
		}
		return "";
	};

	const getGroup = (groupId) => {
		if (groupId) {
			const item = siteMap.find((x) => x.groupId === parseInt(groupId));
			if (item) {
				return item;
			}
		}
		return null;
	};

	const getZone = (zoneId) => {
		if (zoneId) {
			const item = siteMap.find((x) => x.zoneId === zoneId);
			if (item) {
				return item;
			}
		}
		return null;
	};

	const getZoneDetails = (zoneId) => {
		if (zoneId) {
			const item = siteMap.find((x) => x.zoneId.toString() === zoneId);
			if (item) {
				return `${item.buildingName} - ${item.zoneName}`;
			}
		}
		return "";
	};

	const getZoneForGroupId = (groupId) => {
		const item = siteMap.find((x) => x.groupId === groupId);
		if (item) {
			return item.zoneId;
		}
		return "";
	};

	const getLocationsForGroupId = (groupId) => {
		return siteMap.filter((x) => x.groupId === groupId);
	};

	const getBuildingIdForGroupId = (groupId) => {
		const item = siteMap.find((x) => x.groupId === groupId);
		if (item) {
			return item.buildingId;
		}
		return null;
	};

	const getLocation = (locationId) => {
		if (locationId) {
			const item = siteMap.find((x) => x.locationId === locationId);
			if (item) {
				return item;
			}
		}
		return null;
	};

	// Make the context object:
	const userPreferencesContext = {
		prefsLoaded,
		siteFeaturesLoaded,
		siteId,
		siteName,
		siteMap,
		siteDefaultLocale,
		siteProductType,
		preferences,
		clearPreference,
		savePreference,
		savePreferenceCallback,
		saveTierPreference,
		dashboardLayout,
		dashboardLayoutLoaded,
		pinToDashboard,
		pinLocationWidgetsToDashboard,
		pinZoneWidgetsToDashboard,
		sortDashboard,
		getLocation,
		getLocationName,
		getLocationsForGroupId,
		getZone,
		getZoneDetails,
		getZoneForGroupId,
		getBuildingIdForGroupId,
		getGroup,
		getGroupName,
		prefMeterLocationIds,
		prefMeterSort,
		prefMeterType,
		LoadSiteData,
		prefTooltip,
		prefsObj,
		newReleaseNotes,
	};

	useEffect(() => {
		LoadSiteData();
		LoadData();
	}, [LoadSiteData, LoadData]);

	// pass the value in provider and return
	return <Context.Provider value={userPreferencesContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;

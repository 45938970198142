export const siteSettingsGetBooleanValue = (siteSettings, settingKey, defaultValue) => {
	const found = siteSettings?.find((x) => x.settingKey === settingKey);

	if (found) {
		return found.settingValue === "1";
	}

	return defaultValue;
};

export const siteSettingsGetStringValue = (siteSettings, settingKey, defaultValue) => {
	const found = siteSettings?.find((x) => x.settingKey === settingKey);

	if (found) {
		return found.settingValue;
	}

	return defaultValue;
};

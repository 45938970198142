import React, { useEffect, useState } from "react";
import { Button, Col, CustomInput, Input, Row } from "reactstrap";
import { logEvent } from "../../../helpers/ga";
import local from "../../../localization/strings";
import SiteTreeMultiSelect from "../../common/SiteTreeMultiSelect";
import CustomDateTime from "./CustomDateTime";

const HardwareReportFilter = ({ triggerGenerate, filterData, onChange, emailReports }) => {
	const [period, setPeriod] = useState("Day");
	const [startTime, setStartTime] = useState(null);
	const [endTime, setEndTime] = useState(null);
	const [groupIds, setGroupIds] = useState([]);
	const [showNullCount, setShowNullCount] = useState(false);
	const [showMinMaxAvg, setShowMinMaxAvg] = useState(false);
	const [showDateRange, setShowDateRange] = useState(false);
	const initialGroupIds = filterData.groupIds || [];

	useEffect(() => {
		setGroupIds(filterData.groupIds || []);
		setShowDateRange(!!filterData.showDateRange);
		setPeriod(filterData.period || "Day");
		setShowNullCount(filterData.showNullCount || false);
		setShowMinMaxAvg(filterData.showMinMaxAvg || false);
		setStartTime(filterData.startTime ? new Date(filterData.startTime) : null);
		setEndTime(filterData.endTime ? new Date(filterData.endTime) : null);
	}, [filterData]);

	const generate = () => {
		logEvent("Hardware Report", "Generate Click");

		triggerGenerate({ period, startTime, endTime, groupIds, showNullCount, showDateRange, showMinMaxAvg });
	};

	const change = (field, value) => {
		logEvent("Hardware Report", "Filter Changed", `${field}: ${value}`);

		switch (field) {
			case "period":
				setPeriod(value);
				if (onChange) {
					onChange({ period: value, startTime, endTime, groupIds, showNullCount, showDateRange, showMinMaxAvg });
				}
				break;
			case "startTime":
				setStartTime(value);
				if (onChange) {
					onChange({ period, startTime: value, endTime, groupIds, showNullCount, showDateRange, showMinMaxAvg });
				}
				break;
			case "endTime":
				setEndTime(value);
				if (onChange) {
					onChange({ period, startTime, endTime: value, groupIds, showNullCount, showDateRange, showMinMaxAvg });
				}
				break;
			case "groupIds":
				setGroupIds(value);
				if (onChange) {
					onChange({ period, startTime, endTime, groupIds: value, showNullCount, showDateRange, showMinMaxAvg });
				}
				break;
			default:
				break;
		}
	};

	const changeShowNullCount = (e) => {
		logEvent("Hardware Report", "Filter Changed", `Show Null Count: ${e.target.checked}`);

		setShowNullCount(e.target.checked);
		if (onChange) {
			onChange({ period, startTime, endTime, groupIds, showNullCount: e.target.checked, showDateRange, showMinMaxAvg });
		}
	};

	const changeShowDateRange = (e) => {
		logEvent("Hardware Report", "Filter Changed", `Show Date Range: ${e.target.checked}`);

		setShowDateRange(e.target.checked);
		if (onChange) {
			onChange({ period, startTime, endTime, groupIds, showNullCount, showDateRange: e.target.checked, showMinMaxAvg });
		}
	};

	const changeShowMinMaxAvg = (e) => {
		logEvent("Hardware Report", "Filter Changed", `Show Min/Max/Avg: ${e.target.checked}`);

		setShowMinMaxAvg(e.target.checked);
		if (onChange) {
			onChange({ period, startTime, endTime, groupIds, showNullCount, showDateRange, showMinMaxAvg: e.target.checked });
		}
	};

	return (
		<>
			<h5 className="mb-2">{local.TF_Area_to_report}</h5>
			<div className="border rounded-soft" style={{ height: 405, overflowY: "auto", overflowX: "hidden" }}>
				<SiteTreeMultiSelect expandToLevel={0} siteMapLevel={3} inline={true} onCheckedChanged={(e) => change("groupIds", e)} initialChecked={initialGroupIds.join(",")} />
			</div>
			<h5 className="mb-2 mt-3">{local.TF_Time_period}</h5>
			<PeriodSelect onPeriodChanged={(e) => change("period", e)} onStartChanged={(e) => change("startTime", e)} onEndChanged={(e) => change("endTime", e)} period={period} start={startTime} end={endTime} emailReports={emailReports} />

			<CustomInput type="checkbox" className="mt-2" id="chkShowNullCount" checked={showNullCount} onChange={(e) => changeShowNullCount(e)} label={local.TF_ShowConsecutiveNoDatas} />
			<CustomInput type="checkbox" className="mt-2" id="chkShowDateRange" checked={showDateRange} onChange={(e) => changeShowDateRange(e)} label={local.TF_Start_End_Time_of_Data} />
			<CustomInput type="checkbox" className="mt-2" id="chkMinMaxAvg" checked={showMinMaxAvg} onChange={(e) => changeShowMinMaxAvg(e)} label={local.TF_Include_Performance_Statistics} />

			{triggerGenerate && (
				<Row className="border-top pt-3 mt-3">
					<Col>
						<Button color="primary" onClick={generate} className="col-md-3">
							{local.TS_Generate}
						</Button>
					</Col>
				</Row>
			)}
		</>
	);
};

const PeriodSelect = ({ onPeriodChanged, onStartChanged, onEndChanged, period, start, end, emailReports }) => {
	const periodChanged = (e) => {
		onPeriodChanged(e.target.value);
	};

	return (
		<>
			<Input type="select" className="form-control" value={period} onChange={periodChanged}>
				<option value="Day">{local.TF_LastDay}</option>
				<option value="Week">{local.TF_LastWeek}</option>
				<option value="Month">{local.TF_LastMonth}</option>
				<option value="Year">{local.TF_LastYear}</option>
				{!emailReports && <option value="Custom">{local.TF_Custom}</option>}
			</Input>
			{period === "Custom" ? <CustomDateTime onStartChanged={onStartChanged} onEndChanged={onEndChanged} start={start} end={end} /> : null}
		</>
	);
};

export default HardwareReportFilter;

import React, { useEffect, useState } from "react";
import { CurrentUserDisplayName, CurrentUserSites } from "../../api/auth";
import { siteStats } from "../../api/site";
import { CurrentSiteIdSet } from "../../api/userSettings";
import PageTitle from "../common/PageTitle";
import UserSiteImages from "./UserSiteImages";

const User = () => {
	const [sites, setSites] = useState(undefined);
	const [asAt, setAsAt] = useState(undefined);

	async function loadData() {
		setSites(await siteStats());
		setAsAt(new Date());
	}

	useEffect(() => {
		loadData();
	}, []);

	const accessSite = (id) => {
		if (CurrentUserSites().includes(id)) {
			CurrentSiteIdSet(id);
			window.location.href = "/";
			return;
		}
	};

	return (
		<>
			<PageTitle title={CurrentUserDisplayName()} />
			{asAt && <UserSiteImages sites={sites} accessSite={accessSite} when={asAt} />}
		</>
	);
};

export default User;

import moment, { isMoment } from "moment";
import "moment/min/locales";
import React, { useEffect, useState } from "react";
import Datetime from "react-datetime";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { logEvent } from "../../helpers/ga";
import local from "../../localization/strings";

const isUtc = true;

const ChartPeriod = ({ show, action, options, overlays }) => {
	const [open, setOpen] = useState(show);
	const [from, setFrom] = useState(null);
	const [to, setTo] = useState(null);
	const [compareFrom, setCompareFrom] = useState(null);
	const [compareTo, setCompareTo] = useState(null);
	const [isDisabled, setIsDisabled] = useState(true);
	const [language, setLanguage] = useState("en");

	useEffect(() => {
		setFrom(options.from);
		setTo(options.to);
		setCompareFrom(options.compareFrom);
		setCompareTo(options.compareTo);
		setIsDisabled(!options.from || !options.to);
		setOpen(show);
	}, [show, options]);

	useEffect(() => {
		setLanguage(local.getLanguage());
	}, []);

	const changeFrom = (e, reset = false) => {
		try {
			if (isMoment(e)) {
				setFrom(e.toDate());
			} else {
				if (reset) {
					setFrom(moment().toDate());
				}
			}
		} catch {
			//console.error("Failed to Parse Date!");
		}
	};

	const changeTo = (e, reset = false) => {
		try {
			if (isMoment(e)) {
				setTo(e.toDate());
			} else {
				if (reset) {
					setTo(moment().toDate());
				}
			}
		} catch {
			//console.error("Failed to Parse Date!");
		}
	};

	const changeCompareFrom = (e, reset = false) => {
		try {
			if (isMoment(e)) {
				setCompareFrom(e.toDate());
			} else {
				if (reset) {
					setCompareFrom(moment().toDate());
				}
			}
		} catch {
			//console.error("Failed to Parse Date!");
		}
	};

	const changeCompareTo = (e, reset = false) => {
		try {
			if (isMoment(e)) {
				setCompareTo(e.toDate());
			} else {
				if (reset) {
					setCompareTo(moment().toDate());
				}
			}
		} catch {
			//console.error("Failed to Parse Date!");
		}
	};

	useEffect(() => {
		setIsDisabled(!from || !to);
	}, [from, to]);

	function apply() {
		logEvent("Zone Charts", "Custom Period Apply");
		action("ApplyPeriod", from, to, compareFrom, compareTo);
	}

	return (
		<Modal isOpen={open} toggle={() => setOpen(false)} size="lg" centered backdrop="static">
			<ModalHeader>
				<span className="float-left">{local.TF_Time_period}</span>
				<span className="float-right close-modal" style={{ position: "absolute", top: 15, right: 20 }} onClick={() => action("ClosePeriod")}>
					X
				</span>
			</ModalHeader>
			<ModalBody>
				<Row>
					<Col md={6}>{local.TF_Range_from}</Col>
					<Col md={6}>{local.TF_Range_to}</Col>
				</Row>
				<Row>
					<Col md={6}>
						<Datetime timeFormat="HH:mm" dateFormat="DD MMM YYYY" value={from} onBlur={(e) => changeFrom(e, true)} onChange={changeFrom} closeOnSelect={false} input={true} locale={language} utc={isUtc} />
					</Col>
					<Col md={6}>
						<Datetime timeFormat="HH:mm" dateFormat="DD MMM YYYY" value={to} onBlur={(e) => changeTo(e, true)} onChange={changeTo} closeOnSelect={false} input={true} locale={language} utc={isUtc} />
					</Col>
				</Row>
				{overlays && overlays.length > 0 && (
					<>
						<Row className="mt-2">
							<Col>{local.TF_Compare}</Col>
						</Row>
						<Row>
							<Col md={6}>{local.TF_Range_from}</Col>
							<Col md={6}>{local.TF_Range_to}</Col>
						</Row>
						<Row>
							<Col md={6}>
								<Datetime timeFormat="HH:mm" dateFormat="DD MMM YYYY" value={compareFrom} onBlur={(e) => changeCompareFrom(e, true)} onChange={changeCompareFrom} closeOnSelect={false} input={true} locale={language} utc={isUtc} />
							</Col>
							<Col md={6}>
								<Datetime timeFormat="HH:mm" dateFormat="DD MMM YYYY" value={compareTo} onBlur={(e) => changeCompareTo(e, true)} onChange={changeCompareTo} closeOnSelect={false} input={true} locale={language} utc={isUtc} />
							</Col>
						</Row>
					</>
				)}
			</ModalBody>
			<ModalFooter>
				<Button onClick={() => action("ClearPeriod")}>{local.TS_Reset}</Button>
				<Button color="primary" onClick={() => apply()} disabled={isDisabled}>
					{local.TS_Apply}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default ChartPeriod;

import React from "react";

const ExpandDiv = ({ expand, maxHeight, children }) => {
	if (expand) {
		return <>{children}</>;
	}

	return <div style={{ maxHeight: maxHeight, overflowY: "auto", overflowX: "hidden" }}>{children}</div>;
};

export default ExpandDiv;

import React, { useEffect, useState, useContext } from "react";
import { TabPane, Form, Row, Label, Input, Button } from "reactstrap";
import local from "../../localization/strings";
import { availableLanguages } from "../../localization/LanguageList";
import { UserPreferencesContext } from "../../context/userPreferences";
import { siteDefaultLocaleSet } from "../../api/site";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const Language = () => {
	const [language, setLanguage] = useState("en");
	const languageOptions = availableLanguages();
	const { siteDefaultLocale, LoadSiteData } = useContext(UserPreferencesContext);

	useEffect(() => {
		setLanguage(siteDefaultLocale);
	}, [siteDefaultLocale]);

	const save = async () => {
		await siteDefaultLocaleSet(language);
		await LoadSiteData();
		toast.success(local.TF_Site_language_changed);
	};

	return (
		<TabPane tabId="3" className="p-3 p-md-4">
			<Form>
				<Row>
					<Label for="site-language">{local.TF_Site_Language}</Label>

					<Input type="select" id="site-language" className="form-control" value={language} onChange={(e) => setLanguage(e.target.value)}>
						{languageOptions.map((lang, i) => {
							return (
								<option key={i} value={lang[1]}>
									{lang[0]}
								</option>
							);
						})}
					</Input>
					<small>
						{local.TF_This_is_language_for_site} <Link to="../user_settings">{local.TS_UserSettings}.</Link>
					</small>
				</Row>
			</Form>
			<Row className="mt-3">
				<Button color="primary" onClick={() => save()}>
					{local.TS_Save}
				</Button>
			</Row>
		</TabPane>
	);
};

export default Language;

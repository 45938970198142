import React, { useEffect, useState } from "react";
import Datetime from "react-datetime";
import "moment/min/locales";
import local from "../../localization/strings";

const AvDateTime = ({ value, onChange, disabled, utc = true }) => {
	const [language, setLanguage] = useState("en");

	useEffect(() => {
		setLanguage(local.getLanguage());
	}, []);

	return <Datetime locale={language} utc={utc} inputProps={{ disabled: disabled }} value={value} onChange={onChange} dateFormat="DD/MM/YYYY" timeFormat="HH:mm" showTimeSelect closeOnSelect />;
};

export default AvDateTime;

import React, { useState, useEffect, useCallback } from "react";
import { Card, Row, Col, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import FluctuationReportFilter from "./FluctuationReportFilter";
import FluctuationReportDisplay from "./FluctuationReportDisplay";
import local from "../../../localization/strings";
import PageTitle from "../../common/PageTitle";
import { userPreferencesGetList, userPreferenceSave } from "../../../api/userPreferences";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logEvent } from "../../../helpers/ga";
import { CSVLink } from "react-csv";
import moment from "moment";
import EmailModal from "../../common/EmailModal";

const buttonDefaultClasses = "btn-shadow btn-sm mb-sm-2 py-2 px-2 px-sm-3 py-sm-1";

const FluctuationReport = () => {
	const [showFilter, setShowFilter] = useState(true);
	const [reportRunning, setReportRunning] = useState(false);
	const [report, setReport] = useState(undefined);
	const [data, setData] = useState({});
	const [showEmail, setShowEmail] = useState(false);

	const generateStats = async (data) => {
		await userPreferenceSave("FluctuationReport_Filter", JSON.stringify(data));

		setReportRunning(true);
		setData(data);
		setShowFilter(false);
	};

	const filter = () => {
		logEvent("Fluctuation Report", "Filter");

		setShowFilter(true);
	};

	useEffect(() => {
		const LoadData = async () => {
			const prefs = await userPreferencesGetList();

			var match = prefs.find((x) => x.preferenceKey === "FluctuationReport_Filter");

			if (match) {
				setData(JSON.parse(match.preferenceValue));
			}
		};

		LoadData();
	}, []);

	const reportComplete = useCallback((report) => {
		setReport(report);
		setReportRunning(false);
	}, []);

	const csvHeaders = [{ label: local.TS_Range, key: "key" }, { label: local.TS_Value, key: "value" }];

	const toggleEmailModal = () => {
		setShowEmail(!showEmail);
	};

	return (
		<>
			<PageTitle title={local.TS_FluctuationReport} />
			<Row id="toolbar-row" className="justify-content-between">
				{showFilter ? (
					""
				) : (
					<>
						<Col className="col-6">
							<Button size="sm" color="secondary" className={buttonDefaultClasses} onClick={() => filter()}>
								<FontAwesomeIcon icon="filter" />
								<span className="d-none d-md-inline-block ml-2">{local.TS_Filter}</span>
							</Button>
						</Col>
						<Col className="col-6">
							<UncontrolledDropdown size="sm" direction="left" className="allow-overflow float-right">
								<DropdownToggle caret color="info" transform="shrink-3" className={buttonDefaultClasses} disabled={reportRunning}>
									<FontAwesomeIcon icon="share-alt" />
									<span className="d-none d-md-inline-block ml-2">{local.TS_Options}</span>
								</DropdownToggle>
								<DropdownMenu className="menu-border-blue dropdown-menu">
									{/* v0.9 */}
									<DropdownItem>
										{report && (
											<CSVLink className="w-100 d-block" data={Object.keys(report).map((key) => ({ key, value: report[key] }))} headers={csvHeaders} filename={`${local.TS_FluctuationReport} ${moment().format("DD MMM YYYY")}.csv`}>
												<FontAwesomeIcon icon="download" /> {local.TS_Download}
											</CSVLink>
										)}
									</DropdownItem>
									<DropdownItem disabled>
										<FontAwesomeIcon icon="print" /> {local.TS_Print}
									</DropdownItem>
									<DropdownItem disabled>
										<FontAwesomeIcon icon="copy" /> {local.TS_Copy}
									</DropdownItem>
									<DropdownItem disabled>
										<FontAwesomeIcon icon="envelope-square" /> {local.TS_Email}
									</DropdownItem>
								</DropdownMenu>
							</UncontrolledDropdown>
						</Col>
					</>
				)}
			</Row>

			<Card className="p-3">{showFilter ? <FluctuationReportFilter triggerGenerate={generateStats} data={data} /> : <FluctuationReportDisplay data={data} reportComplete={reportComplete} />}</Card>

			<EmailModal show={showEmail} toggleEmailModal={toggleEmailModal} />
		</>
	);
};

export default FluctuationReport;

import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from "reactstrap";
import local from "../../../localization/strings";
import Datetime from "react-datetime";
import "moment/min/locales";
import { logEvent } from "../../../helpers/ga";
import moment, { isMoment } from "moment";

const ChartReportPeriod = ({ show, options, set, clear }) => {
	const [open, setOpen] = useState(show);
	const [from, setFrom] = useState(null);
	const [to, setTo] = useState(null);
	const [language, setLanguage] = useState("en");

	useEffect(() => {
		setFrom(options.from);
		setTo(options.to);
		setOpen(show);
	}, [show, options]);

	useEffect(() => {
		setLanguage(local.getLanguage());
	}, []);

	const changeFrom = (e, reset = false) => {
		try {
			if (isMoment(e)) {
				setFrom(e.toDate());
			} else {
				if (reset) {
					setFrom(moment().toDate());
				}
			}
		} catch {
			//console.error("Failed to Parse Date!");
		}
	};

	const changeTo = (e, reset = false) => {
		try {
			if (isMoment(e)) {
				setTo(e.toDate());
			} else {
				if (reset) {
					setTo(moment().toDate());
				}
			}
		} catch {
			//console.error("Failed to Parse Date!");
		}
	};

	function apply() {
		logEvent("Chart Reports", "Custom Period Apply");
		set(from, to);
	}

	return (
		<Modal isOpen={open} toggle={() => setOpen(false)} size="lg" centered backdrop="static">
			<ModalHeader>
				<span className="float-left">{local.TF_Time_period}</span>
				<span className="float-right close-modal" style={{ position: "absolute", top: 15, right: 20 }} onClick={() => clear()}>
					X
				</span>
			</ModalHeader>
			<ModalBody>
				<Row>
					<Col md={6}>{local.TF_Range_from}</Col>
					<Col md={6}>{local.TF_Range_to}</Col>
				</Row>
				<Row>
					<Col md={6}>
						<Datetime timeFormat="HH:mm" dateFormat="DD MMM YYYY" value={from} onBlur={(e) => changeFrom(e, true)} onChange={changeFrom} closeOnSelect={false} locale={language} utc={true} />
					</Col>
					<Col md={6}>
						<Datetime timeFormat="HH:mm" dateFormat="DD MMM YYYY" value={to} onBlur={(e) => changeTo(e, true)} onChange={changeTo} closeOnSelect={false} locale={language} utc={true} />
					</Col>
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button onClick={() => clear()}>{local.TS_Reset}</Button>
				<Button color="primary" onClick={() => apply()}>
					{local.TS_Apply}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default ChartReportPeriod;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from "reactstrap";
import { CurrentUserHasRole } from "../../api/auth";
import { readingsLatest, readingsLatestTable, siteRefreshValuesRequest, siteRefreshValuesRequestOutstanding } from "../../api/readings";
import { dashboardRefreshValuesPollInterval, features, isFeatureEnabled, meterPollingInterval, roles } from "../../config";
import { UserPreferencesContext } from "../../context/userPreferences";
import { logEvent } from "../../helpers/ga";
import useInterval from "../../hooks/useInterval";
import local from "../../localization/strings";
import ButtonIcon from "../common/ButtonIcon";
import Loader from "../common/Loader";
import PageTitle from "../common/PageTitle";
import SiteTreeMultiSelect from "../common/SiteTreeMultiSelect";
import Meters from "./Meters";
import MetersTable from "./MetersTable";

const buttonDefaultClasses = "btn-shadow";

const MeteringAll = () => {
	const { savePreference, prefMeterLocationIds, prefMeterType, prefMeterSort, prefsLoaded } = useContext(UserPreferencesContext);
	const [showLoading, setShowLoading] = useState(true);
	const [readings, setReadings] = useState([]);
	const [readingsTable, setReadingsTable] = useState([]);
	const [showFilter, setShowFilter] = useState(false);
	const [groupIds, setGroupIds] = useState("");
	const [sortOpen, setSortOpen] = useState(false);
	const [sortBy, setSortBy] = useState("groupName");
	const [typeOpen, setTypeOpen] = useState(false);
	const [meterType, setMeterType] = useState("Gauge");
	const [showTransmitterDetails, setShowTransmitterDetails] = useState(false);
	const [dateTime, setDateTime] = useState("");
	const [refreshingValues, setRefreshingValues] = useState(false);

	const refreshValues = async () => {
		logEvent("Metering", "Refresh Values Clicked");
		setRefreshingValues(true);
		await siteRefreshValuesRequest(groupIds.split(",").map((x) => (x || "").replace("G:", "")), undefined);
	};

	useEffect(() => {
		if (!prefsLoaded) {
			return;
		}

		if (prefMeterLocationIds === "") {
			setShowFilter(true);
			setShowLoading(false);
		}

		setGroupIds(prefMeterLocationIds);
		setMeterType(prefMeterType);
		setSortBy(prefMeterSort);
	}, [prefMeterLocationIds, prefMeterSort, prefMeterType, prefsLoaded]);

	useEffect(() => {
		setShowFilter(true);
		setShowLoading(false);
	}, []);

	const fetchDataCallback = useCallback(async () => {
		if (!showFilter && groupIds) {
			if (meterType === "Table") {
				let result = await readingsLatestTable(null, groupIds);
				result.columns = result.columns.filter((x) => x.extra !== "DisplayOnLatestValues:False");

				setReadingsTable(result);
				if (result) {
					formatDateTime(result.dateTime);
				}
			} else {
				let result = await readingsLatest(groupIds);
				result = result.filter((x) => x.displayOnLatestValues);
				setReadings(result);

				//Find max date/time, not the first
				const record = result.filter((x) => !x.dateTime.startsWith("0001")).reduce((prev, current) => (prev.dateTime > current.dateTime ? prev : current));
				if (record) {
					formatDateTime(record.dateTime);
				} else {
					formatDateTime(undefined);
				}
			}

			setShowLoading(false);
		}
	}, [showFilter, groupIds, meterType]);

	const checkRefreshingValues = useCallback(
		async (initial = false) => {
			if (!CurrentUserHasRole(roles.SiteAdmin) || !isFeatureEnabled(features.RefreshValues)) {
				setRefreshingValues(false);
				return;
			}

			const isRefreshing = await siteRefreshValuesRequestOutstanding();

			if (isRefreshing) {
				setRefreshingValues(true);
			} else {
				if (!initial) {
					setRefreshingValues(false);
					await fetchDataCallback();
				}
			}
		},
		[fetchDataCallback],
	);

	useInterval(async () => {
		if (refreshingValues) {
			await checkRefreshingValues();
		}
	}, dashboardRefreshValuesPollInterval);

	useInterval(() => {
		fetchDataCallback();
	}, meterPollingInterval);

	useEffect(() => {
		checkRefreshingValues(true);
		fetchDataCallback();
	}, [showFilter, fetchDataCallback, groupIds, checkRefreshingValues]);

	function formatDateTime(stringValue) {
		if (stringValue) {
			if (isFeatureEnabled(features.Seconds)) {
				setDateTime(moment(stringValue).format("DD MMM YYYY HH:mm:ss"));
			} else {
				setDateTime(moment(stringValue).format("DD MMM YYYY HH:mm"));
			}
		} else {
			setDateTime("");
		}
	}

	const onFilterApply = (data) => {
		logEvent("Metering", "Apply Filter");

		setShowLoading(true);

		var groupIds = data.join(",");

		savePreference("Meter_LocationIds", groupIds);

		setGroupIds(groupIds);

		setShowFilter(false);

		fetchDataCallback();
	};

	function action(sortName) {
		savePreference("Meter_Sort", sortName);
		logEvent("Metering", "Sort", sortName);
		setSortOpen(false);
		setSortBy(sortName);
	}

	function actionType(typeName) {
		savePreference("Meter_Type", typeName);
		logEvent("Metering", "Type", typeName);
		setTypeOpen(false);
		setMeterType(typeName);
	}

	const csvHeaders = (reportData) => {
		if (reportData?.columns) {
			return reportData.columns.map((item, idx) => {
				if (idx > 2) {
					return { label: item.name, key: `${item.key}_Data` };
				}
				return { label: item.name, key: item.key };
			});
		}
		return null;
	};

	const csvParseData = (reportData) => {
		try {
			if (reportData && reportData.rows && reportData.columns) {
				for (const row of reportData.rows) {
					for (const idx in reportData.columns) {
						if (idx > 2) {
							const col = reportData.columns[idx];

							if (col) {
								let working = row[col.key];
								if (working && working.includes(":")) {
									const parts = working.split(":");
									if (parts.length === 3) {
										working = parts[1];
									}
								}

								row[`${col.key}_Data`] = working;
							}
						}
					}
				}
			}
		} catch (ex) {
			console.error(ex);
		}

		return reportData;
	};

	return (
		<Fragment>
			<PageTitle title={local.TS_LatestValues} />
			{showLoading ? (
				<Loader />
			) : showFilter ? (
				<SiteTreeMultiSelect onFilterApply={onFilterApply} initialChecked={groupIds} expandToLevel={1} />
			) : (
				<Fragment>
					<Row className="p-1">
						<Col className="col-6 col-lg-5 order-2 order-lg-1">
							<ButtonIcon
								size="sm"
								className="secondary-button mx-1 float-left"
								icon="undo-alt"
								iconAlign="left"
								color="secondary"
								onClick={() => {
									logEvent("Metering", "Filter");
									setShowFilter(!showFilter);
								}}
							>
								{local.TS_Change_Area}
							</ButtonIcon>
							{meterType !== "Table" && (
								<>
									{showTransmitterDetails ? (
										<ButtonIcon
											outline
											size="sm"
											className="danger-button mx-1"
											icon="satellite-dish"
											iconAlign="left"
											color="danger"
											onClick={() => {
												logEvent("Metering", "Show Transmitter Details", `${!showTransmitterDetails}`);
												setShowTransmitterDetails(!showTransmitterDetails);
											}}
										>
											<span className="d-none d-md-block float-right">{local.TS_HideTransmitterDetails}</span>
											<span className="d-block d-md-none float-right">{local.TS_Hide}</span>
										</ButtonIcon>
									) : (
										<ButtonIcon
											outline
											size="sm"
											className="danger-button mx-1"
											icon="satellite-dish"
											iconAlign="left"
											color="danger"
											onClick={() => {
												logEvent("Metering", "Show Transmitter Details", `${!showTransmitterDetails}`);
												setShowTransmitterDetails(!showTransmitterDetails);
											}}
										>
											<span className="d-none d-md-block float-right">{local.TS_ShowTransmitterDetails}</span>
											<span className="d-block d-md-none float-right">{local.TS_Show}</span>
										</ButtonIcon>
									)}
								</>
							)}
						</Col>
						<Col className="col-12 col-lg text-left text-lg-center text-xl-left order-1 order-lg-2">
							<h5 className="meter-timestamp">
								{local.TF_Data_from}: {dateTime}
							</h5>
						</Col>
						<Col className="col-6 col-lg text-right order-3">
							<Dropdown tag="span" isOpen={typeOpen} toggle={() => setTypeOpen(!typeOpen)} className="mx-1">
								<DropdownToggle caret outline size="sm" className="dark-button" color="dark">
									{local.TF_MeteringType}
								</DropdownToggle>
								<DropdownMenu>
									<DropdownItem onClick={() => actionType("Normal")}>{local.TF_MeteringTypeNormal}</DropdownItem>
									<DropdownItem onClick={() => actionType("Gauge")}>{local.TF_MeteringTypeGauge}</DropdownItem>
									<DropdownItem onClick={() => actionType("Table")}>{local.TF_MeteringTypeTable}</DropdownItem>
								</DropdownMenu>
							</Dropdown>
							{meterType !== "Table" && (
								<Dropdown tag="span" isOpen={sortOpen} toggle={() => setSortOpen(!sortOpen)} className="mx-1">
									<DropdownToggle caret outline size="sm" className="dark-button" color="dark">
										{local.TS_Sort}
									</DropdownToggle>
									<DropdownMenu>
										<DropdownItem onClick={() => action("groupName")}>{local.TS_Group}</DropdownItem>
										<DropdownItem onClick={() => action("-groupName")}>
											{local.TS_Group} ({local.TS_Desc})
										</DropdownItem>
										<DropdownItem onClick={() => action("parameterName")}>{local.TS_Parameter}</DropdownItem>
										<DropdownItem onClick={() => action("-parameterName")}>
											{local.TS_Parameter} ({local.TS_Desc})
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
							)}
							{isFeatureEnabled(features.LatestValuesTableOptions) && meterType === "Table" && readingsTable && (
								<UncontrolledDropdown size="sm" direction="left" className="allow-overflow float-right">
									<DropdownToggle caret color="info" transform="shrink-3" className={buttonDefaultClasses} disabled={showLoading}>
										<FontAwesomeIcon icon="share-alt" />
										<span className="d-none d-md-inline-block ml-2">{local.TS_Options}</span>
									</DropdownToggle>
									<DropdownMenu className="menu-border-blue dropdown-menu">
										{/* v0.9 */}
										<DropdownItem>
											{readingsTable && (
												<CSVLink className="w-100 d-block" data={csvParseData(readingsTable)?.rows || []} headers={csvHeaders(readingsTable)} filename={`${local.TS_StatisticsReport} ${moment().format("DD MMM YYYY")}.csv`}>
													<FontAwesomeIcon icon="download" /> {local.TS_Download}
												</CSVLink>
											)}
										</DropdownItem>
									</DropdownMenu>
								</UncontrolledDropdown>
							)}
						</Col>
					</Row>
					<Row>
						<Col size={12}>{meterType === "Table" ? <MetersTable readings={readingsTable} showTransmitterDetails={showTransmitterDetails} sortBy={sortBy} meterType={meterType} /> : <Meters readings={readings} showTransmitterDetails={showTransmitterDetails} sortBy={sortBy} meterType={meterType} />}</Col>
					</Row>
					{isFeatureEnabled(features.RefreshValues) && CurrentUserHasRole(roles.SiteAdmin) && (
						<Row className="mt-2">
							<Col className="text-center">
								<Button onClick={refreshValues} color="falcon-warning" disabled={refreshingValues}>
									{refreshingValues ? local.TF_Refreshing_Values : local.TF_Refresh_Values}
								</Button>
							</Col>
						</Row>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

export default MeteringAll;

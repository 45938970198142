import React, { useCallback, useEffect, useState } from "react";
import jsPDF from "jspdf";
import { withRouter } from "react-router-dom";
import { apiEmailChartReport } from "../../../api/reports";
import CanvasJSReact from "../../../lib/canvasjs.react";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const EmailChartReport = ({ match }) => {
	const [token, setToken] = useState(undefined);
	const [reportType, setReportType] = useState(undefined);

	const [data, setData] = useState(undefined);

	const [dataURLs, setDataURLs] = useState([]);
	const top = 8;
	const left = 0;
	const width = (landscape) => {
		return landscape ? 297 : 210;
	};
	const height = (landscape) => {
		return landscape ? 210 : 297;
	};

	useEffect(() => {
		if (match && match.params) {
			setToken(match.params.token);
			setReportType(match.params.reportType);
		}
	}, [match]);

	const makePdf = useCallback(() => {
		const writeHeaderOrFooter = (text, font, fontStyle, fontSize, align, y) => {
			if (text) {
				doc.setFont(font);
				if (fontStyle) {
					doc.setFontStyle(fontStyle);
				} else {
					doc.setFontStyle("normal");
				}
				doc.setFontSize(fontSize);
				switch (align) {
					case "left":
						doc.text(text, left + 5, y);
						break;
					default:
					case "center":
						doc.text(text, width(data.landscape) / 2, y, null, null, "center");
						break;
					case "right":
						doc.text(text, width(data.landscape) - 5, y, null, null, "right");
						break;
				}
			}
		};

		const writeHeaderAndFooter = () => {
			writeHeaderOrFooter(data.headerText, data.headerFont, data.headerFontStyle, data.headerFontSize, data.headerAlign, top);
			writeHeaderOrFooter(data.footerText, data.footerFont, data.footerFontStyle, data.footerFontSize, data.footerAlign, height(data.landscape) - 5);
		};

		var doc = new jsPDF(data.landscape ? "landscape" : "portrait");

		const yOffset = (data.landscape ? 180 : 280) / data.chartsPerPage;
		const containers = document.getElementsByClassName("canvasjs-chart-container");

		let y = 10;
		let chartNumber = 0;
		containers.forEach((container) => {
			if (chartNumber === data.chartsPerPage) {
				writeHeaderAndFooter();
				doc.addPage();
				y = 10;
				chartNumber = 0;
			}

			var canvas = container.firstChild;
			if (canvas) {
				var dataUrl = canvas.toDataURL("image/jpeg", 1);
				doc.addImage(dataUrl, "JPEG", 5, y);
				y += yOffset;
			}
			chartNumber += 1;
		});

		writeHeaderAndFooter();
		return doc;
	}, [data]);

	useEffect(() => {
		const loadData = async () => {
			var result = await apiEmailChartReport(token, reportType);

			result.chartWidth = result.width / window.devicePixelRatio;
			result.chartHeight = result.height / window.devicePixelRatio;

			result.chartOptions.forEach((chart) => {
				chart.data.forEach((element) => {
					element.lineThickness = 1;
					element.dataPoints.forEach((point) => {
						point.x = new Date(point.x);
					});
				});

				if (!chart.axisLabels) {
					if (chart.axisY) {
						chart.axisY.titleFontSize = 1;
						chart.axisY.titleFontColor = "transparent";
					}
					if (chart.axisY2) {
						chart.axisY2.titleFontSize = 1;
						chart.axisY2.titleFontColor = "transparent";
					}
				}

				if (chart.axisX) {
					chart.axisX.crosshair.snapToDataPoint = true;

					if (chart.axisX.minimum) {
						chart.axisX.minimum = new Date(chart.axisX.minimum);
					}

					if (chart.axisX.maximum) {
						chart.axisX.maximum = new Date(chart.axisX.maximum);
					}
				}
			});

			setData(result);
		};

		if (token && reportType) {
			loadData();
		}
	}, [token, reportType]);

	var timeOut;
	const convert = (ref) => {
		if (data.documentType === "pdf") {
			if (timeOut) {
				clearTimeout(timeOut);
			}
			timeOut = setTimeout(() => {
				const pdf = makePdf();

				setDataURLs([pdf.output("datauristring")]);
				pdf.save("x.pdf");
			}, 500);
		} else {
			if (ref) {
				ref.render();

				var base64Image = ref.exportChart({ toDataURL: true });

				setDataURLs((p) => [...p, base64Image]);
			}
		}
	};

	return (
		<>
			{dataURLs.map((data, i) => {
				return (
					<div key={i} id={`dataurl_${i}`} className="dataurl">
						{data}
					</div>
				);
			})}

			{data &&
				data.chartOptions &&
				data.chartOptions.map((chart, i) => {
					return <CanvasJSChart options={chart} key={i} onRef={convert} containerProps={{ height: data.chartHeight, minHeight: data.chartHeight, width: data.chartWidth, minWidth: data.chartWidth }} />;
				})}
		</>
	);
};

export default withRouter(EmailChartReport);

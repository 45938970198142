import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { roles } from "../config";

export const isIterableArray = (array) => Array.isArray(array) && !!array.length;

export const getDuration = (startDate, endDate) => {
	if (!moment.isMoment(startDate)) throw new Error(`Start date must be a moment object, received ${typeof startDate}`);
	if (endDate && !moment.isMoment(endDate)) throw new Error(`End date must be a moment object, received ${typeof startDate}`);

	return `${startDate.format("ll")} - ${endDate ? endDate.format("ll") : "Present"} • ${startDate.from(endDate || moment(), true)}`;
};

export const hexToRgb = (hexValue) => {
	let hex;
	hexValue.indexOf("#") === 0 ? (hex = hexValue.substring(1)) : (hex = hexValue);
	// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
	const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b));
	return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
};
export const rgbColor = (color = colors[0]) => `rgb(${hexToRgb(color)})`;
export const rgbaColor = (color = colors[0], alpha = 0.5) => `rgba(${hexToRgb(color)},${alpha})`;
export const colors = ["#2c7be5", "#00d97e", "#e63757", "#39afd1", "#fd7e14", "#02a8b5", "#727cf5", "#6b5eae", "#ff679b", "#f6c343"];
export const rgbColors = colors.map((color) => rgbColor(color));
export const rgbaColors = colors.map((color) => rgbaColor(color));

export function chunk(array, size) {
	const chunked_arr = [];
	let index = 0;
	while (index < array.length) {
		chunked_arr.push(array.slice(index, size + index));
		index += size;
	}
	return chunked_arr;
}

export function randomKey() {
	return (
		Math.random()
			.toString(36)
			.substring(2, 15) +
		Math.random()
			.toString(36)
			.substring(2, 15)
	);
}

export function RoleToDisplayRole(role) {
	switch (role) {
		case roles.SiteAdmin:
			return "Site Administrator";
		case roles.DepartmentAdmin:
			return "Department Administrator";
		case roles.SecondaryUser:
			return "Secondary User";
		case roles.DormantUser:
			return "Dormant User";
		default:
			return role;
	}
}

export const createGuid = () => {
	return uuidv4();
};

export const zoneGraphicGroupArrow = (group) => {
	var fromTop = group.y;
	var toTop = group.arrowY;
	var fromLeft = group.x;
	var toLeft = group.arrowX;

	var CA = Math.abs(toTop - fromTop);
	var CO = Math.abs(toLeft - fromLeft);
	var H = Math.sqrt(CA * CA + CO * CO);
	var ANG = (180 / Math.PI) * Math.acos(CA / H);

	let top = 0;
	if (toTop > fromTop) {
		top = (toTop - fromTop) / 2 + fromTop;
	} else {
		top = (fromTop - toTop) / 2 + toTop;
	}

	let left = 0;
	if (toLeft > fromLeft) {
		left = (toLeft - fromLeft) / 2 + fromLeft;
	} else {
		left = (fromLeft - toLeft) / 2 + toLeft;
	}

	if ((fromTop < toTop && fromLeft < toLeft) || (toTop < fromTop && toLeft < fromLeft) || (fromTop > toTop && fromLeft > toLeft) || (toTop > fromTop && toLeft > fromLeft)) {
		ANG *= -1;
	}
	top -= H / 2;

	return {
		msTransform: "rotate(" + ANG + "deg)",
		WebKitTransform: "rotate(" + ANG + "deg)",
		MozTransform: "rotate(" + ANG + "deg)",
		OTransform: "rotate(" + ANG + "deg)",
		transform: "rotate(" + ANG + "deg)",
		top: top + "px",
		left: left + "px",
		height: H + "px",
		position: "absolute",
		width: "2px",
		backgroundColor: "gray",
	};
};

export const getSquirrelRangeType = (type) => {
	switch (type) {
		case 1:
			return "Digital Temperature";
		case 10:
			return "Voltage";
		case 11:
			return "Wind Speed";
		case 12:
			return "Wind Direction";
		case 14:
			return "AC Voltage";
		case 16:
			return "Resistance";
		case 17:
			return "Conductivity";
		case 19:
			return "pH";
		case 20:
			return "Current";
		case 24:
			return "AC Current";
		case 30:
			return "Sensirion Temperature";
		case 31:
			return "U type Thermistor";
		case 32:
			return "S type Thermistor";
		case 33:
			return "3 Wire PT100";
		case 34:
			return "K type Thermocouple";
		case 35:
			return "T type Thermocouple";
		case 36:
			return "J type Thermocouple";
		case 37:
			return "YSI type Thermistor";
		case 38:
			return "N type Thermocouple";
		case 39:
			return "4 Wire PT100";
		case 40:
			return "Sensirion Humidity";
		case 41:
			return "L type Humidity";
		case 42:
			return "LP type Humidity";
		case 43:
			return "E type Humidity";
		case 44:
			return "Humidity (Digi Hyclip)";
		case 50:
			return "Pulse Count";
		case 51:
			return "Pulse Rate";
		case 52:
			return "Temperature (Digi Hyclip)";
		case 54:
			return "Visible Light (763SU) (fine)";
		case 55:
			return "Visible Light (763SU)";
		case 56:
			return "Accumulated Light (763SU)";
		case 60:
			return "Event";
		case 61:
			return "Event";
		case 62:
			return "Event Alarm!";
		case 63:
			return "State";
		case 65:
			return "UV Light (763SU)";
		case 66:
			return "UV Proportion (763SU)";
		case 70:
			return "Elapsed Time";
		case 83:
			return "R type Thermocouple";
		case 84:
			return "S type Thermocouple";
		case 85:
			return "B type Thermocouple";
		case 86:
			return "Thermocouple PR";
		case 87:
			return "Channel Average";
		case 88:
			return "CO2";
		case 90:
			return "RainFall";
		case 91:
			return "Wind Direction";
		case 92:
			return "Wind Speed";
		case 93:
			return "Temperature";
		case 94:
			return "Humidity";
		case 95:
			return "Pressure";
		case 96:
			return "Temperature (EE Series Digital)";
		case 97:
			return "Humidity (EE Series Digital)";
		case 98:
			return "Global Irradiance";
		case 99:
			return "Diffuse Irradiance";
		case 100:
			return "Pulse Width";
		case 101:
			return "Pulse Period";
		case 110:
			return "Power";
		case 111:
			return "Power Factor";
		case 120:
			return "Temperature (Vaisala)";
		case 121:
			return "Humidity (Vaisala)";
		case 122:
			return "Temperature Wet (Vaisala)";
		case 130:
			return "Rain Intensity";
		case 131:
			return "Hail Intensity";
		case 132:
			return "Luminance";
		case 133:
			return "Visibility";
		case 134:
			return "Weather Code";
		case 135:
			return "0-1um Particle count";
		case 136:
			return "1-2.5um Particle count";
		case 137:
			return "2.5-10um Particle count";
		case 138:
			return "Airflow L/min";
		case 139:
			return "NO2";
		case 140:
			return "Ozone";
		case 141:
			return "CO";
		case 142:
			return "VOC";
		case 143:
			return "Volume";

		default:
			return "";
	}
};

export function uniqueTransmitterSerial(a, key) {
	let seen = new Set();
	return a.filter((item) => {
		let k = key(item);
		return seen.has(k) ? false : seen.add(k);
	});
}

export function measureText(pText, pFontSize, pStyle) {
	var lDiv = document.createElement("div");

	document.body.appendChild(lDiv);

	if (pStyle != null) {
		lDiv.style = pStyle;
	}
	lDiv.style.fontSize = "" + pFontSize + "px";
	lDiv.style.position = "absolute";
	lDiv.style.left = -1000;
	lDiv.style.top = -1000;

	lDiv.textContent = pText;

	var lResult = {
		width: lDiv.clientWidth,
		height: lDiv.clientHeight,
	};

	document.body.removeChild(lDiv);
	lDiv = null;

	return lResult;
}

export const precisionRound = (number, precision) => {
	var factor = Math.pow(10, precision);
	var n = precision < 0 ? number : 0.01 / factor + number;
	return Math.round(n * factor) / factor;
};

import React, { useEffect, useState, Fragment, useContext, useCallback } from "react";
import { Col, Card } from "reactstrap";
import { Scrollbars } from "react-custom-scrollbars";
import { zoneGraphic, zoneGraphicOverlays } from "../../../../api/zoneGraphics";
import Loader from "../../../common/Loader";
import { UserPreferencesContext } from "../../../../context/userPreferences";
import withRedirect from "../../../../hoc/withRedirect";
import { logEvent } from "../../../../helpers/ga";
import local from "../../../../localization/strings";
import { zoneGraphicGroupArrow } from "../../../../helpers/utils";

const DashZoneGraphic = ({ locationId, setRedirect, setRedirectUrl }) => {
	const [loading, setLoading] = useState(true);
	const [image, setImage] = useState(null);
	const [captions, setCaptions] = useState([]);
	const [groups, setGroups] = useState([]);
	const titleFontSize = "8pt";
	const fontSize = "8pt";
	const font = "Verdana";
	const { getZoneDetails } = useContext(UserPreferencesContext);
	const localGetZoneDetails = useCallback((id) => getZoneDetails(id), [getZoneDetails]);

	useEffect(() => {
		let isSubscribed = true;
		const zoneId = locationId;

		async function setZoneGraphic() {
			var result = await zoneGraphic(zoneId);

			if (isSubscribed) {
				setImage(result);
				var overlays = await zoneGraphicOverlays(zoneId);

				if (isSubscribed) {
					setCaptions(overlays.captions);
					setGroups(overlays.groups);

					setLoading(false);
				}
			}
		}

		setZoneGraphic();
		return () => (isSubscribed = false);
	}, [locationId]);

	const groupClicked = (group) => {
		logEvent("Zone Graphics", "Group Clicked on Dashboard Widget", `ZoneId: ${locationId}, GroupId: ${group.groupId}`);

		setRedirectUrl(`/charts/zone_charts/${locationId}/${group.groupId}`);
		setRedirect(true);
	};

	return (
		<Col className="col-12 col-lg-6 float-left">
			<Card className="mb-2 rounded-soft dash-border-grey" style={{ maxHeight: "460px" }}>
				<Scrollbars autoHeight autoHeightMax={460} width={"100%"}>
					<div id="zone-graphic-div">
						{loading ? (
							<Loader />
						) : (
							<Fragment>
								<div id={"zg_" + locationId} className="zone-graphic-info p-2">
									<p className="mx-2 my-auto">{localGetZoneDetails(locationId)}</p>
								</div>
								<div className="p2 zone-graphic-map">
									<img alt="" className="" src={`data:image/png;base64,${image}`} />
									{/* Do all lines first or they stop you clicking on group boxes */}
									{groups.map((group, index) => {
										return <Fragment key={`${index}_Line`}>{group.hasArrow && <div style={zoneGraphicGroupArrow(group)} />}</Fragment>;
									})}
									{groups.map((group, index) => {
										return (
											<Fragment key={index}>
												<div className="zone-graphic-readings" style={{ left: `${group.x}px`, top: `${group.y}px`, position: "absolute", backgroundColor: "white", borderColor: "black", borderWidth: "1px", borderStyle: "solid", padding: "5px 10px", textAlign: "left", whiteSpace: "nowrap", lineHeight: "1.2" }} onClick={() => groupClicked(group)}>
													<div style={{ fontSize: titleFontSize, fontWeight: "bold", fontFamily: font }}>{group.name}</div>
													{group.readings.map((reading, readingIndex) => (
														<Fragment key={readingIndex}>
															{reading.realValue !== 0 && !reading.realValue ? (
																<div style={{ fontSize: fontSize, fontFamily: font }}>
																	{reading.name}:<span style={{ padding: "0 2px" }}>{local.TS_NO_DATA}</span>
																</div>
															) : reading.mainAlarm ? (
																<div style={{ fontSize: fontSize, fontFamily: font }}>
																	{reading.name}:<span style={{ backgroundColor: "red", color: "white", padding: "0 2px" }}>{reading.realValue.toFixed(reading.decimalPlaces)}</span>
																</div>
															) : reading.warnAlarm ? (
																<div style={{ fontSize: fontSize, fontFamily: font }}>
																	{reading.name}:<span style={{ backgroundColor: "orange", color: "white", padding: "0 2px" }}>{reading.realValue.toFixed(reading.decimalPlaces)}</span>
																</div>
															) : (
																<div style={{ fontSize: fontSize, fontFamily: font }}>
																	{reading.name}:<span style={{ padding: "0 2px" }}>{reading.realValue.toFixed(reading.decimalPlaces)}</span>
																</div>
															)}
														</Fragment>
													))}
												</div>
											</Fragment>
										);
									})}
									{captions.map((caption, index) => (
										<div key={index} style={{ left: `${caption.x}px`, top: `${caption.y}px`, position: "absolute", backgroundColor: "white", borderColor: "black", borderWidth: "1px", borderStyle: "solid", padding: "5px", textAlign: "left", whiteSpace: "nowrap", lineHeight: "1" }}>
											<div style={{ fontSize: fontSize, fontFamily: font }}>{caption.caption}</div>
										</div>
									))}
								</div>
							</Fragment>
						)}
					</div>
				</Scrollbars>
			</Card>
		</Col>
	);
};

export default withRedirect(DashZoneGraphic);

import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import withRedirect from "../../../hoc/withRedirect";
import { Button, Card, Col, Row } from "reactstrap";
import PageTitle from "../../common/PageTitle";
import Loader from "../../common/Loader";
import local from "../../../localization/strings";
import { apiLoggerAdd } from "../../../api/logger";

const defaultData = {
    name: '',
    serialNumber: '',
}

const LoggerWizard = ({ setRedirect, setRedirectUrl }) => {
	const [loading, setLoading] = useState(true);

    useEffect(() => {

        setLoading(false);

    }, []);

    const handleCancel = () => {
        setRedirectUrl("/site_settings");
		setRedirect(true);
    }

    async function save(_e, values) {
        await apiLoggerAdd(values.name, values.serialNumber);
        
        handleCancel();
	}

    return (
		<>
			{loading ? (
				<Loader />
			) : (
				<>
					<PageTitle title={local.TF_Add_a_Logger} />
					<Card className="p-4">
					<AvForm model={defaultData} onValidSubmit={async (e, values) => await save(e, values)}>
						<AvField name="name" label={local.TS_Name} validate={{ required: { value: true, errorMessage: local.TS_Required } }} maxLength={24} />
                        <AvField name="serialNumber" label={local.TS_Serial_Number} validate={{ required: { value: true, errorMessage: local.TS_Required } }} maxLength={12} />

						<Row>
							<Col>
								<Button color="primary" className="mt-2 float-left">
									{local.TS_Save}
								</Button>
								<Button color="secondary" className="mt-2 ml-2 float-left" type="button" onClick={handleCancel}>
									{local.TS_Cancel}
								</Button>
							</Col>
						</Row>
					</AvForm>
					</Card>
				</>
			)}
		</>
	);

}

export default withRedirect(LoggerWizard);
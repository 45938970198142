import { CurrentUserSites, CurrentSites } from "./auth";

const KEY_CurrentSiteId = "CurrentSiteId";
const KEY_CurrentDepartmentId = "CurrentDepartmentId";

export const CurrentSiteIdGet = () => {
	const currentValue = localStorage.getItem(KEY_CurrentSiteId);
	if (currentValue && currentValue.includes("/")) {
		localStorage.removeItem(KEY_CurrentSiteId);
		localStorage.removeItem(KEY_CurrentDepartmentId);
	}

	const accessToken = localStorage.getItem("AccessToken") || "";
	if (accessToken === "") {
		return "";
	}

	var currentSites = CurrentUserSites();

	if (currentSites.length === 0) {
		localStorage.removeItem(KEY_CurrentSiteId);
		localStorage.removeItem(KEY_CurrentDepartmentId);
		return "";
	}

	var currentSiteId = localStorage.getItem(KEY_CurrentSiteId) || "";

	if (currentSiteId === "" || !currentSites.includes(currentSiteId)) {
		storeSiteIdAndDepartmentId(currentSites[0]);
		return currentSites[0].split("/")[0];
	}

	return currentSiteId;
};

export const CurrentDepartmentIdGet = () => {
	const stored = localStorage.getItem(KEY_CurrentDepartmentId);
	if (stored) {
		return parseInt(stored);
	}
	return null;
};

const storeSiteIdAndDepartmentId = (input) => {
	const parts = input.split("/");
	localStorage.setItem(KEY_CurrentSiteId, parts[0]);
	if (parts.length > 1) {
		localStorage.setItem(KEY_CurrentDepartmentId, parts[1]);
	} else {
		localStorage.removeItem(KEY_CurrentDepartmentId);
	}
};

export const CurrentSiteIdSet = (siteId) => {
	var currentSites = CurrentUserSites();

	if (currentSites.includes(siteId)) {
		storeSiteIdAndDepartmentId(siteId);
	} else {
		localStorage.removeItem(KEY_CurrentSiteId);
		localStorage.removeItem(KEY_CurrentDepartmentId);
	}
};

export const CurrentSiteProductType = () => {
	const sites = CurrentSites();

	var currentSiteId = localStorage.getItem(KEY_CurrentSiteId) || undefined;

	if (currentSiteId) {
		const currentSite = sites.find((x) => x.startsWith(currentSiteId));

		if (currentSite) {
			return currentSite.split(":")[1];
		}
	}
	return "Lite";
};

export const CurrentSiteDetails = () => {
	const sites = CurrentSites();

	var currentSiteId = localStorage.getItem(KEY_CurrentSiteId) || undefined;

	if (currentSiteId) {
		const currentSite = sites.find((x) => x.startsWith(currentSiteId));

		const colonParts = currentSite.split(":");
		const siteParts = colonParts[0].split("/");

		return {
			siteId: siteParts[0],
			departmentId: siteParts.length > 1 ? parseInt(siteParts[1]) : null,
			productType: colonParts[1],
		};
	}

	return null;
};

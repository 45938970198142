import React, { useContext } from "react";
import { Collapse, Navbar, NavbarToggler, NavItem, Nav } from "reactstrap";
import AppContext from "../../context/AppContext";
import Logo from "./LogoMini";
import SearchBox from "./SearchBox";
import ProfileDropdown from "./ProfileDropdown";
import NotificationDropdown from "./NotificationDropdown";
import { isFeatureEnabled, features } from "../../config";

const NavbarTop = ({ admin }) => {
	const { showBurgerMenu, setShowBurgerMenu } = useContext(AppContext);

	return (
		<Navbar light className="navbar-glass fs--1 font-weight-semi-bold row navbar-top sticky-kit" expand>
			<NavbarToggler onClick={() => setShowBurgerMenu(!showBurgerMenu)} id="burgerMenu" />
			<Logo at="navbar-top" width={80} id="topLogo" />
			<Collapse navbar>
				<Nav navbar className="align-items-center d-none d-lg-block">
					<NavItem>{isFeatureEnabled(features.Search) && <SearchBox />}</NavItem>
				</Nav>

				<Nav navbar className="align-items-center ml-auto">
					{admin ? "" : isFeatureEnabled(features.CurrentAlarms) ? <NotificationDropdown /> : ""}

					<ProfileDropdown admin={admin} />
				</Nav>
			</Collapse>
		</Navbar>
	);
};

export default NavbarTop;

import arrayMove from "array-move";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.scss";
import { Card } from "reactstrap";
import { zoneChartList } from "../../api/chart";
import { UserPreferencesContext } from "../../context/userPreferences";
import { logEvent } from "../../helpers/ga";
import { dynamicSortAlphaNumericMultiple } from "../../helpers/sort";
import useKeyPress from "../../hooks/useKeyPress";
import Loader from "../common/Loader";
import GroupChart from "./GroupChart";
import { siteMapFlat } from "../../api/site";

const ZoneChart = ({ selectedZoneId, match, tooltip, toggleTooltip }) => {
	const [groups, setGroups] = useState([]);
	const [tabNames, setTabNames] = useState([]);
	const [showLoading, setShowLoading] = useState(true);
	const [isMaximised, setIsMaximised] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [focusOn, setFocusOn] = useState(undefined);
	const [siteMap, setSiteMap] = useState([]);

	const leftPress = useKeyPress("ArrowLeft");
	const rightPress = useKeyPress("ArrowRight");
	const timeoutRef = useRef(null);
	const { prefsLoaded, prefsObj } = useContext(UserPreferencesContext);

	const tabActive = () => {
		const currentActive = document.activeElement;

		if (currentActive.className && currentActive.className.includes("react-tabs__tab--selected")) {
			return true;
		}
		return false;
	};

	const moveLeft = useCallback(() => {
		logEvent("Zone Charts", "Left Arrow Key");
		if (selectedTab > 0) {
			setSelectedTab(selectedTab - 1);
		}
	}, [selectedTab]);

	const moveRight = useCallback(() => {
		logEvent("Zone Charts", "Right Arrow Key");
		if (selectedTab < groups.length - 1) {
			setSelectedTab(selectedTab + 1);
		}
	}, [selectedTab, groups.length]);

	useEffect(() => {
		if (leftPress && !tabActive()) {
			if (timeoutRef.current !== null) {
				clearTimeout(timeoutRef.current);
			}

			timeoutRef.current = setTimeout(() => {
				timeoutRef.current = null;

				moveLeft();
			}, 500);
		}
	}, [leftPress, moveLeft]);

	useEffect(() => {
		if (rightPress && !tabActive()) {
			if (timeoutRef.current !== null) {
				clearTimeout(timeoutRef.current);
			}

			timeoutRef.current = setTimeout(() => {
				timeoutRef.current = null;

				moveRight();
			}, 500);
		}
	}, [rightPress, moveRight]);

	useEffect(() => {
		const LoadSiteMap = async () => {
			const result = await siteMapFlat();
			setSiteMap(result);
		};
		LoadSiteMap();
	}, []);

	useEffect(() => {
		if (tabNames.length > 0) {
			return;
		}

		if (prefsLoaded && siteMap && siteMap.length > 0) {
			const data = groups;

			const overlaysObject = JSON.parse(prefsObj?.ChartOverlays || "{}");
			const visibilityObject = JSON.parse(prefsObj?.ChartVisibility || "{}");
			const orderObject = JSON.parse(prefsObj?.ChartOrder || "{}");

			data.forEach((x) => {
				const defaultLocations = [];
				let currentLocationIds = [];

				x.units.split(" | ").forEach((u) => {
					const found = siteMap.find((sm) => sm.groupId === x.groupId && sm.locationName === u);
					if (found) {
						defaultLocations.push(found);
					}
				});

				if (defaultLocations.length > 0) {
					//Overlays
					const prefValueOverlays = overlaysObject[`S/${defaultLocations[0].zoneId}/${x.groupId}/${x.graphIndex}`];
					if (prefValueOverlays) {
						let prefArray = JSON.parse(prefValueOverlays).map((l) => parseInt(l));

						currentLocationIds = defaultLocations.filter((l) => prefArray.includes(l.locationId)).map((dl) => dl.locationId);
						currentLocationIds.push(...prefArray.filter((l) => !currentLocationIds.includes(l)));
					} else {
						currentLocationIds = defaultLocations.map((dl) => dl.locationId);
					}

					//Visibility
					const prefValueVisibility = visibilityObject[`S/${defaultLocations[0].zoneId}/${x.groupId}/${x.graphIndex}`];
					if (prefValueVisibility) {
						JSON.parse(prefValueVisibility).forEach((v) => {
							const parts = v.split(":");
							if (parts.length > 1 && parts[1] === "false") {
								currentLocationIds = currentLocationIds.filter((a) => parseInt(a) !== parseInt(parts[0]));
							}
						});
					}

					//Order
					const prefValueOrder = orderObject[`S/${defaultLocations[0].zoneId}/${x.groupId}/${x.graphIndex}`];
					if (prefValueOrder) {
						let position = 0;
						JSON.parse(prefValueOrder).forEach((v) => {
							const foundIndex = currentLocationIds.findIndex((x) => parseInt(x) === parseInt(v));
							if (foundIndex > -1) {
								currentLocationIds = arrayMove(currentLocationIds, foundIndex, position);

								++position;
							}
						});
					}

					const currentLocations = [];
					currentLocationIds.forEach((cli) => {
						const found = siteMap.find((sm) => sm.locationId === parseInt(cli));
						if (found && found.parameterPlotted) {
							currentLocations.push(found);
						}
					});

					//Build Tab Name
					const zones = [...new Set(currentLocations.map((item) => item.zoneName))].map((z) => {
						return {
							name: z,
							groups: [...new Set(currentLocations.filter((x) => x.zoneName === z).map((f) => f.groupName))].map((g) => {
								return {
									name: g,
									parameters: [...new Set(currentLocations.filter((x) => x.groupName === g).map((f) => f.locationName))],
								};
							}),
						};
					});

					const zonesDisplay = zones.map((z) => {
						const groupsDisplay = z.groups.map((g) => `${g.name} (${g.parameters.join("|")})`);
						return (zones.length > 1 ? `${z.name} - ` : "") + groupsDisplay.join(", ");
					});
					x.groupName = zonesDisplay.join(". ");
				}
			});
			setTabNames(data);
		}
	}, [tabNames, groups, prefsLoaded, prefsObj, siteMap]);

	useEffect(() => {
		let isSubscribed = true;
		async function LoadData() {
			if (selectedZoneId) {
				let groupId = null;

				if (match && match.params && match.params.groupId) {
					groupId = match.params.groupId;
				}

				if (match && match.params && match.params.focusOn) {
					setFocusOn(match.params.focusOn);
				} else {
					setFocusOn(undefined);
				}

				var data = await zoneChartList(selectedZoneId, groupId);

				if (isSubscribed) {
					data.sort(dynamicSortAlphaNumericMultiple("groupIndex", "graphIndex"));
					setGroups(data);
					setShowLoading(false);
				}
			}
		}

		LoadData();

		return () => (isSubscribed = false);
	}, [selectedZoneId, match]);

	const updateTabName = useCallback((groupId, graphIndex, groupName) => {
		setTabNames((x) => {
			const index = x.findIndex((x) => x.groupId === groupId && x.graphIndex === graphIndex);
			const updatedTab = { ...x[index], groupName: groupName };
			return [...x.slice(0, index), updatedTab, ...x.slice(index + 1)];
		});
	}, []);

	return (
		<Card className={isMaximised ? "fixed-top maximised rounded-soft" : "mt-2 rounded-soft"}>
			{showLoading ? (
				<Loader />
			) : (
				<>
					{tabNames.length > 0 && (
						<Tabs selectedIndex={selectedTab} onSelect={(index) => setSelectedTab(index)}>
							<TabList style={{ overflowX: "auto", overflowY: "hidden", whiteSpace: "nowrap" }}>
								{tabNames.map((tab) => (
									<Tab key={`${tab.groupId}_${tab.graphIndex}`}>{tab.groupName}</Tab>
								))}
							</TabList>

							{groups.map((group, index) => (
								<TabPanel key={index} forceRender>
									<GroupChart key={`${group.groupId}_${group.graphIndex}`} zoneId={selectedZoneId} group={group} chartIndex={group.graphIndex} setIsMaximise={(x) => setIsMaximised(x)} isMaximised={isMaximised} isCurrentTab={index === selectedTab} toggleTooltip={toggleTooltip} tooltip={tooltip} focusOn={focusOn} updateTabName={updateTabName} />
								</TabPanel>
							))}
						</Tabs>
					)}
				</>
			)}
		</Card>
	);
};

export default withRouter(ZoneChart);

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Card, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { logEvent } from "../../../../helpers/ga";
import { uniqueTransmitterSerial } from "../../../../helpers/utils";
import withRedirect from "../../../../hoc/withRedirect";
import local from "../../../../localization/strings";
import Loader from "../../../common/Loader";

import CanvasJSReact from "../../../../lib/canvasjs.react";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const BatteryLevelDoughnut = ({ latestReadings, viewBatteries }) => {
	const [data, setData] = useState([]);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	const [lowBatteries, setLowBatteries] = useState([]);

	useEffect(() => {
		let isSubscribed = true;
		setLoading(true);
		const LoadData = () => {
			var transmitterArray = [];
			var lowBatteryArray = [];

			if (latestReadings) {
				//filter for the first instance of a transmitter's serial number to avoid duplicate battery levels
				const transmitters = uniqueTransmitterSerial(latestReadings.filter((x) => x.transmitterSerialNumber), (item) => item.transmitterSerialNumber);
				if (transmitters) {
					const batteryLevels = transmitters.map(({ transmitterBatteryLevel }) => transmitterBatteryLevel);
					function inRange(x) {
						return this[0] <= x && x <= this[1];
					}
					var batteryVeryLow = batteryLevels.filter(inRange, [0, 10]).length;
					var batteryLow = batteryLevels.filter(inRange, [11, 20]).length;
					var batteryNormal = batteryLevels.filter(inRange, [21, 100]).length;
					var batteryUnknown = batteryLevels.filter(inRange, [101, 256]).length;
					var batteryNull = batteryLevels.filter(function(value) {
						return value === null;
					}).length;

					for (var i = 0; i < transmitters.length; i++) {
						if (transmitters[i].transmitterBatteryLevel > 100 || transmitters[i].transmitterBatteryLevel === null) {
							transmitters[i].transmitterBatteryLevel = -1;
						}
						transmitters[i].transmitterBatteryLevelExport = transmitters[i].transmitterBatteryLevel === -1 ? "-" : transmitters[i].transmitterBatteryLevel;
						lowBatteryArray.push(transmitters[i]);
					}

					if (batteryVeryLow > 0) {
						transmitterArray.push({ name: local.TF_Very_Low, y: batteryVeryLow, color: "#e63757" });
					}
					if (batteryLow > 0) {
						transmitterArray.push({ name: local.TS_Low, y: batteryLow, color: "#fcdd32" });
					}
					if (batteryNormal > 0) {
						transmitterArray.push({ name: local.TS_Normal, y: batteryNormal, color: "#18aa6a" });
					}
					if (batteryUnknown > 0 || batteryNull > 0) {
						transmitterArray.push({ name: local.TS_Unknown, y: batteryUnknown + batteryNull, color: "#888" });
					}
				}
			}

			if (isSubscribed) {
				setLowBatteries(lowBatteryArray);
				setData(transmitterArray);
				setLoading(false);
			}
		};
		LoadData();
		return () => (isSubscribed = false);
	}, [latestReadings]);

	const options = {
		animationEnabled: false,
		subtitles: [
			{
				text: local.TF_Battery_Levels,
				verticalAlign: "center",
				fontSize: 16,
				dockInsidePlotArea: true,
				fontFamily: ["Poppins", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"],
			},
		],
		legend: {
			fontSize: 14,
		},
		data: [
			{
				type: "doughnut",
				startAngle: 120,
				showInLegend: true,
				innerRadius: "85%",
				dataPoints: data,
			},
		],
	};

	const showBatteryLevels = () => {
		logEvent("View Battery Levels Table", "Menu Clicked on Dashboard Widget");
		if (viewBatteries) {
			viewBatteries(lowBatteries);
		}
	};

	return (
		<Col className="col-12 col-sm-6 col-lg-3 mb-2 d-flex align-items-stretch">
			<Card className="rounded-soft dash-border-grey w-100 p-1">
				{loading ? (
					<div className="ml-2">
						<Loader />
					</div>
				) : (
					<>
						{lowBatteries.length > 0 && (
							<div className="widget-menu" style={{ position: "absolute", right: 10, top: 10 }}>
								<Dropdown isOpen={open} toggle={() => setOpen(!open)}>
									<DropdownToggle tag="div" color="secondary" transform="shrink-3" size="sm" style={{ color: "#46505e" }}>
										<FontAwesomeIcon icon="ellipsis-v" fixedWidth />
									</DropdownToggle>
									<DropdownMenu right className="border">
										<DropdownItem onClick={() => showBatteryLevels()}>{local.TF_View_in_table}</DropdownItem>
									</DropdownMenu>
								</Dropdown>
							</div>
						)}
						<CanvasJSChart options={options} containerProps={{ height: "90%", minHeight: "250px" }} />
					</>
				)}
			</Card>
		</Col>
	);
};

export default withRedirect(BatteryLevelDoughnut);

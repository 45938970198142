import Datetime from "react-datetime";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import "moment/min/locales";
import local from "../../localization/strings";
import moment, { isMoment } from "moment";
import { configSiteAnnualSmsSegmentLimit } from "../../config";

const SiteConfigure = ({ site, save, cancel }) => {
	const [siteId, setSiteId] = useState(undefined);
	const [siteProduct, setSiteProduct] = useState(undefined);
	const [siteMaxAge, setSiteMaxAge] = useState(undefined);
	const [siteAlarmsEnabled, setSiteAlarmsEnabled] = useState(undefined);
	const [siteAlarmRecordingEnabled, setSiteAlarmRecordingEnabled] = useState(undefined);
	const [siteSmsAlarmNotificationsEnabled, setSiteSmsAlarmNotificationsEnabled] = useState(undefined);
	const [siteAnnualSmsSegmentLimit, setSiteAnnualSmsSegmentLimit] = useState(undefined);
	const [siteDateCreated, setSiteDateCreated] = useState(undefined);
	const [language, setLanguage] = useState("en");

	useEffect(() => {
		setLanguage(local.getLanguage());
	}, []);

	useEffect(() => {
		if (site) {
			setSiteId(site.id);
			setSiteProduct(site.productType);
			setSiteMaxAge(site.maxReadingsAgeMonths || 0);
			setSiteAlarmsEnabled(site.alarmNotificationsEnabled);
			setSiteAlarmRecordingEnabled(site.alarmRecordingEnabled);
			setSiteSmsAlarmNotificationsEnabled(site.smsAlarmNotificationsEnabled);
			setSiteAnnualSmsSegmentLimit(site.annualSmsSegmentLimit);
			setSiteDateCreated(moment(site.dateCreated).format("DD MMM YYYY"));
		} else {
			setSiteId(undefined);
			setSiteProduct(undefined);
			setSiteMaxAge(undefined);
			setSiteAlarmsEnabled(undefined);
			setSiteAlarmRecordingEnabled(undefined);
			setSiteSmsAlarmNotificationsEnabled(undefined);
			setSiteAnnualSmsSegmentLimit(configSiteAnnualSmsSegmentLimit);
			setSiteDateCreated(undefined);
		}
	}, [site]);

	const dateCreatedChanged = (e) => {
		if (isMoment(e)) {
			setSiteDateCreated(e);
		}
	};

	const dateCreatedBlur = (e) => {
		if (isMoment(e)) {
			setSiteDateCreated(e);
		} else {
			setSiteDateCreated(moment());
		}
	};

	return (
		<Card className="p-3">
			{siteId && (
				<Form onSubmit={async (e) => await save(e, siteId, siteProduct, siteAlarmsEnabled, siteAlarmRecordingEnabled, siteSmsAlarmNotificationsEnabled, siteMaxAge, siteDateCreated, siteAnnualSmsSegmentLimit)}>
					<Label for="product">{local.TF_ChangeProductTypeTo}:</Label>
					<Input type="select" className="form-control mb-2" value={siteProduct} onChange={({ target }) => setSiteProduct(target.value)}>
						<option value="Lite">{local.TS_Lite}</option>
						<option value="Standard">{local.TS_Standard}</option>
						{/* <option value="Plus">{local.TS_Plus}</option>
                            <option value="Pro">{local.TS_Pro}</option> */}
					</Input>
					<Label>
						{local.TF_ChangeMaxAge}: {siteMaxAge === 0 ? "Off" : siteMaxAge}
					</Label>
					<div className="mx-2 mb-2">
						<Slider min={0} max={60} defaultValue={0} value={siteMaxAge} dots onChange={(value) => setSiteMaxAge(value)} />
					</div>
					<FormGroup tag="fieldset">
						<Label for="alarmRadio">{local.TF_Alarm_Notifications_in_DC}:</Label>
						<FormGroup check>
							<Label check>
								<Input type="radio" value="true" name="alarmRadio" onChange={() => setSiteAlarmsEnabled(true)} checked={siteAlarmsEnabled ? true : false} /> {local.TS_Enabled}
							</Label>
						</FormGroup>
						<FormGroup check>
							<Label check>
								<Input type="radio" value="false" name="alarmRadio" onChange={() => setSiteAlarmsEnabled(false)} checked={!siteAlarmsEnabled ? true : false} /> {local.TS_Disabled}
							</Label>
						</FormGroup>
					</FormGroup>
					<FormGroup tag="fieldset">
						<Label for="alarmRecord">{local.TF_Alarm_Recording_in_DC}:</Label>
						<FormGroup check>
							<Label check>
								<Input type="radio" value="true" name="alarmRecord" onChange={() => setSiteAlarmRecordingEnabled(true)} checked={siteAlarmRecordingEnabled ? true : false} /> {local.TS_Enabled}
							</Label>
						</FormGroup>
						<FormGroup check>
							<Label check>
								<Input type="radio" value="false" name="alarmRecord" onChange={() => setSiteAlarmRecordingEnabled(false)} checked={!siteAlarmRecordingEnabled ? true : false} /> {local.TS_Disabled}
							</Label>
						</FormGroup>
					</FormGroup>
					<FormGroup tag="fieldset">
						<Label for="smsAlarmNotifications">{local.TF_SMS_Alarms}:</Label>
						<FormGroup check>
							<Label check>
								<Input type="radio" value="true" name="smsAlarmNotifications" onChange={() => setSiteSmsAlarmNotificationsEnabled(true)} checked={siteSmsAlarmNotificationsEnabled ? true : false} /> {local.TS_Enabled}
							</Label>
						</FormGroup>
						<FormGroup check>
							<Label check>
								<Input type="radio" value="false" name="smsAlarmNotifications" onChange={() => setSiteSmsAlarmNotificationsEnabled(false)} checked={!siteSmsAlarmNotificationsEnabled ? true : false} /> {local.TS_Disabled}
							</Label>
						</FormGroup>
					</FormGroup>
					<FormGroup tag="fieldset">
						<Label for="annualSmsSegmentLimit">{local.TF_SMS_AnnualSegmentLimit}:</Label>
						<div className="mx-2 mb-2">
							<Input type="number" min="0" value={siteAnnualSmsSegmentLimit} name="annualSmsSegmentLimit" onChange={({ target }) => setSiteAnnualSmsSegmentLimit(target.value)} />
						</div>
					</FormGroup>
					<Label>{local.TF_Created}</Label>
					<div className="mx-2 mb-2">
						<Datetime timeFormat={null} dateFormat="DD MMM YYYY" value={siteDateCreated} onBlur={dateCreatedBlur} onChange={dateCreatedChanged} closeOnSelect={true} input={true} locale={language} utc={true} />
					</div>
					<Row>
						<Col>
							<Button color="primary" className="mt-2 float-left">
								{local.TS_Save_Changes}
							</Button>
							<Button color="secondary" className="mt-2 ml-2 float-left" onClick={cancel}>
								{local.TS_Cancel}
							</Button>
						</Col>
					</Row>
				</Form>
			)}
		</Card>
	);
};

export default SiteConfigure;

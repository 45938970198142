import axios from "axios";
import { CurrentSiteIdGet } from "./userSettings";

export const apiLocationsGetListForSite = async (siteId, groupId) => {
	try {
		const result = await axios({
			method: "get",
			url: "v2/Locations",
			params: {
				siteId,
				groupId,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const apiLocationsGetList = async (groupId) => {
	const siteId = CurrentSiteIdGet();

	return await apiLocationsGetListForSite(siteId, groupId);
};

export const apiLocationGet = async (groupId, id) => {
	const locations = await apiLocationsGetList(groupId);

	return locations.find((x) => x.id === id);
};

export const apiLocationSaveAlarms = async (id, parameterId, groupId, warnAlarmLo, warnAlarmHi, mainAlarmLo, mainAlarmHi) => {
	const siteId = CurrentSiteIdGet();

	await axios({
		method: "post",
		url: "v2/Location",
		data: {
			siteId,
			location: {
				id,
				parameterId,
				groupId,
				warnAlarmLo,
				warnAlarmHi,
				mainAlarmLo,
				mainAlarmHi,
			},
			updateType: "Alarms",
		},
	});
};

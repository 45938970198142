import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import local from "../../localization/strings";

const BatteryIcon = ({ reading }) => {
	const batteryLabel = " " + local.TF_Battery + ": ";
	const batteryLevel = reading;
	const unit = "%";
	const batteryText = batteryLabel + batteryLevel + unit;
	const batteryNoData = batteryLabel + " - ";
	const noBatteryLevel = " " + local.TF_Unknown_Battery_Level;

	if (batteryLevel === 255 || batteryLevel === null || batteryLevel === -1) {
		return (
			<Fragment>
				<FontAwesomeIcon size="lg" icon="battery-empty" color="#9c9c9c" />
				<span className="shift-left-no-bg">
					<FontAwesomeIcon size="lg" icon="question" color="#dc3545" />
				</span>
				{batteryNoData}
			</Fragment>
		);
	} else if (batteryLevel >= 85) {
		return (
			<Fragment>
				<FontAwesomeIcon size="lg" icon="battery-full" color="#28a745" />
				{batteryText}
			</Fragment>
		);
	} else if (batteryLevel >= 60) {
		return (
			<Fragment>
				<FontAwesomeIcon size="lg" icon="battery-three-quarters" color="#28a745" />
				{batteryText}
			</Fragment>
		);
	} else if (batteryLevel >= 40) {
		return (
			<Fragment>
				<FontAwesomeIcon size="lg" icon="battery-half" color="#f0ad4e" />
				{batteryText}
			</Fragment>
		);
	} else if (batteryLevel >= 15) {
		return (
			<Fragment>
				<FontAwesomeIcon size="lg" icon="battery-quarter" color="#f0ad4e" />
				{batteryText}
			</Fragment>
		);
	} else if (batteryLevel >= 5) {
		return (
			<Fragment>
				<FontAwesomeIcon size="lg" icon="battery-empty" color="#dc3545" />
				{batteryText}
			</Fragment>
		);
	} else if (batteryLevel >= 0) {
		return (
			<Fragment>
				<FontAwesomeIcon size="lg" icon="battery-slash" color="#dc3545" />
				{batteryText}
			</Fragment>
		);
	} else {
		return (
			<Fragment>
				<FontAwesomeIcon size="lg" icon="times" color="red" />
				{noBatteryLevel}
			</Fragment>
		);
	}
};

export default BatteryIcon;

import moment from "moment";
import React, { useEffect, useState } from "react";
import { multiChartData } from "../../../api/chart";
import CanvasJSReact from "../../../lib/canvasjs.react";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const MultiPeriodChart = ({ chart, timeSpan, filterData, heightOverride }) => {
	const [options, setOptions] = useState({});
	const [ref, setRef] = useState(undefined);

	function chartZoomed(e, ref) {
		function dateFormatString(min, max) {
			if (!min || !max) {
				return "DD MMM YY";
			}

			const days = (max - min) / 1000.0 / 86400.0;

			if (days > 3) {
				return "DD MMM YY";
			}

			if (days > 1) {
				return "DD MMM HH:mm";
			}

			if (days > 0.1) {
				return "HH:mm";
			}

			return "HH:mm:ss";
		}

		function dateFormatStringLong(min, max) {
			if (!min || !max) {
				return "DD MMM YY HH:mm";
			}

			const days = (max - min) / 1000.0 / 86400.0;

			if (days > 0.1) {
				return "DD MMM YY HH:mm";
			}

			return "DD MMM YY HH:mm:ss";
		}

		if (ref && ref.axisX && ref.axisX[0]) {
			if (e.trigger === "reset") {
				ref.subtitles[0].set("text", `${moment(e.chart.axisX[0].viewportMinimum).format("DD MMM YYYY HH:mm")} - ${moment(e.chart.axisX[0].viewportMaximum).format("DD MMM YYYY HH:mm")}`);
				ref.axisX[0].set("valueFormatString", dateFormatString(e.chart.axisX[0].viewportMinimum, e.chart.axisX[0].viewportMaximum));
				ref.data[0].set("xValueFormatString", dateFormatStringLong(e.chart.axisX[0].viewportMinimum, e.chart.axisX[0].viewportMaximum));
			} else {
				ref.subtitles[0].set("text", `${moment(e.axisX[0].viewportMinimum).format("DD MMM YYYY HH:mm")} - ${moment(e.axisX[0].viewportMaximum).format("DD MMM YYYY HH:mm")}`);
				ref.axisX[0].set("valueFormatString", dateFormatString(e.axisX[0].viewportMinimum, e.axisX[0].viewportMaximum));
				ref.data[0].set("xValueFormatString", dateFormatStringLong(e.axisX[0].viewportMinimum, e.axisX[0].viewportMaximum));
			}
		}
	}

	useEffect(() => {
		const LoadData = async () => {
			var result = await multiChartData(chart.groupId, chart.graphIndex, timeSpan, filterData.startTime, filterData.period, filterData.noOfPeriod);

			result.data.forEach((element) => {
				element.dataPoints.forEach((point) => {
					point.x = new Date(point.x);
				});
			});

			if (result.axisX) {
				result.axisX.crosshair.snapToDataPoint = true;

				if (result.axisX.minimum) {
					result.axisX.minimum = new Date(result.axisX.minimum);
				}

				if (result.axisX.maximum) {
					result.axisX.maximum = new Date(result.axisX.maximum);
				}
			}
			if (result.axisX2) {
				result.axisX2.crosshair.snapToDataPoint = true;
			}
			if (result.axisY) {
				result.axisY.crosshair.snapToDataPoint = true;
			}
			if (result.axisY2) {
				result.axisY2.crosshair.snapToDataPoint = true;
			}

			setOptions(result);
		};

		LoadData();
	}, [chart, timeSpan, filterData]);

	useEffect(() => {
		let subscribed = true;
		if (ref) {
			ref.render();
			//Doing it straight away stops it wiring up
			setTimeout(() => {
				if (ref && subscribed) {
					try {
						ref.set("rangeChanged", (e) => chartZoomed(e, ref));
					} catch (ex) {
						console.error(ex);
					}
				}
			}, 1000);
		}
		return () => {
			subscribed = false;
		};
	}, [ref]);

	return <CanvasJSChart options={options} onRef={(x) => setRef(x)} containerProps={{ height: heightOverride, minHeight: heightOverride }} />;
};

export default MultiPeriodChart;

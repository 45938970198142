import React, { Component } from "react";
import ReactGA from "react-ga";
import {CurrentSiteIdGet} from '../api/userSettings';

export default function withTracker(WrappedComponent, options = {}) {
	const trackPage = (page) => {
		ReactGA.set({
			page,
			dimension1: CurrentSiteIdGet(),
			...options,
		});
		ReactGA.pageview(page);
	};

	const HOC = class extends Component {
		componentDidMount() {
			const page = this.props.location.pathname;
			trackPage(page);
		}
		componentDidUpdate() {
			const page = this.props.location.pathname;
			trackPage(page);
		}

		render() {
			return <WrappedComponent {...this.props} />;
		}
	};

	return HOC;
}

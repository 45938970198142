import { faBars, faCheckSquare, faChevronDown, faChevronRight, faCloud, faFile, faFolder, faFolderOpen, faMinusSquare, faPlusSquare, faRouter, faSquare, faWarehouse } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { siteMap } from "../../api/site";
import local from "../../localization/strings";
import ButtonIcon from "../common/ButtonIcon";
import Loader from "../common/Loader";

const SiteTreeMultiSelect = ({ onFilterApply, initialChecked, expandToLevel, siteMapLevel = 3, inline = false, dashPin = false, onCheckedChanged, limitToGroupIds, removeDisabled = true, onlyIfHasZoneGraphic = false }) => {
	const [nodes, setNodes] = useState([]);
	const [expanded, setExpanded] = useState([]);
	const [checked, setChecked] = useState([]);
	const [loading, setLoading] = useState(true);

	const setExpandedCallback = useCallback(
		(data) => {
			const getNodeIds = (nodes) => {
				let ids = [];

				nodes.forEach(({ value, nodeLevel, children }) => {
					if (nodeLevel <= expandToLevel) {
						ids = [...ids, value, ...getNodeIds(children)];
					}
				});

				return ids;
			};

			var ids = getNodeIds(data);

			setExpanded(ids);
		},
		[expandToLevel],
	);

	useEffect(() => {
		let isSubscribed = true;

		function removeDisabledNodes(node) {
			node.forEach((child) => {
				removeDisabledNodes(child.children);

				child.children = child.children.filter((x) => !x.disabled);
				child.children = child.children.filter((x) => x.nodeLevel === siteMapLevel || x.children.length > 0);
			});
		}

		function disableOtherGroupIds(node) {
			node.forEach((child) => {
				if (child.nodeLevel === 3) {
					child.disabled = !limitToGroupIds.includes(child.value);
				}

				disableOtherGroupIds(child.children);
			});
		}

		async function loadSiteMap() {
			var data = await siteMap(siteMapLevel, onlyIfHasZoneGraphic, false);

			if (isSubscribed) {
				fixIcons(data);

				setNodes(data);

				setExpandedCallback(data);

				if (limitToGroupIds) {
					disableOtherGroupIds(data);
					if (removeDisabled) {
						removeDisabledNodes(data);
					}
				}

				if (initialChecked) {
					setChecked(initialChecked.split(","));
				}

				setLoading(false);

				var reactTree = document.getElementsByClassName("react-checkbox-tree")[0];
				if (reactTree) {
					reactTree.scrollTo(0, 0);
				}
			}
		}

		function fixIcons(data) {
			data.forEach(function(node) {
				switch (node.nodeLevel) {
					case 0:
					case "Site":
						node.icon = <FontAwesomeIcon className="rct-icon rct-icon-leaf-close gradient-icons" size="lg" icon={faCloud} fixedWidth />;
						break;
					case 1:
					case "Building":
						node.icon = <FontAwesomeIcon className="rct-icon rct-icon-leaf-close gradient-icons" size="lg" icon={faWarehouse} fixedWidth />;
						break;
					case 2:
					case "Zone":
						node.icon = <FontAwesomeIcon className="rct-icon rct-icon-leaf-close gradient-icons" size="lg" icon={faBars} fixedWidth />;
						break;
					case 3:
					case "Group":
						node.icon = <FontAwesomeIcon className="rct-icon rct-icon-leaf-close gradient-icons" size="lg" icon={faRouter} fixedWidth />;
						break;
					default:
						node.icon = "";
						break;
				}

				fixIcons(node.children);
			});
		}

		loadSiteMap();

		return () => (isSubscribed = false);
	}, [initialChecked, setExpandedCallback, siteMapLevel, expandToLevel, limitToGroupIds, removeDisabled, onlyIfHasZoneGraphic]);

	const filterApply = () => {
		onFilterApply(checked);
	};

	const checkedChanged = (checked) => {
		setChecked(checked);

		if (onCheckedChanged) {
			onCheckedChanged(checked);
		}
	};

	return (
		<Fragment>
			{loading ? (
				<div className={inline ? "" : "card"}>
					<Loader />
				</div>
			) : (
				<Fragment>
					{onFilterApply &&
						(dashPin ? (
							<ButtonIcon size="sm" icon="plus" iconAlign="left" color="primary" onClick={filterApply}>
								{local.TS_Add}
							</ButtonIcon>
						) : (
							<ButtonIcon size="sm" icon="check" iconAlign="left" color="primary" onClick={filterApply}>
								{local.TF_Display_Data}
							</ButtonIcon>
						))}
					<div className={`p-2 ${inline ? "" : "card mt-2"}`}>
						<CheckboxTree
							nodes={nodes}
							checked={checked}
							expanded={expanded}
							onCheck={(checked) => checkedChanged(checked)}
							onExpand={(expanded) => setExpanded(expanded)}
							showNodeIcon={true}
							showExpandAll={false}
							optimisticToggle={true}
							icons={{
								check: <FontAwesomeIcon className="rct-icon rct-icon-check" icon={faCheckSquare} size="lg" fixedWidth />,
								uncheck: <FontAwesomeIcon className="rct-icon rct-icon-uncheck" icon={faSquare} size="lg" fixedWidth />,
								halfCheck: <FontAwesomeIcon className="rct-icon rct-icon-half-check" icon={faCheckSquare} size="lg" fixedWidth />,
								expandClose: <FontAwesomeIcon className="rct-icon rct-icon-expand-close" icon={faChevronRight} size="lg" fixedWidth />,
								expandOpen: <FontAwesomeIcon className="rct-icon rct-icon-expand-open" icon={faChevronDown} size="lg" fixedWidth />,
								expandAll: <FontAwesomeIcon className="rct-icon rct-icon-expand-all" icon={faPlusSquare} size="lg" fixedWidth />,
								collapseAll: <FontAwesomeIcon className="rct-icon rct-icon-collapse-all" icon={faMinusSquare} size="lg" fixedWidth />,
								parentClose: <FontAwesomeIcon className="rct-icon rct-icon-parent-close" icon={faFolder} size="lg" fixedWidth />,
								parentOpen: <FontAwesomeIcon className="rct-icon rct-icon-parent-open" icon={faFolderOpen} size="lg" fixedWidth />,
								leaf: <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faFile} size="lg" fixedWidth />,
							}}
						/>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default SiteTreeMultiSelect;

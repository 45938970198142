import React from "react";
import { Row, Col } from "reactstrap";

const TabBilling = () => {
	return (
		<Row>
			<Col sm="12">
				<p>Contents TBC</p>
			</Col>
		</Row>
	);
};

export default TabBilling;

import React, { Fragment } from 'react';
import { Card, Row, Col } from 'reactstrap';
import local from '../../localization/strings';
import PageTitle from '../common/PageTitle';
import Parameters from './Parameters';

const ParameterSetup = () => {

    return (
        <Fragment>
            <PageTitle title={local.TF_Parameter_Setup} />
            <Card className="pl-1 py-2 pr-2">
                <Row>
                    <Col>
                        <Parameters />
                    </Col>
                </Row>
            </Card>
        </Fragment >
    )
}

export default ParameterSetup
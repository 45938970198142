import { faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { apiUserFeatureDetails, apiUserFeatureSetIsEnabled, usersForSiteGetList } from "../../api/users";
import { features, isFeatureEnabled, roles } from "../../config";
import { tableIcons } from "../../helpers/tableIcons";
import { RoleToDisplayRole } from "../../helpers/utils";
import local from "../../localization/strings";

const editIcon = () => <FontAwesomeIcon size="xs" icon="pencil-alt" />;

const SiteUsers = ({ site, editUser }) => {
	const [users, setUsers] = useState([]);
	const [featureResult, setFeatureResult] = useState();
	const departmentsFeatureEnabled = isFeatureEnabled(features.Departments);
	const [toggle, setToggle] = useState();

	const isMaintainUsersAllowed = useCallback(
		(userId) => {
			if (Object.keys(featureResult.userEnabled).includes(userId)) {
				return !featureResult.userEnabled[userId];
			}
			return !featureResult.siteEnabled;
		},
		[featureResult],
	);

	const UserEdit = ({ rowData }) => {
		if (!featureResult) {
			return null;
		}

		switch (rowData.role) {
			case roles.SiteAdmin:
				const allowed = isMaintainUsersAllowed(rowData.id);
				return <FontAwesomeIcon className="text-secondary cursor-pointer" icon={allowed ? faToggleOn : faToggleOff} size="2x" onClick={() => setToggle(rowData.id)} title={allowed ? local.TS_Yes : local.TS_No} />;
			case roles.DepartmentAdmin:
			case roles.SecondaryUser:
			case roles.DormantUser:
			default:
				return null;
		}
	};

	const columns = [
		{ field: "displayName", title: local.TS_Name, defaultSort: "asc" }, //
		{ field: "role", title: local.TS_Type, render: (rowData) => <span>{RoleToDisplayRole(rowData.role)}</span> },
		{ field: "departmentName", title: local.TS_Department, hidden: !departmentsFeatureEnabled },
		{ field: "email", title: local.TS_Email },
		{
			field: "role",
			title: local.TF_Can_Maintain_Users,
			render: (rowData) => <UserEdit rowData={rowData} />,
		},
	];

	const loadData = useCallback(async () => {
		var result = await usersForSiteGetList(site.id);
		setFeatureResult(await apiUserFeatureDetails(site.id, features.UserMaintenanceDisableForSiteAdmins));

		result = result.filter((x) => x.role !== roles.Eltek && x.role !== roles.Distributor);

		setUsers(result);
	}, [site.id]);

	useEffect(() => {
		loadData();
	}, [loadData]);

	const toggleMaintainUsers = useCallback(
		async (id) => {
			await apiUserFeatureSetIsEnabled(site.id, id, features.UserMaintenanceDisableForSiteAdmins, isMaintainUsersAllowed(id));
			await loadData();
			setToggle(undefined);
		},
		[site.id, isMaintainUsersAllowed, loadData],
	);

	return (
		<>
			<Modal isOpen={!!toggle} toggle={() => setToggle(undefined)} centered backdrop="static">
				<ModalHeader>{local.TF_Toggle_Maintain_Users.replace("|X|", users.find((x) => x.id === toggle)?.displayName || "")}</ModalHeader>
				<ModalBody>
					<Button size="sm" color="primary" onClick={() => toggleMaintainUsers(toggle)} className="ml-2">
						{local.TS_Yes}
					</Button>
					<Button size="sm" color="secondary" onClick={() => setToggle(undefined)} className="ml-2">
						{local.TS_No}
					</Button>
				</ModalBody>
			</Modal>
			<MaterialTable
				columns={columns}
				data={users}
				title=""
				icons={tableIcons}
				options={{ exportButton: true, exportAllData: true, exportFileName: "Users", sorting: true, paging: true, pageSize: 50, pageSizeOptions: [50], emptyRowsWhenPaging: false, showEmptyDataSourceMessage: false, headerStyle: { backgroundColor: "#edf2f9", color: "#01579b" } }}
				actions={[
					{
						icon: editIcon,
						tooltip: "Modify",
						onClick: (_event, rowData) => editUser(rowData.id),
					},
				]}
			/>
		</>
	);
};

export default SiteUsers;

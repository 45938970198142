import React, { useState, useCallback, useEffect } from "react";
import { Row, Card, Col, Button } from "reactstrap";
import local from "../../localization/strings";
import { getDepartments } from "../../api/department";
import Loader from "../common/Loader";
import { logEvent } from "../../helpers/ga";
import AddButton from "../common/AddButton";
import Department from "./Department";

const Departments = () => {
	const [loading, setLoading] = useState(true);
	const [depts, setDepts] = useState([]);
	const [selected, setSelected] = useState(null);

	const LoadDepartments = useCallback(async () => {
		const result = await getDepartments();

		setDepts(result);
		setLoading(false);
	}, []);

	useEffect(() => {
		LoadDepartments();
	}, [LoadDepartments]);

	const select = (id) => {
		logEvent("Site Layout", "Select Department", `${id}`);
		setSelected(id);
    };
    
    const done = () => {
        setSelected(undefined);
        LoadDepartments();
    }

	return (
		<>
			{loading ? (
				<div className="ml-2">
					<Loader />
				</div>
			) : (
				<Card className="p-2">
					<Row>
						<Col className="col-12 col-md-4">
							<h4>{local.TS_Departments}</h4>
							{depts.length === 0 ? (
								<p>{local.TF_No_depts_in_site}</p>
							) : (
								<>
									{depts.map((item, i) => (
										<Row key={i}>
											<Col className="pb-1">
												<Button onClick={() => select(item.id)} color="primary" className={`p-1 w-100 department-alarm-user ${selected === item.id && "selected-alarm"}`}>
													{item.name}
												</Button>
											</Col>
										</Row>
									))}
								</>
							)}
							<Row>
								<Col>
									<AddButton string={local.TS_Add} clickFunction={() => select(-1)} buttonClasses={"p-1 w-100"} />
								</Col>
							</Row>
						</Col>

						{selected && <Department key={selected} depts={depts} selected={selected} done={done} />}
					</Row>
				</Card>
			)}
		</>
	);
};

export default Departments;

import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Card, Col, CustomInput, Row } from "reactstrap";
import { apiConfigureLoggerChannel } from "../../../api/apiLoggerConfiguration";
import { apiSquirrelChannelsGetList, apiSquirrelRangesGetList, apiSquirrelsGetList } from "../../../api/apiSquirrels";
import { getSquirrelRangeType, precisionRound } from "../../../helpers/utils";
import local from "../../../localization/strings";
import PageTitle from "../../common/PageTitle";

const Logger = ({ match, history }) => {
	const [data, setData] = useState();
	const [loadedData, setLoadedData] = useState();
	const [squirrel, setSquirrel] = useState({});
	const [savedRanges, setSavedRanges] = useState();
	const [savedRange, setSavedRange] = useState();
	const [delayTime, setDelayTime] = useState();
	const [lowSet, setLowSet] = useState();
	const [highSet, setHighSet] = useState();
	const [log, setLog] = useState();
	const [groups, setGroups] = useState(false);
	const [error, setError] = useState(undefined);
	const [maxNumber, setMaxNumber] = useState(10);
	const [groupLookup, setGroupLookup] = useState([]);

	const calculateDelayTime = useCallback((squirrel, alarmDelay) => {
		if (!alarmDelay) {
			setDelayTime();
			return;
		}

		const intAlarmDelay = parseInt(alarmDelay.toString());
		if (isNaN(intAlarmDelay)) {
			setDelayTime();
			return;
		}

		const interval = squirrel.scanInterval || squirrel.logInterval;
		var dt = new Date("1970-01-01T" + interval + "Z");
		const seconds = dt.getUTCSeconds() + dt.getUTCMinutes() * 60 + dt.getUTCHours() * 60 * 60;
		const delaySeconds = seconds * intAlarmDelay;

		setDelayTime(`${new Date(delaySeconds * 1000).toISOString().substring(11, 19)}`);
	}, []);

	const rangeType = (ranges, rangeNumber) => {
		const range = ranges.find((x) => x.rangeNumber === rangeNumber);
		if (range) {
			return getSquirrelRangeType(range.type);
		}
		return "";
	};

	const rangeUnits = (ranges, rangeNumber) => {
		const range = ranges.find((x) => x.rangeNumber === rangeNumber);
		if (range) {
			return range.units;
		}
		return "";
	};

	const rangeMinToMax = useCallback((ranges, rangeNumber) => {
		const rangeCalcMin = (minValue, decimalPlaces) => {
			return minValue * Math.pow(10, -decimalPlaces);
		};

		const rangeCalcMax = (minValue, decimalPlaces, span) => {
			return span * Math.pow(10, -decimalPlaces) + rangeCalcMin(minValue, decimalPlaces);
		};

		const range = ranges.find((x) => x.rangeNumber === rangeNumber);
		if (range) {
			return `${rangeCalcMin(range.minValue, range.decimalPlaces)} ${local.TS_To} ${rangeCalcMax(range.minValue, range.decimalPlaces, range.span)}`;
		}
		return "";
	}, []);

	const scale = useCallback((ranges, rangeNumber, start) => {
		const range = ranges.find((x) => x.rangeNumber === rangeNumber);
		if (range) {
			let working = (start * range.span) / range.maxValue + range.minValue;
			return precisionRound(working * Math.pow(10, -range.decimalPlaces), 10).toFixed(2);
		}
		return "";
	}, []);

	const unscale = (ranges, rangeNumber, startScaled) => {
		const range = ranges.find((x) => x.rangeNumber === rangeNumber);
		if (range) {
			let working = parseFloat(startScaled);

			if (!isNaN(working)) {
				working = working / Math.pow(10, -range.decimalPlaces);
				working = working - range.minValue;
				working = (working * range.maxValue) / range.span;

				return precisionRound(working, 10);
			}
		}
		return null;
	};

	function getBit(number, bitPosition) {
		return (number & (1 << bitPosition)) === 0 ? 0 : 1;
	}

	useEffect(() => {
		const loadData = async () => {
			const sn = match?.params?.serialNumber;
			const cn = parseInt(match?.params?.channelNumber || "");

			const apiResultRanges = await apiSquirrelRangesGetList();
			const ranges = apiResultRanges.filter((x) => x.serialNumber === sn);
			setSavedRanges(ranges);

			const squirrels = await apiSquirrelsGetList();
			const foundSquirrel = squirrels.find((x) => x.serialNumber === sn);

			//Grouping
			if (getBit(foundSquirrel.specialDarca, 1) === 1) {
				setGroups(true);
				//Extended Grouping
				if (getBit(foundSquirrel.specialDarca, 2) === 1) {
					setMaxNumber(20);
				} else {
					setMaxNumber(10);
				}
			} else {
				setMaxNumber(10);
				setGroups(false);
			}

			setSquirrel(foundSquirrel);
			const apiResult = await apiSquirrelChannelsGetList();
			const found = apiResult.find((x) => x.serialNumber === sn && x.channelNumber === cn);

			if (found.log === null) {
				found.log = true;
			}

			if (found.transmitterSerialNumber === "0" || found.range === 0) {
				history.push(`/site_settings/logger/${match?.params?.serialNumber}`);
				return;
			}

			setLoadedData(found);
			setSavedRange(found.range);

			//Calculated Fields
			if (found) {
				found.channelNumberDisplay = found.channelNumber + 1;
				found.rangeTypeDisplay = rangeType(ranges, found.range);
				found.rangeUnitsDisplay = rangeUnits(ranges, found.range);
				found.rangeMinMaxDisplay = rangeMinToMax(ranges, found.range);
				found.transmitterSerialNumber = found.transmitterSerialNumber && found.transmitterSerialNumber !== "0" ? found.transmitterSerialNumber : "";
				found.transmitterChannelNumber = found.transmitterSerialNumber && found.transmitterSerialNumber !== "0" ? String.fromCharCode(65 + found.transmitterChannelNumber) : "";
				found.transmitterInterval = found.transmitterSerialNumber && found.transmitterSerialNumber !== "0" ? new Date(found.transmitterInterval * 1000).toISOString().substring(11, 19) : "";
				found.highStartScaled = scale(ranges, found.range, found.highStart);
				found.lowStartScaled = scale(ranges, found.range, found.lowStart);
				setLowSet(found.lowSet);
				setHighSet(found.highSet);
			}

			setData(found);
			calculateDelayTime(foundSquirrel, found.alarmDelay);
		};

		loadData();
	}, [match, rangeMinToMax, calculateDelayTime, scale, history]);

	useEffect(() => {
		let lookup = [];
		//Append lettered options for groups
		for (let i = 65; i < 65 + maxNumber; i++) {
			lookup.push(String.fromCharCode(i));
		}
		setGroupLookup(lookup);
	}, [maxNumber]);

	const save = async (_, values) => {
		setError(undefined);
		if (!values.smsGroup) {
			values.smsGroup = "A";
		}

		if (values.highStartScaled && values.lowStartScaled) {
			const lowStart = parseFloat(values.lowStartScaled);
			const highStart = parseFloat(values.highStartScaled);
			if (highStart < lowStart) {
				setError(local.TF_Error_HighSet_Below_LowSet);
				return;
			}
		}

		await apiConfigureLoggerChannel(loadedData.serialNumber, loadedData.channelNumber, values.highSet, unscale(savedRanges, savedRange, values.highStartScaled), values.lowSet, unscale(savedRanges, savedRange, values.lowStartScaled), values.alarmDelay, values.smsGroup, values.identity, values.log);

		history.push(`/site_settings/logger/${match?.params?.serialNumber}`);
	};

	return (
		<>
			<PageTitle title={local.TF_Logger_Configuration} />
			{data && (
				<Card className="p-4">
					<AvForm model={data} onValidSubmit={async (e, values) => await save(e, values)}>
						<AvField name="serialNumber" label={local.TS_Serial_Number} disabled />
						<AvField name="channelNumberDisplay" label={local.TF_Channel_Number} disabled />
						<AvField name="identity" label={local.TS_Name} />
						<AvField tag={CustomInput} type="checkbox" name="log" label={local.TF_Log} checked={log} onChange={(e) => setLog(e.target.checked)} />
						<AvField name="rangeTypeDisplay" label={local.TF_Input} disabled />
						<AvField name="rangeMinMaxDisplay" label={local.TS_Range} disabled />
						<AvField name="rangeUnitsDisplay" label={local.TS_Units} disabled />

						<Row>
							<Col xs={4} sm={2} className="d-flex align-items-center">
								<AvField tag={CustomInput} type="checkbox" name="highSet" label={local.TF_High_Set} checked={highSet} onChange={(e) => setHighSet(e.target.checked)} />
							</Col>
							<Col>
								<AvField
									name="highStartScaled"
									label={local.TF_High_Start}
									type="number"
									step={0.01}
									onChange={(e) => {
										setHighSet(e.target.value || e.target.value === "0" ? true : false);
									}}
								/>
							</Col>
						</Row>

						<Row>
							<Col xs={4} sm={2} className="d-flex align-items-center">
								<AvField tag={CustomInput} type="checkbox" name="lowSet" label={local.TF_Low_Set} checked={lowSet} onChange={(e) => setLowSet(e.target.checked)} />
							</Col>
							<Col>
								<AvField
									name="lowStartScaled"
									label={local.TF_Low_Start}
									type="number"
									step={0.01}
									onChange={(e) => {
										setLowSet(e.target.value || e.target.value === "0" ? true : false);
									}}
								/>
							</Col>
						</Row>

						<Row>
							<Col xs={4} sm={2}>
								<AvField name="alarmDelay" label={local.TF_Delay} type="number" step={1} validate={{ min: { value: 0, errorMessage: local.TS_Invalid } }} onChange={(e) => calculateDelayTime(squirrel, e.target.value)} />
							</Col>
							<Col className="d-flex align-items-center">{delayTime}</Col>
						</Row>

						{groups && (
							<Row>
								<Col xs={4} sm={2}>
									<AvField name="smsGroup" type="select" label={local.TF_Group_Name}>
										{groupLookup.map((item) => (
											<option key={item} value={item}>
												{item}
											</option>
										))}
									</AvField>
								</Col>
							</Row>
						)}

						<AvField name="transmitterSerialNumber" label={local.TF_TransmitterSerialNumber} disabled />
						<AvField name="transmitterChannelNumber" label={local.TF_Transmitter_Channel} disabled />
						<AvField name="transmitterInterval" label={local.TF_Transmit_Interval} disabled />

						{error && (
							<div className="alert alert-danger" role="alert">
								<div className="alert-heading font-weight-semi-bold">{error}</div>
							</div>
						)}
						<Row>
							<Col>
								<Button color="primary" className="mt-3">
									{local.TS_Save_Changes}
								</Button>
								<Button type="button" color="secondary" className="mt-3 ml-2" onClick={() => history.push(`/site_settings/logger/${match?.params?.serialNumber}`)}>
									{local.TS_Cancel}
								</Button>
							</Col>
						</Row>
					</AvForm>
				</Card>
			)}
		</>
	);
};

export default withRouter(Logger);

import swal from "@sweetalert/with-react";
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Prompt } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, Col, CustomInput, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { apiZoneGraphicSetup, zoneGraphic, zoneGraphicOverlays } from "../../../api/zoneGraphics";
import { UserPreferencesContext } from "../../../context/userPreferences";
import { logEvent } from "../../../helpers/ga";
import { zoneGraphicGroupArrow } from "../../../helpers/utils";
import useWindowSize from "../../../hooks/useWindowSize";
import local from "../../../localization/strings";
import FontAwesomeButton from "../../common/FontAwesomeButton";
import PageTitle from "../../common/PageTitle";
import SiteTreeMenu from "../../common/SiteTreeMenu";
import SiteTreeSingleSelect from "../../common/SiteTreeSingleSelect";

const ZoneGraphicSetup = () => {
	const [showFilter, setShowFilter] = useState(true);
	const [mode, setMode] = useState("");
	const [image, setImage] = useState(null);
	const [captions, setCaptions] = useState([]);
	const [groups, setGroups] = useState([]);
	const [zoneId, setZoneId] = useState("");
	const [zoneName, setZoneName] = useState("");
	const [buildingName, setBuildingName] = useState("");
	const [x, setX] = useState(undefined);
	const [y, setY] = useState(undefined);
	const [arrowX, setArrowX] = useState(undefined);
	const [arrowY, setArrowY] = useState(undefined);
	const [showGroupModal, setShowGroupModal] = useState(false);
	const [selectedGroupId, setSelectedGroupId] = useState(undefined);
	const [selectedCaptionId, setSelectedCaptionId] = useState(undefined);
	const titleFontSize = "8pt";
	const fontSize = "8pt";
	const font = "Verdana";
	const size = useWindowSize();
	const addCaptionRef = useRef(null);
	const { getGroup, getLocationsForGroupId } = useContext(UserPreferencesContext);
	const [newCaptionId, setNewCaptionId] = useState(0);
	const [changed, setChanged] = useState(false);

	const onZoneSelect = async (data, zoneName, buildingName) => {
		const zoneId = data.substring(2);

		logEvent("Zone Graphics Setup", "Zone Selected", zoneId);

		const imageResult = await zoneGraphic(zoneId);

		setImage(imageResult);

		if (imageResult) {
			var overlays = await zoneGraphicOverlays(zoneId);
			setCaptions(overlays.captions);
			setGroups(overlays.groups);
		} else {
			setCaptions([]);
			setGroups([]);
		}
		setZoneId(zoneId);
		setBuildingName(buildingName);
		setZoneName(zoneName);
		setShowFilter(false);
	};

	useEffect(() => {
		setChanged(false);
	}, [zoneId]);

	const upload = () => {
		logEvent("Zone Graphics Setup", "Upload");

		setMode("Upload");
	};

	const addCaption = () => {
		logEvent("Zone Graphics Setup", "Add Caption");

		setMode("Add-Caption");
	};

	const addGroup = () => {
		logEvent("Zone Graphics Setup", "Add Group");

		setMode("Add-Group");
		setShowGroupModal(true);
	};

	const filter = () => {
		logEvent("Zone Graphics Setup", "Select Zone Button");
		if (changed) {
			swal({
				title: local.TS_Are_You_Sure,
				buttons: {
					cancel: {
						visible: true,
						text: local.TS_No,
						value: false,
					},
					confirm: {
						visible: true,
						text: local.TS_Yes,
						value: true,
					},
				},
				icon: "warning",
				dangerMode: true,
			}).then((result) => {
				if (result) {
					setZoneId(undefined);
					setShowFilter(true);
				}
			});
		} else {
			setZoneId(undefined);
			setShowFilter(true);
		}
	};

	const reset = () => {
		setShowGroupModal(false);
		setArrowX(undefined);
		setArrowY(undefined);
		setSelectedGroupId(undefined);
		setSelectedCaptionId(undefined);
		setMode("");
	};

	useEffect(() => {
		var dashboardContentDiv = document.getElementById("dashboard-container-div");

		if (dashboardContentDiv) {
			var contentDiv = document.getElementById("zone-graphic-div");
			contentDiv.style.marginRight = `-${dashboardContentDiv.offsetLeft}px`;
		}
	}, [size]);

	const clicked = () => {
		switch (mode) {
			case "Add-Caption":
				const caption = addCaptionRef.current.value;
				if (caption) {
					logEvent("Zone Graphics Setup", "Caption Added");
					setNewCaptionId((x) => x - 1);
					setCaptions([...captions, { id: newCaptionId, caption, x, y }]);
					setChanged(true);
					reset();
				} else {
					logEvent("Zone Graphics Setup", "Caption Add Failed, no text");
				}
				break;
			case "Selected-Caption":
				logEvent("Zone Graphics Setup", "Caption Moved");
				const movedCaption = { ...captions.find((x) => x.id === selectedCaptionId), x, y };
				setCaptions([...captions.filter((x) => x.id !== selectedCaptionId), movedCaption]);
				setChanged(true);
				break;
			default:
				break;
		}
	};

	const mouseDown = () => {
		if (mode === "Add-Group" || "Selected-Group") {
			setArrowX(x);
			setArrowY(y);
		}
	};

	const getArrow = () => {
		let hasArrow = true;
		let useArrowX = arrowX;
		let useArrowY = arrowY;
		if (Math.abs(x - arrowX) < 5 && Math.abs(y - arrowY) < 5) {
			hasArrow = false;
			useArrowX = 0;
			useArrowY = 0;
		}

		return { hasArrow, arrowX: useArrowX, arrowY: useArrowY };
	};

	const mouseUp = () => {
		switch (mode) {
			case "Add-Group":
				logEvent("Zone Graphics Setup", "Group Added");

				const newGroup = {
					...getArrow(),
					groupId: selectedGroupId,
					name: getGroup(selectedGroupId).groupName,
					x,
					y,
					readings: [...getLocationsForGroupId(selectedGroupId)],
				};
				setGroups([...groups.filter((x) => x.groupId !== selectedGroupId), newGroup]);
				setChanged(true);
				reset();
				break;
			case "Selected-Group":
				logEvent("Zone Graphics Setup", "Group Moved");

				const movedGroup = { ...groups.find((x) => x.groupId === selectedGroupId), x, y, ...getArrow() };

				setGroups([...groups.filter((x) => x.groupId !== selectedGroupId), movedGroup]);
				setChanged(true);
				break;
			default:
				break;
		}
	};

	const mouseMove = (e) => {
		setX(e.nativeEvent.offsetX);
		setY(e.nativeEvent.offsetY);
	};

	const onGroupSelect = (id) => {
		setSelectedGroupId(parseInt(id.substring(2)));
		setShowGroupModal(false);
	};

	const onCaptionDivSelect = (id) => {
		logEvent("Zone Graphics Setup", "Caption Clicked");
		setMode("Selected-Caption");
		setSelectedCaptionId(id);
	};

	const onGroupDivSelect = (id) => {
		logEvent("Zone Graphics Setup", "Group Clicked");
		setMode("Selected-Group");
		setSelectedGroupId(id);
	};

	const updateCaption = () => {
		const caption = addCaptionRef.current.value;
		if (caption) {
			logEvent("Zone Graphics Setup", "Caption Update");
			const movedCaption = { ...captions.find((x) => x.id === selectedCaptionId), caption };
			setCaptions([...captions.filter((x) => x.id !== selectedCaptionId), movedCaption]);
			setChanged(true);
		}
	};

	const deleteCaption = () => {
		logEvent("Zone Graphics Setup", "Caption Delete");
		setCaptions([...captions.filter((x) => x.id !== selectedCaptionId)]);
		setChanged(true);
		reset();
	};

	const deleteGroup = () => {
		logEvent("Zone Graphics Setup", "Group Delete");
		setGroups([...groups.filter((x) => x.groupId !== selectedGroupId)]);
		setChanged(true);
		reset();
	};

	const toBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const handleNewImage = async (e) => {
		logEvent("Zone Graphics Setup", "Upload");

		const base64WithHeader = await toBase64(e.target.files[0]);
		const base64Loc = base64WithHeader.indexOf("base64,");

		setImage(base64WithHeader.substring(base64Loc + 7));

		setChanged(true);
		reset();
	};

	const deleteZoneGraphic = () => {
		logEvent("Zone Graphics Setup", "Delete Zone Graphic");

		setImage(undefined);
		setCaptions([]);
		setGroups([]);
		setChanged(true);
	};

	const save = async () => {
		if (image) {
			await apiZoneGraphicSetup(zoneId, image, groups, captions);
		} else {
			await apiZoneGraphicSetup(zoneId, null, null, null);
		}

		setChanged(false);
		toast.success(local.TS_Update_Successful);
	};

	return (
		<div id="zone-graphic-div">
			<Prompt when={changed} message={(_location) => local.TS_Are_You_Sure} />
			<PageTitle title={local.TF_Zone_Graphic_Setup} />
			{showFilter ? (
				<SiteTreeMenu onClick={onZoneSelect} expandToLevel={2} siteMapLevel={2} />
			) : (
				<>
					<Row className="justify-content-between">
						<Col className="col-3">
							<FontAwesomeButton color="secondary" text={local.TF_ZoneCharts_SelectZone} icon="filter" onClick={filter} className="mb-2" />
						</Col>
						<Col className="col-6 text-center">
							<h5>
								{buildingName} - {zoneName}
							</h5>
						</Col>
						<Col className="col-3" />
					</Row>
					<Card className="p-3 zone-graphic-setup-card">
						<div style={{ height: 50 }}>
							{(mode === "" && (
								<Row id="chartOptions" className="my-2">
									<Col className="col-auto">
										<FontAwesomeButton color="info" text={local.TS_Upload} icon="file-upload" onClick={upload} />
										{image && (
											<>
												<FontAwesomeButton color="success" text={local.TF_ZoneGraphicSetup_AddCaption} icon="plus" onClick={addCaption} />
												<FontAwesomeButton color="primary" text={local.TF_ZoneGraphicSetup_AddGroup} icon="plus" onClick={addGroup} />
												<FontAwesomeButton color="danger" text={local.TS_Delete} icon="trash" onClick={deleteZoneGraphic} />
											</>
										)}
										<FontAwesomeButton color="primary" text={local.TS_Save} icon="save" onClick={save} />
									</Col>
								</Row>
							)) ||
								(mode === "Add-Caption" && (
									<Form inline>
										<FormGroup>
											<Input innerRef={addCaptionRef} className="mx-2" />
											<FontAwesomeButton icon="times" text={local.TS_Cancel} onClick={reset} color="secondary" />
										</FormGroup>
									</Form>
								)) ||
								(mode === "Selected-Caption" && (
									<Form inline>
										<FormGroup>
											<Input innerRef={addCaptionRef} className="mx-2" defaultValue={captions.find((x) => x.id === selectedCaptionId).caption} />
											<FontAwesomeButton icon="pencil-alt" text={local.TS_Set} onClick={updateCaption} color="success" />
											<FontAwesomeButton icon="trash" text={local.TS_Delete} onClick={deleteCaption} color="danger" />
											<FontAwesomeButton icon="times" text={local.TS_Back} onClick={reset} color="secondary" />
										</FormGroup>
									</Form>
								)) ||
								(mode === "Selected-Group" && (
									<Row id="chartOptions" className="my-2">
										<Col className="col-auto">
											<FontAwesomeButton icon="trash" text={local.TS_Delete} onClick={deleteGroup} color="danger" />
											<FontAwesomeButton icon="times" text={local.TS_Back} onClick={reset} color="secondary" />
										</Col>
									</Row>
								)) ||
								(mode === "Add-Group" && (
									<Row id="chartOptions" className="my-2">
										<Col className="col-auto">
											<Label className="mr-2">{local.TF_ZoneGraphicSetup_AddGroup}</Label>
											<FontAwesomeButton icon="times" text={local.TS_Cancel} onClick={reset} color="secondary" />
										</Col>
									</Row>
								))}
						</div>
						<Scrollbars autoHeight autoHeightMin={400} autoHeightMax={1000} width={"100%"}>
							<div style={{ position: "relative", margin: "5px" }} className="d-inline-block">
								{image ? <img draggable={false} alt="" src={`data:image/png;base64,${image}`} onClick={clicked} onMouseMove={mouseMove} onMouseDown={mouseDown} onMouseUp={mouseUp} /> : <div>{local.TF_No_Zone_Graphic_Found}</div>}
								{/* Do all lines first or they stop you clicking on group boxes */}
								{groups.map((group, index) => {
									return <Fragment key={`${index}_Line`}>{group.hasArrow && <div className={group.groupId === selectedGroupId ? "selected-div" : ""} style={zoneGraphicGroupArrow(group)} />}</Fragment>;
								})}
								{groups.map((group, index) => {
									return (
										<Fragment key={index}>
											<div className={`zone-graphic-readings ${group.groupId === selectedGroupId ? "selected-div" : ""}`} style={{ left: `${group.x}px`, top: `${group.y}px`, position: "absolute", backgroundColor: "white", borderColor: "black", borderWidth: "1px", borderStyle: "solid", padding: "5px 10px", textAlign: "left", whiteSpace: "nowrap", lineHeight: "1.2" }} onClick={() => onGroupDivSelect(group.groupId)}>
												<div style={{ fontSize: titleFontSize, fontWeight: "bold", fontFamily: font }}>{group.name}</div>
												{group.readings.map((reading, readingIndex) => (
													<div style={{ fontSize: fontSize, fontFamily: font }} key={readingIndex}>
														{reading.name || reading.locationName}:
													</div>
												))}
											</div>
										</Fragment>
									);
								})}
								{captions.map((caption, index) => (
									<div key={index} className={caption.id === selectedCaptionId ? "selected-div" : ""} style={{ left: `${caption.x}px`, top: `${caption.y}px`, position: "absolute", backgroundColor: "white", borderColor: "black", borderWidth: "1px", borderStyle: "solid", padding: "5px", textAlign: "left", whiteSpace: "nowrap", lineHeight: "1" }} onClick={() => onCaptionDivSelect(caption.id)}>
										<div style={{ fontSize: fontSize, fontFamily: font }}>{caption.caption}</div>
									</div>
								))}
							</div>
						</Scrollbars>
					</Card>
					<Modal isOpen={showGroupModal} toggle={reset} centered backdrop="static">
						<ModalHeader>
							{local.TF_ZoneGraphicSetup_AddCaption}
							<span className="float-right close-modal" style={{ position: "absolute", top: 15, right: 20 }} onClick={reset}>
								X
							</span>
						</ModalHeader>
						<ModalBody>
							<SiteTreeSingleSelect inline expandToLevel={1} siteMapLevel={3} onClick={onGroupSelect} />
						</ModalBody>
					</Modal>
					<Modal isOpen={mode === "Upload"} toggle={reset} centered backdrop="static">
						<ModalHeader>
							{local.TS_Upload}
							<span className="float-right close-modal" style={{ position: "absolute", top: 15, right: 20 }} onClick={reset}>
								X
							</span>
						</ModalHeader>
						<ModalBody>
							<div className="site-image-link">
								<CustomInput type="file" id="newImage" name="newImage" label={local.TS_Upload} onChange={(e) => handleNewImage(e)} />
							</div>
						</ModalBody>
					</Modal>
				</>
			)}
		</div>
	);
};

export default ZoneGraphicSetup;

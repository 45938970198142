import { apiUrl } from "../config";
import axios from "axios";
import { CurrentUserStoreCreds, CurrentUserClearCreds } from "./auth";
import swal from "@sweetalert/with-react";

export const configureAxios = () => {
	const UNAUTHORIZED = 401;
	const FORBIDDEN = 403;
	const NOTFOUND = 404;
	const BAD_REQUEST = 400;
	const INTERNAL_SERVER_ERROR = 500;
	const GATEWAY_TIMEOUT = 504;
	const OK = 200;
	const MFA_REQUIRED = 412;

	axios.interceptors.request.use((config) => {
		const accessToken = localStorage.getItem("AccessToken") || "";
		const userLanguage = localStorage.getItem("UserLanguage") || "";

		if (accessToken) {
			config.headers["Authorization"] = "Bearer " + accessToken;
		}
		config.headers["Content-Type"] = "application/json";
		config.headers["Language"] = userLanguage;
		config.baseURL = apiUrl();

		return config;
	});

	axios.interceptors.response.use(
		(response) => response,
		(error) => {
			//Checking Alarms or other background tasks are fine to fail if the system is left running overnight
			const backgroundTaskAllowFail = error?.config?.headers?.backgroundTaskAllowFail;

			if (!error.response) {
				if (!backgroundTaskAllowFail) {
					window.location.href = "/errors/500";
				}
				return Promise.reject(error);
			}

			const originalRequest = error.config;

			const { status } = error.response;

			if (backgroundTaskAllowFail && (status === INTERNAL_SERVER_ERROR || status === GATEWAY_TIMEOUT)) {
				return Promise.reject(error);
			}

			if (status === INTERNAL_SERVER_ERROR) {
				window.location.href = "/errors/500";
			}

			if (status === FORBIDDEN) {
				window.location.href = "/errors/403";
			}
			if (status === NOTFOUND) {
				window.location.href = "/errors/404";
			}

			if (status === MFA_REQUIRED) {
				//Redirect to MFA page passing siteid
				window.location.href = `/mfa/${originalRequest?.params?.siteId ?? originalRequest?.data?.siteId}`;
			}

			if (status === BAD_REQUEST) {
				console.error(error.response);
			}

			if (status === GATEWAY_TIMEOUT) {
				swal({
					title: "Timeout",
					text: "A timeout occurred, please try again.",
					buttons: {
						cancel: "Close",
					},
					icon: "error",
				});
				return Promise.reject(error);
			}

			if (status === UNAUTHORIZED) {
				const refreshToken = localStorage.getItem("RefreshToken") || "";

				return axios
					.post("v2/RefreshLogin", {
						RefreshToken: refreshToken,
					})
					.then((res) => {
						if (res.status === OK) {
							if (res.data.successful) {
								CurrentUserStoreCreds(res.data);

								axios.defaults.headers.common["Authorization"] = "Bearer " + res.data.accessToken;

								return axios(originalRequest);
							} else {
								CurrentUserClearCreds();

								console.error("Failed to Refresh Token");

								window.location.href = "/authentication/login";
							}
						} else {
							CurrentUserClearCreds();

							console.error("Failed to Refresh Token");

							window.location.href = "/authentication/login";
						}
					});
			}

			return Promise.reject(error);
		},
	);
};

import React from "react";
import local from "../../localization/strings";

const SMSUsage = ({ used, limit, siteId }) => {
	let usedPercent = 0;

	if (limit === 0) {
		if (used > 0) {
			usedPercent = 100;
		} else {
			usedPercent = 0;
		}
	} else {
		usedPercent = (used / limit) * 100;
	}

	if (usedPercent > 100) {
		usedPercent = 100;
	}

	const progressHeight = "15px";
	const bgColor = usedPercent < 80 ? "bg-success" : usedPercent < 100 ? "bg-warning" : "bg-danger";

	return (
		<div className="progress" id={`sms-usage-${siteId}`} style={{ height: progressHeight }} title={`${local.TF_SMS_Segments_Used}: ${used} ${local.TS_Of} ${limit} ${limit ? `(${usedPercent.toFixed(1)}%)` : ""}`}>
			<div className={`progress-bar ${bgColor}`} role="progressbar" style={{ width: Math.ceil(usedPercent) + "%", height: progressHeight }} />
		</div>
	);
};

export default SMSUsage;

import React, { useState, useContext } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserPreferencesContext } from '../../context/userPreferences';
import { toast } from 'react-toastify';
import local from '../../localization/strings';
import { logEvent } from '../../helpers/ga';

const Pin = ({ types, color, className, size, tag, menuClass }) => {
  const [open, setOpen] = useState(false);
  const { pinToDashboard } = useContext(UserPreferencesContext);

  const pin = async item => {
    logEvent('Pin', item.type, `${item.data}`);
    pinToDashboard(item.type, item.data);
    notify(item.name);
  };

  const notify = (item) => {
    toast.success(item + " " + local.TF_added_to_dashboard);
  }


  return (
    <div className="pin-button">
      <Dropdown isOpen={open} toggle={() => setOpen(!open)}>
        <DropdownToggle className={className} tag={tag} color={color} transform="shrink-3" size={size}>
          <FontAwesomeIcon icon="ellipsis-v" fixedWidth />
        </DropdownToggle>
        <DropdownMenu right className={menuClass}>
          {types.map((item, index) => (
            <DropdownItem key={index} onClick={() => pin(item)}>
              <FontAwesomeIcon icon="thumbtack" className="mr-1 pin-rotate" />
              {item.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default Pin;

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Card, Row, Col, Button, Alert } from "reactstrap";
import { AvField, AvForm, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import local from "../../localization/strings";
import { defaultDistributorDetails } from "../../config";
import { availableLanguages } from "../../localization/LanguageList";
import { apiSiteAdd } from "../../api/site";
import { refreshLogin } from "../../api/auth";

const formDefaults = {
	displayName: "",
	siteProduct: "Lite",
	siteAlarmsEnabled: false,
	defaultLocale: "en",
	distributorName: defaultDistributorDetails.name,
	distributorPhone: defaultDistributorDetails.tel,
	distributorEmail: defaultDistributorDetails.email,
};

const SiteAdd = ({ cancelAdd }) => {
	const [data, setData] = useState(formDefaults);
	const [error, setError] = useState(undefined);

	useEffect(() => {
		setData(formDefaults);
	}, []);

	async function save(_e, values) {
		const result = await apiSiteAdd(values.displayName, values.siteProduct, values.siteAlarmsEnabled, values.defaultLocale, values.distributorName, values.distributorPhone, values.distributorEmail, values.description, values.contractStartDate);

		if (result) {
			toast.success(local.TF_Site_Created_Successfully);
			// Required to refresh login as accessible sites are read from JWT.
			await refreshLogin();
			setError(undefined);
			cancelAdd();
		} else {
			setError(local.TF_Error_Creating_Site);
		}
	}

	return (
		<Card className="p-3">
			<Row>
				<Col className="text-left">
					<h3 className="mb-2">{local.TF_Adding_Site}</h3>
				</Col>
			</Row>

			{data && (
				<AvForm model={data} onValidSubmit={async (e, values) => await save(e, values)}>
					<AvField name="displayName" label={local.TS_Name} validate={{ required: { value: true, errorMessage: local.TS_Required } }} maxLength={30} />
					<AvField name="description" label={local.TF_Description} validate={{ required: { value: true, errorMessage: local.TS_Required } }} maxLength={100} />
					<AvField name="contractStartDate" label={local.TF_ContractStart} type="date" />
					<AvField type="select" name="siteProduct" label={local.TF_ProductType}>
						<option value="Lite">{local.TS_Lite}</option>
						<option value="Standard">{local.TS_Standard}</option>
					</AvField>
					<AvRadioGroup name="siteAlarmsEnabled" label={local.TF_Alarms}>
						<AvRadio label={local.TS_Disabled} value={false} />
						<AvRadio label={local.TS_Enabled} value={true} />
					</AvRadioGroup>
					<AvField type="select" name="defaultLocale" label={local.TF_Site_Language}>
						{availableLanguages().map((lang, i) => {
							return (
								<option key={i} value={lang[1]}>
									{lang[0]}
								</option>
							);
						})}
					</AvField>

					<h5>{local.TS_Distributor_details}</h5>
					<AvField name="distributorName" label={local.TS_Name} maxLength={100} />
					<AvField name="distributorPhone" label={local.TS_Phone_Number} maxLength={50} />
					<AvField name="distributorEmail" label={local.TS_Email} maxLength={256} />

					{error && <Alert color="danger">{error}</Alert>}
					<Row>
						<Col>
							<Button color="primary" className="mt-2 float-left">
								{local.TS_Add}
							</Button>
							<Button color="secondary" className="mt-2 ml-2 float-left" type="button" onClick={cancelAdd}>
								{local.TS_Cancel}
							</Button>
						</Col>
					</Row>
				</AvForm>
			)}
		</Card>
	);
};

export default SiteAdd;

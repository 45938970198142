import React, { useState } from "react";
import { toast } from "react-toastify";
import { Card, Row, Col, Button, Alert } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import local from "../../localization/strings";
import { demoSiteCreate } from "../../api/demoSite";

const DemoSiteAdd = ({ cancelAdd }) => {
	const [error, setError] = useState(undefined);

	async function save(_e, values) {
		const result = await demoSiteCreate(values.id, values.name, values.email, values.displayName, values.companyName, values.password);

		if (result) {
			toast.success(local.TF_Site_Created_Successfully);
			setError(undefined);
			cancelAdd();
		} else {
			setError(local.TF_Error_Creating_Site);
		}
	}

	//localise strings, create searchable dropdown (by site name) with sites to clone. Add delete function. Password validation on client side.

	return (
		<Card className="p-3">
			<Row>
				<Col className="text-left">
					<h3 className="mb-2">{local.TF_Adding_Site}</h3>
				</Col>
			</Row>

			<AvForm onValidSubmit={async (e, values) => await save(e, values)}>
				<AvField name="id" label="Site ID to clone" validate={{ required: { value: true, errorMessage: local.TS_Required } }} />
				<AvField name="name" label="Site Name" maxLength={30} />

				<h5 className="mt-2">Site User Details</h5>
				<AvField name="email" label={local.TS_Email} maxLength={256} />
				<AvField name="displayName" label="Display Name" maxLength={100} />
				<AvField name="companyName" label="Company Name" maxLength={100} />
				<AvField name="password" label="Password" maxLength={256} />

				{error && <Alert color="danger">{error}</Alert>}
				<Row>
					<Col>
						<Button color="primary" className="mt-2 float-left">
							{local.TS_Add}
						</Button>
						<Button color="secondary" className="mt-2 ml-2 float-left" type="button" onClick={cancelAdd}>
							{local.TS_Cancel}
						</Button>
					</Col>
				</Row>
			</AvForm>
		</Card>
	);
};

export default DemoSiteAdd;

import React, { useState, useEffect, useCallback, useContext } from "react";
import { Card } from "reactstrap";
import { Link } from "react-router-dom";

import PageTitle from "../common/PageTitle";
import Loader from "../common/Loader";

import MaterialTable from "material-table";
import { tableIcons } from "../../helpers/tableIcons";

import { noteList, noteUpdate, noteDelete } from "../../api/note";
import local from "../../localization/strings";
import { logEvent } from "../../helpers/ga";
import { UserPreferencesContext } from "../../context/userPreferences";
import { CurrentUserHasRole } from "../../api/auth";
import { roles } from "../../config";

const NotesAll = () => {
	const [showLoading, setShowLoading] = useState(true);
	const [data, setData] = useState([]);
	const { getZoneForGroupId } = useContext(UserPreferencesContext);
	const canDelete = CurrentUserHasRole(roles.DepartmentAdmin);

	const columns = [
		{
			title: local.TS_Date_Time,
			field: "displayDateTime",
			editable: "never",
			customSort: (a, b) => {
				if (a.textX < b.textX) {
					return -1;
				}
				if (a.textX > b.textX) {
					return 1;
				}
				return 0;
			},
			render: (rowData) => (
				<>
					<div>{rowData.displayDate}</div>
					<div>{rowData.displayTime}</div>
				</>
			),
		},
		{
			title: local.TS_Location,
			field: "locationDisplay",
			editable: "never",
			render: (rowData) => (
				<>
					{rowData.groupName ? (
						<Link to={`/charts/zone_charts/${getZoneForGroupId(rowData.groupId)}/${rowData.groupId}/${(new Date(rowData.textX)).getTime()}`}>
							<div>{rowData.buildingName}</div>
							<div>{rowData.zoneName}</div>
							<div>{rowData.groupName}</div>
						</Link>
					) : (
						<div>{rowData.buildingName}</div>
					)}
				</>
			),
		},
		{
			title: local.TS_Value,
			field: "textY",
			render: (rowData) => `${rowData.rightAxis ? local.TS_Right : local.TS_Left}: ${rowData.textY}`,
			editable: "never",
		},
		{
			title: local.TS_Text,
			field: "text",
			editable: "always",
		},
		{
			title: local.TF_Entire_Building,
			field: "onAllCharts",
			render: (rowData) => `${rowData.onAllCharts ? "Y" : ""}`,
			editable: "never",
		},
	];

	const editRow = async (id, newText) => {
		logEvent("Notes", "Edit", `${id}`);

		const row = data.find((x) => x.id === id);
		row.text = newText;

		setData(data);

		await noteUpdate(id, newText);
	};

	const deleteRow = async (id) => {
		logEvent("Notes", "Delete", `${id}`);

		setData(
			data.filter(function(obj) {
				return obj.id !== id;
			}),
		);

		await noteDelete(id);
	};

	const fetchDataCallback = useCallback(async () => {
		const result = await noteList();

		setData(result);

		setShowLoading(false);
	}, []);

	useEffect(() => {
		fetchDataCallback();
	}, [fetchDataCallback]);

	return (
		<>
			<PageTitle title={local.TF_NotesTitle} />
			{showLoading ? (
				<Card>
					<Loader />
				</Card>
			) : (
				<div className="MuiTable-NoPad">
					<MaterialTable
						columns={columns}
						data={data}
						title=""
						icons={tableIcons}
						options={{
							sorting: true,
							exportButton: true,
							exportAllData: true,
							exportFileName: "Notes",
							paging: true,
							pageSize: 10,
							pageSizeOptions: [10, 50, 100],
							headerStyle: {
								backgroundColor: "#fff",
								color: "#000",
							},
							rowStyle: {
								fontFamily: '"Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
								textAlign: "center",
							},
						}}
						editable={{
							isDeletable: (_rowData) => canDelete,
							onRowUpdate: (newData, oldData) =>
								new Promise((resolve, _reject) => {
									editRow(oldData.id, newData.text);
									resolve();
								}),
							onRowDelete: (oldData) =>
								new Promise((resolve, _reject) => {
									deleteRow(oldData.id);
									resolve();
								}),
						}}
					/>
				</div>
			)}
		</>
	);
};

export default NotesAll;

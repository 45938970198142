import React, { useState, useEffect, useCallback } from "react";
import { Card, Row, Col, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import PerformanceStatsFilter from "./PerformanceStatsFilter";
import PerformanceStatsDisplay from "./PerformanceStatsDisplay";
import local from "../../../localization/strings";
import PageTitle from "../../common/PageTitle";
import useWindowSize from "../../../hooks/useWindowSize";
import { userPreferencesGetList, userPreferenceSave } from "../../../api/userPreferences";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logEvent } from "../../../helpers/ga";
import { isFeatureEnabled, features } from "../../../config";
import { CSVLink } from "react-csv";
import moment from "moment";
import EmailModal from "../../common/EmailModal";
import { emailReport } from "../../../api/reports";

const buttonDefaultClasses = "btn-shadow btn-sm mb-sm-2 py-2 px-2 px-sm-3 py-sm-1";

const PerformanceStatistics = () => {
	const [showFilter, setShowFilter] = useState(true);
	const [reportRunning, setReportRunning] = useState(false);
	const [data, setData] = useState({});
	const [report, setReport] = useState(undefined);
	const [showEmail, setShowEmail] = useState(false);
	const size = useWindowSize();

	const generateStats = async (data) => {
		await userPreferenceSave("PerformanceStatisticsReport_Filter", JSON.stringify(data));

		setReportRunning(true);
		setData(data);
		setShowFilter(false);
	};

	const filter = () => {
		logEvent("Performance Statistics", "Filter");

		setShowFilter(true);
	};

	useEffect(() => {
		const LoadData = async () => {
			const prefs = await userPreferencesGetList();

			var match = prefs.find((x) => x.preferenceKey === "PerformanceStatisticsReport_Filter");

			if (match) {
				setData(JSON.parse(match.preferenceValue));
			}
		};

		LoadData();
	}, []);

	const reportComplete = useCallback((report) => {
		setReport(report);
		setReportRunning(false);
	}, []);

	const csvHeaders = (reportData) => {
		return reportData.columns.map((item) => ({ label: item.name, key: item.key }));
	};

	useEffect(() => {
		var dashboardContentDiv = document.getElementById("dashboard-container-div");

		if (dashboardContentDiv) {
			var contentDiv = document.getElementById("toolbar-row");
			contentDiv.style.marginRight = `-${dashboardContentDiv.offsetLeft}px`;
		}
	}, [size]);

	const toggleEmailModal = () => {
		setShowEmail(!showEmail);
	};

	const sendEmail = async (to, subject, body) => {
		logEvent("Performance Statistics", "Send Email");

		emailReport("Performance Statistics", data, to, subject, body);

		setShowEmail(false);
	};

	return (
		<>
			<PageTitle title={local.TS_PerformanceStatistics} />

			<Row id="toolbar-row" className="justify-content-between">
				{showFilter ? (
					""
				) : (
					<>
						<Col className="col-3">
							<Button size="sm" color="secondary" className={buttonDefaultClasses} onClick={() => filter()}>
								<FontAwesomeIcon icon="filter" />
								<span className="d-none d-md-inline-block ml-2">{local.TS_Filter}</span>
							</Button>
						</Col>
						<Col className="col-3 p-0">
							<UncontrolledDropdown size="sm" direction="left" className="allow-overflow float-right">
								<DropdownToggle caret color="info" transform="shrink-3" className={buttonDefaultClasses} disabled={reportRunning}>
									<FontAwesomeIcon icon="share-alt" />
									<span className="d-none d-md-inline-block ml-2">{local.TS_Options}</span>
								</DropdownToggle>
								<DropdownMenu className="menu-border-blue dropdown-menu">
									{/* v0.9 */}
									<DropdownItem>
										{report && (
											<CSVLink className="w-100 d-block" data={report.rows} headers={csvHeaders(report)} filename={`${local.TS_PerformanceStatistics} ${moment().format("DD MMM YYYY")}.csv`}>
												<FontAwesomeIcon icon="download" /> {local.TS_Download}
											</CSVLink>
										)}
									</DropdownItem>
									<DropdownItem disabled>
										<FontAwesomeIcon icon="print" /> {local.TS_Print}
									</DropdownItem>
									<DropdownItem disabled>
										<FontAwesomeIcon icon="copy" /> {local.TS_Copy}
									</DropdownItem>
									{isFeatureEnabled(features.ReportEmail) && (
										<DropdownItem onClick={() => toggleEmailModal()}>
											<FontAwesomeIcon icon="envelope-square" /> {local.TS_Email}
										</DropdownItem>
									)}
								</DropdownMenu>
							</UncontrolledDropdown>
						</Col>
					</>
				)}
			</Row>
			<Card className="p-3" id="performance-statistics-report-div">
				{showFilter ? <PerformanceStatsFilter triggerGenerate={generateStats} filterData={data} /> : <PerformanceStatsDisplay filterData={data} reportComplete={reportComplete} />}
			</Card>
			<EmailModal show={showEmail} cancel={() => setShowEmail(false)} sendEmail={sendEmail} />
		</>
	);
};

export default PerformanceStatistics;

import React, { useState } from "react";
import { CardHeader, CardBody, Row, Col, Button, Alert } from "reactstrap";
import local from "../../localization/strings";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { currentUserUpdate } from "../../api/users";
import { CurrentUserClearCreds } from "../../api/auth";
import { toast } from "react-toastify";

const ProfileDetailsEdit = (props) => {
	const [error, setError] = useState(undefined);

	async function save(_e, values) {
		const result = await currentUserUpdate(values.name, values.email);

		switch (result) {
			case "DuplicateUserName":
				setError(local.TS_EmailTaken);
				return;
			default:
				setError(undefined);
				toast.success(local.TF_Current_User_successfully_updated);

				CurrentUserClearCreds();
				window.location.href = "/authentication/login";
				return;
		}
	}

	return (
		<>
			<CardHeader>
				<Row className="align-items-center">
					<Col>
						<h5 className="mb-0">{local.TF_Edit_User_Details}</h5>
					</Col>
				</Row>
			</CardHeader>
			<CardBody className="bg-light border-top">
				<AvForm model={props} onValidSubmit={async (e, values) => await save(e, values)}>
					<AvField name="name" label={local.TS_Name} validate={{ required: { value: true, errorMessage: local.TS_Required } }} />
					<AvField name="email" label={local.TS_Email} validate={{ email: true, required: { value: true, errorMessage: local.TS_Required } }} />
					{error && <Alert color="danger">{error}</Alert>}
					<Button color={"primary"} className="mt-2">
						{local.TS_Save_Changes}
					</Button>
				</AvForm>
			</CardBody>
		</>
	);
};

export default ProfileDetailsEdit;

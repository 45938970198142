import moment, { isMoment } from "moment";
import "moment/min/locales";
import React, { useCallback, useEffect, useState } from "react";
// v0.9
// import AddButton from "../../common/AddButton";
import Datetime from "react-datetime";
import { Button, Col, Form, Input, Row } from "reactstrap";
import { logEvent } from "../../../helpers/ga";
import local from "../../../localization/strings";
import SiteTreeSingleSelect from "../../common/SiteTreeSingleSelect";

const MultiPeriodReportFilter = ({ triggerGenerate, filterData, onChange, emailReports }) => {
	const [groupId, setGroupId] = useState(null);
	const [startTime, setStartTime] = useState(new Date());
	const [period, setPeriod] = useState("Hour");
	const [periodMax, setPeriodMax] = useState(24);
	const [noOfPeriod, setNoOfPeriod] = useState(1);
	const [noOfTimeSpans, setNoOfTimeSpans] = useState(1);
	const initialGroupId = filterData.groupId || null;
	const [language, setLanguage] = useState("en");

	useEffect(() => {
		setGroupId(filterData.groupId || null);
		setStartTime(filterData.startTime ? new Date(filterData.startTime) : new Date());
		setPeriod(filterData.period || "Hour");
		setNoOfPeriod(filterData.noOfPeriod || 1);
		setNoOfTimeSpans(filterData.noOfTimeSpans || 1);
	}, [filterData]);

	useEffect(() => {
		setLanguage(local.getLanguage());
	}, []);

	const startTimeChange = (e, reset = false) => {
		try {
			if (isMoment(e)) {
				setStartTime(e.toDate());

				logEvent("Multi-Period Report", "Filter Changed", `Start Time: ${e.toDate()}`);

				if (onChange) {
					onChange({ groupId, startTime: e.toDate(), noOfPeriod, period, noOfTimeSpans });
				}
			} else {
				if (reset) {
					setStartTime(moment().toDate());

					if (onChange) {
						onChange({ groupId, startTime: moment().toDate(), noOfPeriod, period, noOfTimeSpans });
					}
				}
			}
		} catch {
			setStartTime(null);
		}
	};

	const periodChange = (e) => {
		setPeriod(e.target.value);

		logEvent("Multi-Period Report", "Filter Changed", `Period: ${e.target.value}`);

		if (onChange) {
			onChange({ groupId, startTime, noOfPeriod, period: e.target.value, noOfTimeSpans });
		}
	};

	const noOfPeriodChange = useCallback(
		(e) => {
			let newValue = typeof e === "number" ? e : parseInt(e.target.value);

			setNoOfPeriod(newValue);

			logEvent("Multi-Period Report", "Filter Changed", `No Of Period: ${newValue}`);

			if (onChange) {
				onChange({ groupId, startTime, noOfPeriod: newValue, period, noOfTimeSpans });
			}
		},
		[groupId, startTime, noOfTimeSpans, onChange, period],
	);

	const noOfTimeSpansChange = (e) => {
		setNoOfTimeSpans(parseInt(e.target.value));

		logEvent("Multi-Period Report", "Filter Changed", `Number Of Time Spans: ${e.target.value}`);

		if (onChange) {
			onChange({ groupId, startTime, noOfPeriod, period, noOfTimeSpans: parseInt(e.target.value) });
		}
	};

	const generate = () => {
		logEvent("Multi-Period Report", "Generate Click");

		triggerGenerate({ groupId, startTime, noOfPeriod, period, noOfTimeSpans });
	};

	const clicked = (e) => {
		setGroupId(e);

		logEvent("Multi-Period Report", "Filter Changed", `Group Id: ${e}`);

		if (onChange) {
			onChange({ groupId: e, startTime, noOfPeriod, period, noOfTimeSpans });
		}
	};

	useEffect(() => {
		let newMax = 24;

		switch (period) {
			case "Hour":
				newMax = 24;
				break;
			case "Day":
				newMax = 30;
				break;
			case "Week":
				newMax = 52;
				break;
			case "Month":
				newMax = 12;
				break;
			case "Year":
				newMax = 10;
				break;
			default:
				break;
		}

		setPeriodMax(newMax);
		if (noOfPeriod > newMax) {
			noOfPeriodChange(1);
		}
	}, [period, noOfPeriod, noOfPeriodChange]);

	return (
		<Form>
			<h5 className="mb-2">{local.TF_Area_to_report}</h5>
			<div className="border rounded-soft" style={{ height: 250, overflowY: "auto", overflowX: "hidden" }}>
				<SiteTreeSingleSelect expandToLevel={0} siteMapLevel={3} inline={true} onClick={clicked} initialChecked={initialGroupId || ""} />
			</div>
			<Row className="mb-2">
				<Col sm={6}>
					<h5 className="mb-2 mt-3">{local.TS_Start_Date_Time}</h5>
					{emailReports ? <div>{local.TF_Calculated}</div> : <Datetime locale={language} dateFormat="DD MMM YYYY" timeFormat="HH:mm" value={startTime} onBlur={(e) => startTimeChange(e, true)} onChange={startTimeChange} showTimeSelect timeConstraints={{ minutes: { step: 15 } }} utc />}
					{/* v0.9 */}
					{/* <AddButton string={"Add Overlay"} buttonClasses={"my-3 w-100"} /> */}
				</Col>
				<Col sm={6}>
					<h5 className="mb-2 mt-3">{local.TF_Time_Span}</h5>
					<div className="form-inline">
						<Input type="select" className="form-control" value={noOfPeriod} onChange={noOfPeriodChange}>
							{[...Array(periodMax)].map((_, i) => {
								const num = i + 1;
								return (
									<option key={i} value={num}>
										{num}
									</option>
								);
							})}
						</Input>
						<Input type="select" className="form-control ml-2" value={period} onChange={periodChange}>
							<option value="Hour">{local.TS_Hour_s}</option>
							<option value="Day">{local.TS_Day_s}</option>
							<option value="Week">{local.TS_Week_s}</option>
							<option value="Month">{local.TS_Month_s}</option>
							<option value="Year">{local.TS_Year_s}</option>
						</Input>
					</div>

					<h5 className="mt-2 mb-3">{local.TF_Number_of_Time_Spans}</h5>
					<div className="form-inline">
						<Input type="select" className="form-control" value={noOfTimeSpans} onChange={noOfTimeSpansChange}>
							{[...Array(30)].map((_, i) => {
								const num = i + 1;
								return (
									<option key={i} value={num}>
										{num}
									</option>
								);
							})}
						</Input>
					</div>
				</Col>
			</Row>
			{triggerGenerate && (
				<Row className="border-top pt-3 mt-3">
					<Col>
						<Button color="primary" onClick={generate} className="col-md-3">
							{local.TS_Generate}
						</Button>
					</Col>
				</Row>
			)}
		</Form>
	);
};

export default MultiPeriodReportFilter;

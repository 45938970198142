import React, { useCallback, useEffect, useState } from "react";
import { Alert } from "reactstrap";
import PageTitle from "../common/PageTitle";
import AlarmCurrentItem from "./AlarmCurrentItem";
import Loader from "../common/Loader";

import local from "../../localization/strings";
import { readingsInAlarm } from "../../api/readings";
import $ from "jquery";

const AlarmCurrent = () => {
	const [loading, setLoading] = useState(true);
	const [alarms, setAlarms] = useState([]);

	const setBadge = useCallback((badgeName, count) => {
		var elem = $(`.badge-${badgeName}`);
		elem.removeClass("d-none");
		if (elem) {
			elem.text(count);
			if (count === 0) {
				elem.addClass("d-none");
			}
		}
	}, []);

	const LoadData = useCallback(async () => {
		setLoading(true);

		const result = await readingsInAlarm();

		// Badge & Bell are on a 5 minute refresh.  The alarm could have cleared, so if none found here we need to remove the badge and bell alarm indicators
		// If alarms then update the count
		const any = result && result.filter((x) => !x.clearedBy).length > 0;
		if (!any) {
			var elemTop = $(".bell-Alarm");
			if (elemTop) {
				elemTop.removeClass("unread-indicator");
			}
		}

		setBadge("AlarmCount", result?.filter((x) => !x.clearedBy).length || 0);
		setBadge("AlarmClearedCount", result?.filter((x) => x.clearedBy).length || 0);

		setAlarms(result);
		setLoading(false);
	}, [setBadge]);

	useEffect(() => {
		LoadData();
	}, [LoadData]);

	return (
		<>
			<PageTitle title={local.TF_CurrentAlarms_Title} />
			{loading && <Loader />}
			{!loading && (
				<>
					{alarms && alarms.length > 0 ? (
						<>
							{alarms
								.filter((x) => !x.clearedBy)
								.map((item, key) => (
									<AlarmCurrentItem item={item} key={key} reload={LoadData} />
								))}
							{alarms
								.filter((x) => x.clearedBy)
								.map((item, key) => (
									<AlarmCurrentItem item={item} key={key} reload={LoadData} />
								))}
						</>
					) : (
						<Alert className="alert-success rounded-soft p-2">{local.TF_CurrentAlarms_None}</Alert>
					)}
				</>
			)}
		</>
	);
};

export default AlarmCurrent;

import React from 'react'

const GradientIcons = () => {
    return (
        <svg style={{ width: 0, height: 0, position: 'absolute' }} aria-hidden="true" focusable="false">
            <linearGradient id="gradient-icons" x2="1" y2="1">
                <stop offset="0%" stopColor="#009bde" />
                <stop offset="100%" stopColor="#0443a2" />
            </linearGradient>
        </svg>
    )
}

export default GradientIcons
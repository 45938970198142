import React from "react";
import SiteTreeMenu from "../../common/SiteTreeMenu";

const ChartReportFilter = ({ selected }) => {
	return (
		<div id="chart-report-div" className="w-100 h-100 p-0" style={{ maxHeight: 600, overflowY: "auto", overflowX: "auto" }}>
			<SiteTreeMenu expandToLevel={1} siteMapLevel={2} inline onClick={selected} />
		</div>
	);
};

export default ChartReportFilter;

import React from "react";
import { Row, Col, Button } from "reactstrap";

import MaterialTable from "material-table";
import { tableIcons } from "../../helpers/tableIcons";

import local from "../../localization/strings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BatteryLevels = ({ data, viewBatteries }) => {
	const batteryBackground = (value) => {
		if (value === -1) {
			return "battery-missing";
		}
		if (value > -1 && value <= 10) {
			return "battery-very-low";
		}
		if (value > 10 && value <= 20) {
			return "battery-low";
		}
		if (value > 15 && value <= 100) {
			return "";
		}
		return "battery-very-low";
	};

	const columns = [
		{
			title: local.TS_Building,
			field: "buildingName",
		},
		{
			title: local.TS_Zone,
			field: "zoneName",
		},
		{
			title: local.TS_Group,
			field: "groupName",
		},
		{
			title: local.TS_Serial_Number,
			field: "transmitterSerialNumber",
			sorting: false,
		},
		{
			title: local.TF_Battery,
			field: "transmitterBatteryLevel",
			type: "numeric",
			defaultSort: "asc",
			render: (rowData) => <div className={"text-center " + batteryBackground(rowData.transmitterBatteryLevel)}>{rowData.transmitterBatteryLevel === -1 ? "-" : rowData.transmitterBatteryLevel}</div>,
			export: false,
		},
		{
			title: local.TF_Battery,
			field: "transmitterBatteryLevelExport",
			export: true,
			cellStyle: { display: "none" },
			headerStyle: { display: "none" },
		},
	];

	return (
		<>
			{
				<div>
					<Row className="mb-2">
						<Col className="col-12">
							<Button color="primary" className="float-right mt-n4" onClick={() => viewBatteries()}>
								<FontAwesomeIcon icon="arrow-left" /> {local.TS_Back}
							</Button>
						</Col>
					</Row>
					<MaterialTable
						columns={columns}
						data={data}
						title={local.TF_Battery_Levels}
						icons={tableIcons}
						options={{
							sorting: true,
							exportButton: true,
							exportAllData: true,
							exportFileName: "Battery Levels",
							paging: true,
							pageSize: 20,
							pageSizeOptions: [20, 50, 100],
							headerStyle: {
								backgroundColor: "#fff",
								color: "#000",
							},
							rowStyle: {
								fontFamily: '"Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
								textAlign: "center",
							},
						}}
					/>
				</div>
			}
		</>
	);
};

export default BatteryLevels;

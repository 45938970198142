import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AvCheckbox, AvCheckboxGroup, AvFeedback, AvField, AvForm, AvGroup, AvInput } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { Button, Col, InputGroup, InputGroupAddon, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { alarmTeamDelete, alarmTeamGet, alarmTeamSave } from "../../../api/alarmTeams";
import { CurrentUserHasRole } from "../../../api/auth";
import { getDepartments } from "../../../api/department";
import { CurrentDepartmentIdGet } from "../../../api/userSettings";
import { features, isFeatureEnabled, roles } from "../../../config";
import { randomKey } from "../../../helpers/utils";
import local from "../../../localization/strings";
import AddButton from "../../common/AddButton";
import AutoHeightDiv from "../../common/AutoHeightDiv";
import OptionalButton from "../../common/OptionalButton";
import SiteTreeMultiSelect from "../../common/SiteTreeMultiSelect";
import Tooltip from "../../common/Tooltip";

const AlarmTeam = ({ selected, reload }) => {
	const [activeTab, setActiveTab] = useState(1);
	const [data, setData] = useState(null);
	const [emailAddresses, setEmailAddresses] = useState([]);
	const [mobileNumbers, setMobileNumbers] = useState([]);
	const [groupIds, setGroupIds] = useState([]);
	const [newGroupIds, setNewGroupIds] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [isSiteAdmin, setIsSiteAdmin] = useState(false);
	const [selectedDepartmentId, setSelectedDepartmentId] = useState(undefined);
	const [limitToGroups, setLimitToGroups] = useState(undefined);
	const [canDelete, setCanDelete] = useState(false);
	const departmentsFeatureEnabled = isFeatureEnabled(features.Departments) && CurrentUserHasRole(roles.SiteAdmin);
	const locationAlarmsFeatureEnabled = isFeatureEnabled(features.LocationAlarms);
	const configureSMSAlarmsFeatureEnabled = isFeatureEnabled(features.ConfigureSMSAlarms);
	const darcaHubOfflineAlarmFeatureEnabled = isFeatureEnabled(features.DarcaHubOfflineAlarm);

	useEffect(() => {
		setIsSiteAdmin(CurrentUserHasRole(roles.SiteAdmin));
	}, []);

	useEffect(() => {
		const LoadDepartments = async () => {
			if (CurrentUserHasRole(roles.SiteAdmin)) {
				var deptResults = await getDepartments();
				setDepartments(deptResults);
			}
		};

		const LoadData = async () => {
			var result = await alarmTeamGet(selected);

			if (result.alarmTeam) {
				const selectedItem = result.alarmTeam;

				let alarmsForTeam = [];

				if (selectedItem.darcaHubOffline) {
					alarmsForTeam.push("darcaHubOffline");
				}
				if (selectedItem.alarm) {
					alarmsForTeam.push("alarm");
				}
				if (selectedItem.reliability) {
					alarmsForTeam.push("reliability");
				}
				if (selectedItem.transmitterBattery) {
					alarmsForTeam.push("transmitterBattery");
				}
				if (selectedItem.loggerContact) {
					alarmsForTeam.push("loggerContact");
				}

				setSelectedDepartmentId(selectedItem.departmentId || -1);

				setData({
					departmentId: selectedItem.departmentId || -1,
					name: selectedItem.name,
					continuous: selectedItem.continuous,
					alarmsForTeam,
				});

				setEmailAddresses(result.emailAddresses.map((item) => ({ key: randomKey(), value: item })));
				setMobileNumbers(Object.keys(result.mobileNumbers).map((itemKey) => ({ key: randomKey(), name: itemKey, value: result.mobileNumbers[itemKey] })));
				setGroupIds(result.groupIds.map((item) => `G:${item}`));
				setNewGroupIds(result.groupIds.map((item) => `G:${item}`));

				if (CurrentUserHasRole(roles.SiteAdmin)) {
					setCanDelete(true);
				} else {
					setCanDelete(CurrentUserHasRole(roles.DepartmentAdmin) && CurrentDepartmentIdGet() === selectedItem.departmentId);
				}
			} else {
				setSelectedDepartmentId(-1);
				setData({ departmentId: -1, userName: "" });
				setEmailAddresses([]);
				setMobileNumbers([]);
				setGroupIds([]);
				setNewGroupIds([]);
				setCanDelete(false);
			}
		};

		if (locationAlarmsFeatureEnabled) {
			setActiveTab(1);
		} else {
			setActiveTab(2);
		}

		LoadDepartments();
		if (selected === -1) {
			setSelectedDepartmentId(-1);
			setData({ departmentId: -1, name: "" });
			setEmailAddresses([]);
			setMobileNumbers([]);
			setGroupIds([]);
			setNewGroupIds([]);
			setCanDelete(false);
		} else {
			LoadData();
		}
	}, [selected, isSiteAdmin, locationAlarmsFeatureEnabled]);

	const addEmailAddress = () => {
		setEmailAddresses(emailAddresses.concat([{ key: randomKey(), value: "" }]));
	};

	const removeEmailAddress = (key) => {
		let temp = emailAddresses.filter((x) => x.key !== key).concat();

		setEmailAddresses(temp);
	};

	const addMobileNumber = () => {
		setMobileNumbers(mobileNumbers.concat([{ key: randomKey(), name: "", value: "" }]));
	};

	const removeMobileNumber = (key) => {
		let temp = mobileNumbers.filter((x) => x.key !== key).concat();

		setMobileNumbers(temp);
	};

	const save = async (_e, values) => {
		const newEmailAddresses = Object.keys(values)
			.map((key) => {
				if (key.startsWith("emailAddresses")) {
					return values[key];
				}
				return null;
			})
			.filter((x) => x);

		let newMobileNumbers = {};

		Object.keys(values).forEach((key) => {
			if (key.startsWith("mobileNumbers_name")) {
				var rand = key.split("_")[2];
				newMobileNumbers[values[key]] = values[`mobileNumbers_value_${rand}`];
			}
		});

		const departmentId = isSiteAdmin ? values.departmentId : CurrentDepartmentIdGet();

		await alarmTeamSave(selected, departmentId, values.name, values.alarmsForTeam, newEmailAddresses, newMobileNumbers, newGroupIds, values.continuous);

		reload();
	};

	const deleteTeam = async () => {
		await alarmTeamDelete(selected);

		reload();
	};

	const submit = (_event, errors, _values) => {
		if (errors && errors.length > 0) {
			if (errors.some((e) => e.startsWith("emailAddresses"))) {
				setActiveTab(2);
			} else if (errors.some((e) => e.startsWith("mobileNumbers"))) {
				setActiveTab(3);
			}
		}
	};

	useEffect(() => {
		var userName = document.getElementById("userName");

		if (userName && userName.value === "") {
			userName.focus();
		}
	});

	const departmentChanged = (e) => {
		const value = e.target.value;
		setSelectedDepartmentId(parseInt(value || -1));
		setNewGroupIds([]);
	};

	useEffect(() => {
		if (selectedDepartmentId === -1) {
			setLimitToGroups(null);
			return;
		}

		const found = departments.find((x) => x.id === parseInt(selectedDepartmentId));

		if (found) {
			setLimitToGroups((found.groupIds || "").split(",").map((item) => `G:${item}`));
			return;
		}
		setLimitToGroups(null);
	}, [selectedDepartmentId, departments]);

	return (
		<>
			{data && (
				<AvForm model={data} key={data.id} onValidSubmit={async (e, values) => await save(e, values)} onSubmit={(event, errors, values) => submit(event, errors, values)}>
					<Row>
						<Col xs="2" className="pt-1">
							<Label>{local.TS_Team}:</Label>
						</Col>
						<Col>
							<AvField name="name" placeholder={local.TF_Enter_name} validate={{ required: { value: true, errorMessage: local.TS_Required } }} />
						</Col>
					</Row>
					{departmentsFeatureEnabled && (
						<Row>
							<Col xs="2">
								<Label>{local.TS_Department}:</Label>
							</Col>
							<Col>
								<AvField type="select" name="departmentId" onChange={departmentChanged}>
									{/* If departments not in use, or user is site admin: */}
									<option value="">{local.TS_None}</option>
									{departments.map((item) => (
										<option key={item.id} value={item.id}>
											{item.name}
										</option>
									))}
								</AvField>
							</Col>
						</Row>
					)}
					<Nav tabs>
						{locationAlarmsFeatureEnabled && (
							<NavItem>
								<NavLink className={`${activeTab === 1 && "active"}`} onClick={() => setActiveTab(1)}>
									{local.TF_Area}
								</NavLink>
							</NavItem>
						)}
						<NavItem>
							<NavLink className={`${activeTab === 2 && "active"}`} onClick={() => setActiveTab(2)}>
								{local.TF_Alarms}
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink className={`${activeTab === 3 && "active"}`} onClick={() => setActiveTab(3)}>
								{local.TF_Emails}
							</NavLink>
						</NavItem>
						{configureSMSAlarmsFeatureEnabled && (
							<NavItem>
								<NavLink className={`${activeTab === 4 && "active"}`} onClick={() => setActiveTab(4)}>
									{local.TF_SMS_Texting}
								</NavLink>
							</NavItem>
						)}
					</Nav>
					<TabContent activeTab={activeTab} className="border p-2">
						<TabPane tabId={1}>
							<AutoHeightDiv padding={400}>
								<h5>{local.TF_Add_Site_Area_For_Alarm}:</h5>
								<SiteTreeMultiSelect key={limitToGroups} expandToLevel={1} siteMapLevel={3} inline={true} initialChecked={groupIds.join(",")} onCheckedChanged={setNewGroupIds} limitToGroupIds={limitToGroups} />
							</AutoHeightDiv>
						</TabPane>
						<TabPane tabId={2}>
							<AutoHeightDiv padding={400}>
								<h5>{local.TF_Select_Alarm_Types}:</h5>
								<AvCheckboxGroup name="alarmsForTeam">
									{darcaHubOfflineAlarmFeatureEnabled && (
										<>
											<AvCheckbox customInput label={local.TF_Darca_hub_offline} value="darcaHubOffline" />
										</>
									)}
									{locationAlarmsFeatureEnabled && (
										<>
											<AvCheckbox customInput label={local.TF_Channel_in_alarm_description} value="alarm" />
											<AvCheckbox customInput label={local.TF_Reliability_description} value="reliability" />
											<AvCheckbox customInput label={local.TF_Low_battery_description} value="transmitterBattery" className="mt-2" />
											<AvCheckbox customInput label={local.TF_Unable_contact_logger} value="loggerContact" className="mt-2" />
										</>
									)}
								</AvCheckboxGroup>
							</AutoHeightDiv>
						</TabPane>
						<TabPane tabId={3}>
							<AutoHeightDiv padding={400}>
								<h5>{local.TF_Add_Email_Addresses_For_Alarm}:</h5>
								<Label>{local.TF_Enter_email_addresses}:</Label>

								{emailAddresses.map((item) => (
									<AvGroup key={item.key} className="mb-2">
										<InputGroup>
											<AvInput value={item.value} name={`emailAddresses_${item.key}`} validate={{ required: true, email: true }} />
											<InputGroupAddon addonType="append">
												<Button color="danger" onClick={() => removeEmailAddress(item.key)} className="btn-sm input-button-radius">
													X
												</Button>
											</InputGroupAddon>
											<AvFeedback>{local.TS_Invalid}</AvFeedback>
										</InputGroup>
									</AvGroup>
								))}

								<AddButton string={local.TS_Add} clickFunction={addEmailAddress} block margin={1} />
							</AutoHeightDiv>
						</TabPane>
						<TabPane tabId={4}>
							<AutoHeightDiv padding={400}>
								{locationAlarmsFeatureEnabled && (
									<>
										<Tooltip placement="bottom" id="AlarmContinuousTooltip" text={local.TS_AlarmContinuousInfo} />
										<h5>
											{local.TF_Continuous}
											<FontAwesomeIcon icon="question-circle" size="xs" href="#" id="AlarmContinuousTooltip" className="ml-2" />
										</h5>
										<AvField name="continuous" type="select" label={`${local.TF_Channel_in_alarm_description}?`}>
											<option value="">{local.TS_No}</option>
											<option value="LI">{local.TS_Logger_Interval}</option>
											<option value="1H">{local.TS_1_Hour}</option>
										</AvField>
									</>
								)}
								<h5>{local.TF_Add_Mobiles_For_Alarm}:</h5>
								<Label>{local.TF_Enter_mobile_numbers}:</Label>
								<br />
								<small>{local.TF_Valid_format_example}</small>
								{mobileNumbers.map((item) => (
									<AvGroup key={item.key} className="mb-2">
										<InputGroup>
											<AvInput value={item.name} name={`mobileNumbers_name_${item.key}`} validate={{ required: true }} placeholder={local.TS_Name} />
											<AvInput value={item.value} name={`mobileNumbers_value_${item.key}`} validate={{ pattern: { value: "^[+][1-9][0-9]{6,15}$" }, required: true }} placeholder={local.TF_Mobile_number} />
											<InputGroupAddon addonType="append">
												<Button color="danger" onClick={() => removeMobileNumber(item.key)} className="btn-sm input-button-radius">
													X
												</Button>
											</InputGroupAddon>
											<AvFeedback>{local.TS_Invalid}</AvFeedback>
										</InputGroup>
									</AvGroup>
								))}
								<AddButton string={local.TS_Add} clickFunction={addMobileNumber} block margin={1} />
							</AutoHeightDiv>
						</TabPane>
					</TabContent>
					<Row>
						<Col>
							<Button color="primary" className="mt-2">
								{local.TS_Save_Changes}
							</Button>
							<OptionalButton show={canDelete} color="danger" className="mt-2 ml-2" onClick={deleteTeam}>
								{local.TS_Delete}
							</OptionalButton>
						</Col>
					</Row>
				</AvForm>
			)}
		</>
	);
};

export default AlarmTeam;

import axios from "axios";
import { CurrentSiteIdGet } from "./userSettings";

export const SendEmail = async (to, subject, body, base64String) => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "post",
			url: "v2/SendEmail",
			data: {
				siteId,
				to,
				subject,
				body,
				base64Images: [base64String],
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const SendEmailPDF = async (to, subject, body, base64String) => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "post",
			url: "v2/SendEmail",
			data: {
				siteId,
				to,
				subject,
				body,
				base64Images: [base64String],
				attachmentName: "attachment.pdf",
				attachmentType: "application/pdf"
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { CardDeck, Row } from "reactstrap";
import Meter from "./Meter";
import GaugeMeter from "./GaugeMeter";
import { dynamicSortAlphaNumeric } from "../../helpers/sort";
import Pagination from "react-js-pagination";
import { logEvent } from "../../helpers/ga";

const Meters = ({ readings, sortBy, meterType, showTransmitterDetails }) => {
	const [page, setPage] = useState(1);
	const pageSize = 12;

	function pageChange(newPage) {
		logEvent("Metering", "Change Page", `${newPage}`);
		setPage(newPage);
	}

	if (readings) {
		readings.sort(dynamicSortAlphaNumeric(sortBy));
	}

	const readingsPage = readings ? readings.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize) : [];

	const layoutClass = meterType === "Gauge" ? "col-6 col-md-4 col-xxl-3 mb-2" : "col-6 col-md-4 col-lg-3 p-2 mb-2";

	return (
		<Fragment>
			<CardDeck className="p-0 m-0 no-gutters">
				{readingsPage &&
					readingsPage.map((item, key) => (
						<div key={key} className={layoutClass}>
							{meterType === "Gauge" ? <GaugeMeter reading={item} showTransmitterDetails={showTransmitterDetails} /> : <Meter reading={item} showTransmitterDetails={showTransmitterDetails} />}
						</div>
					))}
			</CardDeck>
			{readings && readings.length > pageSize && (
				<Row className="mt-3">
					<div className="col-auto mx-auto">
						<Pagination activePage={page} itemsCountPerPage={pageSize} itemClass="page-item" linkClass="page-link" totalItemsCount={readings.length} pageRangeDisplayed={10} onChange={pageChange} />
					</div>
				</Row>
			)}
		</Fragment>
	);
};

Meters.propTypes = {
	readings: PropTypes.array,
	sortBy: PropTypes.string,
};

export default Meters;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useState } from "react";
import { Button, Card, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from "reactstrap";
import { CurrentUserHasRole } from "../../api/auth";
import { features, isFeatureEnabled, roles } from "../../config";
import { logEvent } from "../../helpers/ga";
import local from "../../localization/strings";
import ExpandDiv from "../common/ExpandDiv";
import PageTitle from "../common/PageTitle";
import Departments from "./Departments";
import BuildingEdit from "./edit/BuildingEdit";
import GroupEdit from "./edit/GroupEdit";
import LocationEdit from "./edit/LocationEdit";
import LocationEditAlarm from "./edit/LocationAlarm";
import Reindex from "./edit/Reindex";
import ZoneEdit from "./edit/ZoneEdit";
import SiteLayoutBuildings from "./SiteLayoutBuildings";
import SiteLayoutGroups from "./SiteLayoutGroups";
import SiteLayoutZones from "./SiteLayoutZones";
// import swal from "@sweetalert/with-react";
// import { apiBuildingDelete } from "../../api/building";
// import { apiGroupDelete } from "../../api/group";
// import { apiZoneDelete } from "../../api/zone";

// const SwalDeleteOptions = {
// 	title: local.TS_Are_You_Sure,
// 	buttons: {
// 		cancel: {
// 			visible: true,
// 			text: local.TS_No,
// 			value: false,
// 		},
// 		confirm: {
// 			visible: true,
// 			text: local.TS_Yes,
// 			value: true,
// 		},
// 	},
// 	icon: "warning",
// 	dangerMode: true,
// };

const SiteLayout = () => {
	const [buildingId, setBuildingId] = useState();
	const [editBuilding, setEditBuilding] = useState(false);
	const [zones, setZones] = useState(false);
	const [zoneId, setZoneId] = useState();
	const [editZone, setEditZone] = useState(false);
	const [groups, setGroups] = useState(false);
	const [groupId, setGroupId] = useState();
	const [editGroup, setEditGroup] = useState(false);
	const [locationId, setLocationId] = useState();
	const [editLocation, setEditLocation] = useState(false);
	const [editLocationAlarm, setEditLocationAlarm] = useState(false);
	const [expandAll, setExpandAll] = useState(false);
	const [viewDepts, setViewDepts] = useState(false);
	const [version, setVersion] = useState(1);
	const departmentsFeatureEnabled = isFeatureEnabled(features.Departments) && CurrentUserHasRole(roles.SiteAdmin);
	const siteEditFeatureEnabled = isFeatureEnabled(features.SiteEdit) && CurrentUserHasRole(roles.SiteAdmin);
	const [showReindex, setShowReindex] = useState(false);
	const [reindexEntityType, setReindexEntityType] = useState();
	const [reindexEntityParentId, setReindexEntityParentId] = useState();

	const handleSetCurrentBuilding = (id) => {
		logEvent("Site Layout", "Select Building", `${id}`);
		setZones(false);
		setBuildingId(id);
		setZoneId();
		setGroups(false);
		setZones(true);
	};

	const handleSetCurrentZone = (id) => {
		logEvent("Site Layout", "Select Zone", `${id}`);
		setGroups(false);
		setZoneId(id);
		setGroups(true);
	};

	const handleSetCurrentGroup = useCallback((id) => {
		logEvent("Site Layout", "Select Group", `${id}`);
		setGroupId(id);
	}, []);

	// const handleDeleteBuilding = () => {
	// 	if (buildingId) {
	// 		swal(SwalDeleteOptions).then(async (result) => {
	// 			if (result) {
	// 				await apiBuildingDelete(buildingId);
	// 				setGroups(false);
	// 				setZones(false);
	// 				setZoneId();
	// 				setBuildingId();
	// 				setVersion((x) => x + 1);
	// 			}
	// 		});
	// 	}
	// };

	const handleEditBuilding = (id) => {
		if (!id) {
			setGroups(false);
			setGroupId();
			setZones(false);
			setZoneId();
		}

		setBuildingId(id);
		setEditBuilding(true);
	};
	const handleSavedBuilding = () => {
		setEditBuilding(false);
		setVersion((x) => x + 1);
	};

	// const handleDeleteZone = () => {
	// 	if (zoneId) {
	// 		swal(SwalDeleteOptions).then(async (result) => {
	// 			if (result) {
	// 				await apiZoneDelete(zoneId);
	// 				setGroups(false);
	// 				setZoneId();
	// 				setZones(true);
	// 				setVersion((x) => x + 1);
	// 			}
	// 		});
	// 	}
	// };

	const handleEditZone = (id) => {
		if (!id) {
			setGroups(false);
			setGroupId();
		}

		setZoneId(id);
		setEditZone(true);
	};
	const handleSavedZone = () => {
		setEditZone(false);
		setVersion((x) => x + 1);
	};

	// const handleDeleteGroup = () => {
	// 	if (groupId) {
	// 		swal(SwalDeleteOptions).then(async (result) => {
	// 			if (result) {
	// 				await apiGroupDelete(groupId);
	// 				setGroupId();
	// 				setVersion((x) => x + 1);
	// 			}
	// 		});
	// 	}
	// };

	const handleEditGroup = (id) => {
		setGroupId(id);
		setEditGroup(true);
	};
	const handleSavedGroup = () => {
		setEditGroup(false);
		setVersion((x) => x + 1);
	};

	// const handleDeleteLocation = () => {
	// 	if (locationId) {
	// 		swal(SwalDeleteOptions).then(async (result) => {
	// 			if (result) {
	// 				//await apiLocationDelete(locationId);
	// 				setLocationId();
	// 				setVersion((x) => x + 1);
	// 			}
	// 		});
	// 	}
	// };

	// const handleEditLocation = (id) => {
	// 	setLocationId(id);
	// 	setEditLocation(true);
	// };

	const handleSavedLocation = () => {
		setEditLocation(false);
		setVersion((x) => x + 1);
	};

	const handleEditLocationAlarm = (locationId, groupId) => {
		setGroupId(groupId);
		setLocationId(locationId);
		setEditLocationAlarm(true);
	};

	const handleSavedLocationAlarm = () => {
		setEditLocationAlarm(false);
		setVersion((x) => x + 1);
	};

	const startReindex = (entityType, entityParentId) => {
		setReindexEntityType(entityType);
		setReindexEntityParentId(entityParentId);
		setShowReindex(true);
	};
	const completeReindex = () => {
		setShowReindex(false);
		setReindexEntityType();
		setReindexEntityParentId();
		setVersion((x) => x + 1);
	};

	const EntityMenu = ({ showAlways, parentId, id, icon, name, addFunction, editFunction, deletefunction, entityType }) => {
		return (
			<>
				{(showAlways || parentId) && (
					<UncontrolledDropdown size="sm" direction="down" className="d-inline-block">
						<DropdownToggle caret color="dark" transform="shrink-3" className="dark-button mr-2">
							<FontAwesomeIcon icon={icon} />
							<span className="d-none d-md-inline-block ml-2">{name}</span>
						</DropdownToggle>
						<DropdownMenu className="menu-border-black dropdown-menu">
							{addFunction && (
								<DropdownItem onClick={() => addFunction()}>
									<FontAwesomeIcon icon="plus" /> {local.TS_Add}
								</DropdownItem>
							)}
							{id && (
								<>
									<DropdownItem onClick={() => editFunction(id)}>
										<FontAwesomeIcon icon="pencil-alt" /> {local.TS_Edit}
									</DropdownItem>
									{deletefunction && (
										<DropdownItem onClick={() => deletefunction()}>
											<FontAwesomeIcon icon="times" /> {local.TS_Delete}
										</DropdownItem>
									)}
								</>
							)}
							<DropdownItem onClick={() => startReindex(entityType, parentId)}>
								<FontAwesomeIcon icon="sort" /> {local.TS_Sort}
							</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>
				)}
			</>
		);
	};

	return (
		<>
			<Reindex show={showReindex} entityType={reindexEntityType} parentId={reindexEntityParentId} done={completeReindex} />
			<BuildingEdit buildingId={buildingId} buildingEdit={editBuilding} cancel={() => setEditBuilding(false)} saved={handleSavedBuilding} />
			<ZoneEdit buildingId={buildingId} zoneId={zoneId} zoneEdit={editZone} cancel={() => setEditZone(false)} saved={handleSavedZone} />
			<GroupEdit zoneId={zoneId} groupId={groupId} groupEdit={editGroup} cancel={() => setEditGroup(false)} saved={handleSavedGroup} />
			<LocationEdit groupId={groupId} locationId={locationId} locationEdit={editLocation} cancel={() => setEditLocation(false)} saved={handleSavedLocation} />
			<LocationEditAlarm groupId={groupId} locationId={locationId} locationEditAlarm={editLocationAlarm} cancel={() => setEditLocationAlarm(false)} saved={handleSavedLocationAlarm} />
			<PageTitle title={local.TF_SiteLayoutTitle} />
			<Row className="mb-2 mt-n2">
				<Col className="d-flex ">
					{departmentsFeatureEnabled && (
						<Button
							size="sm"
							className="dark-button mr-2"
							color="dark"
							onClick={() => {
								logEvent("Site Layout", "Toggle Site/Department");
								setViewDepts(!viewDepts);
							}}
						>
							{viewDepts ? local.TF_View_site : local.TF_View_Departments}
						</Button>
					)}
					{siteEditFeatureEnabled && (
						<>
							<EntityMenu
								entityType="Building"
								showAlways
								id={buildingId}
								icon="warehouse"
								name={local.TS_Building}
								editFunction={handleEditBuilding}
								// deletefunction={handleDeleteBuilding}
							/>
							<EntityMenu
								entityType="Zone"
								parentId={buildingId}
								id={zoneId}
								icon="bars"
								name={local.TS_Zone}
								editFunction={handleEditZone}
								// deletefunction={handleDeleteZone}
							/>
							<EntityMenu
								entityType="Group"
								parentId={zoneId}
								id={groupId}
								icon="router"
								name={local.TS_Group}
								editFunction={handleEditGroup}
								// deletefunction={handleDeleteGroup}
							/>
							{/* <EntityMenu
								entityType="Location"
								parentId={groupId}
								id={locationId}
								icon="sliders-h"
								name={local.TS_Parameter}
								editFunction={handleEditLocation}
								deletefunction={handleDeleteLocation}
							/> */}
						</>
					)}
					{!viewDepts && (
						<Button
							size="sm"
							className="dark-button ml-auto"
							color="dark"
							onClick={() => {
								logEvent("Site Layout", "Toggle Expand All");
								setExpandAll(!expandAll);
							}}
						>
							{expandAll ? local.TS_Collapse_All : local.TS_Expand_All}
						</Button>
					)}
				</Col>
			</Row>

			{viewDepts ? (
				<Departments />
			) : (
				<>
					<ExpandDiv expand={expandAll} maxHeight={400}>
						<SiteLayoutBuildings setCurrentBuilding={handleSetCurrentBuilding} version={version} selected={buildingId} />
					</ExpandDiv>
					{zones && (
						<Row className="mt-3">
							<Col>
								<Card className="p-3">
									<Row>
										<Col className="col-12 col-md-4">
											<ExpandDiv expand={expandAll} maxHeight={600}>
												<SiteLayoutZones selected={zoneId} buildingId={buildingId} setCurrentZone={handleSetCurrentZone} version={version} />
											</ExpandDiv>
										</Col>
										<Col className="col-12 col-md-8">
											{groups && (
												<ExpandDiv expand={expandAll} maxHeight={600}>
													<SiteLayoutGroups selected={groupId} zoneId={zoneId} setCurrentGroup={handleSetCurrentGroup} editLocationAlarm={handleEditLocationAlarm} version={version} />
												</ExpandDiv>
											)}
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
					)}
				</>
			)}
		</>
	);
};

export default SiteLayout;

import React, { useState, useEffect, useCallback } from "react";
import ReactDataGrid from "react-data-grid";
import Loader from "../../common/Loader";
import useWindowSize from "../../../hooks/useWindowSize";
import { performanceStatistics } from "../../../api/reports";
import { chunk } from "../../../helpers/utils";
import { Progress } from "reactstrap";

const PerformanceStatsDisplay = ({ filterData, reportComplete }) => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({});
	const [columns, setColumns] = useState([]);
	const [rowCount, setRowCount] = useState(0);
	const size = useWindowSize();
	const [minHeight, setMinHeight] = useState(500);
	const [progress, setProgress] = useState(0);
	const [progressLength, setProgressLength] = useState(0);

	useEffect(() => {
		size.then((r) => setMinHeight(r.height - 225));

		var dashboardContentDiv = document.getElementById("dashboard-container-div");

		if (dashboardContentDiv) {
			var contentDiv = document.getElementById("performance-statistics-report-div");

			if (contentDiv) {
				contentDiv.style.marginRight = `-${dashboardContentDiv.offsetLeft}px`;
			}
		}
	}, [size]);

	const updateProgress = useCallback(() => {
		setProgress((p) => p + 1);
	}, []);

	useEffect(() => {
		const LoadData = async () => {
			const { groupIds, period, startTime, endTime } = filterData;

			if (groupIds.length === 0) {
				setData(null);
				setRowCount(0);
				reportComplete(undefined);
			} else {
				let resultsData = null;

				const chunks = chunk(groupIds, 10);
				setProgress(0);
				setProgressLength(chunks.length);

				for (const i in chunks) {
					var result = await performanceStatistics(chunks[i].join(","), period, 1, startTime, endTime);
					updateProgress();

					//First set?
					if (!resultsData) {
						resultsData = result;
					} else {
						//Append new rows
						resultsData.rows.push(...result.rows);
						resultsData.rowsCount = resultsData.rowsCount + result.rowsCount;

						//Append new columns
						for (const j in result.columns) {
							let found = false;
							for (const k in resultsData.columns) {
								if (result.columns[j].key === resultsData.columns[k].key) {
									found = true;
								}
							}
							if (!found) {
								resultsData.columns.push(result.columns[j]);
							}
						}
					}

					//Apply cell formatters
					resultsData.columns.forEach((column) => {
						if (column.extra && column.extra === "Colour") {
							column.formatter = ValueFormatter;
						} else {
							column.formatter = NormalFormatter;
						}
					});

					//Reformat for no battery levels or signal
					resultsData.rows.forEach((row) => {
						if (row.BatteryLevel === 255) {
							row.BatteryLevel = "-";
						}
					});

					resultsData.rows.forEach((row) => {
						if (row.SignalStrength === 255) {
							row.SignalStrength = "-";
						}
					});

					//Sort data
					resultsData.rows.sort((a, b) => {
						if (a.Building === b.Building) {
							if (a.Zone === b.Zone) {
								return a.Group > b.Group ? 1 : -1;
							}
							return a.Zone > b.Zone ? 1 : -1;
						}
						return a.Building > b.Building ? 1 : -1;
					});

					//Show it
					setColumns(resultsData.columns.concat());
					setData(resultsData);
					setRowCount(resultsData.rowsCount); //This causes the redraw, if not done then only first set of data is shown
					setLoading(false);
				}
				reportComplete(resultsData);
			}
		};

		LoadData();
	}, [filterData, reportComplete, updateProgress]);

	const NormalFormatter = ({ value }) => (
		<div title={value} className="rdg_innerCell">
			{value}
		</div>
	);

	const ValueFormatter = ({ value }) => {
		return (
			value && (
				<div title={value} className="rdg_innerCell bg-secondary text-white">
					{value}
				</div>
			)
		);
	};

	return (
		<>
			{loading ? <Loader /> : <div className="rdg-no-gutters rounded-grid">{data && rowCount > 0 && <ReactDataGrid columns={columns} minColumnWidth={40} resizable={true} rowGetter={(i) => data.rows[i]} rowsCount={rowCount} minHeight={minHeight} enableRowSelect={null} />}</div>}
			{progressLength > 0 && progress !== progressLength && <Progress animated value={progress} max={progressLength} />}
		</>
	);
};

export default PerformanceStatsDisplay;

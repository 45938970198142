import MaterialTable from "material-table";
import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { apiSquirrelsGetList } from "../../../api/apiSquirrels";
import { tableIcons } from "../../../helpers/tableIcons";
import local from "../../../localization/strings";
import PageTitle from "../../common/PageTitle";

const Loggers = ({ history }) => {
	const [data, setData] = useState([]);
	const columns = [
		{ field: "serialNumber", title: local.TS_Serial_Number, defaultSort: "asc" },
		{ field: "name", title: local.TS_Name },
		{ field: "logInterval", title: local.TF_Log_Interval },
		{ field: "scanInterval", title: local.TF_Sample_Interval },
		{
			field: "usedChannels",
			title: local.TF_Channels_Used,
			render: (rowData) => (
				<div>
					{rowData.usedChannels} / {rowData.numberOfChannels}
				</div>
			),
		},
	];

	useEffect(() => {
		const loadData = async () => {
			setData(await apiSquirrelsGetList());
		};

		loadData();
	}, []);

	const editLogger = useCallback(
		(_, rowData) => {
			history.push(`/site_settings/logger/${rowData.serialNumber}`);
		},
		[history],
	);

	return (
		<>
			<PageTitle title={local.TF_Logger_Configuration} />
			<MaterialTable columns={columns} data={data} title="" icons={tableIcons} onRowClick={editLogger} options={{ sorting: true, paging: true, pageSize: 20, pageSizeOptions: [20, 50, 100], emptyRowsWhenPaging: false, showEmptyDataSourceMessage: false, headerStyle: { backgroundColor: "#edf2f9", color: "#01579b" } }} />
		</>
	);
};

export default withRouter(Loggers);

import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import GetCssValuePrefix from '../common/GetCssValuePrefix';

const ParameterButton = props => {

    const [boxIsLight, setBoxIsLight] = useState(true);

    useEffect(() => {
        async function setTextColour() {
            if (props.boxColour === "light") {
                setBoxIsLight(true)
            } else {
                setBoxIsLight(false)
            }
        }
        setTextColour();
    }, [props.boxColour])

    return (
        <Button onClick={props.buttonClick}
            id={props.buttonId}
            style={{ backgroundImage: GetCssValuePrefix() + 'linear-gradient(-45deg, ' + props.colorOne + ', ' + props.colorTwo + ')' }}
            className={boxIsLight
                ? `light-param-box ${props.buttonClasses}`
                : `${props.buttonClasses}`
            }
        >
            {props.name}
        </Button>
    )
}

export default ParameterButton
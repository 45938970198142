import { faBell, faChartBar, faChartLine, faListAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import local from "../../../localization/strings";

const StatsTypes = [
	{
		"id": "1",
		"value": "all-statistics",
		"label": local.TF_All_Statistics,
		"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faChartBar} />,
		"showCheckbox": false,
		"children": [
			{
				"id": "2",
				"value": "show-zone-name",
				"label": local.TF_Show_Zone_Name,
				"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faListAlt} />,
			},
			{
				"id": "3",
				"value": "show-building-name",
				"label": local.TF_Show_Building_Name,
				"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faListAlt} />,
			},
			{
				"id": "4",
				"value": "general-statistics",
				"label": local.TF_General_Statistics,
				"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faChartLine} />,
				"children": [
					{
						"id": "5",
						"value": "start-end-time-of-data",
						"label": local.TF_Start_End_Time_of_Data,
						"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faChartLine} />,
					},
					{
						"id": "6",
						"value": "valid-data",
						"label": local.TF_Valid_Data,
						"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faChartLine} />,
					},
					{
						"id": "7",
						"value": "max-value",
						"label": local.TF_Max_Value,
						"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faChartLine} />,
					},
					{
						"id": "8",
						"value": "time-first-max-value",
						"label": local.TF_Time_of_First_Max_Value,
						"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faChartLine} />,
					},
					{
						"id": "9",
						"value": "min-value",
						"label": local.TF_Min_Value,
						"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faChartLine} />,
					},
					{
						"id": "10",
						"value": "time-first-min-value",
						"label": local.TF_Time_of_First_Min_Value,
						"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faChartLine} />,
					},
					{
						"id": "11",
						"value": "average",
						"label": local.TF_Average,
						"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faChartLine} />,
					},
					{
						"id": "12",
						"value": "sum",
						"label": local.TF_Sum,
						"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faChartLine} />,
					},
					{
						"id": "13",
						"value": "standard-deviation",
						"label": local.TF_Standard_Deviation,
						"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faChartLine} />,
					},
				],
			},
			{
				"id": "14",
				"value": "safe-limit-statistics",
				"label": local.TF_Safe_Limit_Statistics,
				"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faBell} />,
				"children": [
					{
						"id": "15",
						"value": "alarms",
						"label": local.TS_Alarms,
						"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faBell} />,
					},
					{
						"id": "16",
						"value": "thresholds",
						"label": local.TS_Thresholds,
						"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faBell} />,
					},
					{
						"id": "17",
						"value": "within",
						"label": local.TF_Within,
						"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faBell} />,
						"children": [
							{
								"id": "18",
								"value": "readings-within",
								"label": local.TF_Readings_Within,
								"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faBell} />,
							},
							// {
							// 	"id": "19",
							// 	"value": "time-within",
							// 	"label": local.TF_Time_Within,
							// 	"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faBell} />,
							// },
							{
								"id": "20",
								"value": "percent-readings-within",
								"label": local.TF_Percent_Readings_Within,
								"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faBell} />,
							},
							// {
							// 	"id": "21",
							// 	"value": "colour-code-percent-within",
							// 	"label": local.TF_Colour_Code_Within,
							// 	"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faBell} />,
							// },
						],
					},
					{
						"id": "22",
						"value": "outside",
						"label": local.TF_Outside,
						"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faBell} />,
						"children": [
							{
								"id": "23",
								"value": "readings-outside",
								"label": local.TF_Readings_Outside,
								"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faBell} />,
							},
							// {
							// 	"id": "24",
							// 	"value": "time-outside",
							// 	"label": local.TF_Time_Outside,
							// 	"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faBell} />,
							// },
							{
								"id": "25",
								"value": "percent-readings-outside",
								"label": local.TF_Percent_Readings_Outside,
								"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faBell} />,
							},
						],
					},
					{
						"id": "26",
						"value": "above",
						"label": local.TF_Above,
						"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faBell} />,
						"children": [
							{
								"id": "27",
								"value": "readings-above",
								"label": local.TF_Readings_Above,
								"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faBell} />,
							},
							// {
							// 	"id": "28",
							// 	"value": "time-above",
							// 	"label": local.TF_Time_Above,
							// 	"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faBell} />,
							// },
							{
								"id": "29",
								"value": "percent-readings-above",
								"label": local.TF_Percent_Readings_Above,
								"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faBell} />,
							},
						],
					},
					{
						"id": "30",
						"value": "below",
						"label": local.TF_Below,
						"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faBell} />,
						"children": [
							{
								"id": "31",
								"value": "readings-below",
								"label": local.TF_Readings_Below,
								"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faBell} />,
							},
							// {
							// 	"id": "32",
							// 	"value": "time-below",
							// 	"label": local.TF_Time_Below,
							// 	"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faBell} />,
							// },
							{
								"id": "33",
								"value": "percent-readings-below",
								"label": local.TF_Percent_Readings_Below,
								"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faBell} />,
							},
						],
					},
				],
			},
			// {
			// 	"id": "34",
			// 	"value": "custom-threshold-statistics",
			// 	"label": local.TF_Custom_Threshold_Statistics,
			// 	"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faEdit} />,
			// 	"children": [
			// 		{
			// 			"id": "35",
			// 			"value": "custom-readings-above",
			// 			"label": local.TF_Readings_Above,
			// 			"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faEdit} />,
			// 		},
			// 		{
			// 			"id": "36",
			// 			"value": "custom-time-above",
			// 			"label": local.TF_Time_Above,
			// 			"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faEdit} />,
			// 		},
			// 		{
			// 			"id": "37",
			// 			"value": "custom-percent-readings-above",
			// 			"label": local.TF_Percent_Readings_Above,
			// 			"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faEdit} />,
			// 		},
			// 		{
			// 			"id": "38",
			// 			"value": "custom-readings-below",
			// 			"label": local.TF_Readings_Below,
			// 			"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faEdit} />,
			// 		},
			// 		{
			// 			"id": "39",
			// 			"value": "custom-time-below",
			// 			"label": local.TF_Time_Below,
			// 			"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faEdit} />,
			// 		},
			// 		{
			// 			"id": "40",
			// 			"value": "custom-percent-readings-below",
			// 			"label": local.TF_Percent_Readings_Below,
			// 			"icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faEdit} />,
			// 		},
			// 	],
			// },
			// {
			//     "id": "41",
			//     "value": "show-average-parameter-stats",
			//     "label": local.TF_Show_Average_Parameter_Stats,
			//     "icon": <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faCalculator} />
			// }
		],
	},
];

export default StatsTypes;

import axios from "axios";
import { CurrentSiteIdGet } from "./userSettings";
import { CurrentUserEmailAddress } from "./auth";

export const distributorsGetList = async () => {
	try {
		const result = await axios({
			method: "get",
			url: "v2/Distributors",
			params: {},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const usersForSiteGetList = async (siteId) => {
	try {
		const result = await axios({
			method: "get",
			url: "v2/Users",
			params: {
				siteId,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const apiUserFeatureDetails = async (siteId, feature) => {
	try {
		const result = await axios({
			method: "get",
			url: "v2/User/Feature",
			params: {
				siteId,
				feature,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const apiUserFeatureSetIsEnabled = async (siteId, forUserId, feature, isEnabled) => {
	try {
		const result = await axios({
			method: "post",
			url: "v2/User/Feature/SetIsEnabled",
			data: {
				siteId,
				forUserId,
				feature,
				isEnabled,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const usersGetList = async () => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "get",
			url: "v2/Users",
			params: {
				siteId,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const userGet = async (forUserId) => {
	const siteId = CurrentSiteIdGet() || "00000000-0000-0000-0000-000000000000";

	try {
		const result = await axios({
			method: "get",
			url: "v2/User",
			params: {
				siteId,
				forUserId,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const distributorGet = async (forUserId) => {
	try {
		const result = await axios({
			method: "get",
			url: "v2/User",
			params: {
				forUserId,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const siteUserGet = async (siteId, forUserId) => {
	try {
		const result = await axios({
			method: "get",
			url: "v2/User",
			params: {
				siteId,
				forUserId,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const siteUserCreate = async (siteId, emailAddress, displayName, role, companyName, departmentId, password, emailInvite) => {
	const sites = [departmentId ? `${siteId}/${departmentId}` : siteId];

	try {
		const result = await axios({
			method: "post",
			url: "v2/User",
			data: {
				siteId,
				newUser: {
					emailAddress,
					displayName,
					companyName,
					role,
					password,
					sites,
					emailInvite,
				},
			},
		});

		return result.data || "OK";
	} catch (err) {
		if (err.response && err.response.status === 409) {
			return "DUP";
		}
		if (err.response && err.response.status === 402) {
			return "UPGRADE";
		}
		if (err.response && err.response.status === 400) {
			if (err.response && err.response.data && err.response.data.Errors && err.response.data.Errors[0]) {
				return err.response.data.Errors[0].ErrorCode;
			}
		}

		console.error(err);
	}
};

export const distributorCreate = async (emailAddress, displayName, role, companyName, departmentId) => {
	try {
		const result = await axios({
			method: "post",
			url: "v2/User",
			data: {
				newUser: {
					emailAddress,
					displayName,
					companyName,
					role,
					password: "N/A",
					emailInvite: true,
				},
			},
		});

		return result.data;
	} catch (err) {
		if (err.response && err.response.status === 409) {
			return "DUP";
		}
		if (err.response && err.response.status === 402) {
			return "UPGRADE";
		}
		console.error(err);
	}
};

export const userCreate = async (emailAddress, displayName, role, companyName, departmentId) => {
	const siteId = CurrentSiteIdGet() || "00000000-0000-0000-0000-000000000000";

	const sites = [departmentId ? `${siteId}/${departmentId}` : siteId];

	try {
		const result = await axios({
			method: "post",
			url: "v2/User",
			data: {
				siteId,
				newUser: {
					emailAddress,
					displayName,
					companyName,
					role,
					password: "N/A",
					sites,
					emailInvite: true,
				},
			},
		});

		return result.data;
	} catch (err) {
		if (err.response && err.response.status === 409) {
			return "DUP";
		}
		if (err.response && err.response.status === 402) {
			return "UPGRADE";
		}
		console.error(err);
	}
};

export const siteUserUpdate = async (siteId, forUserEmail, displayName, newRole, companyName, newEmail) => {
	if (!newEmail) {
		newEmail = null;
	}

	try {
		const result = await axios({
			method: "patch",
			url: "v2/User",
			data: {
				siteId,
				forUserEmail,
				displayName,
				companyName,
				newRole,
				newEmail,
			},
		});

		return result.data;
	} catch (err) {
		if (err.response && err.response.status === 409) {
			if (err.response && err.response.data && err.response.data.Errors && err.response.data.Errors[0] && err.response.data.Errors[0].Message.startsWith("Unable to remove last Site Admin for Site")) {
				return "LASTADMIN";
			}
		}

		if (err.response && err.response.status === 400) {
			if (err.response && err.response.data && err.response.data.Errors && err.response.data.Errors[0]) {
				return err.response.data.Errors[0].ErrorCode;
			}
		}

		console.error(err);
	}
};

export const userUpdate = async (forUserEmail, displayName, newRole, companyName, newEmail) => {
	const siteId = CurrentSiteIdGet() || "00000000-0000-0000-0000-000000000000";

	return await siteUserUpdate(siteId, forUserEmail, displayName, newRole, companyName, newEmail);
};

export const currentUserUpdate = async (displayName, newEmail) => {
	const siteId = CurrentSiteIdGet();
	const forUserEmail = CurrentUserEmailAddress();

	try {
		const result = await axios({
			method: "patch",
			url: "v2/User",
			data: {
				siteId,
				forUserEmail,
				displayName,
				newEmail,
				UpdateType: "UsersDisplayNameAndEmail",
			},
		});

		return result.data;
	} catch (err) {
		if (err.response && err.response.status === 400) {
			if (err.response && err.response.data && err.response.data.Errors && err.response.data.Errors[0]) {
				return err.response.data.Errors[0].ErrorCode;
			}
		}

		console.error(err);
	}
};

export const siteUserSendInvite = async (siteId, forUserEmail) => {
	try {
		const result = await axios({
			method: "post",
			url: "v2/UserInvite",
			data: {
				siteId,
				forUserEmail,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const currentUserChangePassword = async (currentPassword, newPassword) => {
	const siteId = CurrentSiteIdGet();

	try {
		await axios({
			method: "patch",
			url: "v2/UserPasswordChange",
			data: {
				siteId,
				currentPassword,
				newPassword,
			},
		});

		return "OK";
	} catch (err) {
		if (err.response && err.response.status === 400) {
			if (err.response && err.response.data && err.response.data.Errors && err.response.data.Errors[0]) {
				return err.response.data.Errors[0].ErrorCode;
			}
		}

		console.error(err);
	}
};

export const userDelete = async (forUserId) => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "delete",
			url: "v2/User",
			data: {
				siteId,
				forUserId,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const siteUserDelete = async (siteId, forUserId) => {
	try {
		const result = await axios({
			method: "delete",
			url: "v2/User",
			data: {
				siteId,
				forUserId,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const userAddToSite = async (forUserEmail, siteId) => {
	try {
		const result = await axios({
			method: "post",
			url: "v2/UserSite",
			data: {
				siteId,
				forUserEmail,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const userRemoveFromSite = async (forUserEmail, siteId) => {
	try {
		const result = await axios({
			method: "delete",
			url: "v2/UserSite",
			data: {
				siteId,
				forUserEmail,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const userUpdateSite = async (forUserId, siteId, newDepartmentId) => {
	if (!siteId) {
		siteId = CurrentSiteIdGet();
	}

	if (newDepartmentId === "") {
		newDepartmentId = null;
	}

	try {
		await axios({
			method: "patch",
			url: "v2/UserSite",
			data: {
				siteId,
				forUserId,
				newDepartmentId,
			},
		});

		return true;
	} catch (err) {
		console.error(err);
		return false;
	}
};

import React, { useState } from "react";
import { Button, Card, CardDeck, Modal, ModalBody, Row } from "reactstrap";
import { SiteRequiresMFA } from "../../api/auth";
import local from "../../localization/strings";
import MFA from "../mfa/MFA";
import SiteThumbnail from "./SiteThumbnail";

const UserSiteImages = ({ sites, accessSite, when }) => {
	const [mfaSiteId, setMFASiteId] = useState(undefined);
	const [showMfa, setShowMfa] = useState(false);

	const mfaOnStart = (siteId) => {
		setMFASiteId(siteId);
		setShowMfa(true);
	};

	const mfaOnDone = () => {
		accessSite(mfaSiteId);
		setShowMfa(false);
	};

	return (
		<div className="fs--1 font-weight-normal p-3 w-100">
			<Modal isOpen={showMfa} toggle={() => setShowMfa(false)} centered backdrop="static">
				<ModalBody>
					<MFA siteId={mfaSiteId} onCancel={() => setShowMfa(false)} onDone={() => mfaOnDone()} />
				</ModalBody>
			</Modal>

			{sites && (
				<CardDeck className="bg-transparent">
					{sites.map((site, i) => (
						<Card key={`${i}_${when}`} index={i} className="dash-border col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 py-3 px-4 mb-2 distributor-card">
							<Row className="distributor-site-image">
								<SiteThumbnail displayName={site.displayName} id={site.id} when={when} />
							</Row>
							<Row className="mt-2 distributor-site-name">
								<h5 className="mx-auto text-center">{site.displayName}</h5>
							</Row>
							<Row>
								{SiteRequiresMFA(site.id) ? (
									<Button color="primary" className="mx-auto mt-2 mb-0" onClick={() => mfaOnStart(site.id)}>
										{local.TF_View_site}
									</Button>
								) : (
									<Button color="primary" className="mx-auto mt-2 mb-0" onClick={() => accessSite(site.id)}>
										{local.TF_View_site}
									</Button>
								)}
							</Row>
						</Card>
					))}
				</CardDeck>
			)}
		</div>
	);
};

export default UserSiteImages;

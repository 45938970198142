import React, { useState, useContext } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logEvent } from "../../helpers/ga";
import withRedirect from "../../hoc/withRedirect";
import { UserPreferencesContext } from "../../context/userPreferences";

const WidgetDropdownMenu = ({ options, color, className, size, tag, setRedirect, setRedirectUrl, textColour }) => {
	const [open, setOpen] = useState(false);
	const { getZoneForGroupId } = useContext(UserPreferencesContext);

	const showChartClicked = (groupId) => {
		logEvent("Widget Menu click", "Menu Clicked on Dashboard Widget", `GroupId: ${groupId}`);

		const zoneId = getZoneForGroupId(groupId);

		setRedirectUrl(`/charts/zone_charts/${zoneId}/${groupId}`);
		setRedirect(true);
	};

	return (
		<div className="widget-menu" style={{ position: "absolute", right: 10, top: 10 }}>
			<Dropdown isOpen={open} toggle={() => setOpen(!open)}>
				<DropdownToggle className={className} tag={tag} color={color} transform="shrink-3" size={size} style={{ color: textColour }}>
					<FontAwesomeIcon icon="ellipsis-v" fixedWidth />
				</DropdownToggle>
				<DropdownMenu right className="border">
					{options.map((item, index) => (
						<DropdownItem key={index} onClick={() => showChartClicked(item.groupId)}>
							{item.name}
						</DropdownItem>
					))}
				</DropdownMenu>
			</Dropdown>
		</div>
	);
};

export default withRedirect(WidgetDropdownMenu);

import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

import local from "../../../localization/strings";
import { apiLocationsGetList } from "../../../api/location";
import { parametersGetList } from "../../../api/parameter";

const LocationEdit = ({ groupId, locationId, locationEdit, cancel, saved }) => {
	const [data, setData] = useState(undefined);
	const [locations, setLocations] = useState([]);
	const [parameters, setParameters] = useState([]);

	//Get all parameters
	//remove any on this group except this location, if in edit mode

	useEffect(() => {
		const LoadParameters = async () => {
			const result = await parametersGetList();

			setParameters(result);
		};

		const LoadLocations = async () => {
			const result = await apiLocationsGetList(groupId);

			setLocations(result);

			if (locationId) {
				setData(result.find((x) => x.id === locationId));
			} else {
				setData({});
			}
		};

		LoadParameters();

		if (locationEdit) {
			LoadLocations();
		} else {
			setData(undefined);
			setLocations([]);
			setParameters([]);
		}
	}, [groupId, locationId, locationEdit]);

	async function save(_e, values) {
		// await apiLocationSave(zoneId, locationId, values.name, data.index);

		saved();
	}

	return (
		<Modal isOpen={locationEdit} toggle={cancel} centered backdrop="static">
			<ModalHeader toggle={cancel}>{local.TS_Parameter}</ModalHeader>
			<ModalBody>
				{data && (
					<AvForm model={data} onValidSubmit={async (e, values) => await save(e, values)}>
						<AvField name="parameterId" type="select" label={local.TS_Parameter} validate={{ required: { value: true, errorMessage: local.TS_Required } }}>
                            <option></option>
							{parameters.map((p) => {
								if (data.parameterId === p.id) {
									return (
										<option key={p.id} value={p.id} selected>
											{p.name}
										</option>
									);
								}
								if (locations.find((x) => x.parameterId === p.id)) {
									return null;
								}
								return <option key={p.id} value={p.id}>{p.name}</option>;
							})}
						</AvField>

						<Row>
							<Col>
								<Button color="primary" className="mt-2 float-left">
									{local.TS_Save}
								</Button>
								<Button color="secondary" className="mt-2 ml-2 float-left" type="button" onClick={cancel}>
									{local.TS_Cancel}
								</Button>
							</Col>
						</Row>
					</AvForm>
				)}
			</ModalBody>
		</Modal>
	);
};

export default LocationEdit;

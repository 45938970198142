import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "reactstrap";

const buttonDefaultClasses = "mr-2 btn-shadow btn-sm px-3 py-2 px-md-2 py-md-1 button-font";

const FontAwesomeButton = ({ color, icon, text, onClick, className }) => {
	return (
		<Button className={`${buttonDefaultClasses} ${className}`} color={color} transform="shrink-3" onClick={onClick}>
			<FontAwesomeIcon icon={icon} />
			<span className="d-none d-md-inline-block ml-2">{text}</span>
		</Button>
	);
};

export default FontAwesomeButton;

export function dynamicSort(property) {
	var sortOrder = 1;
	if (property[0] === "-") {
		sortOrder = -1;
		property = property.substr(1);
	}
	return function(a, b) {
		var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
		return result * sortOrder;
	};
}

export function dynamicSortAlphaNumeric(property) {
	var sortOrder = 1;
	if (property[0] === "-") {
		sortOrder = -1;
		property = property.substr(1);
	}
	return function(a, b) {
		// Some browsers don't implement localeCompare or locales, so catch any error and fallback to alpha sorting
		try {
			var result = a[property].localeCompare(b[property], "en", { numeric: true });
			return result * sortOrder;
		} catch {
			var result2 = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
			return result2 * sortOrder;
		}
	};
}

export function dynamicSortMultiple() {
	/*
	 * save the arguments object as it will be overwritten
	 * note that arguments object is an array-like object
	 * consisting of the names of the properties to sort by
	 */
	var props = arguments;
	return function(obj1, obj2) {
		var i = 0,
			result = 0,
			numberOfProperties = props.length;
		/* try getting a different result from 0 (equal)
		 * as long as we have extra properties to compare
		 */
		while (result === 0 && i < numberOfProperties) {
			result = dynamicSort(props[i])(obj1, obj2);
			i++;
		}
		return result;
	};
}

export function dynamicSortAlphaNumericMultiple() {
	/*
	 * save the arguments object as it will be overwritten
	 * note that arguments object is an array-like object
	 * consisting of the names of the properties to sort by
	 */
	var props = arguments;
	return function(obj1, obj2) {
		var i = 0,
			result = 0,
			numberOfProperties = props.length;
		/* try getting a different result from 0 (equal)
		 * as long as we have extra properties to compare
		 */
		while (result === 0 && i < numberOfProperties) {
			result = dynamicSortAlphaNumeric(props[i])(obj1, obj2);
			i++;
		}
		return result;
	};
}

import React, { useEffect, useState } from "react";
import local from "../../localization/strings";
import { Card, Button, Row, Col, CardHeader, CardBody } from "reactstrap";
import { siteGet, siteAccessCodeGet, siteAccessCodeSet } from "../../api/site";
import { defaultDistributorDetails } from "../../config";
import { AvField, AvForm, AvInput } from "availity-reactstrap-validation";
import { toast } from "react-toastify";

const TabHelp = ({ activeTab }) => {
	const [siteData, setSiteData] = useState(null);
	const [accessCodeData, setAccessCodeData] = useState(undefined);

	useEffect(() => {
		const LoadData = async () => {
			setSiteData(await siteGet());

			setAccessCodeData(await siteAccessCodeGet());
		};

		if (activeTab === 4) {
			LoadData();
		} else {
            setAccessCodeData(undefined);
        }
	}, [activeTab]);

	const distributorName = () => (siteData ? siteData.distributorName || defaultDistributorDetails.name : "");
	const distributorPhone = () => (siteData ? siteData.distributorPhone || defaultDistributorDetails.tel : "");
	const distributorEmail = () => (siteData ? siteData.distributorEmail || defaultDistributorDetails.email : "");

	async function save(_e, values) {
        siteAccessCodeSet(values.accessCode, values.eltekRequiresAccessCode, values.distributorRequiresAccessCode);

        toast.success(local.TF_Access_Code_Updated);
	}

	return (
		<Row>
			<Col className="col-12 col-lg-6">
				<Card>
					<CardHeader>
						<h4>{local.TS_Distributor_details}</h4>
					</CardHeader>
					<CardBody className="bg-light border-top p-3">
						<label>{local.TS_Name}:</label>
						<div>{distributorName()}</div>
						<label className="mt-4">{local.TS_Phone_Number}:</label>
						<div>
							<a href={`tel:${distributorPhone()}`}>{distributorPhone()}</a>
						</div>
						<label className="mt-4">{local.TS_EmailAddress}:</label>
						<div>
							<a href={`mailto:${distributorEmail()}`}>{distributorEmail()}</a>
						</div>
					</CardBody>
				</Card>
			</Col>
			<Col className="col-12 col-lg-6">
				<Card>
					<CardHeader>
						<h4>{local.TF_Access_Code}</h4>
					</CardHeader>
					<CardBody className="bg-light border-top p-3">
						{accessCodeData && (
							<AvForm model={accessCodeData} onValidSubmit={async (e, values) => await save(e, values)}>
								<AvField name="accessCode" label={local.TF_Access_Code} validate={{ required: { value: true, errorMessage: local.TS_Required } }} />
								<Row>
									<Col>
										<h6>{local.TF_Require_access_code_for}:</h6>
									</Col>
								</Row>
								<Row className="px-4">
									<Col className="col-12">
										<AvInput type="checkbox" name="eltekRequiresAccessCode" /> Eltek
									</Col>
									<Col className="col-12">
										<AvInput type="checkbox" name="distributorRequiresAccessCode" /> {local.TS_Distributor}
									</Col>
								</Row>
								<Row>
									<Col>
										<Button color="primary" size="sm" className="mt-3">
											{local.TF_Save_Changes}
										</Button>
									</Col>
								</Row>
							</AvForm>
						)}
					</CardBody>
				</Card>
			</Col>
		</Row>
	);
};

export default TabHelp;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MaterialTable from "material-table";
import React, { useCallback, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert, Button, Col, Row } from "reactstrap";
import { apiSquirrelChannelAction, apiSquirrelChannelsGetListForCode } from "../../api/apiSquirrels";
import { tableIcons } from "../../helpers/tableIcons";
import local from "../../localization/strings";
import Loader from "../common/Loader";
import PageTitle from "../common/PageTitle";
import Section from "../common/Section";
import Logo from "../navbar/LogoMini";

const SquirrelChannelsForCode = ({ match, history }) => {
	const code = match?.params?.code;

	const [loading, setLoading] = useState(true);
	const [muting, setMuting] = useState([]);
	const [unmuting, setUnmuting] = useState([]);
	const [data, setData] = useState(undefined);
	const [error, setError] = useState(undefined);

	const loadData = useCallback(async () => {
		try {
			const apiResult = await apiSquirrelChannelsGetListForCode(code);
			setData(apiResult);
			setLoading(false);
		} catch (err) {
			switch (err?.response?.status) {
				case 400:
					if (err?.response?.data?.indexOf("CodeExpiredException")) {
						setError(local.TF_LinkExpired);
					} else {
						setError(err?.response?.data);
					}
					setLoading(false);
					break;
				case 500:
					history.push("/errors/500");
					break;
				default:
					console.log(err?.response);
					break;
			}
		}
	}, [history, code]);

	useEffect(() => {
		loadData();
	}, [loadData]);

	const columns = [
		{
			field: "channelNumber",
			title: local.TF_Channel_Number,
			render: (rowData) => <div>{rowData.channelNumber + 1}</div>,
			defaultSort: "asc",
			cellStyle: {
				width: "30%",
			},
		},
		{
			field: "identity",
			title: local.TS_Name,
			cellStyle: {
				width: "30%",
			},
		},
		{
			render: (rowData) => (
				<div className="text-nowrap">
					<Button
						title={local.TF_Mute}
						size="sm"
						color="danger"
						disabled={muting.includes(rowData.channelNumber)}
						onClick={async () => {
							setMuting((x) => [...x, rowData.channelNumber]);
							await apiSquirrelChannelAction(rowData.channelNumber + 1, code, "mute");
							setMuting((x) => x.filter((y) => y !== rowData.channelNumber));
							toast.success(`${local.TF_Muted} ${rowData.identity}`);
						}}
						className="mx-1"
					>
						<FontAwesomeIcon icon="volume-mute" className="mr-2" />
						{local.TF_Mute}
					</Button>
					<Button
						title={local.TF_Unmute}
						size="sm"
						color="primary"
						disabled={unmuting.includes(rowData.channelNumber)}
						onClick={async () => {
							setUnmuting((x) => [...x, rowData.channelNumber]);
							await apiSquirrelChannelAction(rowData.channelNumber + 1, code, "alarm");
							setUnmuting((x) => x.filter((y) => y !== rowData.channelNumber));
							toast.success(`${local.TF_Unmuted} ${rowData.identity}`);
						}}
					>
						<FontAwesomeIcon icon="volume-up" className="mr-2" />
						{local.TF_Unmute}
					</Button>
				</div>
			),
			cellStyle: {
				width: "40%",
			},
		},
	];

	return (
		<>
			{loading && <Loader />}
			{!loading && (
				<Section className="py-0">
					<Row className="min-vh-100 py-4 d-flex justify-content-center">
						<Col xs={12} lg={9} xl={6}>
							<Logo />
							{error && (
								<div>
									<Alert color="danger">{error}</Alert>
								</div>
							)}
							{data && (
								<>
									<div className="text-center">
										<PageTitle title={data[0].serialNumber} />
										<PageTitle title={`(${data[0].name})`} />
									</div>
									<MaterialTable columns={columns} data={data} title="" icons={tableIcons} options={{ tableLayout: "auto", sorting: true, paging: true, pageSize: 20, pageSizeOptions: [20, 50, 100], emptyRowsWhenPaging: false, showEmptyDataSourceMessage: false, headerStyle: { backgroundColor: "#edf2f9", color: "#01579b" } }} />
								</>
							)}
							<Link className="btn btn-primary btn-sm mt-3" to="/">
								<FontAwesomeIcon icon="home" className="mr-2" />
								{local.TF_404_Go_to_Dashboard}
							</Link>
						</Col>
					</Row>
				</Section>
			)}
		</>
	);
};

export default withRouter(SquirrelChannelsForCode);

import axios from "axios";
import { CurrentSiteIdGet } from "./userSettings";

export const readingsLatestTable = async (buildingId, groupIds, condensed = false) => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "post",
			url: "v2/Readings/Latest/Table",
			data: {
				siteId,
				buildingId,
				groupIds,
				condensed,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};
const readingsLatestForSite = async (siteId, groupIds, backgroundTaskAllowFail, includeCalculated = true) => {
	try {
		const result = await axios({
			method: "post",
			url: "v2/Readings/Latest",
			data: {
				SiteId: siteId,
				GroupIds: groupIds,
				includeCalculated,
			},
			headers: {
				backgroundTaskAllowFail,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const readingsLatest = async (groupIds, backgroundTaskAllowFail, includeCalculated = true) => {
	const siteId = CurrentSiteIdGet();

	return await readingsLatestForSite(siteId, groupIds, backgroundTaskAllowFail, includeCalculated);
};

export const readingsInAlarm = async () => {
	const siteId = CurrentSiteIdGet();

	if (!siteId) {
		return [];
	}

	try {
		const result = await axios({
			method: "get",
			url: "v2/Readings/InAlarm",
			params: {
				SiteId: siteId,
			},
			headers: {
				backgroundTaskAllowFail: true,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const readingsLatestForLocation = async (locationId) => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "post",
			url: "v2/Readings/Latest",
			data: {
				SiteId: siteId,
				locationId: locationId,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const siteRefreshValuesRequest = async (groupIds = [], locationIds = []) => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "post",
			url: "v2/Site/RefreshValues",
			data: {
				siteId,
				groupIds,
				locationIds,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const siteRefreshValuesRequestOutstanding = async () => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "get",
			url: "v2/Site/OutstandingRefreshValues",
			params: {
				siteId,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

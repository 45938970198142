import axios from "axios";
import { CurrentSiteIdGet } from "./userSettings";

export const noteAdd = async (id, zoneId, groupId, graphIndex, noteText, initials, x, y, y2, leaderX, leaderY, leaderY2, yType, onAllCharts, buildingId = null) => {
	const siteId = CurrentSiteIdGet();
	const rightAxis = yType === "1";

	if (!leaderX) {
		leaderX = x;
	}
	if (!leaderY) {
		leaderY = y;
	}
	if (!leaderY2) {
		leaderY2 = y2;
	}

	try {
		const result = await axios({
			method: "post",
			url: "v2/Note",
			data: {
				SiteId: siteId,
				Note: {
					id,
					zoneId,
					groupId,
					graphIndex,
					Text: `${noteText} - ${initials}`,
					LeaderX: leaderX,
					LeaderY: rightAxis ? leaderY2 : leaderY,
					TextX: x,
					TextY: rightAxis ? y2 : y,
					onAllCharts,
					rightAxis,
					buildingId,
				},
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const noteDelete = async (id) => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "delete",
			url: "v2/Note",
			data: {
				SiteId: siteId,
				Id: id,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const noteUpdate = async (id, noteText) => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "patch",
			url: "v2/Note",
			data: {
				SiteId: siteId,
				Id: id,
				Text: noteText,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const noteList = async () => {
	const siteId = CurrentSiteIdGet();

	try {
		const result = await axios({
			method: "get",
			url: "v2/Notes",
			params: {
				SiteId: siteId,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

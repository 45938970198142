import React, { useState, useEffect } from "react";
import { AvForm, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import { Label, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import local from "../../localization/strings";
import "react-input-range/lib/css/index.css";
import moment from "moment";
import { noteAdd } from "../../api/note";
import { logEvent } from "../../helpers/ga";
import { useContext } from "react";
import { UserPreferencesContext } from "../../context/userPreferences";

const ChartNote = ({ show, action, notePoint, options, groupId, graphIndex }) => {
	const [open, setOpen] = useState(show);
	const [model, setModel] = useState({});
	const { getBuildingIdForGroupId } = useContext(UserPreferencesContext);

	useEffect(() => {
		var newModel = {
			xValue: moment(notePoint.x).format("DD MMM YY HH:mm"),
			yType: notePoint.y ? "0" : "1",
			onAllCharts: 0,
		};

		setModel(newModel);

		setOpen(show);
	}, [show, notePoint]);

	async function save(_e, values) {
		logEvent("Zone Charts", "Note Added", `GroupId: ${groupId}, GraphIndex: ${graphIndex}, YType: ${values.yType}`);

		let buildingId = null;
		if (values.onAllCharts) {
			buildingId = getBuildingIdForGroupId(groupId);
			await noteAdd(-1, null, null, null, values.noteText, values.initials, moment(notePoint.x).format("YYYY-MM-DDTHH:mm:00"), notePoint.y, notePoint.y2, moment(notePoint.leaderX).format("YYYY-MM-DDTHH:mm:00"), notePoint.leaderY, notePoint.leaderY2, values.yType, values.onAllCharts, buildingId);
		} else {
			await noteAdd(-1, null, groupId, graphIndex, values.noteText, values.initials, moment(notePoint.x).format("YYYY-MM-DDTHH:mm:00"), notePoint.y, notePoint.y2, moment(notePoint.leaderX).format("YYYY-MM-DDTHH:mm:00"), notePoint.leaderY, notePoint.leaderY2, values.yType, values.onAllCharts, null);
		}

		const text = `${values.noteText} - ${values.initials}`;
		const xValue = notePoint.x;
		const yValue = values.yType === "0" ? notePoint.y : notePoint.y2;

		action("SaveAddNote", { text, yType: values.yType, xValue, yValue });
	}

	return (
		<Modal isOpen={open} toggle={() => setOpen(false)} centered backdrop="static">
			<ModalHeader>
				<span className="float-left">{local.TF_Add_a_note}</span>
				<span className="float-right close-modal" style={{ position: "absolute", top: 15, right: 20 }} onClick={() => action("CancelAddNote")}>
					X
				</span>
			</ModalHeader>
			<ModalBody>
				<AvForm model={model} onValidSubmit={async (e, values) => await save(e, values)}>
					<AvGroup>
						<Label for="noteText">{local.TF_NoteText}</Label>
						<AvInput name="noteText" id="noteText" type="textarea" required />
						<AvFeedback>{local.TS_Required}</AvFeedback>
					</AvGroup>
					<AvGroup>
						<Label for="initials">{local.TF_Initial}</Label>
						<AvInput name="initials" id="initials" className="col-3" required />
						<AvFeedback>{local.TS_Required}</AvFeedback>
					</AvGroup>
					<AvGroup>
						<Label for="xValue">{local.TS_Date_Time}</Label>
						<AvInput name="xValue" id="xValue" disabled />
					</AvGroup>

					{(options.axisY || options.axisY2) && (
						<AvRadioGroup inline name="yType" label={local.TS_Axis}>
							{!isNaN(notePoint.y) && notePoint.y && <AvRadio label={`${options.axisY.title}`} value="0" />}
							{!isNaN(notePoint.y2) && notePoint.y2 && <AvRadio label={`${options.axisY2.title}`} value="1" />}
						</AvRadioGroup>
					)}

					<AvRadioGroup inline name="onAllCharts" label={local.TF_Entire_Building}>
						<AvRadio label={local.TS_No} value={0} />
						<AvRadio label={local.TS_Yes} value={1} />
					</AvRadioGroup>

					<hr className="mx-n3" />
					<Button color="primary">{local.TS_Save}</Button>
					<Button className="ml-2" onClick={() => action("CancelAddNote")}>
						{local.TS_Cancel}
					</Button>
				</AvForm>
			</ModalBody>
		</Modal>
	);
};

export default ChartNote;

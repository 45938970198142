import React, { useEffect, useState, Fragment } from "react";
import { Card, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { buildingsGetList } from "../../api/building";
import Loader from "../common/Loader";

const SiteLayoutBuildings = ({ selected, setCurrentBuilding, version }) => {
	const [buildings, setBuildings] = useState([]);
	const [showLoading, setShowLoading] = useState(true);
	const [currentVersion, setCurrentVersion] = useState(0);

	useEffect(() => {
		async function loadBuildings() {
			var data = await buildingsGetList();

			setBuildings(data);

			setShowLoading(false);
		}

		if (version !== currentVersion) {
			loadBuildings();
			setCurrentVersion(version);
		}
	}, [version, currentVersion]);

	const buildingsMap = buildings.map((building, i) => (
		<Col key={i} id={building.id} className="align-items-stretch mb-3 text-center">
			<div onClick={() => setCurrentBuilding(building.id)}>
				<span className={building.id === selected ? "is-selected card-img-top building-icon" : "card-img-top building-icon"} alt={building.name}>
					<FontAwesomeIcon icon="warehouse" />
				</span>
				<h5 className="building-title text-center mt-n3">{building.name}</h5>
			</div>
		</Col>
	));

	return (
		<Fragment>
			<Card className="p-3">
				<Row>
					{showLoading ? (
						<div className="ml-2">
							<Loader />
						</div>
					) : (
						buildingsMap
					)}
				</Row>
			</Card>
		</Fragment>
	);
};

export default SiteLayoutBuildings;
